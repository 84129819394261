import React from 'react'
import { connect } from 'react-redux'
import { DropDownTreeComponent } from '@syncfusion/ej2-react-dropdowns'
import '@syncfusion/ej2-react-dropdowns/styles/material.css'

import { State, Dispatch } from '../reducers'
import { setPosition, MapPosition } from '../reducers/map'
interface ViewFilterListItem {
  country?: string | null
  merchant_id?: string
  merchantType?: string
  merchant_name?: string
  filterText?: string | null
  level?: string
  ecommerce?: boolean
}

interface ViewFilterSet {
  fields: {}[]
  token: string | null
  tenant_id: string | null
  values: string[]
  setPosition?: (position: MapPosition) => void
}

export class ViewFilterComponent extends React.Component<
  ViewFilterSet,
  { values: string[]; flag: boolean }
> {
  dropdowntreeObj: React.RefObject<DropDownTreeComponent>

  constructor(props: ViewFilterSet) {
    super(props)
    this.dropdowntreeObj = React.createRef<DropDownTreeComponent>()
    this.onChange = this.onChange.bind(this)
    this.state = {
      values: this.props.values,
      flag: false
    }
  }

  private onChange(e: any): void {
    if (!this.props.setPosition || this.state.flag) return

    let position: MapPosition = {}
    let global = false
    let country: string = ''
    let countries: any[] = []
    let merchantIds: any[] = []
    let merchantTypes: any[] = []

    if (e.value === null || e.value.length == this.props.fields.length)
      position = { global: true }
    else {
      let results: any[] = []
      e.value.forEach((item: any) => {
        const result: any[] = this.props.fields.filter(
          (field: any) => field.id == item
        )
        if (result && result.length > 0) {
          results.push(result[0])
        }
      })

      let globalRslt = results.filter((result: any) => result.level == 'global')
      if (globalRslt.length > 0) {
        global = true
      }

      if (!global) {
        let rslt = results.filter(
          (result: any) => result.level == 'merchantType'
        )
        if (rslt.length > 0) {
          var merchants = false
          merchantTypes = rslt.map((item: any) => item.merchantName)
          let rslt1 = results.filter(
            (result: any) => result.level == 'merchants'
          )
          if (rslt1 && rslt1.length) {
            merchants = true
          }

          rslt1 = results.filter((result: any) => result.level == 'country')
          if (rslt1 && rslt1.length > 0) {
            countries = rslt1.map((item: any) => item.name)
          }

          rslt.forEach((merchantTypes: any) => {
            rslt1 = results.filter(
              (result: any) =>
                result.level == 'merchants' &&
                result.merchant_name == merchantTypes.merchantName &&
                result.ecommerce == merchantTypes.ecommerce
            )
            if (rslt1 && rslt1.length > 0) {
              const tmp = rslt1.map((item: any) => item.merchant_id)
              merchantIds = merchantIds.concat(tmp)
            }
          })

          if (countries.length == 0 && merchantIds.length == 0) {
            // disabled merchants == false for Nike + individual locations
            rslt.forEach((merchantTypes: any) => {
              rslt1 = this.props.fields.filter(
                (field: any) =>
                  field.level == 'merchants' &&
                  field.merchant_name == merchantTypes.merchantName &&
                  field.ecommerce == merchantTypes.ecommerce
              )
              if (rslt1 && rslt1.length > 0) {
                const tmp = rslt1.map((item: any) => item.merchant_id)
                merchantIds = merchantIds.concat(tmp)
              }
            })
          }

          // Nike + individual locations (belong to another partner like Footlocker) - Filter all locations that belong to Nike & individual locations that belong to Footlocker
          rslt1 = results.filter((result: any) => result.level == 'merchants')
          if (rslt1 && rslt1.length > 0) {
            const tmp = rslt1.map((item: any) => item.merchant_id)
            merchantIds = merchantIds.concat(tmp)
          }
        } else {
          let rslt1 = results.filter((result: any) => result.level == 'country')
          if (rslt1.length > 0) {
            countries = rslt1.map((item: any) => item.name)
          }

          rslt1 = results.filter((result: any) => result.level == 'merchants')
          if (rslt1.length > 0) {
            const tmp = rslt1.map((item: any) => item.merchant_id)
            merchantIds = merchantIds.concat(tmp)
          }

          if (countries.length == 1) {
            country = countries[0]
          } else if (rslt1.length == 1) {
            country = rslt1[0].country
          }
        }

        // duplicate itmes
        merchantIds = Array.from(new Set(merchantIds))
        merchantTypes = Array.from(new Set(merchantTypes))
        countries = Array.from(new Set(countries))
      }
    }

    if (global) {
      position = { global: true }
    } else {
      position = {
        merchantTypes,
        countries,
        country,
        merchantIds
      }
    }

    this.props.setPosition(position)
    this.setState({ flag: true })
  }

  componentDidUpdate(prevProps: any) {
    // Compare if the data source has changed
    if (prevProps.fields !== this.props.fields) {
      // Close the popup
      this.dropdowntreeObj.current?.hidePopup()
      this.setState({ flag: false })
    }
  }

  public render(): JSX.Element {
    let fields: Object = {
      dataSource: this.props.fields,
      value: 'id',
      text: 'name',
      parentValue: 'pid',
      hasChildren: 'hasChild',
      selected: 'selected'
    }
    let treeSettings: Object = { autoCheck: true }

    return (
      <div style={{ width: '350px' }}>
        <DropDownTreeComponent
          id="dropdowntree"
          fields={fields}
          showCheckBox={true}
          treeSettings={treeSettings}
          showDropDownIcon={true}
          showClearButton={true}
          value={this.props.values}
          ref={this.dropdowntreeObj}
          mode="Delimiter"
          // select={this.onSelect.bind(this)}
          change={e => this.onChange(e)}
        />
      </div>
    )
  }
}

const mapStateToProps = (state: State): ViewFilterSet => {
  const token = state.userState.token
  const tenant_id = state.userState.tenant_id
  const data = state.mapState.geojson
  const locations = state.mapState.locations
  const regions = state.constraintsState.regions
  const position = state.mapState.position
  const merchants = state.mapState.merchants
  const data1 = data.features.map(
    (feature: GeoJSON.Feature): ViewFilterListItem => {
      const result: any = locations.filter(
        (location: any) =>
          location.merchantId == feature.properties?.merchant_id
      )
      return {
        ...feature.properties,
        merchant_name: result[0].merchantName,
        ecommerce: result[0].ecommerce
      }
    }
  )

  let fields: { [key: string]: Object }[] = []
  let index = 0

  fields.push({
    id: index,
    level: 'global',
    name: 'Global',
    selected: false
  })

  let uniqueMerchants = merchants.reduce((unique: any, o: any) => {
    if (!unique.some((obj: any) => obj.merchantName === o.merchantName)) {
      unique.push(o)
    }
    return unique
  }, [])

  const addMerchantsToData = (
    merchants1: any,
    merchantName: string,
    parentId: number
  ) => {
    merchants1.forEach((merchant: any) => {
      let oneMerchantId = index + 1

      if (merchant.merchantType != 'own_brand') {
        fields.push({
          id: ++index,
          pid: parentId,
          name: merchant.merchantName,
          hasChild: true,
          expanded: true,
          level: 'merchantType',
          merchantName: merchant.merchantName,
          selected: false
        })
      } else {
        oneMerchantId = index
      }

      ;['Online', 'Retail'].forEach((type: string) => {
        let filteredMerchants: any = merchants.filter(
          (m: any) =>
            m.merchantName === merchant.merchantName &&
            m.ecommerce === (type === 'Online')
        )
        if (filteredMerchants.length > 0) {
          fields.push({
            id: ++index,
            pid: oneMerchantId,
            name: type,
            level: 'merchantType',
            ecommerce: filteredMerchants[0].ecommerce,
            merchantName: merchant.merchantName,
            selected: false
          })
        }
      })
    })
  }
  ;['own_brand', 'wholesale_partner'].forEach((type: string, i: number) => {
    let filteredMerchants = uniqueMerchants.filter(
      (merchant: any) => merchant.merchantType === type
    )
    if (filteredMerchants.length > 0) {
      const parentId = index + 1

      fields.push({
        id: ++index,
        name: i === 0 ? filteredMerchants[0].merchantName : 'Partners',
        hasChild: true,
        expanded: true,
        merchantName: i === 0 ? filteredMerchants[0].merchantName : null,
        level: i === 0 ? 'merchantType' : 'Partners',
        selected: false
      })

      addMerchantsToData(filteredMerchants, type, parentId)
    }
  })

  const countriesId = index + 1

  fields.push({
    id: ++index,
    name: 'Countries',
    hasChild: true,
    expanded: true,
    selected: false,
    level: 'countries'
  })

  regions.forEach((region: any) => {
    const countryId = index + 1
    fields.push({
      id: ++index,
      pid: countriesId,
      name: region.country,
      hasChild: true,
      expanded: true,
      level: 'country',
      selected: false
    })

    data1.forEach((field: any) => {
      if (field.country == region.country) {
        fields.push({
          id: ++index,
          pid: countryId,
          name: field.address,
          merchant_id: field.merchant_id,
          merchant_name: field.merchant_name,
          merchant_type: field.merchant_type,
          ecommerce: field.ecommerce,
          level: 'merchants',
          country: field.country,
          selected: false
        })
      }
    })
  })

  let values: string[] = []

  if (position.global) {
    values.push('0')
  } else {
    if (position.country && position.merchantIds) {
      position.merchantIds.forEach(merchantId => {
        const item = fields.find(
          (item: any): {} => item.merchant_id === merchantId
        )
        if (item?.id) {
          values.push(item.id.toString())
        }
      })
    }
  }

  return { fields, token, tenant_id, values }
}

interface DispatchFromProps {
  setPosition: (position: MapPosition) => void
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  setPosition: (position): void => {
    dispatch(setPosition(position))
  }
})

export const ViewFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewFilterComponent)
