import React from 'react'
import { connect } from 'react-redux'
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars'
import '@syncfusion/ej2-react-calendars/styles/material.css'

import { State, Dispatch } from '../reducers'
import { DatesRange, setDateRange } from '../reducers/constraints'
import { getDataByRange } from '../sagas'
import { SFWrapper } from './charts/SampleBase'
import { createSelector } from 'reselect'

interface DatesRangeComponent extends DatesRange {
  setDateRange: (dates: Date[]) => void
}

export const DateRangePickerFC = ({
  startDate,
  endDate,
  setDateRange
}: DatesRangeComponent): JSX.Element => (
  <div style={{ width: '200px' }}>
    <DateRangePickerComponent
      change={({ value }: any): void => setDateRange(value)}
      startDate={startDate}
      endDate={endDate}
      id="datepicker"
      placeholder="Enter date"
    />
  </div>
)

interface DateRangeProps {
  startDate: Date
  endDate: Date
}

const mapStateToProps = createSelector(
  (state: State): DateRangeProps['startDate'] => state.constraintsState.startDate,
  (state: State): DateRangeProps['endDate'] => state.constraintsState.endDate,
  (
    startDate,
    endDate
  ): DateRangeProps => ({
    startDate,
    endDate
  })
)

interface DispatchFromProps {
  setDateRange: (dates: Date[]) => void
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  setDateRange: (dates): void => {
    if (dates) {
      dispatch(setDateRange({ startDate: dates[0], endDate: dates[1] }))
      dispatch(getDataByRange())
    }
  }
})

export const DateRangePicker = connect(
  mapStateToProps,
  mapDispatchToProps
)(DateRangePickerFC)
