import React, { useEffect, useState } from 'react'
import { Paper, Grid, Box, Typography, CircularProgress } from '@mui/material'
import { StyleRules, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import Chart from 'react-apexcharts'
import { getInvestments } from '../../api'
import { DateTime } from 'luxon'

interface BankItem {
  bank_name: string
  current: number
  previous: number
}

interface HistoricalPortfolioItem {
  date: string
  nff: number
  sri: number
  nffsri: number
}

interface GrandTotal {
  current: number
  current_percentage: number
  previous: number
  previous_percentage: number
}
interface InvestmentType {
  details: BankItem[]
  historical_portfolio: HistoricalPortfolioItem[]
  grand_total: GrandTotal
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '35px 25px',
      marginTop: '10px',
      overflow: 'hidden'
    },
    box: {
      display: 'flex'
    },
    boxItem: {
      flex: 1,
      '&:first-child': {
        marginRight: '30px'
      }
    },
    caption: {
      textTransform: 'uppercase',
      color: theme.palette.text.primary
    },
    tableBox: {},
    tableRow: {
      display: 'flex',
      borderBottom: '1px solid #D9D9D9',
      paddingTop: '10px',
      paddingBottom: '10px'
    },
    tableRowItem: {
      flex: 1,
      fontSize: '14px'
    }
  })
)

const Investments = ({ title, token }: any) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<InvestmentType>()
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})

  const setDefaultOptions = (mapped: any[]) => {
    setOptions({
      ...options,
      chart: {
        zoom: { enabled: false },
        toolbar: { show: false }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      tooltip: {
        y: {
          formatter: (val: number) => {
            return val.toLocaleString('en-US', {
              style: 'currency',
              currency: currencyCode
            })
          }
        }
      },
      xaxis: {
        categories: mapped && mapped.length > 0 ? mapped : []
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            //@ts-ignore
            return Intl.NumberFormat('en-US', {
              //@ts-ignore
              notation: 'compact',
              compactDisplay: 'short'
            }).format(val)
          }
        }
      },
      fill: {
        type: 'solid'
      }
    })
  }

  useEffect((): void => {
    if (!token) return
    const callInvestments = async (): Promise<any> => {
      const result: any = await getInvestments({
        token
      })
      let categories: any[] = []
      let nffData: any[] = []
      let sriData: any[] = []
      let nffsriData: any[] = []

      result?.historical_portfolio.forEach((element: any) => {
        categories.push(
          DateTime.fromISO(element.date)
            .toFormat('LLL yyyy')
            .toUpperCase()
        )
        nffData.push(element.nff)
        sriData.push(element.sri)
        nffsriData.push(element.nffsri)
      })
      setDefaultOptions(categories)
      setSeries([
        {
          name: '% Invested in NFF and SRI (RHS)',
          data: nffsriData
        },
        {
          name: '% Invested in NFF (RHS)',
          data: nffData
        },
        {
          name: '% Invested in SRI (RHS)',
          data: sriData
        }
      ])
      setData(result)
    }
    callInvestments()
  }, [token])

  const currencyCode = 'USD'

  const renderTable = () => {
    return (
      <Box className={classes.tableBox}>
        <Box className={classes.tableRow}>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            ADI Lending Status
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            Current Month
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            Previous Month
          </Typography>
        </Box>
        <Box className={classes.tableRow}>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold', textWrap: 'nowrap' }}
          >
            Fossil Fuels Lending ADIs
          </Typography>
          <Box className={classes.tableRowItem}></Box>
          <Box className={classes.tableRowItem}></Box>
        </Box>
        {data?.details?.map((item, index) => (
          <Box className={classes.tableRow} key={index}>
            <Typography
              className={classes.tableRowItem}
              sx={{
                textWrap: 'nowrap'
              }}
            >
              {item?.bank_name}
            </Typography>
            <Typography className={classes.tableRowItem}>
              {item?.current?.toLocaleString(undefined, {
                style: 'currency',
                currency: currencyCode
              })}
            </Typography>
            <Typography className={classes.tableRowItem}>
              {item.previous.toLocaleString(undefined, {
                style: 'currency',
                currency: currencyCode
              })}
            </Typography>
          </Box>
        ))}
        <Box className={classes.tableRow}>
          <Typography
            className={classes.tableRowItem}
            sx={{
              fontWeight: 'bold',
              color: '#999999',
              textAlign: 'right',
              textTransform: 'uppercase'
            }}
          >
            Total&nbsp;&nbsp;&nbsp;
          </Typography>
          <Box
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold', textWrap: 'nowrap' }}
          >
            {data?.grand_total?.current?.toLocaleString(undefined, {
              style: 'currency',
              currency: currencyCode
            })}
            &nbsp;&nbsp;&nbsp; {data?.grand_total?.current_percentage}%
          </Box>
          <Box
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold', textWrap: 'nowrap' }}
          >
            {data?.grand_total?.previous?.toLocaleString(undefined, {
              style: 'currency',
              currency: currencyCode
            })}
            &nbsp;&nbsp;&nbsp; {data?.grand_total?.previous_percentage}%
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Grid item xs={12} sm={12}>
      <Paper className={classes.paper} elevation={1}>
        <Typography
          variant="subtitle2"
          component="h3"
          className={classes.caption}
        >
          {title}
          {isLoading && (
            <CircularProgress
              className={classes.progress}
              size={15}
              color={'secondary'}
            />
          )}
        </Typography>
        <Box className={classes.box}>
          <Box className={classes.boxItem}>
            <Typography sx={{ textAlign: 'center', fontSize: '14px' }}>
              Current Breakdown
            </Typography>
            {renderTable()}
          </Box>
          <Box className={classes.boxItem}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              Historical Portfolio Exposure to NFF Lending ADIs and SRIs
            </Typography>
            <Chart
              options={options}
              series={series}
              type={'line'}
              height={350}
            />
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
}

export default Investments
