import React, { useEffect, useState } from 'react'
import {
  Paper,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Link
} from '@mui/material'
import { StyleRules, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '35px 25px',
      marginTop: '10px',
      overflow: 'hidden'
    },
    box: {
      display: 'flex',
      flexDirection: 'column'
    },
    boxItem: {
      flex: 1
    },
    caption: {
      textTransform: 'uppercase',
      color: theme.palette.text.primary,
      marginBottom: '10px'
    }
  })
)

type NewsType = {
  title: string | null
  link: string | null
}

const ForexNews = ({ title }: any) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<NewsType[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('https://www.benzinga.com/news/feed')
      const textData = await response.text()

      var parser = new DOMParser()
      var xmlDoc = parser.parseFromString(textData, 'text/xml')

      var items = xmlDoc.getElementsByTagName('item')
      var parsedItems = []

      for (let i = 0; i < items.length; i++) {
        let title = items[i].getElementsByTagName('title')[0].childNodes[0]
          .nodeValue
        let link = items[i].getElementsByTagName('link')[0].childNodes[0]
          .nodeValue

        parsedItems.push({ title, link })
      }

      setData(parsedItems)
    }

    fetchData()

    const intervalId = setInterval(fetchData, 30 * 60 * 1000)

    return () => clearInterval(intervalId)
  }, [])

  const renderTable = () => {
    return (
      <Box className={classes.box}>
        {data?.slice(0, 8)?.map((item: NewsType, index) => (
          <Link
            href={item?.link ?? ''}
            underline="none"
            key={index}
            target="_blank"
            rel="noopener"
            sx={{
              fontSize: '14px',
              '&:hover': {
                color: 'blue',
                textDecoration: 'underline'
              }
            }}
          >
            {item.title}
          </Link>
        ))}
      </Box>
    )
  }

  return (
    <Grid item xs={12} sm={12}>
      <Paper className={classes.paper} elevation={1}>
        <Typography
          variant="subtitle2"
          component="h3"
          className={classes.caption}
        >
          {title}
          {isLoading && (
            <CircularProgress
              className={classes.progress}
              size={15}
              color={'secondary'}
            />
          )}
        </Typography>
        {renderTable()}
      </Paper>
    </Grid>
  )
}

export default ForexNews
