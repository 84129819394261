import { DateTime } from 'luxon'

import { LocationTotal30mItemSource } from '../utils'
import { number } from '@storybook/addon-knobs'

export interface RegionTotal {
  country?: string | null
  currency?: string | null
  gross?: number | null
  tax?: number | null
  net?: number | null
  fee_total?: number | null
  txn_count?: number | null
  refunds?: number | null
  grossTrend?: number | null
  taxTrend?: number | null
  netTrend?: number | null
  feesTrend?: number | null
  countTrend?: number | null
  refundsTrend?: number | null
  id?: number | null
  lastPeriodTotals?: {
    gross: number
    tax: number
    net: number
    fee_total: number
    refundsCount: number
    refunds: number
    txn_count: number
  }
}

export interface Location {
  country?: string
  merchantId?: string
}
const LOCATIONS = 'locations'
export interface CompareLocations {
  mode: typeof LOCATIONS
  locations: Location[]
}

export interface LastWeek {
  daily?: {
    merchants: {
      hits: LocationTotal30mItemSource[]
      avg: LocationTotal30mItemSource[]
      maxGross: number
      minGross: number
    }
    regions: {
      hits: LocationTotal30mItemSource[]
      avg: LocationTotal30mItemSource[]
      maxGross: number
      minGross: number
    }
  }
  hourly?: {
    merchants: {
      hits: LocationTotal30mItemSource[]
      maxGross: number
      minGross: number
    }
    regions: {
      hits: LocationTotal30mItemSource[]
      maxGross: number
      minGross: number
    }
  }
}

const DATES = 'dates'
interface CompareDates {
  mode: typeof DATES
  location: Location
  dates: {
    start: Date
    end: Date
  }[]
}
const NULL = null
interface Inactive {
  mode: typeof NULL
}

export type Compare = CompareLocations | Inactive

export const SET_COMPARE = 'SET_COMPARE'
export interface SetCompare {
  type: typeof SET_COMPARE
  payload: Compare
}

export const setCompare = (payload: Compare): SetCompare => ({
  type: SET_COMPARE,
  payload
})

export const SET_DAILY = 'SET_DAILY'
export interface SetDaily {
  type: typeof SET_DAILY
  payload: LastWeek['daily']
}

export const setDaily = (payload: LastWeek['daily']): SetDaily => ({
  type: SET_DAILY,
  payload
})

export const SET_HOURLY = 'SET_HOURLY'
export interface SetHourly {
  type: typeof SET_HOURLY
  payload: LastWeek['hourly']
}

export const setHourly = (payload: LastWeek['hourly']): SetHourly => ({
  type: SET_HOURLY,
  payload
})

export const ADD_COMPARE_LOCATION = 'ADD_COMPARE_LOCATION'
export interface AddCompareLocation {
  type: typeof ADD_COMPARE_LOCATION
  payload: Location
}

export const addCompareLocation = (payload: Location): AddCompareLocation => ({
  type: ADD_COMPARE_LOCATION,
  payload
})
export const REMOVE_COMPARE_LOCATION = 'REMOVE_COMPARE_LOCATION'
export interface RemoveCompareLocation {
  type: typeof REMOVE_COMPARE_LOCATION
  payload: number
}

export const removeCompareLocation = (
  payload: number
): RemoveCompareLocation => ({
  type: REMOVE_COMPARE_LOCATION,
  payload
})

export interface BaseRates {
  [name: string]: number
}

export interface ConstraintsState {
  maxGross: number | null
  minGross: number | null
  startDate: Date
  endDate: Date
  absGross: number | null
  regions: RegionTotal[]
  global: RegionTotal
  region: RegionTotal | null
  merchant: string | null
  compare: Compare
  lastWeek: LastWeek | null
  baseRates: BaseRates
}

export interface ConstraintsUpdate {
  maxGross?: number | null
  minGross?: number | null
  global?: RegionTotal
  regions?: RegionTotal[]
  region?: RegionTotal | null
  merchant?: string | null
}
export interface TrendsUpdate {
  grossTrend?: number | null
  taxTrend?: number | null
  netTrend?: number | null
  feesTrend?: number | null
  countTrend?: number | null
  refundsTrend?: number | null
}

export const SET_CONSTRAINTS = 'SET_CONSTRAINTS'
export interface SetConstraints {
  type: typeof SET_CONSTRAINTS
  payload: ConstraintsUpdate
}

export const setConstraints = (payload: ConstraintsUpdate): SetConstraints => ({
  type: SET_CONSTRAINTS,
  payload
})
export const SET_GLOBAL_TRENDS = 'SET_GLOBAL_TRENDS'
export interface SetGlobalTrends {
  type: typeof SET_GLOBAL_TRENDS
  payload: TrendsUpdate
}

export const setGlobalTrends = (payload: TrendsUpdate): SetGlobalTrends => ({
  type: SET_GLOBAL_TRENDS,
  payload
})

export interface ConstraintsAndTrendsUpdate {
  constraints: ConstraintsUpdate
  trends: TrendsUpdate
  baseRates: BaseRates
}
export const SET_GLOBAL_CONSTRAINTS_AND_TRENDS =
  'SET_GLOBAL_CONSTRAINTS_AND_TRENDS'
export interface SetGlobalConstraintsAndTrends {
  type: typeof SET_GLOBAL_CONSTRAINTS_AND_TRENDS
  payload: ConstraintsAndTrendsUpdate
}

export const setConstraintsAndTrends = (
  payload: ConstraintsAndTrendsUpdate
): SetGlobalConstraintsAndTrends => ({
  type: SET_GLOBAL_CONSTRAINTS_AND_TRENDS,
  payload
})

export interface DatesRange {
  startDate?: Date
  endDate?: Date
}

export const SET_DATE_RANGE = 'SET_DATE_RANGE'
export interface SetDateRange {
  type: typeof SET_DATE_RANGE
  payload: DatesRange
}

export const setDateRange = (payload: DatesRange): SetDateRange => {
  return {
    type: SET_DATE_RANGE,
    payload
  }
}

export const SET_BASE_RATES = 'SET_BASE_RATES'
export interface SetBaseRates {
  type: typeof SET_BASE_RATES
  payload: BaseRates
}

export const setBaseRates = (payload: BaseRates): SetBaseRates => {
  return {
    type: SET_BASE_RATES,
    payload
  }
}

export const constraintsReducer = (
  state: ConstraintsState = {
    maxGross: null,
    minGross: null,
    startDate: DateTime.utc()
      .startOf('day')
      .minus({ days: 6 })
      .toJSDate(),
    endDate: DateTime.utc()
      .endOf('day')
      .toJSDate(),
    absGross: null,
    regions: [],
    global: {},
    region: null,
    merchant: null,
    compare: { mode: null },
    lastWeek: null,
    baseRates: { USD: 1 }
  },
  action:
    | SetConstraints
    | SetDateRange
    | SetGlobalTrends
    | SetCompare
    | AddCompareLocation
    | RemoveCompareLocation
    | SetDaily
    | SetHourly
    | SetBaseRates
    | SetGlobalConstraintsAndTrends
): ConstraintsState => {
  switch (action.type) {
    case SET_DATE_RANGE:
      if (!action.payload.startDate || !action.payload.endDate) return state
      return {
        ...state,
        startDate: DateTime.fromJSDate(action.payload.startDate)
          .startOf('day')
          .toJSDate(),
        endDate: DateTime.fromJSDate(action.payload.endDate)
          .endOf('day')
          .toJSDate()
      }
    case SET_CONSTRAINTS:
      return {
        ...state,
        ...action.payload,
        absGross: action.payload.maxGross
          ? Math.max(
              Math.abs(action.payload.maxGross || 0),
              Math.abs(action.payload.minGross || 0)
            )
          : state.absGross
      }
    case SET_GLOBAL_TRENDS:
      return {
        ...state,
        global: { ...state.global, ...action.payload }
      }
    case SET_GLOBAL_CONSTRAINTS_AND_TRENDS:
      return {
        ...state,
        ...action.payload.constraints,
        absGross: action.payload.constraints.maxGross
          ? Math.max(
              Math.abs(action.payload.constraints.maxGross || 0),
              Math.abs(action.payload.constraints.minGross || 0)
            )
          : state.absGross,
        global: {
          ...state.global,
          ...action.payload.constraints.global,
          ...action.payload.trends
        },
        baseRates: { ...action.payload.baseRates }
      }
    case SET_COMPARE:
      return {
        ...state,
        compare: { ...action.payload }
      }
    case ADD_COMPARE_LOCATION:
      if (state.compare.mode === LOCATIONS) {
        return {
          ...state,
          compare: {
            ...state.compare,
            locations: [...state.compare.locations, action.payload]
          }
        }
      } else {
        return state
      }
    case REMOVE_COMPARE_LOCATION:
      if (state.compare.mode === LOCATIONS) {
        return {
          ...state,
          compare: {
            ...state.compare,
            locations: [
              ...state.compare.locations.filter(
                (i, index): boolean => index !== action.payload
              )
            ]
          }
        }
      } else {
        return state
      }
    case SET_DAILY:
      return {
        ...state,
        lastWeek: {
          ...state.lastWeek,
          daily: action.payload
        }
      }
    case SET_HOURLY:
      return {
        ...state,
        lastWeek: {
          ...state.lastWeek,
          hourly: action.payload
        }
      }
    case SET_BASE_RATES:
      return {
        ...state,
        baseRates: { ...action.payload }
      }
    default:
      return state
  }
}
