import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import MaterialTable, { Column } from 'material-table'
import { StyleRules, createStyles, makeStyles } from '@mui/styles'

import { State } from '../../reducers'
import { editFees, addFees, removeFees } from '../../api'
import { getCountries, Country } from '../../apis/GetCountries'
import { mapCountryToCurrency } from '../../temp/currencies_map'
import { createSelector } from 'reselect'

const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      row: {
        flexGrow: 1,
        backgroundColor: '#f1f1f1',
        display: 'flex',
        height: 224
      }
    })
)
interface FeesProps {
  token: string
  tenant_id: string
}

const mapState = createSelector(
  (state: State): FeesProps['token'] => state.userState.token || '',
  (state: State): FeesProps['tenant_id'] => state.userState.tenant_id || '',
  (
    token,
    tenant_id,
  ): FeesProps => ({
    token,
    tenant_id,
  })
)

interface Row {
  countryId: string
  name: string
  paymentTypeId: string
  currencyId: string
  amount: number
  percent: number
  description: string
}

interface MapCountries {
  [id: string]: string
}
export function Fees(): JSX.Element {
  const classes = useStyles()
  const { token, tenant_id } = useSelector(mapState)
  const [data, setData] = useState<Row[]>([])
  const [cLookup, setCLookup] = useState<MapCountries>({})

  useEffect((): void => {
    const callCountries = async (): Promise<any> => {
      const countries: Country[] | null = await getCountries(token, tenant_id)
      const newData: Row[] = []
      if (countries) {
        const countryLookup: MapCountries = {}
        countries.forEach((country: Country): void => {
          const { id, name, currencyId, xoRate } = country
          country.feeInfos.forEach((fees): void => {
            newData.push({
              countryId: id,
              name,
              currencyId,
              paymentTypeId: fees.paymentTypeId,
              amount: fees.amount,
              percent: fees.percent,
              description: fees.description
            })
          })
          countryLookup[id] = name
        })
        setCLookup(countryLookup)
        setData(newData)
      }
    }
    callCountries()
  }, [token])
  const columns: Column<any>[] = [
    { field: 'countryId', hidden: true, searchable: true },
    {
      title: 'Country',
      field: 'name',
      editable: 'never',
      defaultGroupOrder: 0
    },
    {
      title: 'Country code',
      field: 'name',
      editable: 'onAdd',
      lookup: cLookup
    },
    { title: 'Currency', field: 'currencyId', editable: 'never' },
    { title: 'Payment Type', field: 'paymentTypeId', editable: 'onAdd' },
    // { title: 'XO Rate', field: 'xoRate', editable: 'always', type: 'numeric' },
    { title: 'Amount', field: 'amount', editable: 'always', type: 'numeric' },
    { title: 'Percent', field: 'percent', editable: 'always', type: 'numeric' },
    { title: 'Description', field: 'description', editable: 'always' }
  ]

  return (
    <MaterialTable
      isLoading={data.length === 0}
      columns={columns}
      data={data}
      options={{
        header: false,
        paging: false,
        showTitle: false,
        searchFieldAlignment: 'left',
        grouping: false,
        addRowPosition: 'first'
      }}
      editable={{
        onRowUpdate: async ({
          countryId,
          paymentTypeId,
          currencyId,
          amount,
          percent,
          description
        }: Row): Promise<void> => {
          const item = await editFees(token, {
            tenant_id : tenant_id,
            countryId,
            paymentTypeId,
            currencyId,
            amount,
            percent,
            description
          })
          if (item) {
            const itemToChange = data.find(
              (item): boolean =>
                item.countryId === countryId &&
                item.paymentTypeId === paymentTypeId
            )
            if (itemToChange) itemToChange.amount = amount
            if (itemToChange) itemToChange.percent = percent
            if (itemToChange) itemToChange.description = description
            setData([...data])
          }
        },
        onRowAdd: async ({
          amount,
          paymentTypeId,
          percent,
          description,
          name
        }: Row): Promise<void> => {
          const payload = {
            tenant_id : tenant_id,
            countryId: name,
            paymentTypeId,
            currencyId: mapCountryToCurrency[cLookup[name]] || 'USD',
            amount,
            percent,
            description
          }
          const item = await addFees(token, payload)
          if (item) {
            setData([
              ...data,
              {
                countryId: name,
                name: cLookup[name],
                paymentTypeId,
                currencyId: payload.currencyId,
                amount,
                percent,
                description
              }
            ])
          }
        },
        onRowDelete: async (row: Row): Promise<void> => {
          const { paymentTypeId, countryId, currencyId } = row
          const item = await removeFees(token, {
            tenant_id : tenant_id,
            countryId,
            paymentTypeId,
            currencyId
          })
          if (item) {
            const newData = [...data]
            newData.splice(newData.indexOf(row), 1)
            setData(newData)
          }
        }
      }}
    />
  )
}
