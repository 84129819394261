// @ts-nocheck - may need to be at the start of file
import { put, takeLatest, select } from 'redux-saga/effects'
import axios from '../apis/axiosInstance'
import { DateTime } from 'luxon'

import { State } from '../reducers'
import { setGeojson, setLocations } from '../reducers/map'
import {
  ConstraintsState,
  setConstraintsAndTrends
} from '../reducers/constraints'
import { parseMapOverview } from '../utils'
// import { callForData } from '../rawApi'
import { getMapOverview, ShapeMapOverview } from '../api'
import { setBaseCurrency } from '../reducers/user'
import { getCountries } from '../apis/GetCountries'

function* fetchDataRaw(): Generator {
  try {
    const token: string = yield select(
      (state: State): string | null => state.userState.token
    )
    const firm_ids: string[] = yield select(
      (state: State): string[] | null => state.userState.firm_ids
    )
    const {
      startDate,
      endDate
    }: { startDate: Date; endDate: Date } = yield select(
      (state: State): ConstraintsState => state.constraintsState
    )
    if (!token && startDate && endDate) return
    const getBaseCurrencyPromise = axios.get(
      '/geography/preferences/base_currency',
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    const getMapOverviewPromise = getMapOverview({
      range: { startDate, endDate },
      token,
      tenant_id: firm_ids && firm_ids.length > 0 ? firm_ids[0] : null
    })

    const getCountriesPromise = getCountries(
      token,
      firm_ids && firm_ids.length > 0 ? firm_ids[0] : null
    )

    // yield put(setConstraints({ maxGross, minGross, global, regions }))
    // yield put(setGlobalTrends(current.globalTrends))

    const baseCurrencyObj: any = yield getBaseCurrencyPromise
    yield put(setBaseCurrency({ baseCurrency: baseCurrencyObj.data.value }))

    const getRatesPromise = axios.get(
      `/currency-management/quotes/forex-bunch?basicCurrencyId=${
        baseCurrencyObj.data.value
      }&from=${DateTime.fromJSDate(
        endDate
      ).toISODate()}Z&to=${DateTime.fromJSDate(endDate).toISODate()}Z`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )

    const aggregatedRes: ShapeMapOverview = yield getMapOverviewPromise
    const current = parseMapOverview(aggregatedRes)

    const { geojson, maxGross, minGross, global, regions } = current
    yield put(setGeojson(geojson))

    const countries: any = yield getCountriesPromise
    
    let locations = countries.reduce((acc, item) => {
      return acc.concat(item.locations);
    }, []);
    yield put(setLocations(locations));

    const rates: any = yield getRatesPromise
    let baseRates
    if (rates.data.error) {
      baseRates = {
        [baseCurrencyObj.data.value]: 1
      }
    } else {
      baseRates = rates.data.items[0].rates
    }
    yield put(
      setConstraintsAndTrends({
        trends: current.globalTrends,
        baseRates,
        constraints: { maxGross, minGross, global, regions }
      })
    )
  } catch (e) {
    yield put({ type: 'FETCH_FAILED', message: e.message })
  }
}

interface GetDataByRangePayload {
  dates: Date[]
}

export const GET_DATA_BY_RANGE = 'GET_DATA_BY_RANGE'
export interface GetDataByRange {
  type: typeof GET_DATA_BY_RANGE
}

export const getDataByRange = (): GetDataByRange => ({
  type: GET_DATA_BY_RANGE
})

export function* sagaDataByRange(): IterableIterator<any> {
  yield takeLatest(GET_DATA_BY_RANGE, fetchDataRaw)
}
