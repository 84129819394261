import axios from './axiosInstance'

const getTransactionsQuery = (txnIds: string[]): {} => {
  return {
    size: 10000,
    query: {
      ids: {
        values: txnIds
      }
    }
  }
}

export const getTransactions = async (
  token: string,
  txnIds: string[]
): Promise<TransactionsI | null> => {
  const request = getTransactionsQuery(txnIds)
  const response = await axios.post(
    '/settlement/aggregations/raw?index=raw_txns',
    request,
    {
      headers: { Authorization: 'Bearer ' + token }
    }
  )
  if (response.data.error) return null
  else return response.data
}

interface TransactionsI {
  took: number
  timed_out: boolean
  _shards: Shards
  hits: Hits
}

interface Hits {
  total: Total
  max_score: number
  hits: Hit[]
}

interface Hit {
  _index: string
  _type: string
  _id: string
  _score: number
  _source: Source
}

interface Source {
  id: string
  timestamp: string
  method: string
  customer_id: string
  country: string
  merchant_id: string
  currency: string
  gross: number
  tax: number
  payment_type: string
  refund: boolean
  units: Unit[]
  ip: string
  unit_count: number
  tax_rate: number
  net: number
}

interface Unit {
  sku: string
  name: string
  gross: number
  brand: string
}

interface Total {
  value: number
  relation: string
}

interface Shards {
  total: number
  successful: number
  skipped: number
  failed: number
}
