import React, { useState, useCallback } from 'react'
import ReactResizeDetector from 'react-resize-detector'
import { debounce } from '../../common/utils'

export class SampleBase<P, S> extends React.PureComponent<P, S> {
  public rendereComplete(): void {}

  public componentDidMount(): void {
    setTimeout((): void => {
      this.rendereComplete()
    })
  }
}

export const SFWrapper = (props: {
  children: Function
  refreshOnResize?: boolean
}): JSX.Element => {
  const [treemapInstance, setTMI] = useState({ refresh: (): void => {} })
  const refresh = (): void => {
    treemapInstance.refresh()
  }
  const debounceRefresh = useCallback(debounce(refresh, 500), [treemapInstance])
  const onResize = (): void => {
    debounceRefresh()
  }
  return (
    <>
      {props.refreshOnResize && (
        <ReactResizeDetector
          handleWidth
          handleHeight
          skipOnMount={true}
          onResize={onResize}
        />
      )}
      {props.children(setTMI)}
    </>
  )
}
