import React, { ComponentType, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Paper, Typography, AppBar, Tabs, Tab } from '@mui/material'
import { makeStyles, StyleRules } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import classNames from 'classnames'
import { createSelector } from 'reselect'

import { State } from '../reducers'
import { RegionTotal } from '../reducers/constraints'
import {
  getMerchant,
  chooseRegion,
  getMerchants,
  chooseCountries
} from '../utils'
import { CompareLocations } from './charts/CompareLocations'
import { CompareLocationsGraph } from './charts/CompareLocationsGraph'
import { Comparing } from './Comparing'

import { Breakdowns } from './Breakdowns'
import { TabComponent } from './TabComponent'
import { getChartByTenantId } from '../api'

const a11yProps = (index: number): {} => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    root: {
      position: 'relative',
      bottom: 'calc(-100vh + 160px)',
      flexGrow: 1,
      marginLeft: '1vw',
      marginRight: '1vw',
      zIndex: 3,
      transition: 'bottom 1s'
    },
    bar: {
      display: 'flex'
    },
    chartTabs: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    hidden: { bottom: '-101vh' },
    title: {
      textAlign: 'center',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: '12px',
      paddingTop: '4px'
    },
    value: {
      color: 'black',
      overflow: 'hidden'
    },
    grid: {
      padding: theme.spacing(1)
    },
    mb20: {
      marginBottom: '20px'
    }
  })
)

// Create a mapping of tab names to components

interface Chart {
  id: string
  order: number
  title: string
  hidden: boolean
}

interface TabOption {
  id: string
  order: number
  title: string
  charts: Chart[]
}

interface DashboardCase extends RegionTotal {
  address?: string
  merchant_id?: string
}

interface DashboardProps {
  token: string | null
  tenant_id: string | null
  global: DashboardCase | null
  region: DashboardCase | null
  merchant: DashboardCase | null
  merchantTypes: any
  countries: any
  merchantIds: any
  compareActive: boolean
}

const mapStateToProps = createSelector(
  (state: State): DashboardProps['token'] => state.userState.token,
  (state: State): DashboardProps['tenant_id'] => state.userState.tenant_id,
  (state: State): DashboardProps['global'] => state.constraintsState.global,
  (state: State): DashboardProps['region'] =>
    (state.mapState.position.countries &&
      state.mapState.position.countries.length > 0 &&
      chooseCountries(
        state.constraintsState.regions,
        state.mapState.position.countries
      )) ||
    null,
  (state: State): DashboardProps['merchant'] =>
    state.mapState.position.merchantIds &&
    state.mapState.position.merchantIds.length > 0
      ? getMerchants(
          state.mapState.geojson,
          state.mapState.position.merchantIds
        )
      : null,
  (state: State): DashboardProps['merchantTypes'] =>
    state.mapState.position.merchantTypes,
  (state: State): DashboardProps['countries'] =>
    state.mapState.position.countries,
  (state: State): DashboardProps['merchantIds'] =>
    state.mapState.position.merchantIds,
  (state: State): DashboardProps['compareActive'] =>
    state.constraintsState.compare.mode === 'locations' &&
    state.constraintsState.compare.locations.length > 1,
  (
    token,
    tenant_id,
    global,
    region,
    merchant,
    merchantTypes,
    countries,
    merchantIds,
    compareActive
  ): DashboardProps => ({
    token,
    tenant_id,
    global,
    region,
    merchant,
    merchantTypes,
    countries,
    merchantIds,
    compareActive
  })
)

export const Dashboard = (): JSX.Element => {
  const {
    token,
    tenant_id,
    global,
    region,
    merchant,
    merchantTypes,
    countries,
    merchantIds,
    compareActive
  } = useSelector(mapStateToProps)

  const classes = useStyles()
  const view = merchant || region || global
  const [value, setValue] = React.useState(0)
  const [tabsOption, setTapsOption] = React.useState<TabOption[]>([])

  useEffect((): void => {
    if (!token) return
    const callChartByTenantId = async (): Promise<any> => {
      const result: any = await getChartByTenantId({ token, tenant_id })
      setTapsOption(result?.tabs)
    }
    callChartByTenantId()
  }, [token])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Paper
      className={classNames(classes.root, {
        [classes.hidden]: !view || !view.gross
      })}
    >
      <div className={classes.bar}>
        <Typography variant="h5" component="h3" className={classes.title}>
          {(countries && countries.length > 0) ||
          (merchantTypes && merchantTypes.length > 0) ||
          (merchantIds && merchantIds.length > 0)
            ? ''
            : 'World'}
          {merchantTypes && merchantTypes.length > 0
            ? merchantTypes.join(', ')
            : countries && countries.length > 0
            ? countries.join(', ')
            : merchantIds && merchantIds.length > 0
            ? merchantIds.length == 1
              ? view?.country + '/' + view?.address
              : merchantIds.length + ' locations selected'
            : ''}
        </Typography>
        <Comparing />
      </div>
      <Breakdowns />
      {compareActive && (
        <Grid
          container
          alignContent="space-around"
          spacing={1}
          className={classes.grid}
        >
          <Grid item xs={12} sm={4}>
            <CompareLocations />
          </Grid>
          <Grid item xs={12} sm={8}>
            <CompareLocationsGraph />
          </Grid>
        </Grid>
      )}
      <div className={classes.chartTabs}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#FF6347'
              }
            }}
            textColor="inherit"
            aria-label="simple tabs example"
          >
            {tabsOption &&
              tabsOption.length > 0 &&
              tabsOption.map((option, index) => {
                return (
                  <Tab
                    label={option.title}
                    {...a11yProps(option.order)}
                    key={index}
                  />
                )
              })}
          </Tabs>
        </AppBar>
        {tabsOption &&
          tabsOption.length > 0 &&
          tabsOption.map((option, index) => {
            return (
              <TabComponent
                key={index}
                title={option.title}
                value={value}
                index={index}
                charts={option.charts}
              />
            )
          })}
      </div>
    </Paper>
  )
}
