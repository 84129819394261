import React, { useEffect, useState } from 'react'
import { Paper, Grid, Box, Typography, CircularProgress } from '@mui/material'
import { StyleRules, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { Download } from './Download'
import Chart from 'react-apexcharts'
import { getAgingBalance } from '../../api'

interface IDownloadType {
  title: string
  amount: string
  percentage: string
}

interface CompanyInfo {
  company_code: string
  company_name: string
  current: number | null
  d_0_30: number | null
  d_31_60: number | null
  d_61_90: number | null
  d_91_up: number | null
  total_receivables: number
  overdue: number
  overdue_percentage: number
}

interface GrandTotal {
  current: number
  current_percentage: number
  d_0_30: number
  d_31_60: number
  d_61_90: number
  d_91_up: number
  total_receivables: number
  total_receivables_percentage: number
  overdue: number
  overdue_percentage: number
}

interface AgingBalanceType {
  details: CompanyInfo[]
  grand_total: GrandTotal
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '35px 25px',
      marginTop: '10px',
      overflow: 'hidden'
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    caption: {
      textAlign: 'center',
      color: theme.palette.text.primary,
      textTransform: 'uppercase'
    },
    hr: {
      width: '100%',
      height: '1px',
      backgroundColor: '#B5B5B5',
      marginTop: '30px',
      marginBottom: '30px'
    },
    situationBox: {
      display: 'flex'
    },
    situationBoxCaption: {
      color: theme.palette.text.primary
    },
    situationChartBox: {
      flex: 1
    },
    situationTableBox: {
      display: 'flex',
      flex: 1,
      marginTop: '45px',
      marginLeft: '80px'
    },
    situationTableRowBox: {},
    situationTableText1: {
      fontSize: '12px',
      marginRight: '30px',
      marginBottom: '22px',
      paddingTop: '6px',
      paddingBottom: '6px'
    },
    situationTableText2: {
      fontSize: '12px',
      marginRight: '20px',
      marginBottom: '22px',
      paddingTop: '6px',
      paddingBottom: '6px'
    },
    situationTableText3: {
      flex: 1,
      backgroundColor: '#D9D9D9',
      borderRadius: '20px',
      padding: '6px 12px',
      fontSize: '10px'
    },
    tableBox: {},
    tableRow: {
      display: 'flex',
      borderBottom: '1px solid #D9D9D9',
      paddingTop: '10px',
      paddingBottom: '10px'
    },
    tableRowItem: {
      flex: 1,
      fontSize: '14px'
    }
  })
)

const AgingBalance = ({ title, token }: any) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [downloadData, setDownloadData] = useState<IDownloadType[]>([])
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})
  const [data, setData] = useState<AgingBalanceType>()

  const currencyCode = 'USD'

  useEffect((): void => {
    if (!token) return
    const callAgingBalance = async (): Promise<any> => {
      const result: any = await getAgingBalance({
        token
      })
      setData(result)
      setOptions({
        chart: {
          height: 350,
          type: 'bar',
          zoom: { enabled: false },
          toolbar: { show: false }
        },
        colors: ['#008FFB', '#00E396', '#E3B100', '#8A6BF9', '#E35200'],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        tooltip: {
          y: {
            formatter: (val: number) => {
              return val.toLocaleString('en-US', {
                style: 'currency',
                currency: currencyCode
              })
            }
          }
        },
        xaxis: {
          categories: [
            'Current',
            '0-30 Days',
            '31-60 Days',
            '61-90 Days',
            '91+ Days'
          ],
          labels: {
            style: {
              colors: ['#000000'],
              fontSize: '12px'
            }
          }
        },
        yaxis: {
          labels: {
            formatter: (val: number) => {
              //@ts-ignore
              return Intl.NumberFormat('en-US', {
                //@ts-ignore
                notation: 'compact',
                compactDisplay: 'short',
                style: 'currency',
                currency: 'USD'
              }).format(val)
            }
          },
          tickAmount: 3
        }
      })
      setSeries([
        {
          data: [
            result?.grand_total?.current,
            result?.grand_total?.d_0_30,
            result?.grand_total?.d_31_60,
            result?.grand_total?.d_61_90,
            result?.grand_total?.d_91_up
          ]
        }
      ])
    }
    callAgingBalance()
  }, [token])

  const formatCurrency = (num: number, currencyCode: string) => {
    let value
    if (num >= 1e6) {
      // If it's 1 million or above
      value = (num / 1e6).toFixed(1) + 'M'
    } else if (num >= 1e3) {
      value = (num / 1e3).toFixed(1) + 'K'
    } else {
      value = num.toLocaleString(undefined, {
        style: 'currency',
        currency: currencyCode
      })
    }
    return value
  }

  const receivableData = [
    {
      title: 'Current Receivables',
      amount: data?.grand_total?.current?.toLocaleString(undefined, {
        style: 'currency',
        currency: currencyCode
      }),
      percentage: `${data?.grand_total?.current_percentage?.toFixed(0)}%`
    },
    {
      title: 'Past Due',
      amount: data?.grand_total?.overdue?.toLocaleString(undefined, {
        style: 'currency',
        currency: currencyCode
      }),
      percentage: `${data?.grand_total?.overdue_percentage?.toFixed(0)}%`
    },
    {
      title: 'Total Receivables',
      amount: data?.grand_total?.total_receivables?.toLocaleString(undefined, {
        style: 'currency',
        currency: currencyCode
      }),
      percentage: `${data?.grand_total?.total_receivables_percentage?.toFixed(
        0
      )}%`
    }
  ]

  const renderTable = () => {
    return (
      <Box className={classes.tableBox}>
        <Box className={classes.tableRow}>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold', color: '#999999' }}
          >
            Company Code
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{
              fontWeight: 'bold',
              flex: '1.5 !important',
              color: '#999999'
            }}
          >
            Company/Business Name
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold', color: '#999999' }}
          >
            Current
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold', color: '#999999' }}
          >
            0-30 D
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold', color: '#999999' }}
          >
            31-60 D
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold', color: '#999999' }}
          >
            61-90 D
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold', color: '#999999' }}
          >
            90+ D
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold', color: '#999999' }}
          >
            Total Receivables
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold', color: '#999999' }}
          >
            Overdue
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold', color: '#999999' }}
          >
            Overdue %
          </Typography>
        </Box>
        {data?.details?.map((item, index) => (
          <Box className={classes.tableRow} key={index}>
            <Typography className={classes.tableRowItem}>
              {item?.company_code}
            </Typography>
            <Typography
              className={classes.tableRowItem}
              sx={{ flex: '1.5 !important' }}
            >
              {item?.company_name}
            </Typography>
            <Typography className={classes.tableRowItem}>
              {item?.current ? formatCurrency(item.current, currencyCode) : '-'}
            </Typography>
            <Typography className={classes.tableRowItem}>
              {item?.d_0_30 ? formatCurrency(item.d_0_30, currencyCode) : '-'}
            </Typography>
            <Typography className={classes.tableRowItem}>
              {item?.d_31_60 ? formatCurrency(item.d_31_60, currencyCode) : '-'}
            </Typography>
            <Typography className={classes.tableRowItem}>
              {item?.d_61_90 ? formatCurrency(item.d_61_90, currencyCode) : '-'}
            </Typography>
            <Typography className={classes.tableRowItem}>
              {item?.d_91_up ? formatCurrency(item.d_91_up, currencyCode) : '-'}
            </Typography>
            <Typography className={classes.tableRowItem}>
              {item?.total_receivables
                ? formatCurrency(item.total_receivables, currencyCode)
                : '-'}
            </Typography>
            <Typography className={classes.tableRowItem}>
              {item?.overdue ? formatCurrency(item.overdue, currencyCode) : '-'}
            </Typography>
            <Typography className={classes.tableRowItem}>
              {item?.overdue_percentage}%
            </Typography>
          </Box>
        ))}
        <Box
          className={classes.tableRow}
          sx={{
            borderBottom: 'none !important'
          }}
        >
          <Typography
            className={classes.tableRowItem}
            sx={{ flex: '1.5 !important' }}
          ></Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{
              fontWeight: 'bold',
              color: '#999999',
              textAlign: 'right'
            }}
          >
            Total&nbsp;&nbsp;&nbsp;
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            {data?.grand_total?.current
              ? formatCurrency(data?.grand_total?.current, currencyCode)
              : '-'}
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            {data?.grand_total?.d_0_30
              ? formatCurrency(data?.grand_total?.d_0_30, currencyCode)
              : '-'}
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            {data?.grand_total?.d_31_60
              ? formatCurrency(data?.grand_total?.d_31_60, currencyCode)
              : '-'}
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            {data?.grand_total?.d_61_90
              ? formatCurrency(data?.grand_total?.d_61_90, currencyCode)
              : '-'}
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            {data?.grand_total?.d_91_up
              ? formatCurrency(data?.grand_total?.d_91_up, currencyCode)
              : '-'}
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            {data?.grand_total?.total_receivables
              ? formatCurrency(
                  data?.grand_total?.total_receivables,
                  currencyCode
                )
              : '-'}
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            {data?.grand_total?.overdue
              ? formatCurrency(data?.grand_total?.overdue, currencyCode)
              : '-'}
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            {data?.grand_total?.overdue_percentage}%
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Grid item xs={12} sm={12}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {title}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
        </Box>
        {renderTable()}
        <Box className={classes.hr}></Box>
        <Box className={classes.situationBox}>
          <Box className={classes.situationChartBox}>
            <Typography
              variant="subtitle2"
              component="h3"
              className={classes.situationBoxCaption}
            >
              Portfolio Receivables
            </Typography>
            <Chart
              options={options}
              series={series}
              type={'bar'}
              height={350}
            />
          </Box>
          <Box className={classes.situationTableBox}>
            <Box className={classes.situationTableRowBox}>
              {receivableData.map((item, index) => (
                <Typography
                  variant="subtitle2"
                  className={classes.situationTableText1}
                  key={index}
                >
                  {item.title}
                </Typography>
              ))}
            </Box>
            <Box className={classes.situationTableRowBox}>
              {receivableData.map((item, index) => (
                <Typography
                  variant="subtitle2"
                  className={classes.situationTableText2}
                  sx={{ fontWeight: 'bold' }}
                  key={index}
                >
                  {item.amount}
                </Typography>
              ))}
            </Box>
            <Box className={classes.situationTableRowBox}>
              {receivableData.map((item, index) => (
                <Box
                  sx={{
                    width: '55px',
                    textAlign: 'center',
                    marginBottom: '25px'
                  }}
                  key={index}
                >
                  <Typography
                    variant="subtitle2"
                    className={classes.situationTableText3}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {item.percentage}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Download data={data?.details} />
      </Paper>
    </Grid>
  )
}

export default AgingBalance
