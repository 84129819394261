import React from 'react'
import { Paper, Typography, Button } from '@mui/material'
import { Theme } from '@mui/material/styles'
import TrendingUp from '@mui/icons-material/TrendingUp'
import TrendingDown from '@mui/icons-material/TrendingDown'
import classNames from 'classnames'
import { StyleRules, makeStyles } from '@mui/styles'

import { BC } from './BCTooltip'
import { getChips } from '../utils'
import { currencies } from '../temp/currencies_map'

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    paper: {
      padding: '2px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%'
    },
    caption: {
      textAlign: 'left'
    },
    value: {
      color: 'black',
      overflow: 'hidden'
    },
    trend: {
      backgroundColor: 'mediumseagreen',
      color: 'white',
      padding: '0px 2px 0px 2px',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    trendDown: {
      backgroundColor: 'tomato'
    },
    trendNA: {
      backgroundColor: 'gold'
    },
    trendIcon: {
      marginRight: '8px'
    }
  })
)

interface MetricProps {
  title: string
  value: number
  trend?: number | null
  digits?: number
  currencyCode?: string | null
}

export const Metric = function App(props: MetricProps): JSX.Element {
  const classes = useStyles()
  const { title, value, trend, digits, currencyCode } = props
  const trendy = trend && !isNaN(trend)
  const positive = trend && trend >= 0
  return (
    <Paper className={classes.paper}>
      <Typography variant="caption" display="block" className={classes.caption}>
        {title}
      </Typography>
      <BC value={value} currency={currencyCode || ''}>
        <Typography variant="h6" className={classes.value}>
          {(currencyCode
            ? currencies[currencyCode].symbol + ' '
            : digits === 0
            ? ''
            : '$ ') + getChips(value, digits)}
        </Typography>
      </BC>
      {trendy && trend ? (
        <Button
          size="small"
          className={classNames(classes.trend, {
            [classes.trendDown]: !positive
          })}
        >
          {positive ? (
            <TrendingUp className={classes.trendIcon} />
          ) : (
            <TrendingDown className={classes.trendIcon} />
          )}
          {positive && '+'}
          {isFinite(trend) ? trend.toFixed(1) + '%' : 'N/A'}
        </Button>
      ) : null}
      <Typography variant="caption" display="block">
        Past period
      </Typography>
    </Paper>
  )
}
