import axios from './axiosInstance'

export interface Country {
  id: string
  name: string
  currencyId: string
  xoRate: number
  transitDelays: TransitDelay[]
  locations: Location[]
  feeInfos: FeeInfo[]
}

interface FeeInfo {
  currencyId: string
  paymentTypeId: string
  amount: number
  percent: number
  description: string
}

export interface Location {
  id?: string | string
  merchantId?: string | string
  merchantType?: string | string
  bankAccount: string
  address: string
  contactInfo: string
  latitude: number
  longitude: number
  regionId: number
  regionName: string
  ip: string
  batchCutoff: string
  representative?: Representative | Representative
}

export interface Representative {
  id: string
  name: string
  contactInfo: string
}

export interface TransitDelay {
  paymentTypeId: string
  delay: number
}

export const getCountries = async (
  token: string,
  tenant_id: string | null
): Promise<Country[] | null> => {
  try {
    const response = await axios.get('/geography/countries?tenant_id=' + tenant_id, {
      headers: { Authorization: 'Bearer ' + token }
    })
    return response.data ? response.data.items : null
  } catch (error) {
    console.warn('request error')
    return null
  }
}
