import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  Paper,
  Typography,
  CircularProgress,
  Select,
  Menu,
  MenuItem,
  Button,
  Chip,
  SelectChangeEvent,
  Box,
  FormControl,
  Grid
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'
import { DateTime } from 'luxon'

import { State } from '../../reducers'
import { Download } from './Download'
import {
  currenciesCodes as currencies,
  mapCountryToCurrency
} from '../../temp/currencies_map'
import Chart from "react-apexcharts"
import { createSelector } from 'reselect'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary
      //   minWidth: '400px'
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    formControl: {
      margin: theme.spacing(1),
    },
    legend: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px'
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '10px',
      '&:last-child': {
        marginRight: '0px'
      }
    },
    legendMarker: {
      width: '10px',
      height: '10px',
      background: 'red',
      borderRadius: '100%',
      marginRight: '5px'
    },
    legendText: {
      fontSize: '13px',
      margin: '0px',
      color: 'rgb(53,53,53)'
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary,
      paddingBottom: '15px'
    },
    chip: {
      marginLeft: theme.spacing(0.5),
      marginTop: '3px'
    },
    vsContainer: { display: 'flex', alignItems: 'center' }
  })
)

interface CSVRow {
  base: string
  target: string
  date: string
  rate: number
}

const getCSV = (vs: string[], data: any, base: string): CSVRow[] => {
  const res: CSVRow[] = []
  vs.forEach((curr: string): void => {
    if (data[base] && data[base][curr]) {
      const item = data[base][curr]
      item.forEach((rate: any): void => {
        const date = DateTime.fromJSDate(rate.x)
          ? DateTime.fromJSDate(rate.x).toFormat('MMM dd y')
          : ''
        res.push({
          base,
          target: curr,
          date,
          rate: rate.y
        })
      })
    }
  })
  return res
}

interface ForexProps {
  getData: Function
  country: string | undefined
  title: string
}

interface ForexSelectorProps {
  baseCurrency: string
}

const mapState = createSelector(
  (state: State): ForexSelectorProps['baseCurrency'] => state.userState.baseCurrency,
  (
    baseCurrency,
  ): ForexSelectorProps => ({
    baseCurrency,
  })
)

export const Forex = ({ getData, country, title }: ForexProps): JSX.Element => {
  const classes = useStyles()
  const { baseCurrency } = useSelector(mapState)
  const [data, setData] = useState<any | null>({})
  const [base, setBase] = useState(country ? mapCountryToCurrency[country] : 'USD')
  const [vs, setVs] = useState([baseCurrency])
  const [isLoading, setIsLoading] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [type, setType] = useState('Line')
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})
  const types = [
    'Line',
    'Bar',
    'Area'
  ]

  const baseChange = (event: SelectChangeEvent): void => {
    setVs(
      vs.filter((c: string): boolean => c !== (event.target.value as string))
    )
    setBase(event.target.value as string)
  }
  function handleClick(event: React.MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event.currentTarget)
  }
  function handleClose(): void {
    setAnchorEl(null)
  }

  const setDefaultOptions = (mapped: {x: Date, y: number}[]) => {
    setOptions({
      ...options,
      chart: { 
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      tooltip: {
        y: {
          formatter: (val: number) =>  {
            return val.toLocaleString('en-US', {
              style: 'currency',
              currency: baseCurrency
            })
          }
        }
      },
      xaxis: {
        categories: mapped && mapped.length > 0 ? mapped.map((i) => DateTime.fromJSDate(i.x).toFormat('LLL dd')): []
      },
      yaxis: {
        labels: {
          //@ts-ignore
          formatter: (val: number) => parseFloat(val).toFixed(0)
        }
      },
      fill: {
        type: 'solid'
      }
    })
  }

  useEffect((): void => {
    setBase(country ? mapCountryToCurrency[country] : 'USD')
  }, [country])

  useEffect((): void => {
    const fetchData = async (): Promise<any> => {
      setIsLoading(true)
      vs.forEach(
        async (curr: string): Promise<any> => {
          if (!data[base] || !data[base][curr]) {
            const result = await getData(base, curr)
            if (result) {
              const newData: any = data
              const mapped = result.map(
                (item: {
                  dateTime: string
                  rate: number
                }): {
                  y: number
                  x: Date
                } => ({
                  y: item.rate,
                  x: DateTime.fromISO(item.dateTime).toJSDate()
                })
              )
              if (newData[base]) {
                newData[base][curr] = mapped
              } else {
                newData[base] = {}
                newData[base][curr] = mapped
              }
              setDefaultOptions(mapped)
              setData({ ...newData })
            }
          }
        }
      )
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData, base, vs])

  useEffect(() => {
    switch(type) {
      case 'Line':
        setOptions({
          ...options, 
            chart: { 
              type: 'line',
            },
        })
      break
      case 'Bar':
        setOptions({
          ...options, 
            chart: { 
              type: 'bar',
            },
        })
      break
      case 'Area':
        setOptions({
          ...options, 
            chart: { 
              type: 'area',
            },
        })
      break
    }
  }, [type])
  
  const typeChange = (event: SelectChangeEvent): void => {
    setType(event.target.value as string)
  }

  useEffect(() => {
    if (Object.keys(data).length === 0) return
    let series: any[] = []
    const chartData = vs.map((curr: string) => {
      if (data[base] && data[base][curr]) {
        return {name: curr, items: data[base][curr].map((d: any) => {
          return {
            ...d,
            y: d.y === null ? 0 : d.y
          }
        })}
      }
    }).filter(e => e!== undefined)

    chartData.map((item: any) => {
      series.push({
        name: item.name,
        data: item.items.map((i: {x: Date, y: number}) => i.y)
      })
    })

    setSeries(series)
  }, [data, vs])

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {title ? title : `Currency Conversion Rate Forecast for ${base}`}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={type}
              onChange={typeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {types.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        
        <div className={classes.vsContainer}>
          <Select
            title="Base currency"
            key="base-currency"
            disableUnderline={true}
            native
            value={base}
            variant='standard'
            onChange={baseChange}
            inputProps={{
              name: 'age',
              id: 'age-native-simple'
            }}
          >
            {currencies.map(
              (curr: string): JSX.Element => (
                <option key={`base-select-${curr}`} value={curr}>
                  {curr}
                </option>
              )
            )}
          </Select>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            Add Currency
          </Button>
          <Menu
            id="forex-add-currency"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {currencies
              .filter(
                (curr: string): boolean =>
                  curr !== base && vs.indexOf(curr) === -1
              )
              .map(
                (curr: string): JSX.Element => (
                  <MenuItem
                    key={`compare-to-${curr}`}
                    onClick={(): void => {
                      setVs([...vs, curr])
                    }}
                  >
                    {curr}
                  </MenuItem>
                )
              )}
          </Menu>
          {vs.map(
            (curr: string, index: number): JSX.Element => {
              return (
                <Chip
                  key={`selected-vs-${index}`}
                  label={curr}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onDelete={(): void =>
                    setVs(vs.filter((c: string): boolean => c !== curr))
                  }
                  className={classes.chip}
                />
              )
            }
          )}
        </div>
        <Chart
          options={options}
          series={series}
          type={type === 'Line' ? 'line' : type === 'Bar' ? 'bar' : 'area'}
          height="400"
        />
        <Download data={getCSV(vs, data, base)} />
      </Paper>
    </Grid>
  )
}
