// // @ts-nocheck - may need to be at the start of file

import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import MaterialTable, { Column, MTableToolbar } from 'material-table'
import { Download } from './Download'
import {
  TextField,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormControl,
  Box,
  Chip,
  Grid,
  Button,
  Typography,
  Modal,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { StyleRules, makeStyles, createStyles } from '@mui/styles'
import { updatedDiff } from 'deep-object-diff'
import { getMerchants } from '../../api'
import { State } from '../../reducers'
import { createSelector } from 'reselect'

const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      row: {
        flexGrow: 1,
        backgroundColor: '#f1f1f1',
        display: 'flex',
        height: 224
      },
      boxStyle: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        backgroundColor: 'white',
        // border: '1px solid blue',
        // boxShadow: 24,
        boxShadow: '24',
        padding: 10,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        borderRadius: '10px'
      },
      formItem: {
        width: '100%',
        marginBottom: '20px'
      },
      paper: {
        padding: '10px',
        textAlign: 'center',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
      },
      filter: {
        padding: '0px 10px',
        alignSelf: 'flex-start',
        display: 'flex'
        // width: '100%'
      },
      w20: { width: '200px' },
      btnStyle: {
        width: '40px',
        height: '40px',
        justifySelf: 'flex-right',
        marginRight: '10px',
        backgroundColor: '#e0e0e0'
      },
      btnAlignment: { display: 'flex', justifyContent: 'flex-end' },
      toolbarContainer: { display: 'flex', justifyContent: 'space-between' },
      mb10: { marginBottom: '10px' },
      dropDownStyle: {
        width: '450px ',
        alignSelf: 'center',
        marginBottom: '10px'
      },
      addBtn: { alignSelf: 'center', marginBottom: '10px', color: 'white' },
      gridContainer: { display: 'flex', justifyContent: 'space-between' }
    })
)
interface dataObjI {
  location: string
  promo_id: string
  datetime_from: string
  datetime_to: string
  list_price: number
  discount: number
  discounted_price: number
  sales_count_total: number
  sales_count_discounted: number
  list_sales_total: number
  discounted_sales_total: number
  sales_total: number
  budget_uplift: number
  promo_type: string
  promo_method: string
  promo_start_date: string
  promo_end_date: string
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

interface PromotionTrackProps {
  token: string | null,
  tenant_id: string | null
}

const mapStateToProps = createSelector(
  (state: State): PromotionTrackProps['token'] => state.userState.token,
  (state: State): PromotionTrackProps['tenant_id'] => state.userState.tenant_id,
  (
    token,
    tenant_id
  ): PromotionTrackProps => ({
    token,
    tenant_id
  })
)

export const PromotionTrack = ({title} : any) => {
  const {
    token,
    tenant_id
  } = useSelector(mapStateToProps)
  const classes = useStyles()
  const [isFilterValue, setIsFilterValue] = useState(false)
  const [personName, setPersonName] = React.useState<string[]>([])
  const [allSelectedRows, setAllSelectedRows] = useState<any>([])
  const theme = useTheme()
  const [open1, setOpen1] = React.useState(false)
  const [dataObj, setDataObj] = useState({} as dataObjI)
  const [isShowMessage, setIsShowMessage] = useState(false)
  const [open, setOpen] = useState(false)
  const [merchants, setMerchants] = useState<any>([])
  const [personMerchant, setPersonMerchant] = useState('');

  useEffect((): void => {
    if(!token) return;
    const callMerchants = async (): Promise<any> => {
      const result: any = await getMerchants({token, tenant_id})
      setMerchants(result);
    }
    callMerchants()
  }, [token])

  const topData = [
    {
      location: '4257894470',
      sku: 'pp5007210655',
      id: '2022-01-28_4257894470_pp5007210655',
      promo_id: '21065',
      datetime_from: '2022-01-28',
      datetime_to: '2022-02-22',
      list_price: 41.09,
      discount: 0.07,
      discounted_price: 38.21,
      sales_count_total: 47,
      sales_count_discounted: 13,
      list_sales_total: 1397.06,
      discounted_sales_total: 496.73,
      sales_total: 1893.79,
      budget_uplift: 0.26,
      promo_type: 'Email',
      promo_method: 'Pass Through',
      promo_start_date: '2022-01-20',
      promo_end_date: '2022-02-14'
    },

    {
      location: '4257894470',
      sku: 'pp5007120090',
      id: '2022-01-28_4257894470_pp5007120090',
      promo_id: '12009',
      datetime_from: '2022-02-01',
      datetime_to: '2022-03-01',
      list_price: 24.17,
      discount: 0.11,
      discounted_price: 21.51,
      sales_count_total: 34,
      sales_count_discounted: 8,
      list_sales_total: 628.42,
      discounted_sales_total: 172.08,
      sales_total: 800.5,
      budget_uplift: 0.21,
      promo_type: 'Text Blast',
      promo_method: 'Pass Through',
      promo_start_date: '2022-01-24',
      promo_end_date: '2022-02-05'
    },
    {
      location: '4257894470',
      sku: 'pp5004320142',
      id: '2022-01-28_4257894470_pp5004320142',
      promo_id: '32014',
      datetime_from: '2022-01-15',
      datetime_to: '2022-02-20',
      list_price: 16.92,
      discount: 0.49,
      discounted_price: 8.63,
      sales_count_total: 36,
      sales_count_discounted: 12,
      list_sales_total: 406.08,
      discounted_sales_total: 103.56,
      sales_total: 509.64,
      budget_uplift: 0.2,
      promo_type: 'Text Blast',
      promo_method: 'Loyalty',
      promo_start_date: '2022-01-16',
      promo_end_date: '2022-02-01'
    },
    {
      location: '4257894470',
      sku: 'pp5002780056',
      id: '2022-01-28_4257894470_pp5002780056',
      promo_id: '78005',
      datetime_from: '2022-01-01',
      datetime_to: '2022-01-28',
      list_price: 85.13,
      discount: 0.08,
      discounted_price: 78.32,
      sales_count_total: 28,
      sales_count_discounted: 12,
      list_sales_total: 1362.08,
      discounted_sales_total: 939.84,
      sales_total: 2301.92,
      budget_uplift: 0.41,
      promo_type: 'Email',
      promo_method: 'Loyalty',
      promo_start_date: '2022-01-18',
      promo_end_date: '2022-02-09'
    },
    {
      location: '4257894470',
      sku: 'pp5003710552',
      id: '2022-01-28_4257894470_pp5003710552',
      promo_id: '71055',
      datetime_from: '2022-01-28',
      datetime_to: '2022-01-28',
      list_price: 106.42,
      discount: 0.19,
      discounted_price: 86.2,
      sales_count_total: 32,
      sales_count_discounted: 10,
      list_sales_total: 2341.24,
      discounted_sales_total: 862.0,
      sales_total: 3203.24,
      budget_uplift: 0.27,
      promo_type: 'Email',
      promo_method: 'Exchange',
      promo_start_date: '2022-01-24',
      promo_end_date: '2022-02-06'
    },
    {
      location: '4257894470',
      sku: 'pp5006830281',
      id: '2022-01-28_4257894470_pp5006830281',
      promo_id: '83028',
      datetime_from: '2021-08-05',
      datetime_to: '2022-12-10',
      list_price: 101.52,
      discount: 0.46,
      discounted_price: 54.82,
      sales_count_total: 6,
      sales_count_discounted: 3,
      list_sales_total: 304.56,
      discounted_sales_total: 164.46,
      sales_total: 469.02,
      budget_uplift: 0.35,
      promo_type: 'Mailer',
      promo_method: 'Exchange',
      promo_start_date: '2022-01-15',
      promo_end_date: '2022-02-05'
    },
    {
      location: '4257894470',
      sku: 'pp5002970121',
      id: '2022-01-28_4257894470_pp5002970121',
      promo_id: '97012',
      datetime_from: '2021-06-20',
      datetime_to: '2021-09-12',
      list_price: 60.43,
      discount: 0.21,
      discounted_price: 47.74,
      sales_count_total: 15,
      sales_count_discounted: 7,
      list_sales_total: 483.44,
      discounted_sales_total: 334.18,
      sales_total: 817.62,
      budget_uplift: 0.41,
      promo_type: 'Mailer',
      promo_method: 'Exchange',
      promo_start_date: '2022-01-16',
      promo_end_date: '2022-02-06'
    },
    {
      location: '4257894470',
      sku: 'pp5004390526',
      id: '2022-01-28_4257894470_pp5004390526',
      promo_id: '39052',
      datetime_from: '2022-01-20',
      datetime_to: '2022-02-12',
      list_price: 32.58,
      discount: 0.11,
      discounted_price: 29.0,
      sales_count_total: 43,
      sales_count_discounted: 4,
      list_sales_total: 1270.62,
      discounted_sales_total: 116.0,
      sales_total: 1386.62,
      budget_uplift: 0.08,
      promo_type: 'Text Blast',
      promo_method: 'Exchange',
      promo_start_date: '2022-01-13',
      promo_end_date: '2022-02-12'
    },
    {
      location: '4257894470',
      sku: 'pp5006291610',
      id: '2022-01-28_4257894470_pp5006291610',
      promo_id: '29161',
      datetime_from: '2021-04-28',
      datetime_to: '2021-07-12',
      list_price: 43.09,
      discount: 0.25,
      discounted_price: 32.32,
      sales_count_total: 39,
      sales_count_discounted: 18,
      list_sales_total: 904.89,
      discounted_sales_total: 581.76,
      sales_total: 1486.65,
      budget_uplift: 0.39,
      promo_type: 'Mailer',
      promo_method: 'Pass Through',
      promo_start_date: '2022-01-23',
      promo_end_date: '2022-02-03'
    }
  ]
  const [data, setData] = useState([
    {
      location: '4257894470',
      sku: 'pp5006291610',
      id: '2022-01-28_4257894470_pp5006291610',
      promo_id: '29161',
      datetime_from: '2021-04-28',
      datetime_to: '2021-07-12',
      list_price: 43.09,
      discount: 0.25,
      discounted_price: 32.32,
      sales_count_total: 39,
      sales_count_discounted: 18,
      list_sales_total: 904.89,
      discounted_sales_total: 581.76,
      sales_total: 1486.65,
      budget_uplift: 0.39,
      promo_type: 'Mailer',
      promo_method: 'Pass Through',
      promo_start_date: '2022-01-23',
      promo_end_date: '2022-02-03'
    },
    {
      location: '4257894470',
      sku: 'pp5004320142',
      id: '2022-01-28_4257894470_pp5004320142',
      promo_id: '32014',
      datetime_from: '2022-01-15',
      datetime_to: '2022-02-20',
      list_price: 16.92,
      discount: 0.49,
      discounted_price: 8.63,
      sales_count_total: 36,
      sales_count_discounted: 12,
      list_sales_total: 406.08,
      discounted_sales_total: 103.56,
      sales_total: 509.64,
      budget_uplift: 0.2,
      promo_type: 'Text Blast',
      promo_method: 'Loyalty',
      promo_start_date: '2022-01-16',
      promo_end_date: '2022-02-01'
    },

    {
      location: '4257894470',
      sku: 'pp5007120090',
      id: '2022-01-28_4257894470_pp5007120090',
      promo_id: '12009',
      datetime_from: '2022-02-01',
      datetime_to: '2022-03-01',
      list_price: 24.17,
      discount: 0.11,
      discounted_price: 21.51,
      sales_count_total: 34,
      sales_count_discounted: 8,
      list_sales_total: 628.42,
      discounted_sales_total: 172.08,
      sales_total: 800.5,
      budget_uplift: 0.21,
      promo_type: 'Text Blast',
      promo_method: 'Pass Through',
      promo_start_date: '2022-01-24',
      promo_end_date: '2022-02-05'
    },
    {
      location: '4257894470',
      sku: 'pp5006830281',
      id: '2022-01-28_4257894470_pp5006830281',
      promo_id: '83028',
      datetime_from: '2021-08-05',
      datetime_to: '2022-12-10',
      list_price: 101.52,
      discount: 0.46,
      discounted_price: 54.82,
      sales_count_total: 6,
      sales_count_discounted: 3,
      list_sales_total: 304.56,
      discounted_sales_total: 164.46,
      sales_total: 469.02,
      budget_uplift: 0.35,
      promo_type: 'Mailer',
      promo_method: 'Exchange',
      promo_start_date: '2022-01-15',
      promo_end_date: '2022-02-05'
    },

    {
      location: '4257894470',
      sku: 'pp5002780056',
      id: '2022-01-28_4257894470_pp5002780056',
      promo_id: '78005',
      datetime_from: '2022-01-01',
      datetime_to: '2022-01-28',
      list_price: 85.13,
      discount: 0.08,
      discounted_price: 78.32,
      sales_count_total: 28,
      sales_count_discounted: 12,
      list_sales_total: 1362.08,
      discounted_sales_total: 939.84,
      sales_total: 2301.92,
      budget_uplift: 0.41,
      promo_type: 'Email',
      promo_method: 'Loyalty',
      promo_start_date: '2022-01-18',
      promo_end_date: '2022-02-09'
    },
    {
      location: '4257894470',
      sku: 'pp5003710552',
      id: '2022-01-28_4257894470_pp5003710552',
      promo_id: '71055',
      datetime_from: '2022-01-28',
      datetime_to: '2022-01-28',
      list_price: 106.42,
      discount: 0.19,
      discounted_price: 86.2,
      sales_count_total: 32,
      sales_count_discounted: 10,
      list_sales_total: 2341.24,
      discounted_sales_total: 862.0,
      sales_total: 3203.24,
      budget_uplift: 0.27,
      promo_type: 'Email',
      promo_method: 'Exchange',
      promo_start_date: '2022-01-24',
      promo_end_date: '2022-02-06'
    },
    {
      location: '4257894470',
      sku: 'pp5004390526',
      id: '2022-01-28_4257894470_pp5004390526',
      promo_id: '39052',
      datetime_from: '2022-01-20',
      datetime_to: '2022-02-12',
      list_price: 32.58,
      discount: 0.11,
      discounted_price: 29.0,
      sales_count_total: 43,
      sales_count_discounted: 4,
      list_sales_total: 1270.62,
      discounted_sales_total: 116.0,
      sales_total: 1386.62,
      budget_uplift: 0.08,
      promo_type: 'Text Blast',
      promo_method: 'Exchange',
      promo_start_date: '2022-01-13',
      promo_end_date: '2022-02-12'
    },
    {
      location: '4257894470',
      sku: 'pp5007210655',
      id: '2022-01-28_4257894470_pp5007210655',
      promo_id: '21065',
      datetime_from: '2022-01-28',
      datetime_to: '2022-02-22',
      list_price: 41.09,
      discount: 0.07,
      discounted_price: 38.21,
      sales_count_total: 47,
      sales_count_discounted: 13,
      list_sales_total: 1397.06,
      discounted_sales_total: 496.73,
      sales_total: 1893.79,
      budget_uplift: 0.26,
      promo_type: 'Email',
      promo_method: 'Pass Through',
      promo_start_date: '2022-01-20',
      promo_end_date: '2022-02-14'
    },
    {
      location: '4257894470',
      sku: 'pp5002970121',
      id: '2022-01-28_4257894470_pp5002970121',
      promo_id: '97012',
      datetime_from: '2021-06-20',
      datetime_to: '2021-09-12',
      list_price: 60.43,
      discount: 0.21,
      discounted_price: 47.74,
      sales_count_total: 15,
      sales_count_discounted: 7,
      list_sales_total: 483.44,
      discounted_sales_total: 334.18,
      sales_total: 817.62,
      budget_uplift: 0.41,
      promo_type: 'Mailer',
      promo_method: 'Exchange',
      promo_start_date: '2022-01-16',
      promo_end_date: '2022-02-06'
    }
  ])

  const columns: Column<any>[] = [
    { field: 'sku', title: 'Product' },
    {
      field: 'location',
      title: 'Market'
    },
    {
      field: 'datetime_from',
      title: 'Start Date'
    },
    {
      field: 'datetime_to',
      title: 'End Date'
    },
    {
      field: 'list_price',
      title: 'Gross Value'
    },
    {
      field: 'discounted_price',
      title: 'Net Value'
    },
    {
      field: 'promo_type',
      title: 'Type'
    },
    {
      field: 'budget_uplift',
      title: 'Budget Uplift'
    },
    {
      field: 'promo_method',
      title: 'Method'
    },
    {
      field: 'promo_id',
      title: 'Promotion ID'
    }
  ]
  const SkuFilter = [
    {
      label: 'Top 25 SKUs',
      value: '1'
    },
    {
      label: 'Plus New',
      value: '2'
    },
    {
      label: 'SKU',
      value: '3'
    }
  ]
  function getStyles(
    name: string,
    personName: readonly string[],
    theme: Theme
  ) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    }
  }

  const handleChange1 = (event: any) => {
    // : SelectChangeEvent<typeof personName>
    const {
      target: { value }
    } = event
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const handleChangeMerchant = (event: SelectChangeEvent) => {
    setPersonMerchant(event.target.value)
    setDataObj({ ...dataObj, ['location']: event.target.value});
  };

  const handleChange = (name: any, event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value) === 1) {
      setIsFilterValue(true)
    } else {
      setIsFilterValue(false)
    }
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen1(true)
    setIsShowMessage(false)
    setPersonName([])
    setDataObj({} as dataObjI)
  }
  const handleClose1 = () => setOpen1(false)

  const handleDataChange = (name: any, event: React.ChangeEvent<HTMLInputElement>) => {
    setDataObj({ ...dataObj, [name]: event.target.value })
  }

  function handleUpdateData() {
    if (personName.length > 0) {
      personName.forEach((element: any) => {
        data.forEach((e: any) => {
          if (element === e.sku) {
            e.promo_type = dataObj.promo_type
            e.list_price = dataObj.list_price
            e.discounted_price = dataObj.discounted_price
            e.promo_type = dataObj.promo_type
            e.budget_uplift = dataObj.budget_uplift
            e.promo_method = dataObj.promo_method
            e.promo_id = dataObj.promo_id

            e.location = dataObj.location
            e.datetime_from = dataObj.datetime_from
            e.datetime_to = dataObj.datetime_to
          }
        })
      })
      setIsShowMessage(true)
    }
  }

  useEffect(() => {
    if (isShowMessage) {
      setTimeout(() => {
        handleClose1()
        setPersonName([])
        setDataObj({} as dataObjI)
      }, 1000)
    }
  }, [isShowMessage])

  const inputFile = useRef(null)
  
  const handleUpload = () => {
    //@ts-ignore
    inputFile?.current.click()
  }

  return (
    <Grid item xs={12} sm={12}>
      <div className={classes.paper}>
        <MaterialTable
          columns={columns}
          data={isFilterValue ? topData : data}
          title={'Global'}
          options={{
            paging: false,
            // searchFieldAlignment: 'right',
            maxBodyHeight: 400,
            exportButton: true,
            search: false
          }}
          onSelectionChange={rows => {
            setAllSelectedRows([...rows])
          }}
          components={{
            Toolbar: props => (
              <div className={classes.mb10}>
                <Typography variant="subtitle2" component="h3">
                  {title ? title : 'Promotion Track'}
                </Typography>
                <div className={classes.toolbarContainer}>
                  <Grid item sm={4}>
                    <div className={classes.filter}>
                      <TextField
                        className={classes.w20}
                        id="outlined-select-promotion_id"
                        select
                        label="Select Value"
                        defaultValue=""
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                          handleChange('SkuFilter', value)
                        }}
                      >
                        {SkuFilter.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </Grid>

                  <Grid item sm={5} className={classes.btnAlignment}>
                    <div>
                      <Button
                        onClick={() => handleUpload()}
                        variant="contained"
                        className={classes.btnStyle}
                      >
                        <ArrowUpwardIcon />
                      </Button>
                      <input
                        type="file"
                        id="input-file"
                        // @ts-ignore: Object is possibly 'null'
                        ref={inputFile}
                        style={{ display: 'none' }}
                      />
                    </div>
                    <Button
                      variant="contained"
                      onClick={() => handleOpen()}
                      className={classes.btnStyle}
                    >
                      <AddBoxOutlinedIcon />
                    </Button>
                  </Grid>
                </div>
              </div>
            )
          }}
        />
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={classes.boxStyle}>
            <Grid item sm={6}>
              <FormControl className={classes.dropDownStyle}>
                {/* sx={{ m: 1, width: 300 }} */}
                <InputLabel id="demo-multiple-chip-label">
                  Select Products
                </InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={personName}
                  onChange={handleChange1}
                  input={<OutlinedInput id="select-multiple-chip" label="SKUs" />}
                  renderValue={(selected: any) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selected.map((value: any) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {data.map(name => (
                    <MenuItem
                      key={name.sku}
                      value={name.sku}
                      style={getStyles(name.sku, personName, theme)}
                    >
                      {name.sku}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <div className={classes.gridContainer}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl className={classes.formItem}>
                  <InputLabel id="demo-simple-select-helper-label">Select Market</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={personMerchant}
                    label="Merchant"
                    onChange={handleChangeMerchant}
                  >
                    {merchants.map((merchant: any) => (
                      <MenuItem key={merchant.id} value={merchant.id}>{merchant.id}</MenuItem>  
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.formItem}>
                  <TextField
                    id="brand-input8"
                    name="gross_value"
                    label="Gross Value"
                    type="text"
                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleDataChange('list_price', value)}
                  />
                </FormControl>
                <FormControl className={classes.formItem}>
                  <TextField
                    id="brand-input9"
                    name="budget_uplift"
                    label="Budget Uplift"
                    type="text"
                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleDataChange('budget_uplift', value)}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ marginInline: '10px' }}
              >
                <FormControl className={classes.formItem}>
                  <TextField
                    id="brand-input1"
                    name="start_date"
                    label="Start Date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    type="date"
                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleDataChange('datetime_from', value)}
                  />
                </FormControl>
                <FormControl className={classes.formItem}>
                  <TextField
                    id="brand-input2"
                    name="net_value"
                    label="Net Value"
                    type="text"
                    onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
                      handleDataChange('discounted_price', value)
                    }
                  />
                </FormControl>
                <FormControl className={classes.formItem}>
                  <TextField
                    id="brand-input3"
                    name="promotion_id"
                    label="Promotion Id"
                    type="text"
                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleDataChange('promo_id', value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl className={classes.formItem}>
                  <TextField
                    id="brand-input4"
                    name="end_date"
                    label="End Date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    type="date"
                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleDataChange('datetime_to', value)}
                  />
                </FormControl>
                <FormControl className={classes.formItem}>
                  <TextField
                    id="brand-input5"
                    name="promo_type"
                    label="Type"
                    type="text"
                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleDataChange('promo_type', value)}
                  />
                </FormControl>

                <FormControl className={classes.formItem}>
                  <TextField
                    id="brand-input6"
                    name="method"
                    label="Method"
                    type="text"
                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleDataChange('promo_method', value)}
                  />
                </FormControl>
              </Grid>
            </div>
            <Button
              variant="contained"
              onClick={() => handleUpdateData()}
              className={classes.addBtn}
            >
              Add Promotion
            </Button>
          </div>
        </Modal>
        <Download data={data} />
      </div>
    </Grid>
  )
}
