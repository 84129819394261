import axios from './axiosInstance'

interface Constraints {
  country: string
  startDate: Date
  endDate: Date
  tenant_id: string | null
}
const getTopGrossingLocationsQuery = ({
  country,
  startDate,
  endDate,
  tenant_id
}: Constraints): {} => {
  const must = []
  if (tenant_id) {
    must.push({
      match_phrase: {
        tenant_id: {
          query: tenant_id
        }
      }
    })
  }
  if (country) {
    must.push({
      match_phrase: {
        country: {
          query: country
        }
      }
    })
  }

  return {
    aggs: {
      merchantId: {
        terms: {
          field: 'merchant_id.keyword',
          size: 500,
          order: {
            gross: 'desc'
          }
        },
        aggs: {
          gross: {
            sum: {
              field: 'gross'
            }
          }
        }
      }
    },
    size: 0,
    _source: {
      excludes: []
    },
    docvalue_fields: [
      {
        field: 'datetime_from',
        format: 'date_time'
      },
      {
        field: 'datetime_to',
        format: 'date_time'
      }
    ],
    query: {
      bool: {
        must: [
          ...must,
          {
            range: {
              datetime_from: {
                gte: startDate.getTime(),
                lte: endDate.getTime(),
                format: 'epoch_millis'
              }
            }
          }
        ]
      }
    }
  }
}

interface TopGrossingLocationsDataset {
  took: number
  timed_out: boolean
  _shards: Shards
  hits: Hits
  aggregations: Aggregations
  status: number
}

interface Aggregations {
  merchantId: MerchantId
}

interface MerchantId {
  doc_count_error_upper_bound: number
  sum_other_doc_count: number
  buckets: Bucket[]
}

interface Bucket {
  gross: Gross
  key: number
  doc_count: number
}

interface Gross {
  value: number
}

interface Hits {
  total: number
  max_score?: any
  hits: any[]
}

interface Shards {
  total: number
  successful: number
  skipped: number
  failed: number
}

export const getTopGrossingLocations = async (
  token: string,
  constraints: Constraints
): Promise<TopGrossingLocationsDataset | null> => {
  const request = getTopGrossingLocationsQuery(constraints)
  const response = await axios.post(
    '/settlement/aggregations/raw?index=location_total_30m',
    request,
    {
      headers: { Authorization: 'Bearer ' + token }
    }
  )
  if (response.data.error) return null
  else return response.data
}
