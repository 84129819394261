import React, { useState, useEffect } from 'react'
import { Paper, Typography, CircularProgress, Grid } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'

import { getChips } from '../../utils'
import { SelectionConstraints } from '../../rawApi'
import { getPromotionUseRate } from '../../api'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      maxWidth: '300px'
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary
    },
    description: {
      marginTop: '0px',
      fontSize: '11px',
      textAlign: 'center'
    },
    value: {
      color: '#5645b8',
      fontSize: '20px',
      fontWeight: 700
    },
    table: {
      display: 'table',
      width: '100%',
      paddingTop: '10px'
    },
    tr: {
      display: 'table-row',
      whiteSpace: 'nowrap'
    }
  })
)
interface PromotionUseItem {
  pomotionUsePercent?: number
  promotionTransactionsCount?: number
  totalTransactionsCount?: number
}

interface PromotionUseRateProps extends SelectionConstraints {
  token: string
  tenant_id: string | null
  title: string
}

export const PromotionUseRate = ({
  country,
  merchant,
  range,
  token,
  tenant_id,
  title
}: PromotionUseRateProps): JSX.Element => {
  const classes = useStyles()
  const getData = getPromotionUseRate(token, tenant_id)
  const [data, setData] = useState<PromotionUseItem>({})
  const [isLoading, setIsLoading] = useState(false)

  let name = 'Global'

  if (merchant) {
    name = country + ' / ' + merchant
  } else if (country) {
    name = country || ''
  }

  useEffect((): void => {
    const fetchData = async (): Promise<any> => {
      setIsLoading(true)
      const result = await getData({ range, country, merchant })
      if (result) {
        const mapped: PromotionUseItem = result
        setData(mapped)
      }
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, merchant, range])
  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Typography
          variant="subtitle2"
          component="h3"
          className={classes.caption}
        >
          {title ? title : `Promotion Use Rate for ${name}`}
          {isLoading && (
            <CircularProgress
              className={classes.progress}
              size={15}
              color={'secondary'}
            />
          )}
        </Typography>
        <div className={classes.table}>
          <div className={classes.tr}>
            <span className={classes.value}>
              {getChips(data.pomotionUsePercent || 0)} %
            </span>
          </div>
          <div className={classes.tr}>
            <span className={classes.description}>For Current Time Period</span>
          </div>
        </div>
      </Paper>
    </Grid>
  )
}
