import { DateTime } from 'luxon'
import tz from 'tz-lookup'

export const getCurrentTimeFormated = (lat, lon) => {
  try {
    const local = DateTime.local()
    return local.setZone(tz(lat, lon)).toFormat('t')
  } catch (error) {
    console.warn({ lat, lon })
    return 'not available'
  }
}
