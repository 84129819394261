import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  Paper,
  Typography,
  CircularProgress,
  Select,
  Box,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Grid
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'
import { getMerchant, chooseRegion } from '../../utils'
import { State } from '../../reducers'
import { SelectionConstraints, getSalesVolumeWeekly } from '../../rawApi'
import { getUsdRate, getBaseCurrency } from '../../selectors'
import { DateTime } from 'luxon'
import { Download } from './Download'
import {
  last,
  getPredictions,
  convertDateToTimestamp,
  linearRegression
} from '../../common/forecast/forecastManager'
import Chart from 'react-apexcharts'
import { BaseRates, RegionTotal } from '../../reducers/constraints'
import { createSelector } from 'reselect'

interface DataType {
  date: Date
  value: number
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      marginTop: '5px',
      marginBottom: '10px'
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    caption: {
      textAlign: 'center',
      color: theme.palette.text.primary
    },
    formControl: {
      margin: theme.spacing(1)
    },
    legend: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px'
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '10px',
      '&:last-child': {
        marginRight: '0px'
      }
    },
    legendMarker: {
      width: '10px',
      height: '10px',
      background: 'red',
      borderRadius: '100%',
      marginRight: '5px'
    },
    legendText: {
      fontSize: '13px',
      margin: '0px',
      color: 'rgb(53,53,53)'
    }
  })
)

interface LiquidityRiskProps {
  region: RegionTotal | null
  merchant: any
  token: string
  tenant_id: string | null
  datesRange: { startDate: Date; endDate: Date }
  rates: BaseRates
  countries: string[] | undefined
  merchantIds: string[] | undefined
}

const mapStateToProps = createSelector(
  (state: State): LiquidityRiskProps['region'] =>
    (state.mapState.position.country &&
      chooseRegion(
        state.constraintsState.regions,
        state.mapState.position.country
      )) ||
    null,
  (state: State): LiquidityRiskProps['merchant'] =>
    state.mapState.position.merchantId
      ? getMerchant(state.mapState.geojson, state.mapState.position.merchantId)
      : undefined,
  (state: State): LiquidityRiskProps['token'] => state.userState.token || '',
  (state: State): LiquidityRiskProps['tenant_id'] => state.userState.tenant_id,
  (state: State): LiquidityRiskProps['datesRange']['startDate'] =>
    state.constraintsState.startDate,
  (state: State): LiquidityRiskProps['datesRange']['endDate'] =>
    state.constraintsState.endDate,
  (state: State): LiquidityRiskProps['rates'] =>
    state.constraintsState.baseRates,
  (state: State): LiquidityRiskProps['countries'] =>
    state.mapState.position.countries,
  (state: State): LiquidityRiskProps['merchantIds'] =>
    state.mapState.position.merchantIds,
  (
    region,
    merchant,
    token,
    tenant_id,
    startDate,
    endDate,
    rates,
    countries,
    merchantIds
  ): LiquidityRiskProps => ({
    region,
    merchant,
    token,
    tenant_id,
    datesRange: { startDate, endDate },
    rates,
    countries,
    merchantIds
  })
)

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function getRandomElement<T>(array: T[]): T {
  const randomIndex = Math.floor(Math.random() * array.length)
  return array[randomIndex]
}

export const LiquidityRisk = ({ title }: any): JSX.Element => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState('Line')
  const [timelineType, setTimelineType] = useState('Monthly')
  const [dailyGlobalExpData, setDailyGlobalExpData] = useState<DataType[]>([])
  const [dailyNegativeGlobalExpData, setDailyNegativeGlobalExpData] = useState<
    DataType[]
  >([])
  const [dailyPositiveGlobalExpData, setDailyPositiveGlobalExpData] = useState<
    DataType[]
  >([])
  const [weeklyGlobalExpData, setWeeklyGlobalExpData] = useState<DataType[]>([])
  const [
    weeklyNegativeGlobalExpData,
    setWeeklyNegativeGlobalExpData
  ] = useState<DataType[]>([])
  const [
    weeklyPositiveGlobalExpData,
    setWeeklyPositiveGlobalExpData
  ] = useState<DataType[]>([])
  const [monthlyGlobalExpData, setMonthlyGlobalExpData] = useState<DataType[]>(
    []
  )
  const [
    monthlyNegativeGlobalExpData,
    setMonthlyNegativeGlobalExpData
  ] = useState<DataType[]>([])
  const [
    monthlyPositiveGlobalExpData,
    setMonthlyPositiveGlobalExpData
  ] = useState<DataType[]>([])
  const [dailyLocationExpData, setDailyLocationExpData] = useState<DataType[]>(
    []
  )
  const [
    dailyNegativeLocationExpData,
    setDailyNegativeLocationExpData
  ] = useState<DataType[]>([])
  const [
    dailyPositiveLocationExpData,
    setDailyPositiveLocationExpData
  ] = useState<DataType[]>([])
  const [weeklyLocationExpData, setWeeklyLocationExpData] = useState<
    DataType[]
  >([])
  const [
    weeklyNegativeLocationExpData,
    setWeeklyNegativeLocationExpData
  ] = useState<DataType[]>([])
  const [
    weeklyPositiveLocationExpData,
    setWeeklyPositiveLocationExpData
  ] = useState<DataType[]>([])
  const [monthlyLocationExpData, setMonthlyLocationExpData] = useState<
    DataType[]
  >([])
  const [
    monthlyNegativeLocationExpData,
    setMonthlyNegativeLocationExpData
  ] = useState<DataType[]>([])
  const [
    monthlyPositiveLocationExpData,
    setMonthlyPositiveLocationExpData
  ] = useState<DataType[]>([])
  const [dailyDownloadData, setDailyDownloadData] = useState<any[]>([])
  const [weeklyDownloadData, setWeeklyDownloadData] = useState<any[]>([])
  const [monthlyDownloadData, setMonthlyDownloadData] = useState<any[]>([])
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})
  const {
    region,
    merchant,
    token,
    datesRange,
    rates,
    tenant_id,
    countries,
    merchantIds
  } = useSelector(mapStateToProps)
  const merchantId = merchant ? merchant.merchant_id : undefined
  const country = region && region.country ? region.country : undefined

  let date = new Date(datesRange.endDate)
  const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
  const forecastEndDate = new Date(date.getFullYear(), date.getMonth() + 3, 0)
  const oneDay = 24 * 60 * 60 * 1000
  // @ts-ignore
  const forecastDays =
    // @ts-ignore
    Math.round(Math.abs((forecastEndDate - endDate) / oneDay)) - 1
  const startDate = DateTime.fromJSDate(datesRange.endDate)
    .minus({ days: forecastDays * 2 + 4 })
    .toJSDate()

  const cProps: SelectionConstraints = {
    tenant_id,
    country,
    merchant: merchantId,
    range: {
      startDate,
      endDate
    },
    countries,
    merchantIds
  }

  const USDRate = useSelector(getUsdRate)
  const baseCurrency = useSelector(getBaseCurrency)

  let name = 'Global'

  if (merchantId) {
    name = country + ' / ' + merchantId
  } else if (country) {
    name = country || ''
  }

  const currencyCode =
    name === 'Global' ? baseCurrency : (region && region.currency) || 'USD'
  let transformRate = USDRate ? USDRate : 1

  let oldCurrency = 0,
    newCurrency = 0
  if (rates.hasOwnProperty(baseCurrency)) {
    oldCurrency = rates[baseCurrency]
  }
  if (rates.hasOwnProperty(currencyCode)) {
    newCurrency = rates[currencyCode]
  }

  const types = ['Line', 'Bar', 'Area']

  const timelineTypes = ['Daily', 'Weekly', 'Monthly']

  const getMonthlyData = (dailyData: DataType[]): DataType[] => {
    let tmpYear = 0,
      tmpMonth = 0
    let monthlyData: DataType[] = []
    dailyData.map(item => {
      if (
        item.date.getFullYear() !== tmpYear ||
        item.date.getMonth() !== tmpMonth
      ) {
        monthlyData.push({
          date: item.date,
          value: 0
        })
        tmpYear = item.date.getFullYear()
        tmpMonth = item.date.getMonth()
      }
    })

    monthlyData.map(monthlyItem => {
      dailyData.map(newItem => {
        if (
          newItem.date.getFullYear() === monthlyItem.date.getFullYear() &&
          newItem.date.getMonth() === monthlyItem.date.getMonth()
        ) {
          monthlyItem.value += newItem.value
        }
      })
    })
    return monthlyData
  }

  const getWeeklyData = (dailyData: DataType[]): DataType[] => {
    let count = 0,
      tmpValue = 0,
      tmpDate: Date
    let weeklyData: DataType[] = []
    dailyData.map(item => {
      if (count === 0) {
        tmpDate = item.date
      }
      tmpValue += item.value
      count++
      if (count === 7) {
        weeklyData.push({
          date: tmpDate,
          value: tmpValue
        })
        count = 0
        tmpValue = 0
      }
    })
    return weeklyData
  }

  function generateDates(startDate: string, endDate: string) {
    var start = new Date(startDate)
    var end = new Date(endDate)
    for (var arr = [], dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt).toISOString().slice(0, 10))
    }
    return arr
  }

  useEffect((): void => {
    // for the case of global chart, need to wait for currencies data
    if (((!countries || !merchantIds) && !USDRate) || !token) return

    const fetchData = async (): Promise<any> => {
      setIsLoading(true)
      const result = await getSalesVolumeWeekly(token, cProps)
      if (
        result &&
        cProps.range &&
        cProps.range.startDate &&
        result.aggregations.perDay.buckets.length > 0
      ) {
        let data: DataType[] = []
        let tmpData: any[] = []
        let dates: any[] = []
        let newRevenueData: DataType[] = []
        let newGlobalExpData: DataType[] = []
        let newNegativeGlobalExpData: DataType[] = []
        let newPositiveGlobalExpData: DataType[] = []
        let newLocationExpData: DataType[] = []
        let newNegativeLocationExpData: DataType[] = []
        let newPositiveLocationExpData: DataType[] = []
        let newDownloadData: any[] = []
        let categories: any[] = []

        result.aggregations.perDay.buckets.forEach(
          (item, index: number): void => {
            let value = 0
            const itemDate = DateTime.fromISO(item.key_as_string).toJSDate()
            for (let h in item.perHour.buckets) {
              value += item.perHour.buckets[h].gross.value * transformRate
            }
            // data.push({
            //   date: itemDate,
            //   value: value
            // })
            tmpData.push(value)
            dates.push(item.key_as_string)
            if (DateTime.fromJSDate(itemDate).month === DateTime.now().month) {
              newRevenueData.push({
                date: itemDate,
                value: value
              })
              categories.push(itemDate)
            }
          }
        )

        const average = (list: number[]): number =>
          list.reduce((prev, curr) => prev + curr, 0) / list.length

        const tmpAverageData = average(tmpData)

        var allDates = generateDates(dates[0], dates[dates.length - 1])

        var dateToData = dates.reduce(function(map, date, index) {
          map[date.slice(0, 10)] = tmpData[index]
          return map
        }, {})

        // Interpolate data for missing dates
        allDates.forEach(function(date, index) {
          if (!(date in dateToData)) {
            var prevData = null
            for (var i = index - 1; i >= 0; i--) {
              if (allDates[i] in dateToData) {
                prevData = dateToData[allDates[i]]
                break
              }
            }
            var nextData = null
            for (var i = index + 1; i < allDates.length; i++) {
              if (allDates[i] in dateToData) {
                nextData = dateToData[allDates[i]]
                break
              }
            }
            if (prevData !== null && nextData !== null) {
              dateToData[date] = (prevData + nextData) / 2
            } else if (prevData !== null) {
              dateToData[date] = prevData
            } else if (nextData !== null) {
              dateToData[date] = nextData
            }
          }
        })

        // Convert the object to an array of pairs
        let pairs: [string, number][] = Object.keys(dateToData).map(
          (key: string) => {
            return [key, dateToData[key]]
          }
        )

        // Sort the array by the date (which is the first item of each pair)
        pairs.sort((pair1: [string, number], pair2: [string, number]) => {
          return new Date(pair1[0]).getTime() - new Date(pair2[0]).getTime()
        })

        // If you need to convert it back to an object
        let sortedData: { [key: string]: number } = {}
        pairs.forEach((pair: [string, number]) => {
          sortedData[pair[0]] = pair[1]
        })

        // Get the final dates and data
        dates = Object.keys(sortedData)
        data = dates.map(function(date) {
          return { date: date, value: sortedData[date] }
        })
        // let timestamps = data.map(i => i.date).map(convertDateToTimestamp)
        // let result1 = linearRegression(
        //   timestamps,
        //   data.map(i => i.value)
        // )

        let date1 = new Date(startDate)
        let date2 = new Date(data[0].date)
        let data1: any[] = []

        date2.setDate(date2.getDate() - 1)

        if (
          date1.getFullYear() != date2.getFullYear() ||
          date1.getDate() != date2.getDate() ||
          date1.getMonth() != date2.getMonth()
        ) {
          var getDaysArray = function(s: any, e: any) {
            for (
              var a = [], d = new Date(s);
              d < new Date(e);
              d.setDate(d.getDate() + 1)
            ) {
              a.push(new Date(d))
            }
            return a
          }
          var daylist = getDaysArray(date1, date2)

          daylist.map(date => {
            //const futureTimestamp = convertDateToTimestamp(date)
            // const prediction =
            //   result1.slope * futureTimestamp + result1.intercept
            const prediction =
              getRandomElement(tmpData) +
              (tmpAverageData * randomIntFromInterval(-3, 3)) /
                (tmpAverageData / 100)
            data1.push({
              date: date,
              value: prediction
            })
          })
        }

        data = data1.concat(data)

        const FORECAST_CONFIG = {
          periodSize: forecastDays,
          observationsToForeast: forecastDays - 1
        }

        const dataPreparedToPredict: number[] = data.map(i => i.value)
        // Taking only predicted values + one point for existing history to make chart smoother

        const predictedRow = last(
          //@ts-ignore
          getPredictions(dataPreparedToPredict, FORECAST_CONFIG),
          FORECAST_CONFIG.observationsToForeast + 1
        )

        // calculate the values of 3 months
        let predicted = false

        if (predictedRow && predictedRow.length === forecastDays) {
          predicted = true
        }

        let index = newRevenueData.length

        for (let i = 0; i < forecastDays; i++, index++) {
          //simple linear regression y = x * m + b
          const date = DateTime.now()
            .plus({ days: i + 1 })
            .toJSDate()
          newRevenueData.push({
            date: date,
            value: predicted === true ? predictedRow[i] : 0
          })
          categories.push(date)
        }
        // calculate expenditure from revenue data like -30% variant
        let currencyRate = 1
        if (newCurrency !== 0) currencyRate = oldCurrency / newCurrency

        newRevenueData.map(item => {
          const GlobalExpData = (item.value - item.value * 0.3) * transformRate
          const negativeGlobalExpData =
            GlobalExpData * (1 - randomIntFromInterval(6, 12) / 100)
          const positiveGlobalExpData =
            GlobalExpData * (1 + randomIntFromInterval(6, 12) / 100)
          const LocationExpData = (item.value - item.value * 0.3) * currencyRate
          const negativeLocationExpData =
            LocationExpData * (1 - randomIntFromInterval(6, 12) / 100)
          const positiveLocationExpData =
            LocationExpData * (1 + randomIntFromInterval(6, 12) / 100)

          newGlobalExpData.push({
            date: item.date,
            value: GlobalExpData
          })
          newLocationExpData.push({
            date: item.date,
            value: LocationExpData
          })
          newNegativeGlobalExpData.push({
            date: item.date,
            value: negativeGlobalExpData
          })
          newNegativeLocationExpData.push({
            date: item.date,
            value: negativeLocationExpData
          })
          newPositiveGlobalExpData.push({
            date: item.date,
            value: positiveGlobalExpData
          })
          newPositiveLocationExpData.push({
            date: item.date,
            value: positiveLocationExpData
          })
          if (baseCurrency === currencyCode) {
            newDownloadData.push({
              date: item.date,
              globalExp: GlobalExpData,
              negativeGlobalExp: negativeGlobalExpData,
              positiveGlobalExp: positiveGlobalExpData
            })
          } else {
            newDownloadData.push({
              date: item.date,
              globalExp: GlobalExpData,
              negativeGlobalExp: negativeGlobalExpData,
              positiveGlobalExp: positiveGlobalExpData,
              locationExp: LocationExpData,
              negativeLocationExp: negativeLocationExpData,
              positiveLocationExp: positiveLocationExpData
            })
          }
        })

        setDailyGlobalExpData(newGlobalExpData)
        setDailyNegativeGlobalExpData(newNegativeGlobalExpData)
        setDailyPositiveGlobalExpData(newPositiveGlobalExpData)
        setDailyLocationExpData(newLocationExpData)
        setDailyNegativeLocationExpData(newNegativeLocationExpData)
        setDailyPositiveLocationExpData(newPositiveLocationExpData)
        setDailyDownloadData(newDownloadData)

        // calculate monthly data
        const newMonthlyGlobalExpData = getMonthlyData(newGlobalExpData)
        const newMonthlyLocationExpData = getMonthlyData(newLocationExpData)
        const newMonthlyNegativeGlobalExpData = getMonthlyData(
          newNegativeGlobalExpData
        )
        const newMonthlyNegativeLocationExpData = getMonthlyData(
          newNegativeLocationExpData
        )
        const newMonthlyPositiveGlobalExpData = getMonthlyData(
          newPositiveGlobalExpData
        )
        const newMonthlyPositiveLocationExpData = getMonthlyData(
          newPositiveLocationExpData
        )
        let newMonthlyDownloadData
        if (baseCurrency === currencyCode) {
          newMonthlyDownloadData = newMonthlyGlobalExpData.map(
            (item, index) => {
              return {
                date: item.date,
                globalExp: item.value,
                negativeGlobalExp: newMonthlyNegativeGlobalExpData[index].value,
                positiveGlobalExp: newMonthlyPositiveGlobalExpData[index].value
              }
            }
          )
        } else {
          newMonthlyDownloadData = newMonthlyGlobalExpData.map(
            (item, index) => {
              return {
                date: item.date,
                globalExp: item.value,
                negativeGlobalExp: newMonthlyNegativeGlobalExpData[index].value,
                positiveGlobalExp: newMonthlyPositiveGlobalExpData[index].value,
                locationExp: newMonthlyLocationExpData[index].value,
                negativeLocationExp:
                  newMonthlyNegativeLocationExpData[index].value,
                positiveLocationExp:
                  newMonthlyPositiveLocationExpData[index].value
              }
            }
          )
        }

        setMonthlyGlobalExpData(newMonthlyGlobalExpData)
        setMonthlyLocationExpData(newMonthlyLocationExpData)
        setMonthlyNegativeGlobalExpData(newMonthlyNegativeGlobalExpData)
        setMonthlyNegativeLocationExpData(newMonthlyNegativeLocationExpData)
        setMonthlyPositiveGlobalExpData(newMonthlyPositiveGlobalExpData)
        setMonthlyPositiveLocationExpData(newMonthlyPositiveLocationExpData)
        setMonthlyDownloadData(newMonthlyDownloadData)
        setDefaultOptions(
          newMonthlyGlobalExpData.map(i =>
            DateTime.fromJSDate(i.date).toFormat('LLL')
          )
        )

        // calculate weekly data
        const newWeeklyGlobalExpData = getWeeklyData(newGlobalExpData)
        const newWeeklyLocationExpData = getWeeklyData(newLocationExpData)
        const newWeeklyNegativeGlobalExpData = getWeeklyData(
          newNegativeGlobalExpData
        )
        const newWeeklyNegativeLocationExpData = getWeeklyData(
          newNegativeLocationExpData
        )
        const newWeeklyPositiveGlobalExpData = getWeeklyData(
          newPositiveGlobalExpData
        )
        const newWeeklyPositiveLocationExpData = getWeeklyData(
          newPositiveLocationExpData
        )
        let newWeeklyDownloadData
        if (baseCurrency === currencyCode) {
          newWeeklyDownloadData = newWeeklyGlobalExpData.map((item, index) => {
            return {
              date: item.date,
              globalExp: item.value,
              negativeGlobalExp: newWeeklyNegativeGlobalExpData[index].value,
              positiveGlobalExp: newWeeklyPositiveGlobalExpData[index].value
            }
          })
        } else {
          newWeeklyDownloadData = newWeeklyGlobalExpData.map((item, index) => {
            return {
              date: item.date,
              globalExp: item.value,
              negativeGlobalExp: newWeeklyNegativeGlobalExpData[index].value,
              positiveGlobalExp: newWeeklyPositiveGlobalExpData[index].value,
              locationExp: newWeeklyLocationExpData[index].value,
              negativeLocationExp:
                newWeeklyNegativeLocationExpData[index].value,
              positiveLocationExp: newWeeklyPositiveLocationExpData[index].value
            }
          })
        }
        setWeeklyGlobalExpData(newWeeklyGlobalExpData)
        setWeeklyLocationExpData(newWeeklyLocationExpData)
        setWeeklyNegativeGlobalExpData(newWeeklyNegativeGlobalExpData)
        setWeeklyNegativeLocationExpData(newWeeklyNegativeLocationExpData)
        setWeeklyPositiveGlobalExpData(newWeeklyPositiveGlobalExpData)
        setWeeklyPositiveLocationExpData(newWeeklyPositiveLocationExpData)
        setWeeklyDownloadData(newWeeklyDownloadData)
      }
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, token, datesRange, country, USDRate, countries, merchantIds])

  const setDefaultOptions = (mapped: any[]) => {
    setOptions({
      ...options,
      chart: {
        zoom: { enabled: false },
        toolbar: { show: false }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 1
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      tooltip: {
        y: {
          formatter: (val: number, index: any) => {
            if (index.seriesIndex > 2) {
              return val.toLocaleString('en-US', {
                style: 'currency',
                currency: currencyCode
              })
            } else {
              return val.toLocaleString('en-US', {
                style: 'currency',
                currency: baseCurrency
              })
            }
          }
        }
      },
      xaxis: {
        categories: mapped && mapped.length > 0 ? mapped : [],
        tickAmount: 20
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            //@ts-ignore
            return Intl.NumberFormat('en-US', {
              //@ts-ignore
              notation: 'compact',
              compactDisplay: 'short'
            }).format(val)
          }
        }
      },
      fill: {
        type: 'solid'
      }
    })
  }

  useEffect(() => {
    let series: any[] = []

    const positiveGlobalExp =
      timelineType === 'Daily'
        ? dailyPositiveGlobalExpData
        : timelineType === 'Weekly'
        ? weeklyPositiveGlobalExpData
        : monthlyPositiveGlobalExpData
    const globalExp =
      timelineType === 'Daily'
        ? dailyGlobalExpData
        : timelineType === 'Weekly'
        ? weeklyGlobalExpData
        : monthlyGlobalExpData
    const negativeGlobalExp =
      timelineType === 'Daily'
        ? dailyNegativeGlobalExpData
        : timelineType === 'Weekly'
        ? weeklyNegativeGlobalExpData
        : monthlyNegativeGlobalExpData

    if (timelineType === 'Monthly') {
      setDefaultOptions(
        globalExp.map(i => DateTime.fromJSDate(i.date).toFormat('LLL'))
      )
    } else {
      setDefaultOptions(
        globalExp.map(i => DateTime.fromJSDate(i.date).toFormat('LLL dd'))
      )
    }

    series.push(
      {
        name: baseCurrency + ' Potential High',
        data: positiveGlobalExp.map(i => i.value)
      },
      {
        name: baseCurrency + ' Projection',
        data: globalExp.map(i => i.value)
      },
      {
        name: baseCurrency + ' Potential Low',
        data: negativeGlobalExp.map(i => i.value)
      }
    )

    if (baseCurrency !== currencyCode) {
      const positiveLocationExp =
        timelineType === 'Daily'
          ? dailyPositiveLocationExpData
          : timelineType === 'Weekly'
          ? weeklyPositiveLocationExpData
          : monthlyPositiveLocationExpData
      const locationExp =
        timelineType === 'Daily'
          ? dailyLocationExpData
          : timelineType === 'Weekly'
          ? weeklyLocationExpData
          : monthlyLocationExpData
      const negativeLocationExp =
        timelineType === 'Daily'
          ? dailyNegativeLocationExpData
          : timelineType === 'Weekly'
          ? weeklyNegativeLocationExpData
          : monthlyNegativeLocationExpData
      series.push(
        {
          name: currencyCode + ' Potential High',
          data: positiveLocationExp.map(i => i.value)
        },
        {
          name: currencyCode + ' Projection',
          data: locationExp.map(i => i.value)
        },
        {
          name: currencyCode + ' Potential Low',
          data: negativeLocationExp.map(i => i.value)
        }
      )
    }

    setSeries(series)
  }, [timelineType, isLoading])

  useEffect(() => {
    switch (type) {
      case 'Line':
        setOptions({
          ...options,
          chart: {
            type: 'line',
            stacked: false
          }
        })
        break
      case 'Bar':
        setOptions({
          ...options,
          chart: {
            type: 'bar',
            stacked: true
          }
        })
        break
      case 'Area':
        setOptions({
          ...options,
          chart: {
            type: 'area'
          }
        })
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isLoading])

  const typeChange = (event: SelectChangeEvent): void => {
    setType(event.target.value as string)
  }

  const timelineTypeChange = (event: SelectChangeEvent): void => {
    setTimelineType(event.target.value as string)
  }

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={timelineType}
              onChange={timelineTypeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {timelineTypes.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {title ? title : 'Liquidity Risk based on Foreign Exchange'}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={type}
              onChange={typeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {types.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        <Chart
          options={options}
          series={series}
          type={type === 'Line' ? 'line' : type === 'Bar' ? 'bar' : 'area'}
          height="400"
        />
        <Download
          data={
            timelineType === 'Daily'
              ? dailyDownloadData
              : timelineType === 'Weekly'
              ? weeklyDownloadData
              : monthlyDownloadData
          }
        />
      </Paper>
    </Grid>
  )
}
