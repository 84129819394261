import React, { useEffect, useState } from 'react'
import { Paper, Grid, Box, Typography, CircularProgress } from '@mui/material'
import { StyleRules, makeStyles } from '@mui/styles'
import Chart from 'react-apexcharts'
import { Theme } from '@mui/material/styles'
import { Download } from './Download'
import { getPerformanceCloseDebt } from '../../api'

interface IDownloadType {
  description: string
  amount: string
  percentage: string
}

interface AmountPercentage {
  description: string
  amount: number
  percentage: number
}

interface PerformanceCloseDebtType {
  placed_debts: number
  debt_amount: number
  details: AmountPercentage[]
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '35px 25px',
      marginTop: '10px',
      overflow: 'hidden'
    },
    box: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    tableBox: {
      flex: 1
    },
    tableBody: {
      marginBottom: '20px'
    },
    tableCaption: {
      color: theme.palette.text.primary,
      borderBottom: '1px solid #E1E1E1',
      paddingBottom: '17px',
      textTransform: 'uppercase'
    },
    tableRowBox: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '10px',
      paddingBottom: '10px',
      borderBottom: '1px solid #E1E1E1'
    },
    text: {
      fontSize: '12px',
      flex: 1
    },
    chartBox: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    chartCaption: {
      color: theme.palette.text.primary,
      marginBottom: '10px',
      textAlign: 'center'
    }
  })
)

const PerformanceCloseDebts = ({ title, token }: any) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})
  const [downloadData, setDownloadData] = useState<IDownloadType[]>([])
  const [data, setData] = useState<PerformanceCloseDebtType>()

  useEffect(() => {}, [])

  const currencyCode = 'USD'

  useEffect((): void => {
    if (!token) return
    const callPerformanceCloseDebt = async (): Promise<any> => {
      const result: any = await getPerformanceCloseDebt({
        token
      })
      setData(result)

      var tmpData: IDownloadType[] = []
      tmpData.push({
        description: 'Placed Debts',
        amount: result?.placed_debts?.toString(),
        percentage: ''
      })
      tmpData.push({
        description: 'Debt Amount',
        amount: result?.debt_amount?.toString(),
        percentage: ''
      })
      result?.details.map((item: AmountPercentage) => {
        tmpData.push({
          description: item.description,
          amount: item.amount?.toString(),
          percentage: item.percentage?.toString()
        })
      })

      setDownloadData(tmpData)
      var collectedValue = 0,
        unCollectedValue = 0

      if (result && result?.details) {
        result?.details?.map((item: AmountPercentage) => {
          if (item.description == 'Uncollected') {
            unCollectedValue = item.percentage
          } else {
            collectedValue += item.percentage
          }
        })
        setOptions({
          chart: {
            height: 400,
            type: 'pie'
          },
          labels: ['Uncollected', 'Collected'],
          legend: {
            position: 'bottom'
          }
        })
      }

      setSeries([unCollectedValue, collectedValue])
    }
    callPerformanceCloseDebt()
  }, [token])

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <Box className={classes.tableBox}>
            <Typography
              variant="subtitle2"
              component="h3"
              className={classes.tableCaption}
            >
              {title}
              {isLoading && (
                <CircularProgress
                  className={classes.progress}
                  size={15}
                  color={'secondary'}
                />
              )}
            </Typography>
            <Box className={classes.tableBody}>
              <Box className={classes.tableRowBox}>
                <Typography variant="subtitle2" className={classes.text}>
                  Placed Debts
                </Typography>
                <Typography variant="subtitle2" className={classes.text}>
                  {data?.placed_debts?.toLocaleString()}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.text}
                  sx={{ flex: '0.5 !important' }}
                ></Typography>
              </Box>
              <Box className={classes.tableRowBox}>
                <Typography variant="subtitle2" className={classes.text}>
                  Debt Amount
                </Typography>
                <Typography variant="subtitle2" className={classes.text}>
                  {data?.debt_amount
                    ? new Intl.NumberFormat(undefined, {
                        style: 'currency',
                        currency: currencyCode,
                        maximumFractionDigits: 0
                      }).format(Math.floor(data?.debt_amount))
                    : '-'}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.text}
                  sx={{ flex: '0.5 !important' }}
                ></Typography>
              </Box>
              {data?.details?.map((item, index) => (
                <Box className={classes.tableRowBox} key={index}>
                  <Typography variant="subtitle2" className={classes.text}>
                    {item.description}
                  </Typography>
                  {item.description == 'Payments' ? (
                    <Typography variant="subtitle2" className={classes.text}>
                      {item.amount
                        ? new Intl.NumberFormat(undefined, {
                            style: 'currency',
                            currency: currencyCode,
                            maximumFractionDigits: 0
                          }).format(Math.floor(item.amount))
                        : '-'}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2" className={classes.text}>
                      {item.amount
                        ? Math.floor(item.amount)?.toLocaleString()
                        : ''}
                    </Typography>
                  )}
                  <Typography
                    variant="subtitle2"
                    className={classes.text}
                    sx={{ flex: '0.5 !important' }}
                  >
                    {item.percentage}%
                  </Typography>
                </Box>
              ))}
            </Box>
            <Download data={downloadData} />
          </Box>
          <Box className={classes.chartBox}>
            <Typography
              variant="subtitle2"
              component="h3"
              className={classes.chartCaption}
            >
              Performance
            </Typography>
            <Chart
              options={options}
              series={series}
              type={'pie'}
              height="300"
            />
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
}

export default PerformanceCloseDebts
