import { Feature } from 'geojson'

import { RegionTotal, LastWeek } from './reducers/constraints'
import { ShapeMapOverview, MapOverviewCountry } from './api'
import { mapCountryToCurrency, currencies } from './temp/currencies_map'
const getNested = (arr: {}[], prop: string): {}[] => {
  return arr.map((item: any): {} => item[prop])
}

// tp_loc_aggregation_30m
interface TPLA30mItemSource {
  address: string
  count: number
  country_name: string
  country_tag: string
  currency: string
  end_time: string
  fees: number
  gross: number
  group_id: string
  latitude: number
  longitude: number
  merchant_id: string
  net: number
  refunds: number
  region_id: number
  region_name: string
  start_time: string
  tax: number
}

interface TPLA30mItem {
  _id: string
  _index: 'tp_loc_aggregation_30m'
  _score: number
  _source: TPLA30mItemSource
}

// location_total_30m
export interface LocationTotal30mItemSource {
  merchant_id: string
  timestamp: string
  gross: number
  net: number
  tax: number
  fee_total: number
  refunds: number
  refund_count: number
  txn_count: number
  id: string
  datetime_from: string
  datetime_to: string
  txn_ids: string[]
  country: string
  currency: string
  bank_ac: string
  address: string
  location: { lat: number; lon: number }
}
interface LocationTotal30m {
  _id: string
  _index: 'location_total_30m'
  _score: number
  _source: LocationTotal30mItemSource
}

type TPLA30m = TPLA30mItem[]

type NullNum = number | null

export interface ParsedESToGeo {
  geojson: GeoJSON.FeatureCollection
  maxGross: NullNum
  minGross: NullNum
}

interface BasicMap {
  [name: string]: { [name: string]: any }
}

export interface ParsedES {
  arr: BasicMap[]
  maxGross: NullNum
  minGross: NullNum
}

const dailyFormatter = (s: string): string => {
  return s.substr(0, 10)
}
const hourlyFormatter = (s: string): string => {
  return s.substr(0, 13)
}

const mapChaise = (
  arr: {}[],
  prop: string[],
  formatter: (s: string) => string = (s): string => s
): { map: BasicMap; maxGross: NullNum; minGross: NullNum } => {
  const map: BasicMap = {}
  let maxGross: NullNum = null,
    minGross: NullNum = null
  arr.forEach((item: any): any => {
    const key = formatter(item[prop[0]]) + (prop[1] ? item[prop[1]] : '')
    // 7142536875 2019-1-10-12-2
    // 7142536901 2019-1-10-7-2
    if (map[key] === undefined) {
      map[key] = item
    } else {
      map[key].gross += item.gross
      map[key].tax += item.tax
      map[key].net += item.net
      map[key].fee_total += item.fee_total
      map[key].txn_count += item.txn_count
    }
    if (maxGross === null || map[key].gross > maxGross)
      maxGross = map[key].gross
    if (minGross === null || map[key].gross < minGross)
      minGross = map[key].gross
  })
  return { map, maxGross, minGross }
}

export const aggregateGeojsonBy = (arr: {}[], prop: string): ParsedESToGeo => {
  // const map: BasicMap = {}
  const res: Feature[] = []
  const { maxGross, minGross, map } = mapChaise(arr, [prop])
  for (let item in map) {
    res.push({
      type: 'Feature',
      properties: map[item],
      geometry: {
        type: 'Point',
        coordinates: [map[item].location.lon, map[item].location.lat]
      }
    })
  }
  return {
    geojson: { type: 'FeatureCollection', features: res },
    maxGross,
    minGross
  }
}
export const aggregateBy = (arr: {}[], prop: string): ParsedES => {
  // const map: BasicMap = {}
  const res: BasicMap[] = []
  const { maxGross, minGross, map } = mapChaise(arr, [prop])
  for (let item in map) {
    res.push(map[item])
  }
  return {
    arr: res,
    maxGross,
    minGross
  }
}
export const aggregate = (arr: RegionTotal[]): RegionTotal => {
  let gross = 0
  let tax = 0
  let net = 0
  let fee_total = 0
  let txn_count = 0
  let refunds = 0

  arr.forEach((item): void => {
    gross += item.gross ? item.gross : 0
    tax += item.tax ? item.tax : 0
    net += item.net ? item.net : 0
    fee_total += item.fee_total ? item.fee_total : 0
    txn_count += item.txn_count ? item.txn_count : 0
    refunds += item.refunds ? item.refunds : 0
  })
  return { gross, tax, net, fee_total, txn_count, refunds }
}

const calcTrend = (
  current: number | null | undefined,
  past: number | null | undefined
): number | null => {
  if (current === null || past === null) return null
  if (current === 0 && past === 0) return 0
  if (past === 0 && current) return null
  if (current === 0 && past) return -100
  return current != null && past != null
    ? ((current - past) / past) * 100
    : null
}

interface Trends {
  grossTrend?: number | null
  taxTrend?: number | null
  netTrend?: number | null
  feesTrend?: number | null
  countTrend?: number | null
  refundsTrend?: number | null
}

const getTrends = (current: RegionTotal, past: RegionTotal): Trends => {
  const res: Trends = {}
  res.grossTrend = calcTrend(current.gross, past.gross)
  res.feesTrend = calcTrend(current.fee_total, past.fee_total)
  res.netTrend = calcTrend(current.net, past.net)
  res.refundsTrend = calcTrend(current.refunds, past.refunds)
  res.taxTrend = calcTrend(current.tax, past.tax)
  res.countTrend = calcTrend(current.txn_count, past.txn_count)
  return res
}

export const getGlobalTrends = (
  current: ParsedESWithRegions,
  past: ParsedESWithRegions
): Trends => getTrends(current.global, past.global)

export const getRegionsTrends = (
  current: ParsedESWithRegions,
  past: ParsedESWithRegions
): RegionTotal[] => {
  const regions: RegionTotal[] = current.regions.map(
    (region): RegionTotal => {
      const prev = region.country && chooseRegion(past.regions, region.country)
      if (prev) {
        return { ...region, ...getTrends(region, prev) }
      }
      return region
    }
  )
  return regions
}

export const getMerchantTrends = (
  current: ParsedESWithRegions,
  past: ParsedESWithRegions
): GeoJSON.FeatureCollection => {
  const features: GeoJSON.Feature[] = current.geojson.features.map(
    (feature): GeoJSON.Feature => {
      if (feature.properties) {
        const pastMerch = getMerchant(
          past.geojson,
          feature.properties.merchant_id
        )
        const trends = pastMerch ? getTrends(feature.properties, pastMerch) : {}
        return { ...feature, properties: { ...feature.properties, ...trends } }
      }
      return feature
    }
  )
  return { type: 'FeatureCollection', features }
}

interface ParsedESWithRegions extends ParsedESToGeo {
  global: RegionTotal
  regions: RegionTotal[]
}

export const aggregateESBy = (arr: LocationTotal30m[]): ParsedESWithRegions => {
  const merchants = aggregateGeojsonBy(getNested(arr, '_source'), 'merchant_id')
  const regions = aggregateBy(
    getNested(merchants.geojson.features, 'properties'),
    'country'
  )
  const global = aggregate(regions.arr)
  return { ...merchants, global, regions: regions.arr }
}
// ParsedESWithRegions
export const parseMapOverview = ({ items }: ShapeMapOverview, locations: any[]): any => {
  const features: Feature[] = []
  const geojson = { type: 'FeatureCollection', features }
  let maxGross: number | undefined
  let minGross: number | undefined
  const regions = items.map(
    (item: MapOverviewCountry): RegionTotal => {
      item.locations.forEach((location): void => {
        if (maxGross === undefined || maxGross < location.currentTotals.gross)
          maxGross = location.currentTotals.gross
        if (minGross === undefined || minGross > location.currentTotals.gross)
          minGross = location.currentTotals.gross
        let merchant_type;
        const result = locations.filter((location1: any) => location1.merchantId == location.id)
        if (result && result.length > 0) {
          merchant_type = result[0].merchantType
        }
        geojson.features.push({
          type: 'Feature',
          properties: {
            address: location.address,
            country: location.country,
            latitude: location.latitude,
            longitude: location.longitude,
            location: { lat: location.latitude, lon: location.longitude },
            id: location.id,
            merchant_id: location.id,
            merchant_type,
            fee_total: location.currentTotals.fees,
            tax: location.currentTotals.taxes,
            ...location.currentTotals,
            currency: mapCountryToCurrency[location.country] || 'USD', //TODO: change to currency when exists
            txn_count: location.currentTotals.txnCount,
            grossTrend: calcTrend(
              location.currentTotals.gross,
              location.lastPeriodTotals.gross
            ),
            taxTrend: calcTrend(
              location.currentTotals.taxes,
              location.lastPeriodTotals.taxes
            ),
            netTrend: calcTrend(
              location.currentTotals.net,
              location.lastPeriodTotals.net
            ),
            feesTrend: calcTrend(
              location.currentTotals.fees,
              location.lastPeriodTotals.fees
            ),
            countTrend: calcTrend(
              location.currentTotals.refundsCount,
              location.lastPeriodTotals.refundsCount
            ),
            refundsTrend: calcTrend(
              location.currentTotals.refunds,
              location.lastPeriodTotals.refunds
            )
          },
          geometry: {
            type: 'Point',
            coordinates: [location.longitude, location.latitude]
          }
        })
      })
      return {
        country: item.country,
        currency: mapCountryToCurrency[item.country] || 'USD', //TODO: change to txn count when exists
        gross: item.currentTotals.gross,
        tax: item.currentTotals.taxes,
        net: item.currentTotals.net,
        fee_total: item.currentTotals.fees,
        txn_count: item.currentTotals.txnCount,
        refunds: item.currentTotals.refunds,
        lastPeriodTotals: {
          ...item.lastPeriodTotals,
          fee_total: item.lastPeriodTotals.fees,
          tax: item.lastPeriodTotals.taxes,
          txn_count: item.lastPeriodTotals.txnCount
        },
        grossTrend: calcTrend(
          item.currentTotals.gross,
          item.lastPeriodTotals.gross
        ),
        taxTrend: calcTrend(
          item.currentTotals.taxes,
          item.lastPeriodTotals.taxes
        ),
        netTrend: calcTrend(item.currentTotals.net, item.lastPeriodTotals.net),
        feesTrend: calcTrend(
          item.currentTotals.fees,
          item.lastPeriodTotals.fees
        ),
        countTrend: calcTrend(
          item.currentTotals.refundsCount,
          item.lastPeriodTotals.refundsCount
        ),
        refundsTrend: calcTrend(
          item.currentTotals.refunds,
          item.lastPeriodTotals.refunds
        )
      }
    }
  )
  const global = aggregate(regions)
  const pastRegions = regions.map(
    (region: RegionTotal): RegionTotal['lastPeriodTotals'] =>
      region.lastPeriodTotals
  )
  const pastGlobal = aggregate(pastRegions as any)
  const globalTrends = getTrends(global, pastGlobal)

  if (global.txn_count) global.txn_count *= 11 //TODO: !remove this line! Artificially inflating total for global

  return { maxGross, minGross, geojson, regions, global, globalTrends }
}

const sortAggregation = (
  a: LocationTotal30mItemSource,
  b: LocationTotal30mItemSource
): number => (a.datetime_from >= b.datetime_from ? 1 : -1)
const startTimeFormatter = (s: string): string => s.substr(0, 10)
const startPerHourFormatter = (s: string): string => s.substr(0, 13)
export const aggregateWeek = (arr: LocationTotal30m[]): LastWeek => {
  const hourlyMerch = mapChaise(
    getNested(arr, '_source'),
    ['datetime_from', 'merchant_id'],
    hourlyFormatter
  )
  const hourlyMerchArr: LocationTotal30mItemSource[] = []
  for (let item in hourlyMerch.map)
    hourlyMerchArr.push(hourlyMerch.map[item] as LocationTotal30mItemSource)

  const hourlyCountries = mapChaise(
    hourlyMerchArr,
    ['datetime_from', 'country'],
    startPerHourFormatter
  )
  const hourlyCountriesArr = []
  for (let item in hourlyCountries.map)
    hourlyCountriesArr.push(hourlyCountries.map[
      item
    ] as LocationTotal30mItemSource)

  const dailyMerchMap = mapChaise(
    hourlyMerchArr,
    ['datetime_from', 'merchant_id'],
    dailyFormatter
  )
  const { map } = dailyMerchMap
  let { minGross, maxGross } = dailyMerchMap

  const dailyMerch: LocationTotal30mItemSource[] = []
  for (let item in map) dailyMerch.push(map[item] as LocationTotal30mItemSource)

  const avgMerch = mapChaise(dailyMerch, ['datetime_from'], startTimeFormatter)
  const avgMerchArr = []
  for (let item in avgMerch.map)
    avgMerchArr.push(avgMerch.map[item] as LocationTotal30mItemSource)

  avgMerchArr.forEach((item): void => {
    const gross = item.gross / item.txn_count
    if (gross > (maxGross || 0)) maxGross = gross
    if (gross < (minGross || 0)) minGross = gross
    item.gross = gross
  })

  const countries = mapChaise(
    dailyMerch,
    ['datetime_from', 'country'],
    startTimeFormatter
  )
  const dailyCountries = []
  for (let item in countries.map)
    dailyCountries.push(countries.map[item] as LocationTotal30mItemSource)

  const avgCountries = mapChaise(
    dailyCountries,
    ['datetime_from'],
    startTimeFormatter
  )
  const avgCountriesArr = []
  for (let item in avgCountries.map)
    avgCountriesArr.push(avgCountries.map[item] as LocationTotal30mItemSource)

  avgCountriesArr.forEach((item): void => {
    const gross = (item.gross / dailyCountries.length) * 7
    if (gross > (countries.maxGross || 0)) countries.maxGross = gross
    if (gross < (countries.minGross || 0)) countries.minGross = gross
    item.gross = gross
  })

  return {
    daily: {
      merchants: {
        hits: dailyMerch.sort(sortAggregation),
        avg: avgMerchArr.sort(sortAggregation),
        maxGross: maxGross || 0,
        minGross: minGross || 0
      },
      regions: {
        hits: dailyCountries.sort(sortAggregation),
        avg: avgCountriesArr.sort(sortAggregation),
        maxGross: countries.maxGross || 0,
        minGross: countries.minGross || 0
      }
    },
    hourly: {
      merchants: {
        hits: hourlyMerchArr.sort(sortAggregation),
        maxGross: hourlyMerch.maxGross || 0,
        minGross: hourlyMerch.minGross || 0
      },
      regions: {
        hits: hourlyCountriesArr.sort(sortAggregation),
        maxGross: hourlyCountries.maxGross || 0,
        minGross: hourlyCountries.minGross || 0
      }
    }
  }
}

export interface ChoosenRegions {
  collection: GeoJSON.FeatureCollection
  absGross: number
  absGrossMin: number
}

export const chooseRegions = (
  geojson: GeoJSON.FeatureCollection,
  regions: RegionTotal[]
): ChoosenRegions => {
  const features: GeoJSON.Feature[] = []
  let absGross = 0
  let absGrossMin: number | null = null
  regions.forEach((region): void | undefined => {
    const feature = geojson.features.find(
      (feature): boolean | null =>
        feature.properties && feature.properties.name === region.country
    )
    if (feature) {
      if (region.gross && absGross < Math.abs(region.gross))
        absGross = Math.abs(region.gross)
      if (
        (region.gross && !absGrossMin) ||
        (region.gross && absGrossMin && absGrossMin > Math.abs(region.gross))
      )
        absGrossMin = Math.abs(region.gross)
      features.push({
        ...feature,
        properties: { ...feature.properties, ...region }
      })
    } else console.warn(region)
  })
  return {
    collection: { type: 'FeatureCollection', features },
    absGross,
    absGrossMin: absGrossMin || 0
  }
}

export const chooseRegion = (
  regions: RegionTotal[],
  country: string
): RegionTotal | undefined => {
  return regions.find((region): boolean => region.country === country)
}

export const chooseCountries = (
  regions: RegionTotal[],
  countries: string[]
): RegionTotal | undefined => {
  const res1: any[] = [];

  countries.map((country: string) => {
    const res = regions.find(
      (region): boolean =>
        (region.country === country) ||
        false
    )
    if (res) {
      res1.push(res)
    }
  })

  var ret: RegionTotal = {
    country: '',
    currency: '',
    gross: 0,
    grossTrend: 0,
    net: 0,
    netTrend: 0,
    fee_total: 0,
    feesTrend: 0,
    refunds: 0,
    refundsTrend: 0,
    tax: 0,
    taxTrend: 0,
    txn_count: 0,
    countTrend: 0
  }

  if (res1.length == 1) {
    ret.country = res1[0].country;
    ret.currency = res1[0].currency;
  }

  res1.map((item: any) => {
    ret.gross += item.gross
    ret.grossTrend += item.grossTrend
    ret.net += item.net
    ret.netTrend += item.netTrend
    ret.fee_total += item.fee_total
    ret.feesTrend += item.feesTrend
    ret.refunds += item.refunds
    ret.refundsTrend += item.refundsTrend
    ret.tax += item.tax
    ret.taxTrend += item.taxTrend
    ret.txn_count += item.txn_count
    ret.countTrend += item.countTrend
  })
  
  return ret || null
}

export const selectMerchants = (
  geojson: GeoJSON.FeatureCollection,
  country: string | undefined
): GeoJSON.FeatureCollection => {
  if (!country) return { type: 'FeatureCollection', features: [] }
  const features: GeoJSON.Feature[] = []
  geojson.features.forEach((feature): void => {
    if (feature.properties && country === feature.properties.country)
      features.push(feature)
  })
  return { type: 'FeatureCollection', features }
}

export const getMerchant = (
  geojson: GeoJSON.FeatureCollection,
  merchant: string
): { [name: string]: any } | null => {
  const res = geojson.features.find(
    (feature): boolean =>
      (feature.properties && feature.properties.merchant_id === merchant) ||
      false
  )
  return (res && res.properties) || null
}

export const getMerchants = (
  geojson: GeoJSON.FeatureCollection,
  merchantIds: any[]
): { [name: string]: any } | null => {
  const res1: any[] = [];

  merchantIds.map((merchant: string) => {
    const res = geojson.features.find(
      (feature): boolean =>
        (feature.properties && feature.properties.merchant_id === merchant) ||
        false
    )
    if (res) {
      res1.push(res.properties)
    }
  })

  var ret = {
    country: '',
    address: '',
    currency: '',
    gross: 0,
    grossTrend: 0,
    net: 0,
    netTrend: 0,
    fee_total: 0,
    feesTrend: 0,
    refunds: 0,
    refundsTrend: 0,
    tax: 0,
    taxTrend: 0,
    txn_count: 0,
    countTrend: 0
  }

  if (res1.length == 1) {
    ret.country = res1[0].country;
    ret.address = res1[0].address;
    ret.currency = res1[0].currency;
  }

  res1.map((item: any) => {
    ret.gross += item.gross
    ret.grossTrend += item.grossTrend
    ret.net += item.net
    ret.netTrend += item.netTrend
    ret.fee_total += item.fee_total
    ret.feesTrend += item.feesTrend
    ret.refunds += item.refunds
    ret.refundsTrend += item.refundsTrend
    ret.tax += item.tax
    ret.taxTrend += item.taxTrend
    ret.txn_count += item.txn_count
    ret.countTrend += item.countTrend
  })
  
  return ret || null
}

export const getChips = (value: number, fix: number = 2): string =>
  value.toFixed(fix).replace(/\d(?=(\d{3})+\.)/g, '$&,')

export const getChipWithCurrency = (
  value: number,
  fix: number = 2,
  currencyCode: string = 'USD'
): string => currencies[currencyCode].symbol + ' ' + getChips(value, fix)