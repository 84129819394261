export interface MapPosition {
  global?: boolean
  country?: string
  countries?: string[]
  merchantId?: string
  merchantIds?: string[]
  merchantTypes?: string[]
}
export const SET_POSITION = 'SET_POSITION'
export interface SetPosition {
  type: typeof SET_POSITION
  payload: MapPosition
}

export const setPosition = (payload: MapPosition): SetPosition => ({
  type: SET_POSITION,
  payload
})

export const RESET_POSITION = 'RESET_POSITION'
export interface ResetPosition {
  type: typeof RESET_POSITION
}
export const resetPosition = (): ResetPosition => ({
  type: RESET_POSITION
})

export interface MapReducerState {
  geojson: GeoJSON.FeatureCollection
  position: MapPosition
  accurate: boolean
  style: MapStyle
  locations: []
  merchants: []
}

export const SET_GEOJSON = 'SET_GEOJSON'
export interface SetGeojson {
  type: typeof SET_GEOJSON
  payload: GeoJSON.FeatureCollection
}

export const setGeojson = (payload: GeoJSON.FeatureCollection): SetGeojson => ({
  type: SET_GEOJSON,
  payload
})

export const SET_LOCATIONS = 'SET_LOCATIONS'
export interface SetLocations {
  type: typeof SET_LOCATIONS
  payload: any
}

export const setLocations = (payload: any): SetLocations => ({
  type: SET_LOCATIONS,
  payload
})

export const SET_MERCHANTS = 'SET_MERCHANTS'
export interface SetMerchants {
  type: typeof SET_MERCHANTS
  payload: any
}

export const setMerchants = (payload: any): SetMerchants => ({
  type: SET_MERCHANTS,
  payload
})

export interface MapStyle {
  style?: string
}

export const SET_STYLE = 'SET_STYLE'
export interface SetStyle {
  type: typeof SET_STYLE
  payload: MapStyle
}

export const setStyle = (payload: MapStyle): SetStyle => ({
  type: SET_STYLE,
  payload
})

export const mapReducer = (
  state: MapReducerState = {
    geojson: { type: 'FeatureCollection', features: [] },
    position: { global: true },
    accurate: true,
    style: { style: '' },
    locations: [],
    merchants: []
  },
  action: SetGeojson | SetPosition | ResetPosition | SetStyle | SetLocations | SetMerchants
): MapReducerState => {
  switch (action.type) {
    case SET_GEOJSON:
      return {
        ...state,
        geojson: action.payload
      }
    case SET_POSITION:
      return {
        ...state,
        position: action.payload
      }
    case RESET_POSITION:
      return {
        ...state,
        accurate: false
      }
    case SET_STYLE:
      return {
        ...state,
        style: action.payload
      }
    case SET_LOCATIONS:
      return {
        ...state,
        locations: action.payload
      }
    case SET_MERCHANTS:
      return {
        ...state,
        merchants: action.payload
      }
    default:
      return state
  }
}
