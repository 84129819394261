import { ChoosenRegions, getChipWithCurrency } from '../utils'
import { getCurrentTimeFormated } from '../temp_utils'
import { BaseRates } from '../reducers/constraints'
import { BCCalc } from './BCTooltip'

export const exec3d = (map: mapboxgl.Map): void => {
  map.addLayer({
    id: '3d-buildings',
    source: 'composite',
    'source-layer': 'building',
    filter: ['==', 'extrude', 'true'],
    type: 'fill-extrusion',
    minzoom: 15,
    paint: {
      'fill-extrusion-color': '#aaa',
      'fill-extrusion-height': [
        'interpolate',
        ['linear'],
        ['zoom'],
        15,
        0,
        15.05,
        ['get', 'height']
      ],
      'fill-extrusion-base': [
        'interpolate',
        ['linear'],
        ['zoom'],
        15,
        0,
        15.05,
        ['get', 'min_height']
      ],
      'fill-extrusion-opacity': 0.6
    }
  })
}

interface Description {
  merchant_id?: string | null
  merchant_name?: string | null
  merchant_type?: string | null
  country?: string | null
  gross?: number | null
  net?: number | null
  tax?: number | null
  fee_total?: number | null
  refunds?: number | null
  currency?: string | null
  location: { lat: number; lon: number }
  baseCurr?: string
  rates?: BaseRates
}

function getValueIfBaseCurrency(
  value: number,
  currency: string,
  base?: string,
  rates?: BaseRates
): string {
  return base && rates
    ? BCCalc({ base, rates, value, currency })
    : getChipWithCurrency(value, 2, currency)
}

export const getDescription = ({
  merchant_id,
  merchant_name,
  merchant_type,
  country,
  gross,
  net,
  tax,
  fee_total,
  refunds,
  location,
  currency,
  baseCurr,
  rates
}: Description): string => {
  const t = getCurrentTimeFormated(location.lat, location.lon)
  const cur = currency ? currency : ''
  return `${
    merchant_id !== null
      ? `<span class="merchant-id">#${merchant_id}</span>`
      : ''
  }
    <span class="country-name">${country}</span>
    <span class="local-time">Local Time: ${t}</span>
    <div class="breakdowns">
    ${
      merchant_name !== null
      ? `<span class="description">Merchant:</span><span class="value">
        ${merchant_name}</span><br/>`
      :''
    }
    ${`<span class="description">Gross:</span><span class="value">
          ${getValueIfBaseCurrency(
            gross || 0,
            cur,
            baseCurr,
            rates
          )}</span><br/>`}
    ${`<span class="description">Net:</span><span class="value">
        ${getValueIfBaseCurrency(net || 0, cur, baseCurr, rates)}</span><br/>`}
    ${`<span class="description">Tax:</span><span class="value">
        ${getValueIfBaseCurrency(tax || 0, cur, baseCurr, rates)}</span><br/>`}
    ${`<span class="description">Fees:</span><span class="value">
    ${getValueIfBaseCurrency(
      fee_total || 0,
      cur,
      baseCurr,
      rates
    )}</span><br/>`}
    ${`<span class="description">Refunds:</span><span class="value">
    ${getValueIfBaseCurrency(refunds || 0, cur, baseCurr, rates)}</value>`}
    </div>`
}

const colorsPallete = [
  '#627bc1',
  '#6f77c0',
  '#7d74bf',
  '#8b70be',
  '#986dbd',
  '#a669bc',
  '#b466bb',
  '#c263ba'
]

type Step = string | number
export const createSteps = (
  min: number,
  max: number,
  factor: number = 1,
  arr?: Step[]
): Step[] => {
  if (max === 0) return [4, 0, 4]
  const sizes = [8, 12, 16, 20, 24, 28, 32]
  const inc = ((max - min) / (arr ? arr.length : sizes.length)) >> 0
  const res: Step[] = arr ? [arr[0]] : [4]
  if (arr) {
    arr.forEach((step, index): void => {
      res.push(min + inc * index)
      res.push(step)
    })
  } else {
    sizes.forEach((size, index): void => {
      res.push(min + inc * index)
      res.push(size * factor)
    })
  }
  return res
}

export const setupRegions = (
  regions: ChoosenRegions,
  map: mapboxgl.Map
): void => {
  if (regions.collection.features.length && map.getLayer('world-fills')) {
    // const extrHeightSteps = createSteps(
    //   regions.absGrossMin,
    //   regions.absGross,
    //   91250
    // )
    const extrColorSteps = createSteps(
      regions.absGrossMin,
      regions.absGross,
      1,
      colorsPallete
    )
    // map.setPaintProperty('world-fills', 'fill-extrusion-height', [
    //   'step',
    //   ['get', 'gross'],
    //   ...extrHeightSteps
    // ])
    map.setPaintProperty('world-fills', 'fill-color', [
      'step',
      ['get', 'gross'],
      ...extrColorSteps
    ])
    map.setPaintProperty('world-fills', 'fill-opacity', 0.7)
  }

  const worldSource: mapboxgl.GeoJSONSource = map.getSource(
    'world'
  ) as mapboxgl.GeoJSONSource

  if (worldSource) {
    worldSource.setData(regions.collection)
  } else {
    console.warn('worldSource')
  }
}
