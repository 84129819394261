import axios from './apis/axiosInstance'
import { DateTime } from 'luxon'
import { TopGrossingLocationsInterface } from './apis/TopGrossingLocations'
import { TransitDelaysI } from './apis/TransitDelaysI'

const getSearchQuery = (start: Date, end: Date): {} => ({
  size: 10000,
  query: {
    bool: {
      must: [
        {
          range: {
            datetime_from: {
              gte: start.getTime(),
              lte: end.getTime() + 86399999,
              format: 'epoch_millis'
            }
          }
        }
      ]
    }
  }
})
const getPaymentTypeQuery = ({
  range,
  country,
  merchant,
  tenant_id,
  countries,
  merchantIds
}: SelectionConstraints): {} => {
  if (!range) return {}
  const must = []
  const should: any[] = []

  if (tenant_id) {
    must.push({
      match_phrase: {
        tenant_id: {
          query: tenant_id
        }
      }
    })
  }

  if (countries && countries.length > 0) {
    should.push({
      "terms": {
          "country.keyword": countries
      }
    })
  }

  if (merchantIds && merchantIds.length > 0) {
    should.push({
      "terms": {
        "merchant_id.keyword": merchantIds.map((id: any) => parseInt(id))
      }
    });
  }
  
  return {
    aggs: {
      '2': {
        terms: {
          field: 'payment_type.keyword',
          size: 50,
          order: {
            '1': 'desc'
          }
        },
        aggs: {
          '1': {
            sum: {
              field: 'gross'
            }
          }
        }
      }
    },
    size: 0,
    _source: {
      excludes: []
    },
    stored_fields: ['*'],
    script_fields: {},
    docvalue_fields: [
      {
        field: 'datetime_from',
        format: 'date_time'
      },
      {
        field: 'datetime_to',
        format: 'date_time'
      }
    ],
    query: {
      bool: {
        must: [
          {
            match_all: {}
          },
          {
            match_all: {}
          },
          ...must,
          {
            range: {
              datetime_from: {
                gte: range.startDate && range.startDate.getTime(),
                lte: range.endDate && range.endDate.getTime() + 86399999,
                format: 'epoch_millis'
              }
            }
          }
        ],
        filter: [],
        should: should,
        minimum_should_match: should.length > 0 ? 1 : 0,
        must_not: []
      }
    }
  }
}

export function* callForData(start: Date, end: Date, token: string): Generator {
  return yield axios.post(
    '/settlement/aggregations/raw?index=location_total_30m',
    getSearchQuery(start, end),
    {
      headers: { Authorization: 'Bearer ' + token }
    }
  )
}

interface DateRange {
  startDate?: Date
  endDate?: Date
}

export interface SelectionConstraints {
  range?: DateRange
  country?: string
  merchant?: string
  ascending?: boolean
  tenant_id: string | null
  countries?: string[]
  merchantIds?: string[]
}

export const getPaymentTypes = (token: string | null): Function =>
  token
    ? async (props: SelectionConstraints): Promise<[]> => {
        try {
          const request = getPaymentTypeQuery(props)
          const response = await axios.post(
            '/settlement/aggregations/raw?index=payment_type_location_30m',
            request,
            {
              headers: { Authorization: 'Bearer ' + token }
            }
          )
          return response.data.aggregations[2].buckets
        } catch (error) {
          console.warn('request error')
          return []
        }
      }
    : (): void => {
        console.warn('no token provided')
      }

const getPerformingProductsQuery = ({
  range,
  country,
  merchant,
  tenant_id
}: SelectionConstraints): {} => {
  if (!range) return {}
  const must = []
  if (tenant_id) {
    must.push({
      match_phrase: {
        tenant_id: {
          query: tenant_id
        }
      }
    })
  }
  if (country) {
    must.push({
      match_phrase: {
        country: {
          query: country
        }
      }
    })
  }
  if (merchant) {
    must.push({
      match_phrase: {
        location: {
          query: parseInt(merchant)
        }
      }
    })
  }
  return {
    aggs: {
      '2': {
        terms: {
          field: 'sku.keyword',
          size: 5,
          order: { settled_price_agg: 'desc' }
        },
        aggs: {
          settled_price_agg: { sum: { field: 'settled_price' } },
          home_price_target_agg: { sum: { field: 'home_price_target' } },
          xo_gain_loss_agg: { sum: { field: 'xo_gain_loss' } },
          gross_agg: { sum: { field: 'gross' } },
          txn_count_agg: { sum: { field: 'txn_count' } },
          target_xo_agg: { sum: { field: 'target_xo' } },
          settled_xo_agg: { sum: { field: 'settled_xo' } },
          xo_delta_agg: { sum: { field: 'xo_delta' } }
        }
      }
    },
    size: 0,
    _source: { excludes: [] },
    stored_fields: ['*'],
    script_fields: {},
    docvalue_fields: [
      { field: 'datetime_from', format: 'date_time' },
      { field: 'datetime_to', format: 'date_time' }
    ],
    query: {
      bool: {
        must: [
          {
            match_all: {}
          },
          {
            match_all: {}
          },
          ...must,
          {
            range: {
              datetime_from: {
                gte: range.startDate && range.startDate.getTime(),
                lte: range.endDate && range.endDate.getTime() + 86399999,
                format: 'epoch_millis'
              }
            }
          }
        ]
      }
    }
  }
}
export const getPerformingProducts = (token: string | null): Function =>
  token
    ? async (props: SelectionConstraints): Promise<[]> => {
        try {
          const request = getPerformingProductsQuery(props)
          const response = await axios.post(
            '/settlement/aggregations/raw?index=product_currency_location_1d',
            request,
            {
              headers: { Authorization: 'Bearer ' + token }
            }
          )
          return response.data.aggregations[2].buckets
        } catch (error) {
          console.warn('request error')
          return []
        }
      }
    : (): void => {
        console.warn('no token provided')
      }

const getBankableQuery = ({
  country,
  merchant,
  tenant_id,
  countries,
  merchantIds
}: SelectionConstraints): {} => {
  const must = []
  const should: any[] = []

  if (tenant_id) {
    must.push({
      match_phrase: {
        tenant_id: {
          query: tenant_id
        }
      }
    })
  }

  if (countries && countries.length > 0) {
    should.push({
      "terms": {
          "country.keyword": countries
      }
    })
  }

  if (merchantIds && merchantIds.length > 0) {
    should.push({
      "terms": {
        "merchant_id.keyword": merchantIds.map((id: any) => parseInt(id))
      }
    });
  }

  return {
    aggs: {
      '1': {
        sum: {
          field: 'gross'
        }
      }
    },
    size: 0,
    _source: {
      excludes: []
    },
    stored_fields: ['*'],
    script_fields: {},
    docvalue_fields: [
      {
        field: 'account_deposit_time',
        format: 'date_time'
      },
      {
        field: 'datetime_from',
        format: 'date_time'
      },
      {
        field: 'datetime_to',
        format: 'date_time'
      }
    ],
    query: {
      bool: {
        must: [
          {
            match_all: {}
          },
          {
            match_all: {}
          },
          ...must,
          {
            match_phrase: {
              settled: {
                query: false
              }
            }
          },
          {
            range: {
              datetime_from: {
                gte: DateTime.utc()
                  .startOf('day')
                  .toJSDate()
                  .getTime(),
                lte: DateTime.utc()
                  .endOf('day')
                  .plus({ days: 7 })
                  .toJSDate()
                  .getTime(),
                format: 'epoch_millis'
              }
            }
          }
        ],
        filter: [],
        should: should,
        minimum_should_match: should.length > 0 ? 1 : 0,
        must_not: []
      }
    }
  }
}

export const getBankable = (token: string | null): Function =>
  token
    ? async (props: SelectionConstraints): Promise<any> => {
        try {
          const request = getBankableQuery(props)
          const response = await axios.post(
            '/settlement/aggregations/raw?index=settlement_location_deposit_1d',
            request,
            {
              headers: { Authorization: 'Bearer ' + token }
            }
          )
          return response.data.error ? 0 : response.data.aggregations[1].value
        } catch (error) {
          console.warn('request error')
          return 0
        }
      }
    : (): void => {
        console.warn('no token provided')
      }

const getTopRepeatPurchaseItemsQuery = ({
  range,
  country,
  merchant,
  tenant_id
}: SelectionConstraints): {} => {
  if (!range) return []
  const must = []
  if (tenant_id) {
    must.push({
      match_phrase: {
        tenant_id: {
          query: tenant_id
        }
      }
    })
  }
  if (country) {
    must.push({
      match_phrase: {
        country: {
          query: country
        }
      }
    })
  }
  if (merchant) {
    must.push({
      match_phrase: {
        merchant_id: {
          query: parseInt(merchant)
        }
      }
    })
  }
  return {
    aggs: {
      '2': {
        terms: {
          field: 'product_id.keyword',
          size: 5,
          order: {
            '1': 'desc'
          }
        },
        aggs: {
          '1': {
            sum: {
              field: 'purchase_count'
            }
          }
        }
      }
    },
    size: 0,
    _source: {
      excludes: []
    },
    stored_fields: ['*'],
    script_fields: {},
    docvalue_fields: [
      {
        field: 'datetime_latest_txn',
        format: 'date_time'
      }
    ],
    query: {
      bool: {
        must: [
          {
            range: {
              datetime_latest_txn: {
                gte: range.startDate && range.startDate.getTime(),
                lte: range.endDate && range.endDate.getTime() + 86399999,
                format: 'epoch_millis'
              }
            }
          },
          ...must
        ],
        filter: [],
        should: [],
        must_not: []
      }
    }
  }
}

export const getTopRepeatPurchaseItems = (token: string | null): Function =>
  token
    ? async (props: SelectionConstraints): Promise<any> => {
        try {
          const request = getTopRepeatPurchaseItemsQuery(props)
          const response = await axios.post(
            '/settlement/aggregations/raw?index=customer_repeat_products',
            request,
            {
              headers: { Authorization: 'Bearer ' + token }
            }
          )
          return response.data.error ? 0 : response.data.aggregations[2].buckets
        } catch (error) {
          console.warn('request error')
          return []
        }
      }
    : (): void => {
        console.warn('no token provided')
      }

const getReturningCustomerQuery = ({
  range,
  country,
  merchant,
  tenant_id,
  countries,
  merchantIds
}: SelectionConstraints): {} => {
  if (!range) return {}
  const must = []
  const should: any[] = []
  
  if (tenant_id) {
    must.push({
      match_phrase: {
        tenant_id: {
          query: tenant_id
        }
      }
    })
  }

  if (countries && countries.length > 0) {
    should.push({
      "terms": {
          "country.keyword": countries
      }
    })
  }

  if (merchantIds && merchantIds.length > 0) {
    should.push({
      "terms": {
        "merchant_id.keyword": merchantIds.map((id: any) => parseInt(id))
      }
    });
  }

  return {
    aggs: {
      '2': {
        date_histogram: {
          field: 'datetime_from',
          fixed_interval: '1d',
          time_zone: 'Europe/Helsinki',
          min_doc_count: 1
        },
        aggs: {
          '1': {
            sum: {
              field: 'returning_customer_count'
            }
          }
        }
      }
    },
    size: 0,
    _source: {
      excludes: []
    },
    stored_fields: ['*'],
    script_fields: {},
    docvalue_fields: [
      {
        field: 'datetime_from',
        format: 'date_time'
      },
      {
        field: 'datetime_to',
        format: 'date_time'
      }
    ],
    query: {
      bool: {
        must: [
          {
            match_all: {}
          },
          {
            match_all: {}
          },
          ...must,
          {
            range: {
              datetime_from: {
                gte: range.startDate && range.startDate.getTime(),
                lte: range.endDate && range.endDate.getTime() + 86399999,
                format: 'epoch_millis'
              }
            }
          }
        ],
        filter: [],
        should: should,
        minimum_should_match: should.length > 0 ? 1 : 0,
        must_not: []
      }
    }
  }
}

export const getReturningCustomer = (token: string | null): Function =>
  token
    ? async (props: SelectionConstraints): Promise<any> => {
        try {
          const request = getReturningCustomerQuery(props)
          const response = await axios.post(
            '/settlement/aggregations/raw?index=location_customers_1d',
            request,
            {
              headers: { Authorization: 'Bearer ' + token }
            }
          )
          return response.data.error ? 0 : response.data.aggregations[2].buckets
        } catch (error) {
          console.warn('request error')
          return []
        }
      }
    : (): void => {
        console.warn('no token provided')
      }

const getPrisingAnalysisQuery = ({ range }: SelectionConstraints): {} => {
  if (!range) return {}
  return {
    aggs: {
      country: {
        terms: {
          field: 'country.keyword',
          size: 500
        },
        aggs: {
          currency: {
            terms: {
              field: 'currency.keyword',
              size: 500,
              order: {
                gross: 'desc'
              }
            },
            aggs: {
              gross: {
                sum: {
                  field: 'gross'
                }
              },
              txn_count: {
                sum: {
                  field: 'txn_count'
                }
              },
              net: {
                sum: {
                  field: 'net'
                }
              }
            }
          }
        }
      }
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            range: {
              datetime_from: {
                gte: range.startDate && range.startDate.getTime(),
                lte: range.endDate && range.endDate.getTime() + 86399999,
                format: 'epoch_millis'
              }
            }
          }
        ]
      }
    }
  }
}

export const getPrisingAnalysis = (token: string | null): Function =>
  token
    ? async (props: SelectionConstraints): Promise<[]> => {
        try {
          const request = getPrisingAnalysisQuery(props)
          const response = await axios.post(
            '/settlement/aggregations/raw?index=payment_type_location_30m',
            request,
            {
              headers: { Authorization: 'Bearer ' + token }
            }
          )
          return response.data.error
            ? []
            : response.data.aggregations.country.buckets
        } catch (error) {
          console.warn('request error')
          return []
        }
      }
    : (): void => {
        console.warn('no token provided')
      }

const getPrisingAnalysisPaymentTypesQuery = ({
  range,
  country
}: SelectionConstraints): {} => {
  if (!range) return {}
  const must = []
  if (country) {
    must.push({
      match_phrase: {
        country: {
          query: country
        }
      }
    })
  }
  return {
    aggs: {
      country: {
        terms: {
          field: 'country.keyword',
          size: 500,
          order: {
            countryGross: 'desc'
          }
        },
        aggs: {
          countryGross: {
            sum: {
              field: 'gross'
            }
          },
          currency: {
            terms: {
              field: 'currency.keyword',
              size: 500,
              order: {
                count: 'desc'
              }
            },
            aggs: {
              count: {
                sum: {
                  field: 'txn_count'
                }
              },
              paymentTypes: {
                terms: {
                  field: 'payment_type.keyword',
                  size: 500,
                  order: {
                    gross: 'desc'
                  }
                },
                aggs: {
                  gross: {
                    sum: {
                      field: 'gross'
                    }
                  },
                  txnCount: {
                    sum: {
                      field: 'txn_count'
                    }
                  },
                  net: {
                    sum: {
                      field: 'net'
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    size: 0,
    _source: {
      excludes: []
    },
    stored_fields: ['*'],
    script_fields: {},
    docvalue_fields: [
      {
        field: 'datetime_from',
        format: 'date_time'
      },
      {
        field: 'datetime_to',
        format: 'date_time'
      }
    ],
    query: {
      bool: {
        must: [
          {
            match_all: {}
          },
          {
            match_all: {}
          },
          {
            range: {
              datetime_from: {
                gte: range.startDate && range.startDate.getTime(),
                lte: range.endDate && range.endDate.getTime() + 86399999,
                format: 'epoch_millis'
              }
            }
          },
          ...must
        ]
      }
    }
  }
}

export const getPrisingAnalysisPaymentTypes = (
  token: string | null
): Function =>
  token
    ? async (props: SelectionConstraints): Promise<[]> => {
        try {
          const request = getPrisingAnalysisPaymentTypesQuery(props)
          const response = await axios.post(
            '/settlement/aggregations/raw?index=payment_type_location_30m',
            request,
            {
              headers: { Authorization: 'Bearer ' + token }
            }
          )
          return response.data.error
            ? []
            : response.data.aggregations.country.buckets
            ? response.data.aggregations.country.buckets[0]
            : []
        } catch (error) {
          console.warn('request error')
          return []
        }
      }
    : (): void => {
        console.warn('no token provided')
      }

const getProjectedStaffingNeedsQuery = ({
  range,
  merchant,
  tenant_id
}: SelectionConstraints): {} => {
  if (!range) return {}
  const must = []
  if (tenant_id) {
    must.push({
      match_phrase: {
        tenant_id: {
          query: tenant_id
        }
      }
    })
  }
  if (merchant) {
    must.push({
      match_phrase: {
        location: {
          query: parseInt(merchant)
        }
      }
    })
  }
  return {
    aggs: {
      perDay: {
        date_histogram: {
          field: 'datetime_from',
          fixed_interval: '1d',
          time_zone: 'UTC',
          min_doc_count: 1
        },
        aggs: {
          staffRequired: {
            max: {
              field: 'staff_required'
            }
          },
          staffScheduled: {
            max: {
              field: 'staff_scheduled'
            }
          }
        }
      }
    },
    size: 0,
    _source: {
      excludes: []
    },
    stored_fields: ['*'],
    script_fields: {},
    docvalue_fields: [
      {
        field: 'datetime_from',
        format: 'date_time'
      },
      {
        field: 'datetime_to',
        format: 'date_time'
      }
    ],
    query: {
      bool: {
        must: [
          {
            match_all: {}
          },
          {
            match_all: {}
          },
          ...must,
          {
            range: {
              datetime_from: {
                gte: range.startDate && DateTime.fromJSDate(range.startDate).toFormat(
                  'yyyy-MM-dd'
                ),
                lte: range.endDate && DateTime.fromJSDate(range.endDate).toFormat('yyyy-MM-dd'),
                format: 'yyyy-MM-dd'
              }
            }
          }
        ]
      }
    }
  }
}

export const getProjectedStaffingNeeds = (token: string | null): Function =>
  token
    ? async (props: SelectionConstraints): Promise<[]> => {
        try {
          const request = getProjectedStaffingNeedsQuery(props)
          const response = await axios.post(
            '/settlement/aggregations/raw?index=location_staffing_30m',
            request,
            {
              headers: { Authorization: 'Bearer ' + token }
            }
          )
          return response.data.error
            ? []
            : response.data.aggregations.perDay.buckets
        } catch (error) {
          console.warn('request error', error)
          return []
        }
      }
    : (): void => {
        console.warn('no token provided')
      }

const getInventoryResupplyForecastingQuery = ({
  range,
  merchant,
  country,
  tenant_id
}: SelectionConstraints): {} => {
  if (!range) return {}
  const must = []
  if (tenant_id) {
    must.push({
      match_phrase: {
        tenant_id: {
          query: tenant_id
        }
      }
    })
  }
  if (merchant) {
    must.push({
      match_phrase: {
        location: {
          query: parseInt(merchant)
        }
      }
    })
  }
  if (country) {
    must.push({
      match_phrase: {
        country: {
          query: country
        }
      }
    })
  }
  return {
    aggs: {
      perDay: {
        date_histogram: {
          field: 'out_of_stock_datetime',
          fixed_interval: '1d',
          time_zone: 'UTC',
          min_doc_count: 1
        },
        aggs: {
          qtyAtStock: {
            sum: {
              field: 'stock_count'
            }
          },
          dailySales: {
            sum: {
              field: '1d_sales'
            }
          }
        }
      }
    },
    size: 0,
    stored_fields: ['*'],
    docvalue_fields: [
      {
        field: 'datetime_out_of_stock',
        format: 'date_time'
      },
      {
        field: 'out_of_stock_datetime',
        format: 'date_time'
      }
    ],
    query: {
      bool: {
        must: [
          ...must,
          {
            range: {
              out_of_stock_datetime: {
                gte: range.startDate && range.startDate.getTime(),
                lte: range.endDate && range.endDate.getTime(),
                format: 'epoch_millis'
              }
            }
          }
        ]
      }
    },
    sort: [{ out_of_stock_datetime: { order: 'desc' } }]
  }
}

export const getInventoryResupplyForecasting = (
  token: string | null
): Function =>
  token
    ? async (props: SelectionConstraints): Promise<[]> => {
        try {
          const request = getInventoryResupplyForecastingQuery(props)
          const response = await axios.post(
            '/settlement/aggregations/raw?index=location_inventory',
            request,
            {
              headers: { Authorization: 'Bearer ' + token }
            }
          )
          return response.data.error
            ? []
            : response.data.aggregations.perDay.buckets
        } catch (error) {
          console.warn('request error', error)
          return []
        }
      }
    : (): void => {
        console.warn('no token provided')
      }

const getExpectedRevenueOverTimeQuery = ({
  country,
  merchant,
  tenant_id,
  countries,
  merchantIds
}: SelectionConstraints): {} => {
  const must = []
  const should: any[] = []

  if (tenant_id) {
    must.push({
      match_phrase: {
        tenant_id: {
          query: tenant_id
        }
      }
    })
  }

  if (countries && countries.length > 0) {
    should.push({
      "terms": {
          "country.keyword": countries
      }
    })
  }

  if (merchantIds && merchantIds.length > 0) {
    should.push({
      "terms": {
        "merchant_id.keyword": merchantIds.map((id: any) => parseInt(id))
      }
    });
  }

  return {
    aggs: {
      byDate: {
        date_histogram: {
          field: 'datetime_from',
          fixed_interval: '1d',
          time_zone: 'UTC',
          min_doc_count: 1
        },
        aggs: {
          gross: {
            sum: {
              field: 'gross'
            }
          }
        }
      }
    },
    size: 0,
    _source: {
      excludes: []
    },
    stored_fields: ['*'],
    script_fields: {},
    docvalue_fields: [
      {
        field: 'account_deposit_time',
        format: 'date_time'
      },
      {
        field: 'datetime_from',
        format: 'date_time'
      },
      {
        field: 'datetime_to',
        format: 'date_time'
      }
    ],
    query: {
      bool: {
        must: [
          {
            match_phrase: {
              settled: {
                query: false
              }
            }
          },
          ...must,
          {
            range: {
              datetime_from: {
                gte: DateTime.utc()
                  .startOf('day')
                  .toFormat('yyyy-MM-dd'),
                lte: DateTime.utc()
                  .endOf('day')
                  .plus({ days: 15 })
                  .toFormat('yyyy-MM-dd'),
                format: 'yyyy-MM-dd'
              }
            }
          }
        ],
        filter: [],
        should: should,
        minimum_should_match: should.length > 0 ? 1 : 0,
        must_not: []
      }
    }
  }
}

interface ExpectedRevenueOverTimeInterface {
  took: number
  timed_out: boolean
  _shards: Shards
  hits: Hits
  aggregations: Aggregations
}

interface Aggregations {
  byDate: ByDate
}

interface ByDate {
  buckets: Bucket[]
}

interface Bucket {
  key_as_string: string
  key: number
  doc_count: number
  gross: Gross
}

interface Gross {
  value: number
}

interface Hits {
  total: Total
  max_score?: any
  hits: any[]
}

interface Total {
  value: number
  relation: string
}

interface Shards {
  total: number
  successful: number
  skipped: number
  failed: number
}

export const getExpectedRevenueOverTime = async (
  token: string,
  country: string,
  merchant: string,
  tenant_id: string | null,
  countries: any,
  merchantIds: any
): Promise<ExpectedRevenueOverTimeInterface | null> => {
  const request = getExpectedRevenueOverTimeQuery({ merchant, country, tenant_id, countries, merchantIds })
  const response = await axios.post(
    '/settlement/aggregations/raw?index=settlement_location_deposit_1d',
    request,
    {
      headers: { Authorization: 'Bearer ' + token }
    }
  )

  if (response.data.error) return null
  else return response.data
}

const getSalesVolumeWeeklyQuery = ({
  tenant_id,
  country,
  merchant,
  range,
  countries,
  merchantIds
}: SelectionConstraints): {} => {
  if (!range || !range.startDate || !range.endDate) return {}
  const must = []
  const should: any[] = []

  if (tenant_id) {
    must.push({
      match_phrase: {
        tenant_id: {
          query: tenant_id
        }
      }
    })
  }
  
  if (countries && countries.length > 0) {
    should.push({
      "terms": {
          "country": countries
      }
    })
  }

  if (merchantIds && merchantIds.length > 0) {
    should.push({
      "terms": {
        "merchant_id": merchantIds.map((id: any) => parseInt(id))
      }
    });
  }

  return {
    aggs: {
      perDay: {
        date_histogram: {
          field: 'datetime_from',
          fixed_interval: '1d',
          time_zone: '+3',
          min_doc_count: 1
        },
        aggs: {
          perHour: {
            date_histogram: {
              field: 'datetime_from',
              fixed_interval: '1h',
              time_zone: '+3',
              min_doc_count: 1
            },
            aggs: {
              gross: {
                sum: {
                  field: 'gross'
                }
              }
            }
          }
        }
      }
    },
    size: 0,
    _source: {
      excludes: []
    },
    stored_fields: ['*'],
    script_fields: {},
    docvalue_fields: [
      {
        field: 'datetime_from',
        format: 'date_time'
      },
      {
        field: 'datetime_to',
        format: 'date_time'
      }
    ],
    query: {
      bool: {
        must: [
          ...must,
          {
            range: {
              datetime_from: {
                gte: DateTime.fromJSDate(range.startDate).toFormat(
                  'yyyy-MM-dd'
                ),
                lte: DateTime.fromJSDate(range.endDate).toFormat('yyyy-MM-dd'),
                format: 'yyyy-MM-dd'
              }
            }
          }
        ],
        filter: [],
        should: should,
        minimum_should_match: should.length > 0 ? 1 : 0,
        must_not: []
      }
    }
  }
}

interface Aggregations {
  perDay: PerDay
}

interface PerDay {
  buckets: Bucket2[]
}

interface Bucket2 {
  key_as_string: string
  key: number
  doc_count: number
  perHour: PerHour
}

interface PerHour {
  buckets: Bucket[]
}

interface Bucket {
  key_as_string: string
  key: number
  doc_count: number
  gross: Gross
}

interface Gross {
  value: number
}

interface Hits {
  total: Total
  max_score?: any
  hits: any[]
}

interface Total {
  value: number
  relation: string
}

interface Shards {
  total: number
  successful: number
  skipped: number
  failed: number
}
interface SalesVolumeWeeklyInterface {
  took: number
  timed_out: boolean
  _shards: Shards
  hits: Hits
  aggregations: Aggregations
}

export const getSalesVolumeWeekly = async (
  token: string,
  props: SelectionConstraints
): Promise<SalesVolumeWeeklyInterface | null> => {
  const request = getSalesVolumeWeeklyQuery(props)
  const response = await axios.post(
    '/settlement/aggregations/raw?index=location_total_30m',
    request,
    {
      headers: { Authorization: 'Bearer ' + token }
    }
  )
  if (response.data.error) return null
  else return response.data
}

const getBankableByPaymentTypeQuery = ({
  tenant_id,
  country,
  merchant,
  merchantIds
}: SelectionConstraints): {} => {
  const must = []
  const should: any[] = []

  if (tenant_id) {
    must.push({
      match_phrase: {
        tenant_id: {
          query: tenant_id
        }
      }
    })
  }
  if (country) {
    must.push({
      match_phrase: {
        country: {
          query: country
        }
      }
    })
  }
  if (merchantIds && merchantIds.length == 1) {
    must.push({
      match_phrase: {
        merchant_id: {
          query: parseInt(merchantIds[0])
        }
      }
    })
  }

  return {
    aggs: {
      byType: {
        terms: {
          field: 'payment_type.keyword',
          size: 500,
          order: {
            gross: 'desc'
          }
        },
        aggs: {
          txn_count: {
            sum: {
              field: 'txn_count'
            }
          },
          gross: {
            sum: {
              field: 'gross'
            }
          }
        }
      }
    },
    size: country ? 10000 : 0,
    _source: {
      excludes: []
    },
    query: {
      bool: {
        must: [...must],
        should: should,
        minimum_should_match: should.length > 0 ? 1 : 0
      }
    }
  }
}
// BankableByPaymentTypeInterface
export const getBankableByPaymentType = async (
  token: string,
  props: SelectionConstraints
): Promise<TransitDelaysI | null> => {
  const request = getBankableByPaymentTypeQuery(props)
  const response = await axios.post(
    '/settlement/aggregations/raw?index=settlement_location_payment_type',
    request,
    {
      headers: { Authorization: 'Bearer ' + token }
    }
  )
  if (response.data.error) return null
  else return response.data
}

const getTopGrossingLocationsQuery = ({ range, tenant_id }: SelectionConstraints): {} => {
  if (!range || !range.startDate || !range.endDate) return {}
  return {
    aggs: {
      byCountry: {
        terms: {
          field: 'country.keyword',
          size: 500,
          order: {
            gross: 'desc'
          }
        },
        aggs: {
          gross: {
            sum: {
              field: 'gross'
            }
          }
        }
      }
    },
    size: 0,
    _source: {
      excludes: []
    },
    docvalue_fields: [
      {
        field: 'datetime_from',
        format: 'date_time'
      },
      {
        field: 'datetime_to',
        format: 'date_time'
      }
    ],
    query: {
      bool: {
        must: [
          {
            range: {
              datetime_from: {
                gte: range.startDate.getTime(),
                lte: range.endDate.getTime(),
                format: 'epoch_millis'
              }
            }
          },
          {
            match_phrase: {
              tenant_id: {
                query: tenant_id
              }
            }
          }
        ]
      }
    }
  }
}

export const getTopGrossingLocations = async (
  token: string,
  props: SelectionConstraints
): Promise<TopGrossingLocationsInterface | null> => {
  const request = getTopGrossingLocationsQuery(props)
  const response = await axios.post(
    '/settlement/aggregations/raw?index=location_total_30m',
    request,
    {
      headers: { Authorization: 'Bearer ' + token }
    }
  )
  if (response.data.error) return null
  else return response.data
}

const getPerformingProductsQueryForForecast = ({
  range,
  country,
  merchant,
  tenant_id
}: SelectionConstraints): {} => {
  if (!range) return {}
  const must = []
  if (tenant_id) {
    must.push({
      match_phrase: {
        tenant_id: {
          query: tenant_id
        }
      }
    })
  }
  if (country) {
    must.push({
      match_phrase: {
        country: {
          query: country
        }
      }
    })
  }
  if (merchant) {
    must.push({
      match_phrase: {
        location: {
          query: parseInt(merchant)
        }
      }
    })
  }
  return {
    aggs: {
      '2': {
        terms: {
          field: 'sku.keyword',
          size: 15,
          order: { settled_price_agg: 'desc' }
        },
        aggs: {
          settled_price_agg: { sum: { field: 'settled_price' } }
        }
      }
    },
    size: 0,
    _source: { excludes: [] },
    stored_fields: ['*'],
    script_fields: {},
    docvalue_fields: [
      { field: 'datetime_from', format: 'date_time' },
      { field: 'datetime_to', format: 'date_time' }
    ],
    query: {
      bool: {
        must: [
          {
            match_all: {}
          },
          {
            match_all: {}
          },
          ...must,
          {
            range: {
              datetime_from: {
                gte: range.startDate && range.startDate.getTime(),
                lte: range.endDate && range.endDate.getTime() + 86399999,
                format: 'epoch_millis'
              }
            }
          }
        ]
      }
    }
  }
}
export const getPerformingProductsForForecast = (
  token: string | null
): Function =>
  token
    ? async (props: SelectionConstraints): Promise<[]> => {
        try {
          const request = getPerformingProductsQueryForForecast(props)
          const response = await axios.post(
            '/settlement/aggregations/raw?index=product_currency_location_1d',
            request,
            {
              headers: { Authorization: 'Bearer ' + token }
            }
          )
          return response.data.aggregations[2].buckets
        } catch (error) {
          console.warn('request error')
          return []
        }
      }
    : (): void => {
        console.warn('no token provided')
      }
