import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import MaterialTable, { Column } from 'material-table'
import { StyleRules, createStyles, makeStyles } from '@mui/styles'

import { State } from '../../reducers'
import {
  getPaymentTypes,
  addPaymentType,
  editPaymentType,
  removePaymentType,
  PaymentTypes as PaymentTypesInterface
} from '../../api'
import { createSelector } from 'reselect'

const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      row: {
        flexGrow: 1,
        backgroundColor: '#f1f1f1',
        display: 'flex',
        height: 224
      }
    })
)

interface PaymentTypesProps {
  token: string | null
  tenant_id: string | null
}

const mapStateToProps = createSelector(
  (state: State): PaymentTypesProps['token'] => state.userState.token,
  (state: State): PaymentTypesProps['tenant_id'] => state.userState.tenant_id,
  (token, tenant_id): PaymentTypesProps => ({
    token,
    tenant_id
  })
)

type PItems = PaymentTypesInterface['items']

export function PaymentTypes(): JSX.Element {
  const classes = useStyles()
  const { token, tenant_id } = useSelector(mapStateToProps)
  const [data, setData] = useState<PItems>([])
  const [loading, setLoading] = useState(true)

  useEffect((): void => {
    if (token) {
      const callPaymentTypes = async (): Promise<any> => {
        const paymentTypes = await getPaymentTypes(token, tenant_id)
        setData(paymentTypes.items)
        setLoading(false)
      }
      callPaymentTypes()
    }
  }, [token])
  const columns: Column<any>[] = [
    { title: 'Id', field: 'id', editable: 'onAdd' },
    { title: 'Description', field: 'description' }
  ]

  return (
    <MaterialTable
      isLoading={loading}
      columns={columns}
      data={data}
      options={{
        header: false,
        paging: false,
        showTitle: false,
        searchFieldAlignment: 'left'
      }}
      localization={{
        body: { editRow: { deleteText: 'Delete this Payment Type?' } }
      }}
      editable={{
        onRowAdd: async (newRow): Promise<void> => {
          if (token) {
            const item = await addPaymentType(token, tenant_id, newRow)
            item && setData([...data, item])
          }
        },
        onRowUpdate: async (editedRaw): Promise<void> => {
          if (token) {
            const item = await editPaymentType(token, tenant_id, editedRaw)
            if (item) {
              const itemToChange = data.find(
                (item): boolean => item.id === editedRaw.id
              )
              if (itemToChange) itemToChange.name = item.name
              setData([...data])
            }
          }
        },
        onRowDelete: async (editedRaw): Promise<void> => {
          if (token) {
            const item = await removePaymentType(token, tenant_id, editedRaw)
            if (item) {
              const newData = [...data]
              newData.splice(newData.indexOf(editedRaw), 1)
              setData([...newData])
            }
          }
        }
      }}
    />
  )
}
