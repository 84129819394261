import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles, StyleRules } from '@mui/styles'
import { createSelector } from 'reselect'

import { Metric } from './Metric'
import { RegionTotal } from '../reducers/constraints'
import { getBaseCurrency, getUsdRate } from '../selectors'
import { State } from '../reducers'

import {
  getMerchant,
  chooseRegion,
  getMerchants,
  chooseCountries
} from '../utils'
import { getTenantType } from '../api'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    grid: {
      padding: theme.spacing(1)
    },
    gridItem: {
      display: 'flex',
      alignItems: 'stretch'
    }
  })
)

interface BreakdownsCase extends RegionTotal {
  address?: string
}

interface BreakdownsProps {
  global: BreakdownsCase | null
  region: BreakdownsCase | null
  merchant: BreakdownsCase | null
  countries: any
  merchantIds: any
  tenant_id: string | null
  token: string | null
  tenants: any[]
}

const mapStateToProps = createSelector(
  (state: State): BreakdownsProps['global'] => state.constraintsState.global,
  (state: State): BreakdownsProps['merchant'] =>
    state.mapState.position.merchantIds &&
    state.mapState.position.merchantIds.length > 0
      ? getMerchants(
          state.mapState.geojson,
          state.mapState.position.merchantIds
        )
      : null,
  (state: State): string | undefined => state.mapState.position.country,
  (state: State): any => state.constraintsState.regions,
  (state: State): any => state.mapState.position.countries,
  (state: State): any => state.mapState.position.merchantIds,
  (state: State): BreakdownsProps['tenant_id'] => state.userState.tenant_id,
  (state: State): BreakdownsProps['token'] => state.userState.token,
  (state: State): BreakdownsProps['tenants'] => state.userState.tenants,
  (
    global,
    merchant,
    country,
    regions,
    countries,
    merchantIds,
    tenant_id,
    token,
    tenants
  ): BreakdownsProps => ({
    global,
    merchant,
    region:
      (countries &&
        countries.length > 0 &&
        chooseCountries(regions, countries)) ||
      null,
    countries,
    merchantIds,
    tenant_id,
    token,
    tenants
  })
)

export const Breakdowns = () => {
  const {
    region,
    merchant,
    global,
    countries,
    merchantIds,
    tenant_id,
    token,
    tenants
  } = useSelector(mapStateToProps)
  const USDRate = useSelector(getUsdRate)
  const baseCurrency = useSelector(getBaseCurrency)
  const [tenantType, setTenantType] = useState('')

  const classes = useStyles()
  const view = merchant || region || global
  if (!view) return null
  const transformRate = view === global ? USDRate || 1 : 1
  const currencyCode = view.currency ? view.currency : baseCurrency || 'USD'

  useEffect((): void => {
    if (!token) return
    const callTenantTypeByTenantId = async (): Promise<any> => {
      const tenantTypeId = tenants.filter(item => item.id == tenant_id)?.[0]
        .tenantTypeId
      const result: any = await getTenantType({ token, tenantTypeId })
      setTenantType(result?.name ?? 'Corp')
    }
    callTenantTypeByTenantId()
  }, [token, tenant_id])

  return (
    <Grid
      container
      alignContent="space-around"
      spacing={1}
      className={classes.grid}
    >
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Metric
          title="Gross"
          value={(view.gross || 0) * transformRate}
          trend={view.grossTrend}
          currencyCode={currencyCode}
        />
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Metric
          title={`${tenantType == 'Bank' ? 'Current Balance' : 'Net'}`}
          value={(view.net || 0) * transformRate}
          trend={view.netTrend}
          currencyCode={currencyCode}
        />
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Metric
          title="Fees"
          value={(view.fee_total || 0) * transformRate}
          trend={view.feesTrend}
          currencyCode={currencyCode}
        />
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Metric
          title={`${tenantType == 'Bank' ? 'Expense (WD)' : 'Refunds'}`}
          value={(view.refunds || 0) * transformRate}
          trend={view.refundsTrend}
          currencyCode={currencyCode}
        />
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Metric
          title={`${tenantType == 'Bank' ? 'Work-In-Process' : 'Tax'}`}
          value={(view.tax || 0) * transformRate}
          trend={view.taxTrend}
          currencyCode={currencyCode}
        />
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Metric
          title="Count"
          value={view.txn_count || 0}
          trend={view.countTrend}
          digits={0}
        />
      </Grid>
    </Grid>
  )
}
