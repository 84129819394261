import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import MaterialTable, { Column /*, MTableBodyRow*/ } from 'material-table'
import { StyleRules, createStyles, makeStyles } from '@mui/styles'

import { State } from '../../reducers'
import { editDelay } from '../../api'
import {
  getCountries,
  Country,
  TransitDelay as CountryTransitDelay
} from '../../apis/GetCountries'
import { createSelector } from 'reselect'

const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      row: {
        flexGrow: 1,
        backgroundColor: '#f1f1f1',
        display: 'flex',
        height: 224
      }
    })
)
interface TransitDelaysProps {
  token: string | null
  tenant_id: string | null
}

const mapStateToProps = createSelector(
  (state: State): TransitDelaysProps['token'] => state.userState.token,
  (state: State): TransitDelaysProps['tenant_id'] => state.userState.tenant_id,
  (
    token,
    tenant_id,
  ): TransitDelaysProps => ({
    token,
    tenant_id,
  })
)
interface TransitDelay {
  id: string
  name: string
  currencyId: string
  xoRate: number
  paymentTypeId: string
  delay: number
}

export function TransitDelays(): JSX.Element {
  const classes = useStyles()
  const { token, tenant_id } = useSelector(mapStateToProps)
  const [data, setData] = useState<TransitDelay[]>([])

  useEffect((): void => {
    const callCountries = async (): Promise<any> => {
      if (token) {
        const countries: Country[] | null = await getCountries(token, tenant_id)
        const newData: TransitDelay[] = []
        if (countries) {
          countries.forEach((country: Country): void => {
            const { id, name, currencyId, xoRate } = country
            country.transitDelays.forEach((delay: CountryTransitDelay): void => {
              newData.push({
                id,
                name,
                currencyId,
                xoRate,
                paymentTypeId: delay.paymentTypeId,
                delay: delay.delay
              })
            })
          })
          setData(newData)
        }  
      }
    }
    callCountries()
  }, [token])
  const columns: Column<any>[] = [
    {
      title: 'Country',
      field: 'name',
      editable: 'never',
      defaultGroupOrder: 0
    },
    { field: 'id', hidden: true, searchable: true },
    { title: 'Payment Type', field: 'paymentTypeId', editable: 'never' },
    { title: 'Delay', field: 'delay', editable: 'always', type: 'numeric' }
  ]

  return (
    <MaterialTable
      isLoading={data.length === 0}
      columns={columns}
      data={data}
      options={{
        header: false,
        paging: false,
        showTitle: false,
        searchFieldAlignment: 'left',
        grouping: false
      }}
      // components={Row: }
      editable={{
        onRowUpdate: async ({
          paymentTypeId,
          id: countryId,
          delay
        }): Promise<void> => {
          if (token && tenant_id) {
            const item = await editDelay(token, {
              paymentTypeId,
              countryId,
              delay,
              tenant_id: tenant_id
            })
            if (item) {
              const itemToChange = data.find(
                (item): boolean =>
                  item.id === countryId && item.paymentTypeId === paymentTypeId
              )
              if (itemToChange) itemToChange.delay = delay
              setData([...data])
            }  
          }
        }
      }}
    />
  )
}
