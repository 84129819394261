import React, { useEffect, useState } from 'react'
import { Paper, Grid, Box, Typography, CircularProgress } from '@mui/material'
import { StyleRules, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import classNames from 'classnames'
import { getLiveCurrenciesRate, getLiveIndicesRate } from '../../api'

interface Rate {
  index_name: string
  bid: number
  ask: number
  open: number
  high: number
  low: number
}
interface LiveCurrenciesRateType {
  currencies: Rate[]
}

interface IndexRate {
  index_name: string
  index_code: string
  open: number
  high: number
  low: number
}

interface LiveIndicesRateType {
  indices: IndexRate[]
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '35px 25px',
      marginTop: '10px',
      overflow: 'hidden'
    },
    box: {},
    boxHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '30px'
    },
    caption: {
      color: theme.palette.text.primary,
      textTransform: 'uppercase'
    },
    table: {},
    tableBody: {},
    tableHeaderItem: {
      fontWeight: 700,
      flex: 1
    },
    tableRow: {
      display: 'flex',
      borderBottom: '1px solid #E1E1E1',
      paddingTop: '10px',
      paddingBottom: '10px'
    },
    tableRowItem: {
      flex: 1
    },
    tabs: {
      display: 'flex',
      marginLeft: '125px'
    },
    tab: {
      padding: '6px 13px',
      cursor: 'pointer'
    },
    activeTab: {
      backgroundColor: '#326DE6',
      color: '#FFFFFF',
      borderRadius: '100px'
    }
  })
)

const LiveRates = ({ title, token }: any) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [activeTabIdx, setActiveTabIdx] = useState(0)
  const [currencyData, setCurrencyData] = useState<LiveCurrenciesRateType>()
  const [indicesData, setIndicesData] = useState<LiveIndicesRateType>()

  useEffect(() => {
    const fetchQuotes = async (): Promise<any> => {
      const result1: any = await getLiveCurrenciesRate({
        token
      })
      setCurrencyData(result1)

      const result2: any = await getLiveIndicesRate({
        token
      })
      setIndicesData(result2)
    }

    fetchQuotes()

    const intervalId = setInterval(fetchQuotes, 30 * 60 * 1000)

    return () => clearInterval(intervalId)
  }, [])

  const currencyColumns = [
    {
      title: 'Index Name',
      field: 'index_name'
    },
    {
      title: 'Bid',
      field: 'bid'
    },
    {
      title: 'Ask',
      field: 'ask'
    },
    {
      title: 'Open',
      field: 'open'
    },
    {
      title: 'High',
      field: 'high'
    },
    {
      title: 'Low',
      field: 'low'
    }
  ]

  const indicesColumns = [
    {
      title: 'Index Name',
      field: 'index_name'
    },
    {
      title: 'Index Code',
      field: 'index_code'
    },
    {
      title: 'Open',
      field: 'open'
    },
    {
      title: 'High',
      field: 'high'
    },
    {
      title: 'Low',
      field: 'low'
    }
  ]

  const renderLiveTable = (data: any[], columns: any[]) => {
    return (
      <Box className={classes.tableBody}>
        <Box className={classes.tableRow}>
          {columns.map((column, index) => (
            <Typography key={index} className={classes.tableHeaderItem}>
              {column.title}
            </Typography>
          ))}
          <Box sx={{ flex: 3 }}></Box>
        </Box>
        {data.map((item, index) => (
          <Box className={classes.tableRow} key={index}>
            {columns.map((column, index1) => (
              <Typography key={index1} className={classes.tableRowItem}>
                {index1 == 0 || column.field == 'index_code'
                  ? item?.[column.field]
                  : Number(item?.[column.field]).toFixed(2)}
              </Typography>
            ))}
            <Box sx={{ flex: 3 }}></Box>
          </Box>
        ))}
      </Box>
    )
  }

  return (
    <Grid item xs={12} sm={12}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <Box className={classes.boxHeader}>
            <Typography
              variant="subtitle2"
              component="h3"
              className={classes.caption}
            >
              {title}
              {isLoading && (
                <CircularProgress
                  className={classes.progress}
                  size={15}
                  color={'secondary'}
                />
              )}
            </Typography>
            <Box className={classes.tabs}>
              <Box
                className={classNames(classes.tab, {
                  [classes.activeTab]: activeTabIdx == 0 ? true : false
                })}
                onClick={() => setActiveTabIdx(0)}
              >
                <Typography>Currencies</Typography>
              </Box>
              <Box
                className={classNames(classes.tab, {
                  [classes.activeTab]: activeTabIdx == 1 ? true : false
                })}
                onClick={() => setActiveTabIdx(1)}
              >
                <Typography>Indices</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.table}>
            {activeTabIdx == 0
              ? renderLiveTable(currencyData?.currencies ?? [], currencyColumns)
              : renderLiveTable(indicesData?.indices ?? [], indicesColumns)}
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
}

export default LiveRates
