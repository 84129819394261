import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import MaterialTable, { Column } from 'material-table'
import { StyleRules, createStyles, makeStyles } from '@mui/styles'

import { State } from '../../reducers'
import { getTransactions } from '../../apis/GetTransactions'
import { mapCountryToCurrency } from '../../temp/currencies_map'
import { BC } from '../BCTooltip'
import { getChipWithCurrency } from '../../utils'
import { TransactionUnits } from './TransactionUnits'
import { createSelector } from 'reselect'
import { getTenantType } from '../../api'

const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      row: {
        flexGrow: 1,
        backgroundColor: '#f1f1f1',
        display: 'flex',
        height: 224
      }
    })
)

interface TransactionsProps {
  token: string
  country: string
  tenant_id: string | null
  tenants: any[]
}

const mapStateToProps = createSelector(
  (state: State): TransactionsProps['token'] => state.userState.token || '',
  (state: State): TransactionsProps['country'] =>
    state.mapState.position.country || '',
  (state: State): TransactionsProps['tenant_id'] => state.userState.tenant_id,
  (state: State): TransactionsProps['tenants'] => state.userState.tenants,
  (token, country, tenant_id, tenants): TransactionsProps => ({
    token,
    country,
    tenant_id,
    tenants
  })
)

interface Row {
  id: string
  gross: number
  tax: number
  unit_count: number
  net: number
  units: unknown[]
}

const renderAmount = (currency: string, type: string): React.FC =>
  function renderAmountNext(data: any): JSX.Element {
    return (
      <BC value={data[type]} currency={currency}>
        <span>{getChipWithCurrency(data[type], 2, currency)}</span>
      </BC>
    )
  }

export const Transactions: React.FC<{ txnIds: string[] }> = ({
  txnIds
}): JSX.Element | null => {
  const classes = useStyles()
  const { token, country, tenant_id, tenants } = useSelector(mapStateToProps)
  const [data, setData] = useState<Row[]>([])
  const [pending, setPending] = useState<boolean>(false)
  const [tenantType, setTenantType] = useState('')

  useEffect((): void => {
    if (!token) return
    const callTenantTypeByTenantId = async (): Promise<any> => {
      const tenantTypeId = tenants.filter(item => item.id == tenant_id)?.[0]
        .tenantTypeId
      const result: any = await getTenantType({ token, tenantTypeId })
      setTenantType(result?.name ?? 'Corp')
    }
    callTenantTypeByTenantId()
  }, [token, tenant_id])

  useEffect((): void => {
    ;(async (): Promise<void> => {
      setPending(true)
      const txns = await getTransactions(token, txnIds)
      if (txns) {
        const newData = txns.hits.hits
          .map(
            (txn): Row => ({
              id: txn._id,
              gross: txn._source.gross,
              tax: txn._source.tax,
              unit_count: txn._source.unit_count,
              net: txn._source.net,
              units: txn._source.units
            })
          )
          .sort(({ gross: a }, { gross: b }): number => b - a)
        setData(newData)
      }
      setPending(false)
    })()
  }, [token, txnIds])
  const columns: Column<any>[] = [
    {
      title: tenantType == 'Bank' ? 'P.O. / Account' : 'Id',
      field: 'id'
    },
    {
      title: 'Unit Count',
      field: 'unit_count'
    },
    {
      title: 'Net',
      field: 'net',
      render: renderAmount(
        country ? mapCountryToCurrency[country] : 'USD',
        'net'
      )
    },
    {
      title: tenantType == 'Bank' ? 'Fees' : 'Tax',
      field: 'tax',
      render: renderAmount(
        country ? mapCountryToCurrency[country] : 'USD',
        'tax'
      )
    },
    {
      title: 'Gross',
      field: 'gross',
      render: renderAmount(
        country ? mapCountryToCurrency[country] : 'USD',
        'gross'
      )
    }
  ]

  return (
    <MaterialTable
      isLoading={pending}
      columns={columns}
      data={data}
      options={{
        header: true,
        toolbar: false,
        showTitle: false,
        paging: false
      }}
      detailPanel={function renderDetails({
        units
      }: {
        units: any[]
      }): JSX.Element {
        return <TransactionUnits units={units} />
      }}
    />
  )
}
