import React from 'react'
import { Paper, Typography } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import { connect } from 'react-redux'
import { StyleRules, makeStyles } from '@mui/styles'

import { getChips, getMerchant, chooseRegion } from '../../utils'
import { RegionTotal } from '../../reducers/constraints'
import { State, Dispatch } from '../../reducers'
import { removeCompareLocation } from '../../reducers/constraints'
import { BC } from '../BCTooltip'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      // color: theme.palette.text.secondary,
      width: '450px'
    },
    plus: {
      position: 'relative',
      display: 'inline-block',
      height: '35px',
      color: '#1c943a',
      fontSize: '26px',
      fontWeight: 700,
      top: '3px',
      paddingRight: '5px'
    },
    minus: {
      position: 'relative',
      display: 'inline-block',
      height: '35px',
      paddingRight: '5px',
      color: '#e50f0f',
      fontSize: '26px',
      fontWeight: 700,
      top: '3px'
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary
    },
    download: {
      textAlign: 'left',
      color: theme.palette.text.secondary
    },
    line: {
      textAlign: 'left'
    },
    name: {
      marginLeft: '-14px'
    },
    bar: {
      display: 'flex'
    },
    tab: {
      minWidth: '80px',
      fontSize: '0.7rem'
    },
    description: {
      display: 'inline-block',
      width: '35%',
      marginRight: '6px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    value: {
      fontSize: '0.9rem',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      position: 'relative',
      top: '-3px'
    }
  })
)

interface CompareLocationsSet {
  locations: RegionTotal[]
  active: boolean
}

export const CompareLocationsComponent = function App(
  props: CompareLocationsSet
): JSX.Element {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography
        variant="subtitle2"
        component="h3"
        className={classes.caption}
      >
        Comparing
      </Typography>
      <div className={classes.bar}>
        {props.locations.map(
          (location, index): JSX.Element => (
            <Sview
              key={'comp-location-' + index}
              location={location}
              index={index}
              classes={classes}
              theme={theme}
            />
          )
        )}
      </div>
    </Paper>
  )
}

interface SVIEW {
  location: RegionTotal
  index: number
  classes: Record<string, string>
  theme: Theme
}

const Sview = ({ location, index, classes, theme }: SVIEW): JSX.Element => {
  const {
    gross,
    net,
    tax,
    fee_total,
    refunds,
    grossTrend,
    netTrend,
    taxTrend,
    feesTrend,
    refundsTrend,
    currency,
    country,
    id
  } = location
  const plus = <div className={classes.plus}>+</div>
  const minus = <div className={classes.minus}>-</div>
  return (
    <div>
      <Typography
        variant="subtitle1"
        component="h1"
        className={classes.caption}
      >
        {country} {id}
      </Typography>
      <Typography
        variant="caption"
        display="block"
        className={classes.line}
        key={'tcl-' + index}
      >
        <span>
          <div className="breakdowns">
            {gross != null && (
              <>
                <span className={classes.description}>
                  {!grossTrend || grossTrend >= 0 ? plus : minus}
                  Gross:
                </span>
                <BC value={gross} currency={currency || 'USD'}>
                  <span className={classes.value}>
                    {getChips(gross)} {currency}
                  </span>
                </BC>
              </>
            )}
            {net != null && (
              <>
                <span className={classes.description}>
                  {!netTrend || netTrend >= 0 ? plus : minus}
                  Net:
                </span>
                <BC value={net} currency={currency || 'USD'}>
                  <span className={classes.value}>
                    {getChips(net)} {currency}
                  </span>
                </BC>
              </>
            )}
            {tax != null && (
              <>
                <span className={classes.description}>
                  {!taxTrend || taxTrend >= 0 ? plus : minus}
                  Tax:
                </span>
                <BC value={tax} currency={currency || 'USD'}>
                  <span className={classes.value}>
                    {getChips(tax)} {currency}
                  </span>
                </BC>
              </>
            )}
            {fee_total != null && (
              <>
                <span className={classes.description}>
                  {!feesTrend || feesTrend >= 0 ? plus : minus}
                  Fees:
                </span>
                <BC value={fee_total} currency={currency || 'USD'}>
                  <span className={classes.value}>
                    {getChips(fee_total)} {currency}
                  </span>
                </BC>
              </>
            )}
            {refunds != null && (
              <>
                <span className={classes.description}>
                  {!refundsTrend || refundsTrend >= 0 ? plus : minus}
                  Refunds:
                </span>
                <BC value={refunds} currency={currency || 'USD'}>
                  <span className={classes.value}>
                    {getChips(refunds)} {currency}
                  </span>
                </BC>
              </>
            )}
          </div>
        </span>
      </Typography>
    </div>
  )
}

const mapStateToProps = (state: State): CompareLocationsSet => {
  if (state.constraintsState.compare.mode === 'locations') {
    const locations = state.constraintsState.compare.locations.map(
      (location): RegionTotal => {
        if (location.merchantId) {
          return getMerchant(state.mapState.geojson, location.merchantId) || {}
        } else if (location.country) {
          return (
            chooseRegion(state.constraintsState.regions, location.country) || {}
          )
        } else {
          return {}
        }
      }
    )
    return { locations, active: locations.length > 0 }
  } else {
    return { locations: [], active: false }
  }
}

interface DispatchFromProps {
  onDelete: (index: number) => void
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  onDelete: (index): void => {
    dispatch(removeCompareLocation(index))
  }
})

export const CompareLocations = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompareLocationsComponent)
