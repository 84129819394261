import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Paper, Typography, AppBar, Tabs, Tab, Box } from '@mui/material'
import { createSelector } from 'reselect'
import { State } from '../reducers'
import { RegionTotal } from '../reducers/constraints'
import { getMerchant, chooseRegion } from '../utils'
import { makeStyles, StyleRules } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { CashflowForecast } from './charts/CashflowForecast'
import { LiquidityRisk } from './charts/LiquidityRisk'
import { LiquidityForecast } from './charts/LiquidityForecast'
import { HistoricalLiquidityReport } from './charts/HistoricalLiquidityReport'
import { PaymentTypesAggregatedRechart } from './charts/PaymentTypesAggregatedRechart'
import { EROTComponent } from './charts/ExpectedRevenueOverTimeRawApi'
import { TransitDelays } from './charts/TransitDelays2'
import { Bankable } from './charts/Bankable'
import { CurrencyImpact } from './charts/ExpectedRevenueOverTimeWithRisk'
import { Forex } from './charts/Forex'
import { ProductCurrencyPerformance } from './charts/ProductCurrencyPerformance'
import { ReturningCustomer } from './charts/ReturningCustomer'
import { TopGrossingLocations } from './charts/TopGrossingLocations'
import { TopLifetimeValue } from './charts/TopLifetimeValue'
import { TopRepeatPurchaseItems } from './charts/TopRepeatPurchaseItems'
import { PromotionUseRate } from './charts/PromotionUseRate'
import { PromotionTrack } from './charts/PromotionTrack'
import { TopPerformingProducts } from './charts/TopPerformingProducts'
import CurrencyPerformanceGraphOne from './charts/CurrencyPerformanceGraphOne'
import { CurrencyPerformanceGraph } from './charts/CurrencyPerformanceGraph'
import { InventoryResupplyForecasting } from './charts/InventoryResupplyForecasting'
import { AddProduct } from './charts/AddProduct'
import { ProjectedStaffingNeeds } from './charts/ProjectedStaffingNeeds'
import {
  getPerformingProducts,
  getBankable,
  getPerformingProductsForForecast,
  getReturningCustomer,
  getTopRepeatPurchaseItems
} from '../rawApi'
import { getForex } from '../api'
import PerformanceCloseDebts from './charts/PerformanceCloseDebts'
import StatusOpenDebts from './charts/StatusOpenDebts'
import AgingBalance from './charts/AgingBalance'
import DebtServiceSchedule from './charts/DebtServiceSchedule'
import Investments from './charts/Investments'
import Trading from './charts/Trading'
import ForeignExchange from './charts/ForeignExchange'
import LiveRates from './charts/LiveRates'
import ForexNews from './charts/ForexNews'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    root: {
      position: 'relative',
      bottom: 'calc(-100vh + 160px)',
      flexGrow: 1,
      marginLeft: '1vw',
      marginRight: '1vw',
      zIndex: 3,
      transition: 'bottom 1s'
    },
    bar: {
      display: 'flex'
    },
    chartTabs: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    hidden: { bottom: '-101vh' },
    title: {
      textAlign: 'center',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: '12px',
      paddingTop: '4px'
    },
    value: {
      color: 'black',
      overflow: 'hidden'
    },
    grid: {
      padding: theme.spacing(1)
    },
    mb20: {
      marginBottom: '20px'
    }
  })
)

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

interface DashboardCase extends RegionTotal {
  address?: string
  merchant_id?: string
}

interface DashboardProps {
  region: DashboardCase | null
  merchant: DashboardCase | null
  token: string | null
  tenant_id: string | null
  datesRange: { startDate?: Date; endDate?: Date }
  countries: any
  merchantIds: any
}

const mapStateToProps = createSelector(
  (state: State): DashboardProps['region'] =>
    (state.mapState.position.country &&
      chooseRegion(
        state.constraintsState.regions,
        state.mapState.position.country
      )) ||
    null,
  (state: State): DashboardProps['merchant'] =>
    state.mapState.position.merchantId
      ? getMerchant(state.mapState.geojson, state.mapState.position.merchantId)
      : null,
  (state: State): DashboardProps['token'] => state.userState.token,
  (state: State): DashboardProps['tenant_id'] => state.userState.tenant_id,
  (state: State): DashboardProps['datesRange']['startDate'] =>
    state.constraintsState.startDate,
  (state: State): DashboardProps['datesRange']['endDate'] =>
    state.constraintsState.endDate,
  (state: State): DashboardProps['countries'] =>
    state.mapState.position.countries,
  (state: State): DashboardProps['merchantIds'] =>
    state.mapState.position.merchantIds,
  (
    region,
    merchant,
    token,
    tenant_id,
    startDate,
    endDate,
    countries,
    merchantIds
  ): DashboardProps => ({
    region,
    merchant,
    token,
    tenant_id,
    datesRange: { startDate, endDate },
    countries,
    merchantIds
  })
)

interface Chart {
  id: string
  order: number
  title: string
  hidden: boolean
}

interface TabComponentPRops {
  title: string
  value: number
  index: number
  charts: Chart[]
}

interface ChartProps {
  title: string
}

interface ChartInfo {
  component: ComponentType<ChartProps>
}

export const TabComponent = ({
  title,
  value,
  index,
  charts
}: TabComponentPRops): JSX.Element => {
  const {
    region,
    merchant,
    token,
    tenant_id,
    datesRange,
    countries,
    merchantIds
  } = useSelector(mapStateToProps)

  const classes = useStyles()
  const merchantId = merchant ? merchant.merchant_id : undefined
  const country = region && region.country ? region.country : undefined

  // define the charts map
  const chartsMap: { [key: string]: ChartInfo } = {
    'cashflow-forecast-chart': { component: CashflowForecast },
    'liquidity-risk-chart': { component: LiquidityRisk },
    'liquidity-forecast-chart': { component: LiquidityForecast },
    'historical-liquidity-report-chart': {
      component: HistoricalLiquidityReport
    },
    'payment-type-aggregated-chart': {
      component: ({ title }) => (
        <PaymentTypesAggregatedRechart
          merchant={merchantId}
          country={country}
          range={datesRange}
          region={region}
          token={token || ''}
          tenant_id={tenant_id}
          title={title}
          countries={countries}
          merchantIds={merchantIds}
        />
      )
    },
    'expected-revenue-over-time-chart': {
      component: ({ title }) => (
        <EROTComponent
          region={region}
          merchant={merchantId}
          country={country}
          token={token}
          tenant_id={tenant_id}
          title={title}
          countries={countries}
          merchantIds={merchantIds}
        />
      )
    },

    'transit-delays-chart': { component: TransitDelays },
    'bankable-chart': {
      component: ({ title }) => (
        <Bankable
          merchant={merchantId}
          country={country}
          range={datesRange}
          getData={getBankable(token)}
          tenant_id={tenant_id}
          region={region}
          title={title}
          countries={countries}
          merchantIds={merchantIds}
        />
      )
    },
    'currency-impact-chart': {
      component: ({ title }) => (
        <CurrencyImpact
          region={region}
          token={token}
          merchant={merchantId}
          country={country}
          tenant_id={tenant_id}
          title={title}
          countries={countries}
          merchantIds={merchantIds}
        />
      )
    },
    'performance-close-debts-chart': {
      component: ({ title }) => (
        <PerformanceCloseDebts title={title} token={token} />
      )
    },
    'status-open-debts-chart': {
      component: ({ title }) => <StatusOpenDebts title={title} token={token} />
    },
    'aging-balance-chart': {
      component: ({ title }) => <AgingBalance title={title} token={token} />
    },
    'debt-service-schedule-chart': {
      component: ({ title }) => (
        <DebtServiceSchedule title={title} token={token} />
      )
    },
    'investments-chart': {
      component: ({ title }) => <Investments title={title} token={token} />
    },
    'trading-chart': {
      component: ({ title }) => <Trading title={title} token={token} />
    },
    'foreign-exchange-chart': {
      component: ({ title }) => <ForeignExchange title={title} token={token} />
    },
    'live-rates-chart': {
      component: ({ title }) => <LiveRates title={title} token={token} />
    },
    'forex-news-chart': {
      component: ({ title }) => <ForexNews title={title} />
    },
    'forex-chart': {
      component: ({ title }) => (
        <Forex getData={getForex(token)} country={country} title={title} />
      )
    },
    'product-currency-performance-chart': {
      component: ({ title }) => (
        <ProductCurrencyPerformance
          range={datesRange}
          token={token || ''}
          tenant_id={tenant_id}
          country={country}
          merchant={merchantId}
          title={title}
        />
      )
    },
    'returning-customer-chart': {
      component: ({ title }) => (
        <ReturningCustomer
          merchant={merchantId}
          country={country}
          range={datesRange}
          tenant_id={tenant_id}
          getData={getReturningCustomer(token)}
          title={title}
          countries={countries}
          merchantIds={merchantIds}
        />
      )
    },
    'top-grossing-location-chart': { component: TopGrossingLocations },
    'top-lifetime-value-chart': { component: TopLifetimeValue },
    'top-repeat-purchase-items-chart': {
      component: ({ title }) => (
        <TopRepeatPurchaseItems
          range={datesRange}
          tenant_id={tenant_id}
          getData={getTopRepeatPurchaseItems(token)}
          title={title}
        />
      )
    },
    'promotion-use-rate-chart': {
      component: ({ title }) => (
        <PromotionUseRate
          range={datesRange}
          merchant={merchantId}
          country={country}
          token={token || ''}
          tenant_id={tenant_id}
          title={title}
        />
      )
    },

    'promotion-track-chart': { component: PromotionTrack },
    'top-performing-products-chart': {
      component: ({ title }) => (
        <TopPerformingProducts
          merchant={merchantId}
          country={country}
          range={datesRange}
          region={region}
          getData={getPerformingProducts(token)}
          tenant_id={tenant_id}
          pricing={'PRICING'}
          title={title}
        />
      )
    },
    'currency-performance-graph-one-chart': {
      component: CurrencyPerformanceGraphOne
    },
    'inventory-resupply-forecast-chart': {
      component: ({ title }) => (
        <InventoryResupplyForecasting
          token={token || ''}
          tenant_id={tenant_id}
          country={country}
          merchant={merchantId}
          range={datesRange}
          region={region}
          getProducts={getPerformingProductsForForecast(token)}
          supplyAtRisk={false}
          title={title}
        />
      )
    },
    'currency-performance-graph-chart': {
      component: ({ title }) => (
        <CurrencyPerformanceGraph tenant_id={tenant_id} title={title} />
      )
    },
    'add-product-chart': { component: AddProduct },

    'supply-at-risk-chart': {
      component: ({ title }) => (
        <InventoryResupplyForecasting
          token={token || ''}
          tenant_id={tenant_id}
          country={country}
          merchant={merchantId}
          range={datesRange}
          region={region}
          getProducts={getPerformingProductsForForecast(token)}
          supplyAtRisk={true}
          title={title}
        />
      )
    },
    'top-performing-products-report-chart': {
      component: ({ title }) => (
        <TopPerformingProducts
          merchant={merchantId}
          country={country}
          range={datesRange}
          region={region}
          getData={getPerformingProducts(token)}
          tenant_id={tenant_id}
          pricing={''}
          title={title}
        />
      )
    },
    'projected-staffing-needed-chart': {
      component: ({ title }) => (
        <ProjectedStaffingNeeds
          merchant={merchantId || ''}
          token={token || ''}
          tenant_id={tenant_id}
          title={title}
        />
      )
    }
  }

  return (
    <TabPanel value={value} index={index}>
      <Grid
        container
        alignContent="space-around"
        spacing={1}
        className={classes.grid}
      >
        {charts.map((option, index) => {
          const TabInfo = chartsMap[option.id]
          const ChartComponent = TabInfo.component
          if (option.hidden) {
            return null
          } else {
            if (
              option.id == 'bankable-chart' &&
              charts[index + 1].id == 'currency-impact-chart'
            ) {
              const TabInfo2 = chartsMap[charts[index + 1].id]
              const ChartComponent2 = TabInfo2.component
              return (
                <Grid item xs={12} sm={4} key={index}>
                  <ChartComponent title={option.title} />
                  <ChartComponent2 title={charts[index + 1].title} />
                </Grid>
              )
            } else if (option.id != 'currency-impact-chart') {
              return <ChartComponent title={option.title} key={index} />
            }
          }
        })}
      </Grid>
    </TabPanel>
  )
}
