import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  Paper,
  Typography,
  CircularProgress,
  Select,
  Box,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Grid
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'
import { getMerchant, chooseRegion } from '../../utils'
import { State } from '../../reducers'
import { SelectionConstraints, getSalesVolumeWeekly } from '../../rawApi'
import { getUsdRate, getBaseCurrency } from '../../selectors'
import { DateTime } from 'luxon'
import { Download } from './Download'
import Chart from 'react-apexcharts'
import { BaseRates, RegionTotal } from '../../reducers/constraints'
import { createSelector } from 'reselect'

interface DataType {
  date: Date
  value: number
}

interface DownloadType {
  date: Date
  revenue: number
  expenditure: number
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      marginTop: '5px',
      marginBottom: '10px'
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    caption: {
      textAlign: 'center',
      color: theme.palette.text.primary
    },
    formControl: {
      margin: theme.spacing(1)
    },
    legend: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px'
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '10px',
      '&:last-child': {
        marginRight: '0px'
      }
    },
    legendMarker: {
      width: '10px',
      height: '10px',
      background: 'red',
      borderRadius: '100%',
      marginRight: '5px'
    },
    legendText: {
      fontSize: '13px',
      margin: '0px',
      color: 'rgb(53,53,53)'
    }
  })
)

interface HistoricalLiquidityReportProps {
  region: RegionTotal | null
  merchant: any
  token: string
  tenant_id: string | null
  datesRange: { startDate: Date; endDate: Date }
  rates: BaseRates
  countries: string[] | undefined
  merchantIds: string[] | undefined
}

const mapStateToProps = createSelector(
  (state: State): HistoricalLiquidityReportProps['region'] =>
    (state.mapState.position.country &&
      chooseRegion(
        state.constraintsState.regions,
        state.mapState.position.country
      )) ||
    null,
  (state: State): HistoricalLiquidityReportProps['merchant'] =>
    state.mapState.position.merchantId
      ? getMerchant(state.mapState.geojson, state.mapState.position.merchantId)
      : undefined,
  (state: State): HistoricalLiquidityReportProps['token'] =>
    state.userState.token || '',
  (state: State): HistoricalLiquidityReportProps['tenant_id'] =>
    state.userState.tenant_id,
  (state: State): HistoricalLiquidityReportProps['datesRange']['startDate'] =>
    state.constraintsState.startDate,
  (state: State): HistoricalLiquidityReportProps['datesRange']['endDate'] =>
    state.constraintsState.endDate,
  (state: State): HistoricalLiquidityReportProps['rates'] =>
    state.constraintsState.baseRates,
  (state: State): HistoricalLiquidityReportProps['countries'] =>
    state.mapState.position.countries,
  (state: State): HistoricalLiquidityReportProps['merchantIds'] =>
    state.mapState.position.merchantIds,
  (
    region,
    merchant,
    token,
    tenant_id,
    startDate,
    endDate,
    rates,
    countries,
    merchantIds
  ): HistoricalLiquidityReportProps => ({
    region,
    merchant,
    token,
    tenant_id,
    datesRange: { startDate, endDate },
    rates,
    countries,
    merchantIds
  })
)

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const HistoricalLiquidityReport = ({ title }: any): JSX.Element => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState('Line')
  const [timelineType, setTimelineType] = useState('Daily')
  const [dailyRevenueData, setDailyRevenueData] = useState<DataType[]>([])
  const [dailyExpenditureData, setDailyExpenditureData] = useState<DataType[]>(
    []
  )
  const [weeklyRevenueData, setWeeklyRevenueData] = useState<DataType[]>([])
  const [weeklyExpenditureData, setWeeklyExpenditureData] = useState<
    DataType[]
  >([])
  const [monthlyRevenueData, setMonthlyRevenueData] = useState<DataType[]>([])
  const [monthlyExpenditureData, setMonthlyExpenditureData] = useState<
    DataType[]
  >([])
  const [dailyDownloadData, setDailyDownData] = useState<DownloadType[]>([])
  const [weeklyDownloadData, setWeeklyDownData] = useState<DownloadType[]>([])
  const [monthlyDownloadData, setMonthlyDownData] = useState<DownloadType[]>([])
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})
  const {
    region,
    merchant,
    token,
    datesRange,
    rates,
    tenant_id,
    countries,
    merchantIds
  } = useSelector(mapStateToProps)
  const merchantId = merchant ? merchant.merchant_id : undefined
  const country = region && region.country ? region.country : undefined

  let date = new Date(datesRange.endDate)
  const endDate = new Date(date.getFullYear(), date.getMonth(), 0)
  const startDate = new Date(date.getFullYear(), date.getMonth() - 6, 1)

  const cProps: SelectionConstraints = {
    tenant_id,
    country,
    merchant: merchantId,
    range: {
      startDate,
      endDate
    },
    countries,
    merchantIds
  }

  const USDRate = useSelector(getUsdRate)
  const baseCurrency = useSelector(getBaseCurrency)

  let name = 'Global'

  if (merchantId) {
    name = country + ' / ' + merchantId
  } else if (country) {
    name = country || ''
  }

  const currencyCode =
    name === 'Global' ? baseCurrency : (region && region.currency) || 'USD'

  let oldCurrency = 0,
    newCurrency = 0
  if (rates.hasOwnProperty(baseCurrency)) {
    oldCurrency = rates[baseCurrency]
  }
  if (rates.hasOwnProperty(currencyCode)) {
    newCurrency = rates[currencyCode]
  }

  let transformRate = newCurrency
    ? oldCurrency / newCurrency
    : USDRate
    ? USDRate
    : 1

  const types = ['Line', 'Bar', 'Area']

  const timelineTypes = ['Daily', 'Weekly', 'Monthly']

  const getMonthlyData = (dailyData: DataType[]): DataType[] => {
    let tmpYear = 0,
      tmpMonth = 0
    let monthlyData: DataType[] = []
    dailyData.map(item => {
      if (
        item.date.getFullYear() !== tmpYear ||
        item.date.getMonth() !== tmpMonth
      ) {
        monthlyData.push({
          date: item.date,
          value: 0
        })
        tmpYear = item.date.getFullYear()
        tmpMonth = item.date.getMonth()
      }
    })

    monthlyData.map(monthlyItem => {
      dailyData.map(newItem => {
        if (
          newItem.date.getFullYear() === monthlyItem.date.getFullYear() &&
          newItem.date.getMonth() === monthlyItem.date.getMonth()
        ) {
          monthlyItem.value += newItem.value
        }
      })
    })
    return monthlyData
  }

  const getWeeklyData = (dailyData: DataType[]): DataType[] => {
    let count = 0,
      tmpValue = 0,
      tmpDate: Date
    let weeklyData: DataType[] = []
    dailyData.map(item => {
      if (count === 0) {
        tmpDate = item.date
      }
      tmpValue += item.value
      count++
      if (count === 7) {
        weeklyData.push({
          date: tmpDate,
          value: tmpValue
        })
        count = 0
        tmpValue = 0
      }
    })
    return weeklyData
  }

  useEffect((): void => {
    // for the case of global chart, need to wait for currencies data
    if (((!countries || !merchantIds) && !USDRate) || !token) return

    const fetchData = async (): Promise<any> => {
      setIsLoading(true)
      const result = await getSalesVolumeWeekly(token, cProps)
      if (
        result &&
        cProps.range &&
        cProps.range.startDate &&
        result.aggregations.perDay.buckets.length > 0
      ) {
        let newRevenueData: DataType[] = []
        let newExpenditureData: DataType[] = []
        let newDownloadData: DownloadType[] = []
        let categories: any[] = []

        result.aggregations.perDay.buckets.forEach(
          (item, dayIndex: number): void => {
            let value = 0
            const itemDate = DateTime.fromISO(item.key_as_string).toJSDate()
            for (let h in item.perHour.buckets) {
              value += item.perHour.buckets[h].gross.value * transformRate
            }
            newRevenueData.push({
              date: itemDate,
              value: value
            })
            newDownloadData.push({
              date: itemDate,
              revenue: value,
              expenditure: 0
            })
            categories.push(itemDate)
          }
        )

        // calculate expenditure from revenue data like -8%~-12% variant
        newRevenueData.map((item, index) => {
          const _tmpData =
            item.value - (item.value * randomIntFromInterval(8, 12)) / 100
          newExpenditureData.push({
            date: item.date,
            value: _tmpData
          })
          newDownloadData[index].expenditure = _tmpData
        })

        setDailyRevenueData(newRevenueData)
        setDailyExpenditureData(newExpenditureData)
        setDailyDownData(newDownloadData)
        setDefaultOptions(
          newRevenueData.map(i =>
            DateTime.fromJSDate(i.date).toFormat('LLL dd')
          )
        )

        // calculate monthly data
        const newMonthlyRevenueData = getMonthlyData(newRevenueData)
        const newMonthlyExpenditureData = getMonthlyData(newExpenditureData)
        const newMonthlyDownloadData = newMonthlyRevenueData.map(
          (item, index) => {
            return {
              date: item.date,
              revenue: item.value,
              expenditure: newMonthlyExpenditureData[index].value
            }
          }
        )
        setMonthlyRevenueData(newMonthlyRevenueData)
        setMonthlyExpenditureData(newMonthlyExpenditureData)
        setMonthlyDownData(newMonthlyDownloadData)

        // calculate weekly data
        const newWeeklyRevenueData = getWeeklyData(newRevenueData)
        const newWeeklyExpenditureData = getWeeklyData(newExpenditureData)
        const newWeeklyDownloadData = newWeeklyRevenueData.map(
          (item, index) => {
            return {
              date: item.date,
              revenue: item.value,
              expenditure: newWeeklyExpenditureData[index].value
            }
          }
        )
        setWeeklyRevenueData(newWeeklyRevenueData)
        setWeeklyExpenditureData(newWeeklyExpenditureData)
        setWeeklyDownData(newWeeklyDownloadData)
      }
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, token, datesRange, USDRate, countries, merchantIds])

  const setDefaultOptions = (mapped: any[]) => {
    setOptions({
      ...options,
      chart: {
        zoom: { enabled: false },
        toolbar: { show: false }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 1
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      tooltip: {
        y: {
          formatter: (val: number) => {
            return val.toLocaleString('en-US', {
              style: 'currency',
              currency: currencyCode
            })
          },
          title: {
            formatter: (seriesName: string) =>
              seriesName === 'Revenues'
                ? 'Revenue'
                : seriesName === 'Expenditures'
                ? 'Expenditure'
                : seriesName
          }
        }
      },
      xaxis: {
        categories: mapped && mapped.length > 0 ? mapped : [],
        tickAmount: 20
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            //@ts-ignore
            return Intl.NumberFormat('en-US', {
              //@ts-ignore
              notation: 'compact',
              compactDisplay: 'short'
            }).format(val)
          }
        }
      },
      fill: {
        type: 'solid'
      }
    })
  }

  useEffect(() => {
    const revenue =
      timelineType === 'Daily'
        ? dailyRevenueData
        : timelineType === 'Weekly'
        ? weeklyRevenueData
        : monthlyRevenueData
    const expenditure =
      timelineType === 'Daily'
        ? dailyExpenditureData
        : timelineType === 'Weekly'
        ? weeklyExpenditureData
        : monthlyExpenditureData

    if (timelineType === 'Monthly') {
      setDefaultOptions(
        revenue.map(i => DateTime.fromJSDate(i.date).toFormat('LLL'))
      )
    } else {
      setDefaultOptions(
        revenue.map(i => DateTime.fromJSDate(i.date).toFormat('LLL dd'))
      )
    }

    setSeries([
      {
        name: 'Revenues',
        data: revenue.map(i => i.value)
      },
      {
        name: 'Expenditures',
        data: expenditure.map(i => i.value)
      }
    ])
  }, [timelineType, isLoading])

  useEffect(() => {
    switch (type) {
      case 'Line':
        setOptions({
          ...options,
          chart: {
            type: 'line',
            stacked: false
          }
        })
        break
      case 'Bar':
        setOptions({
          ...options,
          chart: {
            type: 'bar',
            stacked: true
          }
        })
        break
      case 'Area':
        setOptions({
          ...options,
          chart: {
            type: 'area'
          }
        })
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isLoading])

  const typeChange = (event: SelectChangeEvent): void => {
    setType(event.target.value as string)
  }

  const timelineTypeChange = (event: SelectChangeEvent): void => {
    setTimelineType(event.target.value as string)
  }

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={timelineType}
              onChange={timelineTypeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {timelineTypes.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {title ? title : 'Historical Liquidity Report'}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={type}
              onChange={typeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {types.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        <Chart
          options={options}
          series={series}
          type={type === 'Line' ? 'line' : type === 'Bar' ? 'bar' : 'area'}
          height="400"
        />
        <Download
          data={
            timelineType === 'Daily'
              ? dailyDownloadData
              : timelineType === 'Weekly'
              ? weeklyDownloadData
              : monthlyDownloadData
          }
        />
      </Paper>
    </Grid>
  )
}
