import { combineReducers } from 'redux'
import { userReducer, UserState, SetUser, SetBaseCurrency, SetPrimaryTenantId, SetTenants } from './user'
import {
  mapReducer,
  MapReducerState,
  SetGeojson,
  SetPosition,
  ResetPosition,
  SetLocations,
  SetMerchants,
  SetStyle
} from './map'
import {
  constraintsReducer,
  ConstraintsState,
  SetConstraints,
  SetDateRange,
  SetCompare,
  AddCompareLocation,
  RemoveCompareLocation,
  SetDaily,
  SetGlobalConstraintsAndTrends
} from './constraints'
import { GetDataByRange } from '../sagas'

export interface State {
  userState: UserState
  mapState: MapReducerState
  constraintsState: ConstraintsState
}

export const reducers = combineReducers<State>({
  userState: userReducer,
  mapState: mapReducer,
  constraintsState: constraintsReducer
})

type Action =
  | SetGeojson
  | SetPosition
  | ResetPosition
  | SetLocations
  | SetMerchants
  | SetStyle
  | SetConstraints
  | SetDateRange
  | GetDataByRange
  | SetUser
  | SetBaseCurrency
  | SetPrimaryTenantId
  | SetTenants
  | SetCompare
  | AddCompareLocation
  | RemoveCompareLocation
  | SetDaily
  | SetGlobalConstraintsAndTrends
export type Dispatch = (action: Action) => void
