import React, { useEffect } from 'react'

import { MapBox } from './MapBox'
import { Dashboard } from './Dashboard'
import { Container, AppBar, Typography } from '@mui/material'
import { Toolbar } from '../components/Toolbar'
import Box from '@mui/material/Box'
import {
  setPrimaryTenantId,
  setBaseCurrency,
  Tenant,
  setTenants
} from '../reducers/user'
import { setGeojson, setLocations } from '../reducers/map'
import { setConstraintsAndTrends } from '../reducers/constraints'
import { parseMapOverview } from '../utils'
import { getMapOverview, ShapeMapOverview } from '../api'
import { getCountries } from '../apis/GetCountries'
import { State, Dispatch } from '../reducers'
import { useSelector, connect } from 'react-redux'
import { DateTime } from 'luxon'
import axios from '../apis/axiosInstance'
import { createSelector } from 'reselect'
import bgImage from '../assets/background.png'
import { Select, MenuItem } from '@mui/material'

const MainComponent = ({
  setPrimaryTenantId,
  setBaseCurrency,
  setTheTenants,
  setGeojson,
  setLocations,
  setConstraintsAndTrends
}: any): JSX.Element => {
  const {
    firm_ids,
    tenant_id,
    startDate,
    endDate,
    token,
    tenants
  } = useSelector(mapStateToProps)

  const [selectedIndex, setSelectedIndex] = React.useState(-1)

  const handleChange = (event: any) => {
    setSelectedIndex(event.target.value)
    firm_ids && setPrimaryTenantId(event.target.value)
  }

  useEffect((): void => {
    const initMain = async (): Promise<void> => {
      if (!token && startDate && endDate && !tenant_id) return
      if (token) {
        const baseCurrencyObj = await axios.get(
          '/geography/preferences/base_currency',
          {
            headers: { Authorization: 'Bearer ' + token }
          }
        )
        setBaseCurrency(baseCurrencyObj.data.value)

        const countries: any = await getCountries(token, tenant_id)

        let locations = countries.reduce((acc: any, item: any) => {
          return acc.concat(item.locations)
        }, [])

        const aggregatedRes: ShapeMapOverview = await getMapOverview({
          range: { startDate, endDate },
          token,
          tenant_id
        })

        const current = parseMapOverview(aggregatedRes, locations)

        const { geojson, maxGross, minGross, global, regions } = current
        setGeojson(geojson)

        setLocations(locations)

        const rates: any = await axios.get(
          `/currency-management/quotes/forex-bunch?basicCurrencyId=${
            baseCurrencyObj.data.value
          }&from=${DateTime.fromJSDate(
            endDate
          ).toISODate()}Z&to=${DateTime.fromJSDate(endDate).toISODate()}Z`,
          {
            headers: { Authorization: 'Bearer ' + token }
          }
        )

        let baseRates
        if (rates.data.error) {
          baseRates = {
            [baseCurrencyObj.data.value]: 1
          }
        } else {
          baseRates = rates?.data?.items[0]?.rates ?? 1
        }

        setConstraintsAndTrends({
          trends: current.globalTrends,
          baseRates,
          constraints: { maxGross, minGross, global, regions }
        })
      }
    }
    initMain()
  }, [tenant_id, startDate, endDate])

  useEffect((): void => {
    const getTenants = async (): Promise<void> => {
      const tenants: any = await axios.get('/dynamic-ui-mapper/tenants', {
        headers: { Authorization: 'Bearer ' + token }
      })
      setTheTenants(tenants.data.items)
    }
    getTenants()
  }, [token])

  return tenant_id ? (
    <>
      <MapBox />
      <Dashboard />
      <Container maxWidth="xl">
        <AppBar position="static">
          <Toolbar />
        </AppBar>
      </Container>
    </>
  ) : (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(' + bgImage + ')',
        backgroundSize: 'auto'
      }}
    >
      <Box sx={{ width: '100%', maxWidth: 360 }}>
        <Box
          sx={{
            fontSize: '20px',
            borderRadius: '10px',
            backgroundColor: '#00838f'
          }}
        >
          <Select
            sx={{
              width: '100%',
              height: '50px',
              borderRadius: '10px'
            }}
            onChange={handleChange}
            value={selectedIndex}
          >
            <MenuItem disabled value={-1}>
              Select a tenant
            </MenuItem>
            {firm_ids &&
              firm_ids.length > 0 &&
              tenants &&
              tenants.length > 0 &&
              tenants
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((tenant: any, index: number) => {
                  const result = firm_ids.filter(
                    (firm_id: any) => tenant.id == firm_id
                  )
                  if (result.length > 0) {
                    return (
                      <MenuItem value={tenant.id} key={index}>
                        {tenant.name}
                      </MenuItem>
                    )
                  }
                })}
          </Select>
        </Box>
      </Box>
    </div>
  )
}

interface MainProps {
  token: string | null
  tenant_id: string | null
  firm_ids: string[] | null
  tenants: Tenant[]
  startDate: Date
  endDate: Date
}

const mapStateToProps = createSelector(
  (state: State): MainProps['token'] => state.userState.token,
  (state: State): MainProps['firm_ids'] => state.userState.firm_ids,
  (state: State): MainProps['tenant_id'] => state.userState.tenant_id,
  (state: State): MainProps['tenants'] => state.userState.tenants,
  (state: State): MainProps['startDate'] => state.constraintsState.startDate,
  (state: State): MainProps['endDate'] => state.constraintsState.endDate,
  (token, firm_ids, tenant_id, tenants, startDate, endDate): MainProps => ({
    token,
    firm_ids,
    tenant_id,
    tenants,
    startDate,
    endDate
  })
)

interface DispatchFromProps {
  setPrimaryTenantId: (tenant_id: string) => void
  setBaseCurrency: (baseCurrency: string) => void
  setTheTenants: (tenants: Tenant[]) => void
  setGeojson: (payload: any) => void
  setLocations: (payload: any) => void
  setConstraintsAndTrends: (payload: any) => void
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  setPrimaryTenantId: (tenant_id): void => {
    dispatch(setPrimaryTenantId({ tenant_id }))
  },
  setBaseCurrency: (baseCurrency): void => {
    dispatch(setBaseCurrency({ baseCurrency }))
  },
  setTheTenants: (tenants): void => {
    dispatch(setTenants({ tenants }))
  },
  setGeojson: (payload): void => {
    dispatch(setGeojson(payload))
  },
  setLocations: (payload): void => {
    dispatch(setLocations(payload))
  },
  setConstraintsAndTrends: (payload): void => {
    dispatch(setConstraintsAndTrends(payload))
  }
})

export const Main = connect(mapStateToProps, mapDispatchToProps)(MainComponent)
