// @ts-nocheck - may need to be at the start of file
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Auth0Provider } from './auth0_wrapper'
import config from './auth_config.json'

export const onRedirectCallback = (appState?: { targetUrl: string }): void => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const container = document.getElementById('root')

const root = createRoot(container!)

root.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    grant_type={config.grantType}
    redirect_uri={`${window.location.origin}`}
    onRedirectCallback={onRedirectCallback}
    audience={config.audience}
  >
    <App />
  </Auth0Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
