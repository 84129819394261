import React from 'react'
import { connect } from 'react-redux'
import mapboxgl from 'mapbox-gl'

import { State, Dispatch } from '../reducers'
import {
  RegionTotal,
  ConstraintsState,
  addCompareLocation,
  Location
} from '../reducers/constraints'
import {
  MapPosition,
  MapStyle,
  resetPosition,
  setPosition
} from '../reducers/map'
import {
  chooseRegions,
  chooseRegion,
  selectMerchants,
  getMerchant
} from '../utils'
import {
  exec3d,
  getDescription,
  createSteps,
  setupRegions
} from './mapboxUtils'

import { world } from '../common/world-map'
import '../style.css'

mapboxgl.accessToken =
  'pk.eyJ1IjoiaGVubmFkaWktdGFyYXNlbmtvIiwiYSI6ImNqemUyd2h1NDA2dHkzY254a2NwbTdiOXQifQ.u11eGmdmK-SQ_fVmcXZ8uw'
const defaultPosition = {
  lng: -5,
  lat: 13.28,
  zoom: 2
}

interface MapState extends ConstraintsState {
  lng: number
  lat: number
  zoom: number
  data: GeoJSON.FeatureCollection
  position: MapPosition
  setPosition?: Function
  resetPosition?: Function
  style: MapStyle
  addCompareLocation?: Function
  maxGross: number | null
  minGross: number | null
  regions: RegionTotal[]
  baseCurrency: string
  locations: []
}

export class MapBoxComponent extends React.Component<MapState, {}> {
  private mapContainer: any = null
  private map: mapboxgl.Map | null = null
  private selectionMode: boolean = false
  public resetPosition(): void {
    if (!this.map) return
    this.map.flyTo(defaultPosition)
  }

  public updateMap(): void {
    if (!this.map) return

    this.selectionMode =
      this.props.compare.mode === 'locations' &&
      this.props.compare.locations.length < 2
    const steps = createSteps(
      this.props.minGross || 0,
      this.props.absGross || 0
    )

    if (this.map.getLayer('unclustered-point'))
      this.map.removeLayer('unclustered-point')

    if (this.map.getLayer('unclustered-point-1'))
      this.map.removeLayer('unclustered-point-1')

    if (this.map.getSource('merchants')) {
      this.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'merchants',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
          'circle-color': [
            'step',
            ['get', 'gross'],
            '#fff',
            -3000,
            '#f00',
            0,
            '#014e55'
          ],
          'circle-radius': ['step', ['get', 'gross'], ...steps]
        },
        minzoom: 2.5
      })

      this.map.addLayer({
        id: 'unclustered-point-1',
        type: 'circle',
        source: 'merchants',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': [
            'match',
            ['get', 'merchant_type'],
            'own_brand',
            '#ff0000',
            'wholesale_partner',
            '#800080',
            '#014e55' /* default color */
          ],
          'circle-radius': ['step', ['get', 'gross'], ...steps]
        },
        minzoom: 2.5
      })
    }

    const merchSource: mapboxgl.GeoJSONSource = this.map.getSource(
      'merchants'
    ) as mapboxgl.GeoJSONSource
    merchSource && merchSource.setData(this.props.data)

    if (this.props.position.global) this.resetPosition()
    else if (this.props.position.country) {
      const merchants = selectMerchants(
        this.props.data,
        this.props.position.country
      )
      // unclustered points^^^
      let coordinates
      if (
        this.props.position.merchantIds &&
        this.props.position.merchantIds.length == 1
      ) {
        const merchant = getMerchant(
          this.props.data,
          this.props.position.merchantIds[0]
        )
        if (merchant) {
          this.map.flyTo({
            center: { lat: merchant.location.lat, lng: merchant.location.lon },
            zoom: 16,
            duration: 2500,
            animate: true
          })
        }
      } else {
        coordinates =
          merchants.features[0] &&
          merchants.features[0].properties &&
          merchants.features[0].properties.location

        if (coordinates) {
          const thebounds = merchants.features.reduce(function(
            bounds: any,
            feature: any
          ): any {
            return bounds.extend([
              feature.properties.location.lon,
              feature.properties.location.lat
            ])
          },
          new mapboxgl.LngLatBounds(coordinates, coordinates))
          this.map.fitBounds(thebounds)
        } else {
          this.resetPosition()
        }
      }
    }

    const activeRegions = chooseRegions(world, this.props.regions)
    setupRegions(activeRegions, this.map)
  }

  public eventListeners(): void {
    if (!this.map) return
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    })
    let hoveredStateId: number | null = null

    this.map.on('mousemove', 'world-fills', (e: mapboxgl.EventData): void => {
      if (!this.map) return
      if (e.features.length > 0) {
        hoveredStateId &&
          this.map.setFeatureState(
            { source: 'world', id: hoveredStateId },
            { hover: false }
          )
        hoveredStateId = e.features[0].id
        hoveredStateId &&
          this.map.setFeatureState(
            { source: 'world', id: hoveredStateId },
            { hover: true }
          )
      }
    })

    this.map.on('mouseleave', 'world-fills', (): void => {
      if (!this.map) return
      if (hoveredStateId) {
        this.map.setFeatureState(
          { source: 'world', id: hoveredStateId },
          { hover: false }
        )
      }
      hoveredStateId = null
    })

    this.map.on('click', 'world-fills', (e: mapboxgl.EventData): void => {
      if (!this.map) return
      var features = this.map.queryRenderedFeatures(e.point, {
        layers: ['world-fills']
      })
      const properties = features[0]
      if (properties.properties) {
        const position = { country: properties.properties.name }
        if (this.selectionMode) {
          this.props.addCompareLocation &&
            this.props.addCompareLocation(position)
        } else {
          // this.props.setPosition && this.props.setPosition(position)
          this.props.setPosition &&
            this.props.setPosition({
              country: properties.properties.name,
              countries: [properties.properties.name]
            })
        }
      }
    })

    this.map.on('click', 'unclustered-point', (e: mapboxgl.EventData): void => {
      if (!this.map) return
      var features = this.map.queryRenderedFeatures(e.point, {
        layers: ['unclustered-point']
      })
      let props
      if ((props = features[0].properties)) {
        const position = {
          merchantId: props.merchant_id,
          country: props.country
        }
        if (this.selectionMode)
          this.props.addCompareLocation &&
            this.props.addCompareLocation(position)
        // else this.props.setPosition && this.props.setPosition(position)
        else
          this.props.setPosition &&
            this.props.setPosition({
              country: props.country,
              merchantIds: [props.merchant_id]
            })
      }
    })
    // inspect a cluster on click
    this.map.on('click', 'clusters', (e: any): void => {
      if (!this.map) return
      var features = this.map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      })
      const clusterId =
        features[0].properties && features[0].properties.cluster_id
      const geometry: any = features[0].geometry
      const merchSource: mapboxgl.GeoJSONSource = this.map.getSource(
        'merchants'
      ) as mapboxgl.GeoJSONSource

      merchSource.getClusterExpansionZoom(
        clusterId,
        (err: Error, zoom: number): void => {
          if (err || !this.map || !geometry.coordinates) return
          this.map.easeTo({
            center: geometry.coordinates,
            zoom: zoom
          })
        }
      )
    })

    this.map.on('mousemove', 'world-fills', (e: mapboxgl.EventData): void => {
      if (!this.map) return
      this.map.getCanvas().style.cursor = 'pointer'
      const props = chooseRegion(
        this.props.regions,
        e.features[0].properties.name
      )
      if (props) {
        popup
          .setLngLat(e.lngLat)
          .setHTML(
            getDescription({
              ...props,
              location: { lat: e.lngLat.lat, lon: e.lngLat.lng },
              merchant_id: null,
              baseCurr: this.props.baseCurrency,
              rates: this.props.baseRates,
              merchant_name: null,
              merchant_type: null
            })
          )
          .addTo(this.map)
      }
    })
    this.map.on('mouseleave', 'world-fills', (): void => {
      if (!this.map) return
      this.map.getCanvas().style.cursor = ''
      popup.remove()
    })

    this.map.on(
      'mouseenter',
      'unclustered-point',
      (e: mapboxgl.EventData): void => {
        if (!this.map) return
        this.map.getCanvas().style.cursor = 'pointer'
        const coordinates = e.features[0].geometry.coordinates.slice()
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        let merchant_name = '',
          merchant_type = ''

        if (e.features[0].properties.merchant_id) {
          const result: any = this.props.locations.filter(
            (location: any) =>
              location.merchantId == e.features[0].properties.merchant_id
          )
          if (result && result.length > 0) {
            merchant_name = result[0].merchantName
            merchant_type = result[0].merchantType
          }
        }
        popup
          .setLngLat(coordinates)
          .setHTML(
            getDescription({
              ...e.features[0].properties,
              location: JSON.parse(e.features[0].properties.location),
              baseCurr: this.props.baseCurrency,
              rates: this.props.baseRates,
              merchant_name,
              merchant_type
            })
          )
          .addTo(this.map)
      }
    )
    this.map.on('mouseleave', 'unclustered-point', (): void => {
      if (!this.map) return
      this.map.getCanvas().style.cursor = ''
      popup.remove()
    })
  }
  public componentDidUpdate(prevProps: any): void {
    if (!this.map) return

    if (prevProps.style.style !== this.props.style.style) {
      this.map.setStyle('mapbox://styles/mapbox/' + this.props.style.style)
    }

    this.updateMap()
  }

  public addDataLayer(): void {
    if (!this.map) return
    exec3d(this.map)
    this.map.addSource('merchants', {
      type: 'geojson',
      data: this.props.data,
      clusterMaxZoom: 14,
      cluster: true
    })

    this.map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'merchants',
      minzoom: 2.5,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#00838f',
          100,
          '#00838f'
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 2, 30, 4, 40]
      }
    })

    this.map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'merchants',
      minzoom: 2.5,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    })

    const steps = createSteps(0, this.props.absGross || 0)

    this.map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'merchants',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff',
        'circle-color': [
          'step',
          ['get', 'gross'],
          '#fff',
          -3000,
          '#f00',
          0,
          '#00838f'
        ],
        'circle-radius': ['step', ['get', 'gross'], ...steps]
      }
    })
    //circles^^^
    this.map.addSource('world', {
      type: 'geojson',
      data: world
    })
    this.map.addLayer({
      id: 'world-fills',
      type: 'fill',
      source: 'world',
      layout: {},
      paint: {
        'fill-opacity': 0.1,
        'fill-opacity-transition': { duration: 3000, delay: 0 }
      },
      interactive: true,
      maxzoom: 2.5
    })

    this.updateMap()

    this.eventListeners()
  }

  public componentDidMount(): void {
    const { lng, lat, zoom } = this.props

    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [lng, lat],
      zoom,
      minZoom: 2
    })
    this.map.on('load', (): void => {
      if (!this.map) return

      this.addDataLayer()

      this.map.on('style.load', () => {
        this.addDataLayer()
      })
    })
  }

  public render(): JSX.Element {
    return (
      <div
        id="map-container"
        ref={(el): void => {
          this.mapContainer = el
        }}
        style={{ height: '100vh', width: '100vw' }}
        className="top right left bottom custom-popup map-position"
      />
    )
  }
}

interface DispatchFromProps {
  resetPosition: () => void
  setPosition: (position: MapPosition) => void
  addCompareLocation: (location: Location) => void
}
const mapStateToProps = (state: State): MapState => ({
  ...defaultPosition,
  data: state.mapState.geojson,
  position: state.mapState.position,
  locations: state.mapState.locations,
  ...state.constraintsState,
  style: state.mapState.style,
  baseCurrency: state.userState.baseCurrency
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  resetPosition: (): void => dispatch(resetPosition()),
  setPosition: (position: MapPosition): void => {
    dispatch(setPosition(position))
  },
  addCompareLocation: (location: Location): void =>
    dispatch(addCompareLocation(location))
})

export const MapBox = connect(
  mapStateToProps,
  mapDispatchToProps
)(MapBoxComponent)
