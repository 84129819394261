import { cyan } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    body1: {
      fontFamily: 'Verdana, Geneva, sans-serif'
    },
    fontFamily: 'Verdana, Geneva, sans-serif'
  },
  palette: {
    primary: {
      main: cyan[800]
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87)'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Verdana, Geneva, sans-serif'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: 'Verdana, Geneva, sans-serif'
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          position: 'fixed',
          top: '0px',
          zIndex: 3,
          ['@media (min-width:1536px)']: {
            maxWidth: 'initial'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderBottomRightRadius: '10px',
          borderBottomLeftRadius: '10px'
        }
      }
    }
  }
})

export default theme
