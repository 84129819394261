import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import MaterialTable, { Column } from 'material-table'
import { StyleRules, createStyles, makeStyles } from '@mui/styles'

import { State } from '../../reducers'
import { editRep, addRep, removeRep } from '../../api'
import { getCountries, Country, Representative } from '../../apis/GetCountries'
import { createSelector } from 'reselect'

const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      row: {
        flexGrow: 1,
        backgroundColor: '#f1f1f1',
        display: 'flex',
        height: 224
      }
    })
)

interface RepresentativesProps {
  token: string
  tenant_id: string
}

const mapState = createSelector(
  (state: State): RepresentativesProps['token'] => state.userState.token || '',
  (state: State): RepresentativesProps['tenant_id'] => state.userState.tenant_id || '',
  (
    token,
    tenant_id,
  ): RepresentativesProps => ({
    token,
    tenant_id,
  })
)

// Province

interface Row extends Representative {
  country: string
  countryId: string
  locationId: string
}

interface Map {
  [id: string]: string
}
export function Representatives(): JSX.Element {
  const classes = useStyles()
  const { token, tenant_id } = useSelector(mapState)
  const [data, setData] = useState<Row[]>([])
  const [cLookup, setCLookup] = useState<Map>({})
  const [lLookup, setLLookup] = useState<Map>({})
  const [trigger, setTrigger] = useState<number>(1)

  useEffect((): void => {
    const callCountries = async (): Promise<any> => {
      const countries: Country[] | null = await getCountries(token, tenant_id)
      const newData: Row[] = []
      if (countries) {
        const countryLookup: Map = {}
        const locationLookup: Map = {}
        countries.forEach((country: Country): void => {
          const { name, id } = country
          country.locations.forEach((location): void => {
            if (
              location.id &&
              location.representative &&
              location.representative.id
            ) {
              newData.push({
                ...location.representative,
                country: country.name,
                countryId: country.id,
                locationId: location.id
              })
            }
            if (location.id) locationLookup[location.id] = location.id
          })
          countryLookup[id] = name
        })
        setCLookup(countryLookup)
        setLLookup(locationLookup)
        setData(newData)
      }
    }
    callCountries()
  }, [token, trigger])
  const columns: Column<any>[] = [
    {
      field: 'countryId',
      // hidden: true,
      lookup: cLookup,
      searchable: true,
      editable: 'onAdd'
    },
    // {
    //   title: 'Country',
    //   field: 'country',
    //   editable: 'always'
    // },
    {
      field: 'locationId',
      lookup: lLookup,
      editable: 'onAdd',
      hidden: false
    },

    {
      field: 'id',
      title: 'ID',
      //   defaultGroupOrder: 0,
      editable: 'onAdd',
      hidden: false,
      searchable: true
    },
    {
      title: 'Name',
      field: 'name',
      editable: 'always'
    },
    {
      title: 'Contact Info',
      field: 'contactInfo',
      editable: 'always'
    }
  ]

  return (
    <MaterialTable
      isLoading={data.length === 0}
      columns={columns}
      data={data}
      options={{
        header: false,
        paging: false,
        showTitle: false,
        searchFieldAlignment: 'left',
        grouping: false,
        addRowPosition: 'first'
      }}
      editable={{
        onRowUpdate: async (row: Row): Promise<void> => {
          const { name, locationId, countryId, contactInfo } = row

          const item = await editRep(token, {
            tenant_id: tenant_id,
            locationId,
            name,
            countryId,
            contactInfo
          })
          if (item) {
            const itemToChange = data.findIndex(
              (item): boolean =>
                item.countryId === countryId && item.locationId === locationId
            )
            if (itemToChange !== -1) {
              data.splice(itemToChange, 1, row)
              setData([...data])
            }
          }
        },
        onRowAdd: async (): Promise<void> => {},
        // async (row: Row): Promise<void> => {
        //   const { locationId, name, countryId, contactInfo } = row

        //   const item = await addRep(token, {
        //     locationId,
        //     name,
        //     countryId,
        //     contactInfo
        //   })
        //   if (item) {
        //     setTrigger(trigger + 1)
        //     // setData([...data, row])
        //   }
        // },
        onRowDelete: async (): Promise<void> => {}
        // async (row: Row): Promise<void> => {
        //   const { locationId, countryId } = row
        //   const item = await removeRep(token, {
        //     locationId,
        //     countryId
        //   })
        //   if (item) {
        //     const itemToRemove = data.findIndex(
        //       (item): boolean => item.locationId === locationId
        //     )
        //     if (itemToRemove !== -1) {
        //       data.splice(itemToRemove, 1)
        //       setData([...data])
        //     }
        //   }
        // }
      }}
    />
  )
}
