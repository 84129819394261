import React from 'react'
import { Theme } from '@mui/material/styles'
import { makeStyles, StyleRules, createStyles } from '@mui/styles'
import {
  Button,
  Dialog,
  IconButton,
  Tab,
  Tabs,
  Typography,
  Box,
  Slide
} from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { TransitionProps } from '@mui/material/transitions'
import classNames from 'classnames'

import { PaymentTypes } from './settings/PaymentTypes'
import { TransitDelays } from './settings/TransitDelays'
import { BasePreferences } from './settings/BasePreferences'
import { Geography } from './settings/Geography'
import { Representatives } from './settings/Representatives'
import { Fees } from './settings/Fees'
import { Integration } from './settings/Integration'

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      root: {
        flexGrow: 1,
        backgroundColor: '#f1f1f1',
        display: 'flex',
        height: 224
      },
      tabs: {
        backgroundColor: `#19838f`,
        paddingTop: '12vh'
      },
      tabsLabel: {
        fontFamily: 'Verdana, Geneva, sans-serif',
        color: 'white',
        textTransform: 'none',
        paddingRight: '15px',
        paddingLeft: '30px',
        textAlign: 'left',
        fontSize: '14px',
        lineHeight: '20px',
        display: 'initial',
        alignItems: 'start',
        opacity: 0.7
      },
      selectedTab: {
        backgroundColor: `#3fa5b1`,
        color: 'white !important',
        opacity: 1
      },
      tabPanel: {
        paddingTop: '5vh',
        overflow: 'scroll'
      },
      fullWidth: { width: '100%' },
      closeButton: {
        fontSize: '14px',
        width: '24px',
        alignSelf: 'flex-end',
        position: 'absolute',
        padding: '9px 20px',
        marginLeft: '0px',
        borderRadius: '0px 0px 0px 10px',
        backgroundColor: 'hsla(0, 0%, 68.6%, 0.5)',
        color: '#7c7c7c',
        fontWeight: 700
      },
      heading: {
        borderBottom: '2px solid #afafaf',
        minWidth: 'fit-content',
        width: '30vw',
        fontSize: '20px',
        lineHeight: '20px',
        fontWeight: 'bold',
        fontFamily: 'Verdana, Geneva, sans-serif',
        paddingBottom: '.67em'
      }
    })
)

function Heading({ caption }: { caption: string }): JSX.Element {
  const classes = useStyles()
  return (
    <Typography variant="h4" className={classes.heading}>
      {caption}
    </Typography>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  fullWidth?: boolean
  caption: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, fullWidth, caption, ...other } = props
  const classes = useStyles()

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classNames(classes.tabPanel, {
        [classes.fullWidth]: fullWidth
      })}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Heading caption={caption} />
          {children}
        </Box>
      )}
    </Typography>
  )
}

function a11yProps(index: number): {} {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />
})

export function AdminPanel(): JSX.Element {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false) //TODO: must be false
  const [value, setValue] = React.useState(0) //TODO: must be 0

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        <SettingsOutlinedIcon htmlColor="white" />
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          className={classes.closeButton}
        >
          X
        </IconButton>
        <div className={classes.root}>
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            className={classes.tabs}
            TabIndicatorProps={{ className: classes.selectedTab }}
          >
            <Tab
              classes={{
                root: classNames(classes.tabsLabel, {
                  [classes.selectedTab]: value === 0
                })
              }}
              label="Transit Delays"
            />
            <Tab
              classes={{
                root: classNames(classes.tabsLabel, {
                  [classes.selectedTab]: value === 1
                })
              }}
              label="General Settings"
            />
            <Tab
              classes={{
                root: classNames(classes.tabsLabel, {
                  [classes.selectedTab]: value === 2
                })
              }}
              label="Fees"
              {...a11yProps(2)}
            />
            <Tab
              classes={{
                root: classNames(classes.tabsLabel, {
                  [classes.selectedTab]: value === 3
                })
              }}
              label="Payment Types"
              {...a11yProps(3)}
            />
            <Tab
              classes={{
                root: classNames(classes.tabsLabel, {
                  [classes.selectedTab]: value === 4
                })
              }}
              label="Countries & Locations"
              {...a11yProps(4)}
            />
            <Tab
              classes={{
                root: classNames(classes.tabsLabel, {
                  [classes.selectedTab]: value === 5
                })
              }}
              label="Representatives"
              {...a11yProps(5)}
            />
            <Tab
              classes={{
                root: classNames(classes.tabsLabel, {
                  [classes.selectedTab]: value === 6
                })
              }}
              label="Integrations"
              {...a11yProps(6)}
            />
          </Tabs>
          <TabPanel value={value} index={0} caption="Transit Delays">
            <TransitDelays />
          </TabPanel>
          <TabPanel value={value} index={1} caption="Base Currency">
            <BasePreferences />
          </TabPanel>
          <TabPanel value={value} index={2} caption="Fees" fullWidth>
            <Fees />
          </TabPanel>
          <TabPanel value={value} index={3} caption="Payment Types">
            <PaymentTypes />
          </TabPanel>
          <TabPanel
            value={value}
            index={4}
            caption="Countries & Locations"
            fullWidth
          >
            <Geography />
          </TabPanel>
          <TabPanel value={value} index={5} caption="Representatives" fullWidth>
            <Representatives />
          </TabPanel>
          <TabPanel value={value} index={6} caption="Integrations" fullWidth>
            <Integration />
          </TabPanel>
        </div>
      </Dialog>
    </div>
  )
}
