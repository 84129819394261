import React, { useEffect, useState } from 'react'
import { Theme } from '@mui/material/styles'
import { Button, Toolbar as MaterialToolbar, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { makeStyles, StyleRules } from '@mui/styles'

import { useAuth0 } from '../auth0_wrapper'
import { DateRangePicker } from './DateRangePicker'
import { ViewFilter } from './ViewFilter'
import { AdminPanel } from './AdminPanel'
import { useSelector, connect } from 'react-redux'
import { State, Dispatch } from '../reducers'
import { setStyle, MapStyle, setMerchants } from '../reducers/map'
import { getMerchants } from '../api'
import classNames from 'classnames'
import { Tenant } from '../reducers/user'
import { createSelector } from 'reselect'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    toolbar: {
      justifyContent: 'space-between',
      position: 'relative'
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    logo: {
      height: '64px',
      left: '-24px',
      position: 'relative',
      objectFit: 'contain',
      backgroundColor: '#fff',
      paddingLeft: '10px',
      paddingRight: '10px',
      borderBottomLeftRadius: '10px'
    },
    baseLogo: {
      width: '170px',
      height: '44px',
      left: '-24px',
      position: 'relative',
      objectFit: 'contain',
      padding: '10px',
      backgroundColor: '#eee',
      borderBottomLeftRadius: '10px'
    },
    ecsfineLogo: {
      height: '44px',
    },
    formControl: {
      position: 'absolute',
      left: '0px',
      top: '70px',
      backgroundColor: '#00838f',
      borderRadius: '5px'
    },
    radioGroup: {
      paddingLeft: '11px',
      '& .MuiTypography-root': {
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.87)'
      }
    }
  })
)

export const ToolbarComponent = (props: any): JSX.Element => {
  const classes = useStyles()
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()
  const [selectedValue, setSelectedValue] = React.useState('light-v10')
  const { tenant_id, tenants, token } = useSelector(mapStateToProps)
  const [merchants, setMerchants] = useState<any>([])
  const [img, setImg] = useState('');

  useEffect((): void => {
    if (tenants && tenants.length > 0) {
      const result = tenants.filter((tenant: any) => tenant.id == tenant_id);
      if (result.length > 0) {
        setImg("data:image/png;base64, " + result[0].image);
      }
    }
  }, [tenant_id, tenants]);

  useEffect((): void => {
    if(!token || !tenant_id) return;
    const callMerchants = async (): Promise<any> => {
      const result: any = await getMerchants({token, tenant_id})
      props.setMerchants(result);
    }
    callMerchants()
  }, [token, tenant_id])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    props.setStyle({
      style: event.target.value
    })
  }

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'size-radio-button-demo',
    inputProps: { 'aria-label': item },
  })

  return (
    <MaterialToolbar className={classes.toolbar}>
      {isAuthenticated ? (
        <>
          {tenant_id == 'd36f67f1-ffda-4682-a5a9-8eac732b753b' ? (
            <img className={classes.baseLogo} src={img} />
          ): (
            <img className={classes.logo} src={img} />
          )}
          
          {/* {window.location.hostname === "demo.treasurypayinc.com" && <img className={classes.logo} src="/treasurypay_logo.png" />} */}
          {/* {window.location.hostname === "demo.ecsfin.treasurypayinc.com" && <img className={classNames(classes.logo, classes.ecsfineLogo)} src="/ecsfine_logo.png" />} */}
          <DateRangePicker />
          <ViewFilter/>
          <Button
            id="logout-btn"
            color="inherit"
            onClick={(): void => {
              logout()
            }}
          >
            Logout
          </Button>
          <AdminPanel />
          <FormControl className={classes.formControl}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className={classes.radioGroup}
            >
              <FormControlLabel value="light-v10" control={<Radio {...controlProps('light-v10')} size='small' color='default'/>} label="Light" />
              <FormControlLabel value="dark-v10" control={<Radio {...controlProps('dark-v10')} size='small' color='default'/>} label="Dark" />
              <FormControlLabel value="streets-v11" control={<Radio {...controlProps('streets-v11')} size='small' color='default'/>} label="Streets" />
              <FormControlLabel value="outdoors-v11" control={<Radio {...controlProps('outdoors-v11')} size='small' color='default'/>} label="Outdoors" />
              <FormControlLabel value="satellite-v9" control={<Radio {...controlProps('satellite-v9')} size='small' color='default'/>} label="Satellite" />
            </RadioGroup>
          </FormControl>
        </>
      ) : (
        <Button
          id="login-btn"
          color="inherit"
          onClick={(): void => {
            loginWithRedirect()
          }}
        >
          Login
        </Button>
      )}
    </MaterialToolbar>
  )
}


interface ToolbarProps {
  tenant_id: string | null
  tenants: Tenant[]
  token: string | null
}

const mapStateToProps = createSelector(
  (state: State): ToolbarProps['tenant_id'] => state.userState.tenant_id,
  (state: State): ToolbarProps['tenants'] => state.userState.tenants,
  (state: State): ToolbarProps['token'] => state.userState.token,
  (
    tenant_id,
    tenants,
    token,
  ): ToolbarProps => ({
    tenant_id,
    tenants,
    token,
  })
)
interface DispatchFromProps {
  setStyle: (style: MapStyle) => void,
  setMerchants: (payload: any) => void,
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  setStyle: (style): void => {
    dispatch(setStyle(style))
  },
  setMerchants: (payload): void => {
    dispatch(setMerchants(payload))
  },
})

export const Toolbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(ToolbarComponent)