import React, { useState, useEffect, useRef } from 'react'
import {
  Paper,
  Typography,
  CircularProgress,
  Box,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles, StyleRules } from '@mui/styles'
import { DateTime } from 'luxon'

import { SelectionConstraints } from '../../rawApi'
// import { getExpectedRevenueOverTime } from '../../api'
import { getExpectedRevenueOverTime } from '../../rawApi'
import { currencies } from '../../temp/currencies_map'
import { getBaseCurrency, getUsdRate } from '../../selectors'
import { Download } from './Download'
import { useSelector } from 'react-redux'
import { RegionTotal } from '../../reducers/constraints'
import Chart from 'react-apexcharts'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden'
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    caption: {
      textAlign: 'center',
      color: theme.palette.text.primary
    },
    formControl: {
      margin: theme.spacing(1)
    },
    legend: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px'
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '10px',
      '&:last-child': {
        marginRight: '0px'
      }
    },
    legendMarker: {
      width: '10px',
      height: '10px',
      background: 'red',
      borderRadius: '100%',
      marginRight: '5px'
    },
    legendText: {
      fontSize: '13px',
      margin: '0px',
      color: 'rgb(53,53,53)'
    }
  })
)

interface EROTItem {
  location: string
  date: string
  expectedRevenue: number
  worldAverage: number
  childRevenueBreakdowns: ChildRevenueBreakdown[]
  bankRevenueBreakdowns: BankRevenueBreakdown[]
  cumlativeExpectedRevenue: number
  cumlativeWorldAverage: number
}

interface BankRevenueBreakdown {
  bankAccount: string
  expectedRevenue: number
}

interface ChildRevenueBreakdown {
  location: string
  expectedRevenue: number
}

interface DisplayItem {
  date: Date
  expectedRevenue: number
}

interface EROTProps extends SelectionConstraints {
  token: string | null
  region: RegionTotal | null
  title: string
}

export const EROTComponent = function App(props: EROTProps): JSX.Element {
  const {
    country,
    merchant,
    token,
    region,
    tenant_id,
    title,
    countries,
    merchantIds
  } = props
  const classes = useStyles()
  //   const getData = getExpectedRevenueOverTime(token)
  const [data, setData] = useState<DisplayItem[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState('Line')
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})

  let name: string | 'Global' = 'Global'

  if (merchant) {
    name = country + ' / ' + merchant
  } else if (country) {
    name = country || ''
  }
  const USDRate = useSelector(getUsdRate) ? useSelector(getUsdRate) : 1
  const baseCurrency = useSelector(getBaseCurrency)

  const currencyCode =
    name === 'Global' ? baseCurrency : (region && region.currency) || 'USD'
  const currencySymbol = currencies[currencyCode as string].symbol

  const types = ['Line', 'Bar', 'Area']

  const interpolateRevenue = (data: any[], hours: any) => {
    const start = new Date(DateTime.now().toJSDate())

    let newData = []
    let revenuePerDay = 0,
      revenue1 = data[0].expectedRevenue
    if (data.length > 1) {
      const date1 = data[0].date
      const date2 = data[1].date
      const revenue2 = data[1].expectedRevenue

      const daysApart =
        (date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24) // convert ms to days
      revenuePerDay = (revenue2 - revenue1) / daysApart
    } else {
      revenuePerDay = revenue1 / ((-2 * hours) / 24)
    }

    const daysApart =
      (data[0].date.getTime() - start.getTime()) / (1000 * 60 * 60 * 24) // convert ms to days

    for (var i = 0; i <= hours / 24; i++) {
      var newDate = new Date(start.getTime() + i * 1000 * 60 * 60 * 24) // add days
      const item = data.find(
        (val): boolean =>
          val.date.getFullYear() == newDate.getFullYear() &&
          val.date.getMonth() == newDate.getMonth() &&
          val.date.getDate() == newDate.getDate()
      )

      if (item) {
        newData.push({ date: newDate, expectedRevenue: item.expectedRevenue })
      } else {
        const newRevenue = revenue1 + revenuePerDay * (i - daysApart)
        newData.push({
          date: newDate,
          expectedRevenue: newRevenue > 0 ? newRevenue : newRevenue * -1
        })
      }
    }

    return newData
  }

  const setDefaultOptions = (mapped: DisplayItem[]) => {
    setOptions({
      ...options,
      chart: {
        zoom: { enabled: false },
        toolbar: { show: false }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      tooltip: {
        y: {
          formatter: (val: number) => {
            return val.toLocaleString('en-US', {
              style: 'currency',
              currency: currencyCode
            })
          }
        }
      },
      xaxis: {
        categories:
          mapped && mapped.length > 0
            ? mapped.map(i => DateTime.fromJSDate(i.date).toFormat('LLL dd'))
            : []
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            //@ts-ignore
            return Intl.NumberFormat('en-US', {
              //@ts-ignore
              notation: 'compact',
              compactDisplay: 'short'
            }).format(val)
          }
        }
      },
      fill: {
        type: 'solid'
      }
    })
  }

  useEffect(() => {
    let transformRate = USDRate ? USDRate : 1
    // for the case of global chart, need to wait for currencies data
    if (((!countries || !merchantIds) && !USDRate) || !token) return

    const fetchData = async (): Promise<any> => {
      setIsLoading(true)
      const result = await getExpectedRevenueOverTime(
        token || '',
        country || '',
        merchant || '',
        tenant_id || '',
        countries,
        merchantIds
      )
      if (result) {
        const mapped = result.aggregations.byDate.buckets.map(
          (item): DisplayItem => ({
            date: DateTime.fromISO(item.key_as_string).toJSDate(),
            expectedRevenue: item.gross.value * transformRate
          })
        )

        if (mapped.length > 0) {
          setDefaultOptions(interpolateRevenue(mapped, 6 * 24))

          setSeries([
            {
              name: name,
              data: interpolateRevenue(mapped, 6 * 24).map(
                i => i.expectedRevenue
              )
            }
          ])

          setData(interpolateRevenue(mapped, 6 * 24))
        }
      }
      setIsLoading(false)
    }
    fetchData()
  }, [token, USDRate, countries, merchantIds])

  useEffect(() => {
    switch (type) {
      case 'Line':
        setOptions({
          ...options,
          chart: {
            type: 'line'
          }
        })
        break
      case 'Bar':
        setOptions({
          ...options,
          chart: {
            type: 'bar'
          }
        })
        break
      case 'Area':
        setOptions({
          ...options,
          chart: {
            type: 'area'
          }
        })
        break
    }
  }, [type])

  const typeChange = (event: SelectChangeEvent): void => {
    setType(event.target.value as string)
  }

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {title
              ? title
              : `Expected Revenue Over Time1 ${name}, ${currencySymbol}`}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={type}
              onChange={typeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {types.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        <Chart
          options={options}
          series={series}
          type={type === 'Line' ? 'line' : type === 'Bar' ? 'bar' : 'area'}
          height="400"
        />
        <Download data={data} />
      </Paper>
    </Grid>
  )
}
