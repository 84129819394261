import React, { useState, useEffect, useRef } from 'react'
import { Paper, Typography, TextField, MenuItem, Select, Box, FormControl, SelectChangeEvent, Grid } from '@mui/material'
import { StyleRules, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import moment from 'moment'
import { Download } from './Download'
import Chart from "react-apexcharts"

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      marginTop: '10px',
      textAlign: 'center',
      overflow: 'hidden'
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    caption: {
      textAlign: 'center',
      color: theme.palette.text.primary,
    },
    formControl: {
      margin: theme.spacing(1),
    },
    legend: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px'
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '10px',
      '&:last-child': {
        marginRight: '0px'
      }
    },
    legendMarker: {
      width: '10px',
      height: '10px',
      background: 'red',
      borderRadius: '100%',
      marginRight: '5px'
    },
    legendText: {
      fontSize: '13px',
      margin: '0px',
      color: 'rgb(53,53,53)'
    }
  })
)

const CurrencyPerformanceGraphOne = ({title}: any) => {
  const classes = useStyles()
  const accountingTreatment = [
    {
      label: '39034',
      value: '1'
    },
    {
      label: '15296',
      value: '2'
    },
    {
      label: '38022',
      value: '3'
    },
    {
      label: '84068',
      value: '4'
    },
    {
      label: '24178',
      value: '5'
    }
  ]
  const [data, setData] = useState([
    {
      name: 'Sales',
      arr: [
        {
          duration: moment()
            .add(-30, 'days')
            .format("MMM D"),
          discounted_sales: 2200,
          list_sales_total: 1200
        },
        {
          duration: moment()
            .add(-21, 'days')
            .format("MMM D"),
          discounted_sales: 2900,
          list_sales_total: 2600
        },
        {
          duration: moment()
            .add(-15, 'days')
            .format("MMM D"),
          discounted_sales: 3700,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-7, 'days')
            .format("MMM D"),
          discounted_sales: 4700,
          list_sales_total: 3600
        },
        {
          duration: moment()
            .add(-6, 'days')
            .format("MMM D"),
          discounted_sales: 4900,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-5, 'days')
            .format("MMM D"),
          discounted_sales: 1800,
          list_sales_total: 1200
        },
        {
          duration: moment()
            .add(-4, 'days')
            .format("MMM D"),
          discounted_sales: 2900,
          list_sales_total: 1600
        },
        {
          duration: moment()
            .add(-3, 'days')
            .format("MMM D"),
          discounted_sales: 2700,
          list_sales_total: 2200
        },
        {
          duration: moment()
            .add(-2, 'days')
            .format("MMM D"),
          discounted_sales: 3700,
          list_sales_total: 3600
        },
        {
          duration: moment()
            .add(-1, 'days')
            .format("MMM D"),
          discounted_sales: 4700,
          list_sales_total: 4000
        }
      ]
    }
  ])
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})
  const [type, setType] = useState('Line')
  const types = [
    'Line',
    'Bar',
    'Area'
  ]

  const setDefaultOptions = (mapped: any[]) => {
    setOptions({
      ...options,
      chart: { 
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      tooltip: {
        y: {
          formatter: (val: number) =>  {
            return Intl.NumberFormat('en-US', {
              maximumSignificantDigits: 3,
            }).format(val)
          }
        }
      },
      xaxis: {
        categories: mapped && mapped.length > 0 ? mapped: []
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            //@ts-ignore
            return Intl.NumberFormat('en-US', {
              //@ts-ignore
              notation: 'compact',
              compactDisplay: 'short',
            }).format(val)
          }
        }
      },
      fill: {
        type: 'solid'
      }
    })
  }

  const handleChange = (name: any, event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.value) {
      case '1':
        return setData([
          {
            name: 'Sales',
            arr: [
              {
                duration: moment()
                  .add(-30, 'days')
                  .format("MMM D"),
                discounted_sales: 2200,
                list_sales_total: 1200
              },
              {
                duration: moment()
                  .add(-21, 'days')
                  .format("MMM D"),
                discounted_sales: 2900,
                list_sales_total: 2600
              },
              {
                duration: moment()
                  .add(-15, 'days')
                  .format("MMM D"),
                discounted_sales: 3700,
                list_sales_total: 3200
              },
              {
                duration: moment()
                  .add(-7, 'days')
                  .format("MMM D"),
                discounted_sales: 4700,
                list_sales_total: 3600
              },
              {
                duration: moment()
                  .add(-6, 'days')
                  .format("MMM D"),
                discounted_sales: 4900,
                list_sales_total: 3200
              },
              {
                duration: moment()
                  .add(-5, 'days')
                  .format("MMM D"),
                discounted_sales: 1800,
                list_sales_total: 1200
              },
              {
                duration: moment()
                  .add(-4, 'days')
                  .format("MMM D"),
                discounted_sales: 2900,
                list_sales_total: 1600
              },
              {
                duration: moment()
                  .add(-3, 'days')
                  .format("MMM D"),
                discounted_sales: 2700,
                list_sales_total: 2200
              },
              {
                duration: moment()
                  .add(-2, 'days')
                  .format("MMM D"),
                discounted_sales: 3700,
                list_sales_total: 3600
              },
              {
                duration: moment()
                  .add(-1, 'days')
                  .format("MMM D"),
                discounted_sales: 4700,
                list_sales_total: 4000
              }
            ]
          }
        ])
      case '2':
        return setData([
          {
            name: 'Sales',
            arr: [
              {
                duration: moment()
                  .add(-30, 'days')
                  .format("MMM D"),
                discounted_sales: 4200,
                list_sales_total: 2200
              },
              {
                duration: moment()
                  .add(-21, 'days')
                  .format("MMM D"),
                discounted_sales: 2900,
                list_sales_total: 2600
              },
              {
                duration: moment()
                  .add(-15, 'days')
                  .format("MMM D"),
                discounted_sales: 3900,
                list_sales_total: 3100
              },
              {
                duration: moment()
                  .add(-7, 'days')
                  .format("MMM D"),
                discounted_sales: 3700,
                list_sales_total: 3400
              },
              {
                duration: moment()
                  .add(-6, 'days')
                  .format("MMM D"),
                discounted_sales: 2700,
                list_sales_total: 2200
              },
              {
                duration: moment()
                  .add(-5, 'days')
                  .format("MMM D"),
                discounted_sales: 1800,
                list_sales_total: 1300
              },
              {
                duration: moment()
                  .add(-4, 'days')
                  .format("MMM D"),
                discounted_sales: 2200,
                list_sales_total: 2100
              },
              {
                duration: moment()
                  .add(-3, 'days')
                  .format("MMM D"),
                discounted_sales: 3300,
                list_sales_total: 3100
              },
              {
                duration: moment()
                  .add(-2, 'days')
                  .format("MMM D"),
                discounted_sales: 4500,
                list_sales_total: 4200
              },
              {
                duration: moment()
                  .add(-1, 'days')
                  .format("MMM D"),
                discounted_sales: 4800,
                list_sales_total: 4500
              }
            ]
          }
        ])
      case '3':
        return setData([
          {
            name: 'Sales',
            arr: [
              {
                duration: moment()
                  .add(-30, 'days')
                  .format("MMM D"),
                discounted_sales: 4200,
                list_sales_total: 3200
              },
              {
                duration: moment()
                  .add(-21, 'days')
                  .format("MMM D"),
                discounted_sales: 2900,
                list_sales_total: 2600
              },
              {
                duration: moment()
                  .add(-15, 'days')
                  .format("MMM D"),
                discounted_sales: 2700,
                list_sales_total: 2200
              },
              {
                duration: moment()
                  .add(-7, 'days')
                  .format("MMM D"),
                discounted_sales: 3700,
                list_sales_total: 3600
              },
              {
                duration: moment()
                  .add(-6, 'days')
                  .format("MMM D"),
                discounted_sales: 3900,
                list_sales_total: 3200
              },
              {
                duration: moment()
                  .add(-5, 'days')
                  .format("MMM D"),
                discounted_sales: 2800,
                list_sales_total: 2200
              },
              {
                duration: moment()
                  .add(-4, 'days')
                  .format("MMM D"),
                discounted_sales: 1900,
                list_sales_total: 1700
              },
              {
                duration: moment()
                  .add(-3, 'days')
                  .format("MMM D"),
                discounted_sales: 2700,
                list_sales_total: 2200
              },
              {
                duration: moment()
                  .add(-2, 'days')
                  .format("MMM D"),
                discounted_sales: 3300,
                list_sales_total: 3200
              },
              {
                duration: moment()
                  .add(-1, 'days')
                  .format("MMM D"),
                discounted_sales: 4800,
                list_sales_total: 4700
              }
            ]
          }
        ])
      case '4':
        return setData([
          {
            name: 'Sales',
            arr: [
              {
                duration: moment()
                  .add(-30, 'days')
                  .format("MMM D"),
                discounted_sales: 4400,
                list_sales_total: 4200
              },
              {
                duration: moment()
                  .add(-21, 'days')
                  .format("MMM D"),
                discounted_sales: 3900,
                list_sales_total: 2700
              },
              {
                duration: moment()
                  .add(-15, 'days')
                  .format("MMM D"),
                discounted_sales: 4700,
                list_sales_total: 4200
              },
              {
                duration: moment()
                  .add(-7, 'days')
                  .format("MMM D"),
                discounted_sales: 3700,
                list_sales_total: 3600
              },
              {
                duration: moment()
                  .add(-6, 'days')
                  .format("MMM D"),
                discounted_sales: 4900,
                list_sales_total: 3200
              },
              {
                duration: moment()
                  .add(-5, 'days')
                  .format("MMM D"),
                discounted_sales: 2300,
                list_sales_total: 2200
              },
              {
                duration: moment()
                  .add(-4, 'days')
                  .format("MMM D"),
                discounted_sales: 1700,
                list_sales_total: 1600
              },
              {
                duration: moment()
                  .add(-3, 'days')
                  .format("MMM D"),
                discounted_sales: 2700,
                list_sales_total: 2400
              },
              {
                duration: moment()
                  .add(-2, 'days')
                  .format("MMM D"),
                discounted_sales: 3600,
                list_sales_total: 3400
              },
              {
                duration: moment()
                  .add(-1, 'days')
                  .format("MMM D"),
                discounted_sales: 4900,
                list_sales_total: 4600
              }
            ]
          }
        ])

      default:
        return setData([
          {
            name: 'Sales',
            arr: [
              {
                duration: moment()
                  .add(-30, 'days')
                  .format("MMM D"),
                discounted_sales: 2200,
                list_sales_total: 1200
              },
              {
                duration: moment()
                  .add(-21, 'days')
                  .format("MMM D"),
                discounted_sales: 2900,
                list_sales_total: 2600
              },
              {
                duration: moment()
                  .add(-15, 'days')
                  .format("MMM D"),
                discounted_sales: 4800,
                list_sales_total: 4400
              },
              {
                duration: moment()
                  .add(-7, 'days')
                  .format("MMM D"),
                discounted_sales: 4200,
                list_sales_total: 3800
              },
              {
                duration: moment()
                  .add(-6, 'days')
                  .format("MMM D"),
                discounted_sales: 3500,
                list_sales_total: 3200
              },
              {
                duration: moment()
                  .add(-5, 'days')
                  .format("MMM D"),
                discounted_sales: 2300,
                list_sales_total: 1200
              },
              {
                duration: moment()
                  .add(-4, 'days')
                  .format("MMM D"),
                discounted_sales: 1800,
                list_sales_total: 1700
              },
              {
                duration: moment()
                  .add(-3, 'days')
                  .format("MMM D"),
                discounted_sales: 2700,
                list_sales_total: 2500
              },
              {
                duration: moment()
                  .add(-2, 'days')
                  .format("MMM D"),
                discounted_sales: 3600,
                list_sales_total: 3500
              },
              {
                duration: moment()
                  .add(-1, 'days')
                  .format("MMM D"),
                discounted_sales: 4800,
                list_sales_total: 4500
              }
            ]
          }
        ])
    }
  }

  useEffect(() => {
    switch(type) {
      case 'Line':
        setOptions({
          ...options, 
            chart: { 
              type: 'line',
            },
        })
      break
      case 'Bar':
        setOptions({
          ...options, 
            chart: { 
              type: 'bar',
            },
        })
      break
      case 'Area':
        setOptions({
          ...options, 
            chart: { 
              type: 'area',
            },
        })
      break
    }
  }, [type])

  const typeChange = (event: SelectChangeEvent): void => {
    setType(event.target.value as string)
  }

  useEffect(() => {
    if(data.length === 0) return

    let actualData:any[] = []
    let budgetData:any[] = []
    let series: any[] = []
    let dateList: any[] = []
    
    data.map(dataItem => {
      dataItem.arr.map(item => {
        actualData.push(item.discounted_sales)
        budgetData.push(item.list_sales_total)
        dateList.push(item.duration)
      })
    })

    setDefaultOptions(dateList)

    series.push({
      name: 'Actual',
      data: actualData
    })

    series.push({
      name: 'Budget',
      data: budgetData
    })

    setSeries(series)
  }, [data])
  
  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            Promotion ID:
          </Typography>
          <TextField
            id="outlined-select-promotion_id"
            select
            variant='standard'
            style={{ width: '50%' }}
            //   label="Sub Classification"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('promotion_id', event)}
            defaultValue={1}
          >
            {accountingTreatment.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={type}
              onChange={typeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {types.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        <Chart
          options={options}
          series={series}
          type={type === 'Line' ? 'line' : type === 'Bar' ? 'bar' : 'area'}
          height="300"
        />
        <Download data={data[0].arr} />
      </Paper>
    </Grid>
  )
}

export default CurrencyPerformanceGraphOne
