import React, { useState, useEffect, useRef } from 'react'
import {
  Paper,
  Typography,
  CircularProgress,
  Select,
  Menu,
  MenuItem,
  Button,
  Chip,
  SelectChangeEvent,
  Box,
  FormControl,
  Grid
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'
import { DateTime } from 'luxon'

import { SelectionConstraints } from '../../rawApi'
import { getProductCurrencyPerformance } from '../../api'
import { Download } from './Download'
import Chart from "react-apexcharts"

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary
      // marginTop: '5px'
      //   minWidth: '400px'
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    formControl: {
      margin: theme.spacing(1),
    },
    legend: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px'
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '10px',
      '&:last-child': {
        marginRight: '0px'
      }
    },
    legendMarker: {
      width: '10px',
      height: '10px',
      background: 'red',
      borderRadius: '100%',
      marginRight: '5px'
    },
    legendText: {
      fontSize: '13px',
      margin: '0px',
      color: 'rgb(53,53,53)'
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary,
      paddingBottom: '15px'
    },
    chip: {
      marginLeft: theme.spacing(0.5),
      marginTop: '3px'
    },
    vsContainer: { display: 'flex' }
  })
)

interface Received {
  availableSkus: string[]
  from: string
  to: string
  country: string
  location: number
  sku: string
  averageItems: Item[]
  skuItems: Item[]
}

interface Item {
  dateTime: string
  value: number
}

interface DisplayItem {
  date: Date
  value: number
}

interface DisplayLine {
  array: DisplayItem[]
  name: string
  hash: string
}

interface CSVRow {
  sku: string
  date: string
  value: number
}

const getCSV = (SKUs: string[], data: DisplayLine[]): CSVRow[] => {
  const res: CSVRow[] = []
  SKUs.forEach((sku: string): void => {
    const line: DisplayLine | undefined = data.find(
      (l: DisplayLine): boolean => l.name === sku
    )
    if (line) {
      line.array.forEach((point): void => {
        res.push({
          sku: line.name,
          date: DateTime.fromJSDate(point.date).toFormat('MMM dd y'),
          value: point.value
        })
      })
    }
  })
  return res
}

interface ProductCurrencyPerformanceProps extends SelectionConstraints {
  token: string
  title: string
}
const hashCode = (str: string): string => {
  let hash = 0
  if (str.length === 0) {
    return `${hash}`
  }
  for (let i = 0; i < str.length; i++) {
    var char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }
  return `${hash}`
}
const hashForLine = (
  { range }: ProductCurrencyPerformanceProps,
  sku: string
): string =>
  range && range.startDate && range.endDate
    ? hashCode(
        range.startDate.toDateString() + range.endDate.toDateString() + sku
      )
    : 'no range'

const exists = (
  props: ProductCurrencyPerformanceProps,
  sku: string,
  data: DisplayLine[]
): boolean => {
  const hash = hashForLine(props, sku)
  return !!data.find((line: DisplayLine): boolean => line.hash === hash)
}

const Avg = 'Average'

export const ProductCurrencyPerformance = (
  props: ProductCurrencyPerformanceProps
): JSX.Element => {
  const classes = useStyles()
  const [data, setData] = useState<DisplayLine[]>([])
  const [availableSkus, setAvailableSkus] = useState<string[]>([])
  const [SKUs, setSKUs] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [type, setType] = useState('Line')
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})
  const types = [
    'Line',
    'Bar',
    'Area'
  ]
  const open = Boolean(anchorEl);
  
  function handleClick(event: React.MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event.currentTarget)
  }
  function handleClose(): void {
    setAnchorEl(null)
  }

  const setDefaultOptions = (mapped: DisplayItem[]) => {
    setOptions({
      ...options,
      chart: { 
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      xaxis: {
        categories: mapped && mapped.length > 0 ? mapped.map((i: DisplayItem) => DateTime.fromJSDate(i.date).toFormat('LLL dd')): []
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            //@ts-ignore
            return Intl.NumberFormat('en-US', {
              //@ts-ignore
              notation: 'compact',
              compactDisplay: 'short',
            }).format(val)
          }
        }
      },
      fill: {
        type: 'solid'
      }
    })
  }
  
  const typeChange = (event: SelectChangeEvent): void => {
    setType(event.target.value as string)
  }

  useEffect(() => {
    switch(type) {
      case 'Line':
        setOptions({
          ...options, 
            chart: { 
              type: 'line',
            },
        })
      break
      case 'Bar':
        setOptions({
          ...options, 
            chart: { 
              type: 'bar',
            },
        })
      break
      case 'Area':
        setOptions({
          ...options, 
            chart: { 
              type: 'area',
            },
        })
      break
    }
  }, [type])

  useEffect((): void => {
    const fetchData = async (): Promise<any> => {
      if (!props.range || !props.range.startDate || !props.range.endDate || !props.token) return
      setIsLoading(true)
      if (SKUs.length) {
        SKUs.forEach(
          async (sku: string): Promise<any> => {
            if (!exists(props, sku, data)) {
              const result: any = await getProductCurrencyPerformance({
                ...props,
                sku
              })
              if (result) {
                const skuMap = result.skuItems.map(
                  (item: Item): DisplayItem => ({
                    value: item.value,
                    date: DateTime.fromISO(item.dateTime).toJSDate()
                  })
                )
                let newData: DisplayLine[] = [
                  ...data,
                  { array: skuMap, name: sku, hash: hashForLine(props, sku) }
                ]
                setData(newData)
              }
            }
          }
        )
      } else {
        const result: any = await getProductCurrencyPerformance(props)
        if (result.availableSkus) {
          const mapped = result.averageItems.map(
            (item: Item): DisplayItem => ({
              value: item.value,
              date: DateTime.fromISO(item.dateTime).toJSDate()
            })
          )
          setAvailableSkus(result.availableSkus)
          const hash = hashForLine(props, Avg)
          setData([{ array: mapped, name: Avg, hash }])
        }
      }
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SKUs])

  useEffect(() => {
    if(data.length ===0 ) return
    let series = []
    const line: DisplayLine | undefined = data.find(
      (l: DisplayLine): boolean => l.name === Avg
    )
    if (line) {
      setDefaultOptions(line.array)
      series.push({
        name: line.name,
        data: line.array.map((i) => i.value)
      })
    }

    SKUs.map((sku: string) => {
      const line: DisplayLine | undefined = data.find(
        (l: DisplayLine): boolean => l.name === sku
      )
      if (line) {
        series.push({
          name: line.name,
          data: line.array.map((i) => i.value)
        })
      }
    })

    setSeries(series)

  }, [data])

  useEffect((): void => {
    setSKUs([])
  }, [props])

  let name = 'Global'

  if (props.merchant) {
    name = props.country + ' / ' + props.merchant
  } else if (props.country) {
    name = props.country || ''
  }

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {props.title ? props.title : `Product Currency Performance for ${name}`}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={type}
              onChange={typeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {types.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        <div className={classes.vsContainer}>
          {availableSkus && availableSkus.length > 0 && (
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              // aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              Add Product
            </Button>  
          )}
          <Menu
            id="pcp-add-sku"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {availableSkus
              .filter((sku: string): boolean => SKUs.indexOf(sku) === -1)
              .map(
                (sku: string): JSX.Element => (
                  <MenuItem
                    key={`compare-to-${sku}`}
                    onClick={(): void => {
                      setSKUs([...SKUs, sku])
                    }}
                  >
                    {sku}
                  </MenuItem>
                )
              )}
          </Menu>
          {SKUs.map(
            (sku: string, index: number): JSX.Element => {
              return (
                <Chip
                  key={`selected-vs-${index}`}
                  label={sku}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onDelete={(): void =>
                    setSKUs(SKUs.filter((c: string): boolean => c !== sku))
                  }
                  className={classes.chip}
                />
              )
            }
          )}
        </div>
        <Chart
          options={options}
          series={series}
          type={type === 'Line' ? 'line' : type === 'Bar' ? 'bar' : 'area'}
          height="400"
        />
        <Download data={getCSV(SKUs, data)} />
      </Paper>
    </Grid>
  )
}
