import { State } from '../reducers'
import { createSelector } from 'reselect'

export const getBaseCurrency = (state: State) => state.userState.baseCurrency
export const getBaseRates = (state: State) => state.constraintsState.baseRates

export const getBaseCurrencyRate = createSelector(
  getBaseCurrency,
  getBaseRates,
  (baseCurrency, rates) => rates[baseCurrency]
)
export const getUsdRate = (state: State) =>
  state.constraintsState.baseRates && state.constraintsState.baseRates['USD']
