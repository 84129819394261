import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  Paper,
  Typography,
  CircularProgress,
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Grid
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'
import { getMerchant, chooseRegion } from '../../utils'
import { State } from '../../reducers'
import { SelectionConstraints, getSalesVolumeWeekly } from '../../rawApi'
import { getUsdRate, getBaseCurrency } from '../../selectors'
import { DateTime } from 'luxon'
import { Download } from './Download'
import {
  last,
  getPredictions,
  linearRegression,
  convertDateToTimestamp
} from '../../common/forecast/forecastManager'
import Chart from 'react-apexcharts'
import { createSelector } from 'reselect'
import { BaseRates, RegionTotal } from '../../reducers/constraints'

interface DownloadType {
  date: String
  revenue: number
  expenditure: number
  freecash: number
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      marginTop: '5px',
      marginBottom: '10px'
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    caption: {
      textAlign: 'center',
      color: theme.palette.text.primary
    },
    formControl: {
      margin: theme.spacing(1)
    },
    legend: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px'
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '10px',
      '&:last-child': {
        marginRight: '0px'
      }
    },
    legendMarker: {
      width: '10px',
      height: '10px',
      background: 'red',
      borderRadius: '100%',
      marginRight: '5px'
    },
    legendText: {
      fontSize: '13px',
      margin: '0px',
      color: 'rgb(53,53,53)'
    }
  })
)

interface CashflowForecastProps {
  region: RegionTotal | null
  merchant: any
  token: string
  tenant_id: string | null
  datesRange: { startDate: Date; endDate: Date }
  rates: BaseRates
  countries: string[] | undefined
  merchantIds: string[] | undefined
}

const mapStateToProps = createSelector(
  (state: State): CashflowForecastProps['region'] =>
    (state.mapState.position.country &&
      chooseRegion(
        state.constraintsState.regions,
        state.mapState.position.country
      )) ||
    null,
  (state: State): CashflowForecastProps['merchant'] =>
    state.mapState.position.merchantId
      ? getMerchant(state.mapState.geojson, state.mapState.position.merchantId)
      : undefined,
  (state: State): CashflowForecastProps['token'] => state.userState.token || '',
  (state: State): CashflowForecastProps['tenant_id'] =>
    state.userState.tenant_id,
  (state: State): CashflowForecastProps['datesRange']['startDate'] =>
    state.constraintsState.startDate,
  (state: State): CashflowForecastProps['datesRange']['endDate'] =>
    state.constraintsState.endDate,
  (state: State): CashflowForecastProps['rates'] =>
    state.constraintsState.baseRates,
  (state: State): CashflowForecastProps['countries'] =>
    state.mapState.position.countries,
  (state: State): CashflowForecastProps['merchantIds'] =>
    state.mapState.position.merchantIds,
  (
    region,
    merchant,
    token,
    tenant_id,
    startDate,
    endDate,
    rates,
    countries,
    merchantIds
  ): CashflowForecastProps => ({
    region,
    merchant,
    token,
    tenant_id,
    datesRange: { startDate, endDate },
    rates,
    countries,
    merchantIds
  })
)

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const CashflowForecast = ({ title }: any): JSX.Element => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState('Line')
  const [timelineType, setTimelineType] = useState('10')
  const [revenueData, setRevenueData] = useState<any[]>([])
  const [expenditureData, setExpenditureData] = useState<any[]>([])
  const [freecashData, setFreeCashData] = useState<any[]>([])
  const [downloadData, setDownloadData] = useState<DownloadType[]>([])
  const [totalDownloadData, setTotalDownloadData] = useState<DownloadType[]>([])
  const {
    region,
    merchant,
    token,
    datesRange,
    rates,
    tenant_id,
    countries,
    merchantIds
  } = useSelector(mapStateToProps)
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})
  const [categories, setCategories] = useState<any[]>([])
  const merchantId = merchant ? merchant.merchant_id : undefined
  const country = region && region.country ? region.country : undefined
  const forecastDays = 30
  const startDate = DateTime.fromJSDate(datesRange.endDate)
    .minus({ days: forecastDays * 2 + 2 })
    .toJSDate()
  const endDate = datesRange.endDate

  const cProps: SelectionConstraints = {
    country,
    tenant_id,
    merchant: merchantId,
    range: {
      startDate,
      endDate
    },
    countries,
    merchantIds
  }

  const USDRate = useSelector(getUsdRate)
  const baseCurrency = useSelector(getBaseCurrency)

  let name = 'Global'

  if (merchantId) {
    name = country + ' / ' + merchantId
  } else if (country) {
    name = country || ''
  }

  const currencyCode =
    name === 'Global' ? baseCurrency : (region && region.currency) || 'USD'

  let transformRate = USDRate ? USDRate : 1

  const types = ['Line', 'Bar', 'Area']

  const timelineTypes = ['10', '15', '30']

  const setDefaultOptions = (mapped: any[]) => {
    setOptions({
      ...options,
      chart: {
        zoom: { enabled: false },
        toolbar: { show: false }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      tooltip: {
        y: {
          formatter: (val: number) => {
            return val.toLocaleString('en-US', {
              style: 'currency',
              currency: currencyCode
            })
          },
          title: {
            formatter: (seriesName: string) =>
              seriesName === 'Revenues'
                ? 'Revenue'
                : seriesName === 'Expenditures'
                ? 'Expenditure'
                : seriesName
          }
        }
      },
      xaxis: {
        // categories: categories && categories.length > 0 ? categories.slice(0, parseInt(timelineType)): []
        categories: mapped && mapped.length > 0 ? mapped : []
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            //@ts-ignore
            return Intl.NumberFormat('en-US', {
              //@ts-ignore
              notation: 'compact',
              compactDisplay: 'short'
            }).format(val)
          }
        }
      },
      fill: {
        type: 'solid'
      }
    })
  }

  function generateDates(startDate: string, endDate: string) {
    var start = new Date(startDate)
    var end = new Date(endDate)
    for (var arr = [], dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt).toISOString().slice(0, 10))
    }
    return arr
  }

  useEffect((): void => {
    // for the case of global chart, need to wait for currencies data
    if (((!countries || !merchantIds) && !USDRate) || !token) return

    const fetchData = async (): Promise<any> => {
      setIsLoading(true)
      const result = await getSalesVolumeWeekly(token, cProps)
      if (
        result &&
        cProps.range &&
        cProps.range.startDate &&
        result.aggregations.perDay.buckets.length > 0
      ) {
        let categories: any[] = []
        let data: any[] = []
        let dates: any[] = []
        let newRevenueData: any[] = []
        let newExpenditureData: any[] = []
        let newFreeCashData: any[] = []
        let newTotalDownloadData: any[] = []

        result.aggregations.perDay.buckets.forEach((item: any): void => {
          let value = 0
          for (let h in item.perHour.buckets) {
            value += item.perHour.buckets[h].gross.value * transformRate
          }
          data.push(value)
          dates.push(item.key_as_string)
        })

        var allDates = generateDates(dates[0], dates[dates.length - 1])

        var dateToData = dates.reduce(function(map, date, index) {
          map[date.slice(0, 10)] = data[index]
          return map
        }, {})

        // Interpolate data for missing dates
        allDates.forEach(function(date, index) {
          if (!(date in dateToData)) {
            var prevData = null
            for (var i = index - 1; i >= 0; i--) {
              if (allDates[i] in dateToData) {
                prevData = dateToData[allDates[i]]
                break
              }
            }
            var nextData = null
            for (var i = index + 1; i < allDates.length; i++) {
              if (allDates[i] in dateToData) {
                nextData = dateToData[allDates[i]]
                break
              }
            }
            if (prevData !== null && nextData !== null) {
              dateToData[date] = (prevData + nextData) / 2
            } else if (prevData !== null) {
              dateToData[date] = prevData
            } else if (nextData !== null) {
              dateToData[date] = nextData
            }
          }
        })

        // Convert the object to an array of pairs
        let pairs: [string, number][] = Object.keys(dateToData).map(
          (key: string) => {
            return [key, dateToData[key]]
          }
        )

        // Sort the array by the date (which is the first item of each pair)
        pairs.sort((pair1: [string, number], pair2: [string, number]) => {
          return new Date(pair1[0]).getTime() - new Date(pair2[0]).getTime()
        })

        // If you need to convert it back to an object
        let sortedData: { [key: string]: number } = {}
        pairs.forEach((pair: [string, number]) => {
          sortedData[pair[0]] = pair[1]
        })

        // Get the final dates and data
        dates = Object.keys(sortedData)
        data = dates.map(function(date) {
          return sortedData[date]
        })

        let timestamps = dates.map(convertDateToTimestamp)
        let result1 = linearRegression(timestamps, data)

        let date1 = new Date(startDate)
        let date2 = new Date(dates[0])
        let data1: any[] = []

        if (
          date1.getFullYear() != date2.getFullYear() ||
          date1.getDate() != date2.getDate() ||
          date1.getMonth() != date2.getMonth()
        ) {
          var getDaysArray = function(s: any, e: any) {
            for (
              var a = [], d = new Date(s);
              d <= new Date(e);
              d.setDate(d.getDate() + 1)
            ) {
              a.push(new Date(d))
            }
            return a
          }
          var daylist = getDaysArray(date1, date2)

          daylist.map(date => {
            const futureTimestamp = convertDateToTimestamp(date)
            const prediction =
              result1.slope * futureTimestamp + result1.intercept
            data1.push(prediction)
          })
        }

        data = data1.concat(data)

        const FORECAST_CONFIG = {
          periodSize: forecastDays,
          observationsToForeast: forecastDays - 1
        }

        const dataPreparedToPredict: number[] = data.map(i => i)
        // Taking only predicted values + one point for existing history to make chart smoother

        const predictedRow = last(
          //@ts-ignore
          getPredictions(dataPreparedToPredict, FORECAST_CONFIG),
          FORECAST_CONFIG.observationsToForeast + 1
        )

        // calculate the values of 3 months
        let predicted = false

        if (predictedRow && predictedRow.length === forecastDays) {
          predicted = true
        }

        for (let i = 0; i < forecastDays; i++) {
          const date = DateTime.now()
            .plus({ days: i + 1 })
            .toFormat('LLL dd')
          newRevenueData.push(predicted === true ? predictedRow[i] : 0)
          newTotalDownloadData.push({
            date: date,
            revenue: predicted === true ? predictedRow[i] : 0,
            expenditure: 0,
            freecash: 0
          })
          categories.push(date)
        }

        // calculate expenditure from revenue data like -8%~-12% variant
        newRevenueData.map((item, index) => {
          const freeCashValue = (item * randomIntFromInterval(8, 12)) / 100
          newExpenditureData.push(item - freeCashValue)
          newFreeCashData.push(freeCashValue)
          newTotalDownloadData[index].expenditure = item - freeCashValue
          newTotalDownloadData[index].freecash = freeCashValue
        })

        setCategories(categories)
        setDefaultOptions(categories.slice(0, 10))
        setSeries([
          {
            name: 'Revenues',
            data: newRevenueData.slice(0, 10)
          },
          {
            name: 'Expenditures',
            data: newExpenditureData.slice(0, 10)
          },
          {
            name: 'Free Cash',
            data: newFreeCashData.slice(0, 10)
          }
        ])

        setRevenueData(newRevenueData)
        setExpenditureData(newExpenditureData)
        setFreeCashData(newFreeCashData)
        setTotalDownloadData(newTotalDownloadData)
        setDownloadData(newTotalDownloadData.slice(0, parseInt(timelineType)))
      }
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, token, datesRange, USDRate, countries, merchantIds])

  useEffect(() => {
    if (!timelineType) return

    if (totalDownloadData.length === 30) {
      setDownloadData(totalDownloadData.slice(0, parseInt(timelineType)))
    }

    setOptions({
      ...options,
      xaxis: {
        categories:
          categories && categories.length > 0
            ? categories.slice(0, parseInt(timelineType))
            : []
      }
    })

    setSeries([
      {
        name: 'Revenues',
        data: revenueData.slice(0, parseInt(timelineType))
      },
      {
        name: 'Expenditures',
        data: expenditureData.slice(0, parseInt(timelineType))
      },
      {
        name: 'Free Cash',
        data: freecashData.slice(0, parseInt(timelineType))
      }
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineType, baseCurrency])

  useEffect(() => {
    if (categories.length === 0) return
    switch (type) {
      case 'Line':
        setOptions({
          ...options,
          chart: {
            type: 'line',
            stacked: false
          },
          stroke: {
            width: 2
          },
          xaxis: {
            categories:
              categories && categories.length > 0
                ? categories.slice(0, parseInt(timelineType))
                : []
          }
        })
        break
      case 'Bar':
        setOptions({
          ...options,
          chart: {
            type: 'bar',
            stacked: true
          },
          stroke: {
            width: 1
          },
          fill: {
            type: 'solid'
          },
          xaxis: {
            categories:
              categories && categories.length > 0
                ? categories.slice(0, parseInt(timelineType))
                : []
          }
        })
        break
      case 'Area':
        setOptions({
          ...options,
          chart: {
            type: 'area'
          },
          stroke: {
            width: 2
          },
          fill: {
            type: 'solid'
          },
          xaxis: {
            categories:
              categories && categories.length > 0
                ? categories.slice(0, parseInt(timelineType))
                : []
          }
        })
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, categories])

  const typeChange = (event: SelectChangeEvent): void => {
    setType(event.target.value as string)
  }

  const timelineTypeChange = (event: SelectChangeEvent): void => {
    setTimelineType(event.target.value as string)
  }

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={timelineType}
              onChange={timelineTypeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {timelineTypes.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {`${curr} days`}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {title ? title : 'Cashflow Forecast'}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={type}
              onChange={typeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {types.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        <Chart
          options={options}
          series={series}
          type={type === 'Line' ? 'line' : type === 'Bar' ? 'bar' : 'area'}
          height="400"
        />
        <Download data={downloadData} />
      </Paper>
    </Grid>
  )
}
