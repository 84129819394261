import axios from './axiosInstance'

interface Constraints {
  country: string
  merchant: string
  startDate: Date
  endDate: Date
}
const getCompareLocationsQuery = ({
  country,
  merchant,
  startDate,
  endDate
}: Constraints): {} => {
  const must = []
  if (country) {
    must.push({
      match_phrase: {
        country: {
          query: country
        }
      }
    })
  }
  if (merchant) {
    must.push({
      match_phrase: {
        merchant_id: {
          query: parseInt(merchant)
        }
      }
    })
  }

  return {
    aggs: {
      byDay: {
        date_histogram: {
          field: 'datetime_from',
          fixed_interval: '1d',
          time_zone: 'Europe/Helsinki',
          min_doc_count: 1
        },
        aggs: {
          byCountry: {
            terms: {
              field: 'country.keyword',
              size: 500,
              order: {
                gross: 'desc'
              }
            },
            aggs: {
              gross: {
                sum: {
                  field: 'gross'
                }
              },
              net: {
                sum: {
                  field: 'net'
                }
              },
              refunds: {
                sum: {
                  field: 'refunds'
                }
              },
              refundCount: {
                sum: {
                  field: 'refund_count'
                }
              },
              tax: {
                sum: {
                  field: 'tax'
                }
              },
              taxCount: {
                sum: {
                  field: 'txn_count'
                }
              },
              feeTotal: {
                sum: {
                  field: 'fee_total'
                }
              }
            }
          }
        }
      }
    },
    size: 0,
    _source: {
      excludes: []
    },
    docvalue_fields: [
      {
        field: 'datetime_from',
        format: 'date_time'
      },
      {
        field: 'datetime_to',
        format: 'date_time'
      }
    ],
    query: {
      bool: {
        must: [
          ...must,
          {
            range: {
              datetime_from: {
                gte: startDate.getTime(),
                lte: endDate.getTime(),
                format: 'epoch_millis'
              }
            }
          }
        ]
      }
    }
  }
}

interface CompareLocationsDataset {
  took: number
  timed_out: boolean
  _shards: Shards
  hits: Hits
  aggregations: Aggregations
}

interface Aggregations {
  byDay: ByDay
}

interface ByDay {
  buckets: Bucket2[]
}

interface Bucket2 {
  key_as_string: string
  key: number
  doc_count: number
  byCountry: ByCountry
}

interface ByCountry {
  doc_count_error_upper_bound: number
  sum_other_doc_count: number
  buckets: Bucket[]
}

interface Bucket {
  key: string
  doc_count: number
  refundCount: RefundCount
  feeTotal: RefundCount
  gross: RefundCount
  taxCount: RefundCount
  tax: RefundCount
  net: RefundCount
  refunds: RefundCount
}

interface RefundCount {
  value: number
}

interface Hits {
  total: Total
  max_score?: any
  hits: any[]
}

interface Total {
  value: number
  relation: string
}

interface Shards {
  total: number
  successful: number
  skipped: number
  failed: number
}

export const getCompareLocations = async (
  token: string,
  constraints: Constraints
): Promise<CompareLocationsDataset | null> => {
  const request = getCompareLocationsQuery(constraints)
  const response = await axios.post(
    '/settlement/aggregations/raw?index=location_total_30m',
    request,
    {
      headers: { Authorization: 'Bearer ' + token }
    }
  )
  if (response.data.error) return null
  else return response.data
}
