import React, { useState, useEffect } from 'react'
import {
  Paper,
  Typography,
  Box,
  Tabs,
  Tab,
  AppBar,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Grid
} from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import { makeStyles, StyleRules } from '@mui/styles'
import SwipeableViews from 'react-swipeable-views'
import { useSelector } from 'react-redux'

import { State } from '../../reducers'
import { Download } from './Download'
import { getChips } from '../../utils'
import { getTopLifetimeValue } from '../../apis/GetTopLifetimeValue'
import { createSelector } from 'reselect'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      minWidth: '150px',
      marginTop: '5px',
      marginLeft: '5px'
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary
    },
    download: {
      textAlign: 'left'
    },
    line: {
      textAlign: 'left',
      width: '100%'
    },
    name: {
      marginLeft: '-14px'
    },
    value: {
      color: 'black',
      display: 'inline-block',
      float: 'right',
      fontWeight: 500
    },
    desc: {
      display: 'flex',
      justifyContent: 'space-around'
    },
    details: {
      display: 'inline',
      padding: '0'
    },
    tabs: {
      height: '3px'
    },
    tab: {
      minWidth: '80px',
      fontSize: '0.7rem'
    }
  })
)

const a11yProps = (index: number): {} => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const TabPanel = (props: {
  children: any
  value: number
  index: number
  dir: string
}): JSX.Element => {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  )
}
interface BucketItem {
  1: {
    value: number
  }
  doc_count: number
  key: string
}
interface TLItem {
  key: string
  gross: number
  order: number
  refundCount: number
  feeTotal: number
  unitCount: number
  txnCount: number
  tax: number
  net: number
  refunds: number
}

interface TLData {
  top: TLItem[]
  bottom: TLItem[]
}

interface TopLifeTimeProps {
  token: string
  tenant_id: string
}

const mapState = createSelector(
  (state: State): TopLifeTimeProps['token'] => state.userState.token || '',
  (state: State): TopLifeTimeProps['tenant_id'] => state.userState.tenant_id || '',
  (
    token,
    tenant_id,
  ): TopLifeTimeProps => ({
    token,
    tenant_id,
  })
)

export const TopLifetimeValue = function App({title}: any): JSX.Element {
  const classes = useStyles()
  const theme = useTheme()
  const { token, tenant_id } = useSelector(mapState)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<TLData>({ top: [], bottom: [] })

  //   const top = props.data.slice(0, 5)
  //   const bottom = props.data.slice(-5).reverse()

  useEffect((): void => {
    if (!token) return
    
    const fetchData = async (): Promise<any> => {
      setIsLoading(true)
      const result: TLData = { top: [], bottom: [] }
      const topResult = await getTopLifetimeValue(token, false, tenant_id)
      if (topResult) {
        const top = topResult.aggregations.id.buckets.map(
          (item, index: number): TLItem => {
            return {
              order: index + 1,
              key: item.key,
              gross: item.gross.value,
              refundCount: item.refundCount.value,
              feeTotal: item.feeTotal.value,
              unitCount: item.unitCount.value,
              txnCount: item.txnCount.value,
              tax: item.tax.value,
              net: item.net.value,
              refunds: item.refunds.value
            }
          }
        )
        result.top = top
      }
      const bottomResult = await getTopLifetimeValue(token, true, tenant_id)
      if (bottomResult) {
        const total: number = bottomResult.hits.total.value
        const bottom = bottomResult.aggregations.id.buckets.map(
          (item, index: number): TLItem => {
            return {
              order: total - index,
              key: item.key,
              gross: item.gross.value,
              refundCount: item.refundCount.value,
              feeTotal: item.feeTotal.value,
              unitCount: item.unitCount.value,
              txnCount: item.txnCount.value,
              tax: item.tax.value,
              net: item.net.value,
              refunds: item.refunds.value
            }
          }
        )
        result.bottom = bottom
      }
      setData(result)
      setIsLoading(false)
    }
    fetchData()
  }, [token])

  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }

  const handleChangeIndex = (index: number): void => {
    setValue(index)
  }
  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Typography
          variant="subtitle2"
          component="h3"
          className={classes.caption}
        >
          {title ? title: 'Top Lifetime Value'}
          {isLoading && (
            <CircularProgress
              className={classes.progress}
              size={15}
              color={'secondary'}
            />
          )}
        </Typography>
        <AppBar elevation={0} position="static" color="inherit">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            TabIndicatorProps={{ className: classes.tabs }}
          >
            <Tab label="Top" {...a11yProps(0)} className={classes.tab} />
            <Tab label="Bottom" {...a11yProps(1)} className={classes.tab} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <Sview
            data={data.top}
            index={0}
            value={value}
            classes={classes}
            theme={theme}
          />
          <Sview
            data={data.bottom}
            index={1}
            value={value}
            classes={classes}
            theme={theme}
          />
        </SwipeableViews>
        <Download data={[...data.top, ...data.bottom]} />
      </Paper>
    </Grid>
  )
}

interface SVIEW {
  data: TLItem[]
  index: number
  value: number
  classes: any
  theme: any
}

const Sview = ({ data, index, value, classes, theme }: SVIEW): JSX.Element => (
  <TabPanel value={value} index={index} dir={theme.direction}>
    {data.map(
      (item): JSX.Element => (
        <Accordion key={'tcl-' + item.key} elevation={0}>
          <AccordionSummary>
            <Typography
              variant="caption"
              display="block"
              className={classes.line}
            >
              <Tooltip title="SKU" placement="top">
                <span
                  className={classes.name}
                >{`${item.order}. ${item.key}`}</span>
              </Tooltip>
              <Tooltip title="Gross" placement="top">
                <span className={classes.value}>{getChips(item.gross)}</span>
              </Tooltip>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography
              variant="caption"
              display="block"
              className={classes.desc}
            >
              <Tooltip title="Tax" placement="top">
                <span>{`${getChips(item.tax)}`}</span>
              </Tooltip>
              <Tooltip title="Net" placement="top">
                <span>{`${getChips(item.net)} (${item.txnCount})`}</span>
              </Tooltip>
              <Tooltip title="Refunds" placement="top">
                <span>{`${getChips(item.refunds)} (${item.refundCount})`}</span>
              </Tooltip>
            </Typography>
          </AccordionDetails>
        </Accordion>
      )
    )}
  </TabPanel>
)
