import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Paper, Typography, Box, Tabs, Tab, AppBar, Grid } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'
import SwipeableViews from 'react-swipeable-views'

import { mapCountryToCurrency } from '../../temp/currencies_map'
import { calcToBase } from '../BCTooltip'
import { getTopGrossingLocations } from '../../rawApi'
import { getTopGrossingLocations as getWithCountry } from '../../apis/GetTopCrossingLocations'
import { State } from '../../reducers'
import { BaseRates } from '../../reducers/constraints'
import { Download } from './Download'
import { getChips } from '../../utils'
import { createSelector } from 'reselect'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      minWidth: '150px',
      marginTop: '5px',
      marginLeft: '5px'
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary
    },
    download: {
      textAlign: 'left'
    },
    line: {
      textAlign: 'left'
    },
    name: {
      marginLeft: '-14px'
    },
    value: {
      color: 'black',
      display: 'inline-block',
      float: 'right',
      fontWeight: 500
    },
    tabs: {
      height: '3px'
    },
    tab: {
      minWidth: '80px',
      fontSize: '0.7rem'
    }
  })
)

const a11yProps = (index: number): {} => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const TabPanel = (props: {
  children: any
  value: number
  index: number
  dir: string
}): JSX.Element => {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  )
}

interface TopGrossingLocationsProps {
  token: string
  tenant_id: string | null
  base: string
  rates: BaseRates
  startDate: Date
  endDate: Date
  country: string
}

const mapState = createSelector(
  (state: State): TopGrossingLocationsProps['token'] => state.userState.token || '',
  (state: State): TopGrossingLocationsProps['tenant_id'] => state.userState.tenant_id,
  (state: State): TopGrossingLocationsProps['base'] => state.userState.baseCurrency,
  (state: State): TopGrossingLocationsProps['rates'] => state.constraintsState.baseRates,
  (state: State): TopGrossingLocationsProps['startDate'] => state.constraintsState.startDate,
  (state: State): TopGrossingLocationsProps['endDate'] => state.constraintsState.endDate,
  (state: State): TopGrossingLocationsProps['country'] => state.mapState.position.country || '',
  (
    token,
    tenant_id,
    base,
    rates,
    startDate,
    endDate,
    country
  ): TopGrossingLocationsProps => ({
    token,
    tenant_id,
    base,
    rates,
    startDate,
    endDate,
    country
  })
)

interface Location {
  order: number
  name: string
  value: number
  currencyCode: string
}
interface TopGrossingLocationsI {
  top: Location[]
  bottom: Location[]
  all: Location[]
}
export const TopGrossingLocations = function App({title}: any): JSX.Element {
  const classes = useStyles()
  const theme = useTheme()
  const { token, tenant_id, rates, base, startDate, endDate, country } = useSelector(
    mapState
  )
  const [data, setData] = useState<TopGrossingLocationsI>({
    top: [],
    bottom: [],
    all: []
  })
  useEffect((): void => {
    if (!token || !base || !rates) return
    ;(async (): Promise<void> => {
      let topGrossing
      if (country) {
        topGrossing = await getWithCountry(token, {
          startDate,
          endDate,
          country,
          tenant_id
        })
        if (topGrossing) {
          const newData = topGrossing.aggregations.merchantId.buckets
            .map(
              (merchant): Location => {
                const order = 0
                const name = '' + merchant.key
                const value = calcToBase({
                  value: merchant.gross.value,
                  currency: mapCountryToCurrency[country] || '',
                  base,
                  rates
                })
                // mapCountryToCurrency[country.key]
                return { order, name, value, currencyCode: base }
              }
            )
            .sort(({ value: a }, { value: b }): number => b - a)
            .map((val, index): Location => ({ ...val, order: index + 1 }))

          const top = newData
            .slice(0, 5)
            .map((val, index): Location => ({ ...val, order: index + 1 }))
          const bottom = newData
            .slice(-5)
            .reverse()
            .map(
              (val, index): Location => ({
                ...val,
                order: newData.length - index
              })
            )
          setData({ top, bottom, all: newData })
        }
      } else {
        topGrossing = await getTopGrossingLocations(token, {
          range: { startDate, endDate },
          tenant_id: tenant_id
        })
        if (topGrossing) {
          const newData = topGrossing.aggregations.byCountry.buckets
            .map(
              (country): Location => {
                const order = 0
                const name = country.key
                const value = calcToBase({
                  value: country.gross.value,
                  currency: mapCountryToCurrency[country.key] || '',
                  base,
                  rates
                })
                // mapCountryToCurrency[country.key]
                return { order, name, value, currencyCode: base }
              }
            )
            .sort(({ value: a }, { value: b }): number => b - a)
            .map((val, index): Location => ({ ...val, order: index + 1 }))

          const top = newData
            .slice(0, 5)
            .map((val, index): Location => ({ ...val, order: index + 1 }))
          const bottom = newData
            .slice(-5)
            .reverse()
            .map(
              (val, index): Location => ({
                ...val,
                order: newData.length - index
              })
            )
          setData({ top, bottom, all: newData })
        }
      }
    })()
  }, [token, base, rates, startDate, endDate, country])

  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }

  const handleChangeIndex = (index: number): void => {
    setValue(index)
  }
  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Typography
          variant="subtitle2"
          component="h3"
          className={classes.caption}
        >
          {title ? title : `Top Grossing Locations ${country && ` - ${country}`}`}
        </Typography>
        <AppBar elevation={0} position="static" color="inherit">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            TabIndicatorProps={{ className: classes.tabs }}
          >
            <Tab label="Top" {...a11yProps(0)} className={classes.tab} />
            <Tab label="Bottom" {...a11yProps(1)} className={classes.tab} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <Sview
            data={data.top}
            index={0}
            value={value}
            classes={classes}
            theme={theme}
          />
          <Sview
            data={data.bottom}
            index={1}
            value={value}
            classes={classes}
            theme={theme}
          />
        </SwipeableViews>
        <Download data={data.all} />
      </Paper>
    </Grid>
  )
}

interface SVIEW {
  data: Location[]
  index: number
  value: number
  classes: any
  theme: any
}

const Sview = ({ data, index, value, classes, theme }: SVIEW): JSX.Element => (
  <TabPanel value={value} index={index} dir={theme.direction}>
    {data.map(
      (region: Location): JSX.Element => (
        <Typography
          variant="caption"
          display="block"
          className={classes.line}
          key={'tcl-' + region.order}
        >
          <span className={classes.name}>
            {region.order + '. ' + region.name}
          </span>
          <span className={classes.value}>
            {getChips(region.value) + ' ' + region.currencyCode}
          </span>
        </Typography>
      )
    )}
  </TabPanel>
)
