import React, { useState, useEffect, useRef } from 'react'
import { Paper, Typography, CircularProgress, MenuItem, Select, Box, FormControl, SelectChangeEvent, Grid } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'
import {
  getInventoryResupplyForecasting,
  SelectionConstraints
} from '../../rawApi'
import { Download } from './Download'
import { DateTime } from 'luxon'
import { RegionTotal } from '../../reducers/constraints'
import Chart from "react-apexcharts"

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      marginTop: '5px',
      marginBottom: '10px'
      //   minWidth: '400px'
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary,
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    formControl: {
      margin: theme.spacing(1),
    },
    legend: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginBottom: '20px'
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '10px',
      '&:last-child': {
        marginRight: '0px'
      }
    },
    legendMarker: {
      width: '10px',
      height: '10px',
      background: 'red',
      borderRadius: '100%',
      marginRight: '5px'
    },
    legendText: {
      fontSize: '13px',
      margin: '0px',
      color: 'rgb(53,53,53)'
    }
  })
)

interface Value {
  value: number
}

interface RFItem {
  key_as_string: string
  key: number
  doc_count: number
  dailySales: Value
  qtyAtStock: Value
}

interface DisplayDataItem {
  array: {
    date: Date
    value: number
    skuQty: number
  }[]
  name: string
  sku: string
}

interface CSVRow {
  by: string
  date: string
  value: number
}

const getCSV = (data: DisplayDataItem[]): CSVRow[] => {
  const csv: CSVRow[] = []
  data.forEach((item: DisplayDataItem): void => {
    item.array.forEach((row): void => {
      csv.push({
        date: DateTime.fromJSDate(row.date).toFormat('MMM dd y'),
        by: item.name,
        value: row.value
      })
    })
  })
  return csv
}

interface InventoryResupplyForecastingProps extends SelectionConstraints {
  token: string
  getProducts: Function
  region: RegionTotal | null
  supplyAtRisk: boolean
  title: string
}

export const InventoryResupplyForecasting = ({
  token,
  tenant_id,
  merchant,
  country,
  getProducts,
  range,
  region,
  supplyAtRisk,
  title
}: InventoryResupplyForecastingProps): JSX.Element => {
  const classes = useStyles()
  const getData = getInventoryResupplyForecasting(token)
  const [data, setData] = useState<DisplayDataItem[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState('Line')
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})
  const types = [
    'Line',
    'Bar',
    'Area'
  ]

  let name = 'Global'
  if (merchant) {
    name = country + ' / ' + merchant
  } else if (country) {
    name = country || ''
  }

  const setDefaultOptions = (mapped: any[]) => {
    setOptions({
      ...options,
      chart: { 
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 1
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      tooltip: {
        y: {
          formatter: (val: number) =>  {
            return Intl.NumberFormat('en-US', {
              maximumSignificantDigits: 3,
            }).format(val)
          }
        }
      },
      xaxis: {
        categories: mapped && mapped.length > 0 ? mapped: []
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            //@ts-ignore
            return Intl.NumberFormat('en-US', {
              //@ts-ignore
              notation: 'compact',
              compactDisplay: 'short',
            }).format(val)
          }
        }
      },
      fill: {
        type: 'solid'
      }
    })
  }

  useEffect((): void => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)
      const futureRange: SelectionConstraints['range'] = {
        startDate: DateTime.utc().toJSDate(),
        endDate: DateTime.utc()
          .plus({ days: 7 })
          .toJSDate()
      }
      const productsResultPromise = getProducts({ range, country, merchant })
      const result = await getData({ range: futureRange, merchant, tenant_id })
      const productsResult = await productsResultPromise
      const skus = (productsResult || []).map((i: { key: string }) => i.key)

      let optionList: any[] = []

      if (result && productsResult) {
        const mapped = result.map(
          (item: RFItem, index: number): DisplayDataItem => {
            const portion: DisplayDataItem['array'] = []
            for (let i = 0; i < 7; i++) {
              const date = DateTime.utc()
                .plus({ days: i })
                .toJSDate()
              optionList.push(DateTime.fromJSDate(date).toFormat('LLL dd'))
              const deducted = item.qtyAtStock.value - item.dailySales.value * i * (item.qtyAtStock.value / (item.dailySales.value * 6) - 0.5)
              portion.push({
                date,
                value: deducted > 0 ? deducted : 0,
                skuQty: item.doc_count
              })
            }
            setDefaultOptions(optionList)
            return {
              array: portion,
              name:
                'by ' + DateTime.fromISO(item.key_as_string).toFormat('MMM d'),
              sku: skus[index] || ''
            }
          }
        )
        const setMapData = mapped.length > 10 ? mapped.slice(0, 10) : mapped
        setSeries(setMapData.map((item:any) => {
          return {name: item.sku, data: item.array.map((i: any) => i.value)}
        }))
        setData(setMapData)
      }
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, merchant, country, range])

  useEffect(() => {
    switch(type) {
      case 'Line':
        setOptions({
          ...options, 
            chart: { 
              type: 'line',
            },
        })
      break
      case 'Bar':
        setOptions({
          ...options, 
            chart: { 
              type: 'bar',
            },
        })
      break
      case 'Area':
        setOptions({
          ...options, 
            chart: { 
              type: 'area',
            },
        })
      break
    }
  }, [type])

  const typeChange = (event: SelectChangeEvent): void => {
    setType(event.target.value as string)
  }

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        {supplyAtRisk ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: '50px',
                alignItems: 'center'
              }}
            >
              <Typography
                variant="subtitle2"
                component="h3"
                className={classes.caption}
              >
                Location {name}
              </Typography>
              <Typography
                variant="subtitle2"
                component="h3"
                className={classes.caption}
              >
                {title ? title : 'Supply At Risk'}
              </Typography>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  value={type}
                  onChange={typeChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {types.map(
                    (curr: string): JSX.Element => (
                      <MenuItem key={`base-select-${curr}`} value={curr}>
                        {curr}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
            <Typography
              variant="subtitle2"
              component="h3"
              className={classes.caption}
            >
              Burn Down Report
            </Typography>
          </div>
        ) : (
          <Box className={classes.box}>
            <Typography
              variant="subtitle2"
              component="h3"
              className={classes.caption}
            >
              Inventory Resupply Forecasting{' ' + name}
              {isLoading && (
                <CircularProgress
                  className={classes.progress}
                  size={15}
                  color={'secondary'}
                />
              )}
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                value={type}
                onChange={typeChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {types.map(
                  (curr: string): JSX.Element => (
                    <MenuItem key={`base-select-${curr}`} value={curr}>
                      {curr}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
        )}
        <Chart
          options={options}
          series={series}
          type={type === 'Line' ? 'line' : type === 'Bar' ? 'bar' : 'area'}
          height="300"
        />
        <Download data={getCSV(data)} />
      </Paper>
    </Grid>
  )
}
