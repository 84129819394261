import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { StyleRules, createStyles, makeStyles } from '@mui/styles'
import { Select, SelectChangeEvent } from '@mui/material'
import { State, Dispatch } from '../../reducers'
import {
  setBaseCurrency as baseCurrencyDispatcher,
  BaseCurrencySet
} from '../../reducers/user'
import { currenciesCodes } from '../../temp/currencies_map'
import { setBaseCurrency } from '../../api'
import { getDataByRange } from '../../sagas'
import { createSelector } from 'reselect'

const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      select: {
        padding: '10px'
      }
    })
)

interface FeesProps {
  token: string
  base: string
}

const mapState = createSelector(
  (state: State): FeesProps['token'] => state.userState.token || '',
  (state: State): FeesProps['base'] => state.userState.baseCurrency,
  (
    token,
    base,
  ): FeesProps => ({
    token,
    base,
  })
)

export function BasePreferences(): JSX.Element {
  const classes = useStyles()
  const { token, base } = useSelector(mapState)
  const dispatch = useDispatch()
  const [baseSet, setBase] = useState<string>(base)

  const baseChange = (event: SelectChangeEvent): void => {
    const newBase: string = event.target.value as string
    if (newBase !== base) {
      const setCurrency = async (): Promise<void> => {
        const success = await setBaseCurrency(token, newBase)
        if (success) {
          setBase(newBase)
          dispatch(baseCurrencyDispatcher({ baseCurrency: newBase }))
          dispatch(getDataByRange())
        }
      }
      setCurrency()
    }
  }

  return (
    <Select
      title="Base currency"
      key="base-currency"
      className={classes.select}
      disableUnderline={true}
      native
      value={baseSet}
      variant="standard"
      onChange={baseChange}
      inputProps={{
        name: 'age',
        id: 'age-native-simple'
      }}
    >
      {currenciesCodes.map(
        (curr: string): JSX.Element => (
          <option key={`base-select-${curr}`} value={curr}>
            {curr}
          </option>
        )
      )}
    </Select>
  )
}
