import React, { useState } from 'react'
import { StyleRules, createStyles, makeStyles } from '@mui/styles'
import SapImage from '../../assets/sap.png'
import OracleImage from '../../assets/oracle.png'
import KyribaImage from '../../assets/kyriba.png'
import FisImage from '../../assets/fis.png'
import FiservImage from '../../assets/fiserv.png'
import InforImage from '../../assets/infor.png'
import MsDynamicImage from '../../assets/msdynamic.png'
import TableauImage from '../../assets/tableau.png'
import SageImage from '../../assets/sage.png'
import WatsonxImage from '../../assets/watsonx.png'
import MsPowerImage from '../../assets/mspower.png'
import LegacyImage from '../../assets/legacy.png'
import { Grid, Box, Typography, Button } from '@mui/material'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      grid: {
        padding: theme.spacing(1),
        marginTop: '40px'
      },
      gridItem: {
        display: 'flex',
        alignItems: 'stretch'
      },
      box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      },
      title: {},
      btn: {
        width: '50%',
        marginTop: '10px',
        marginBottom: '30px'
      },
      itemIcon: {
        width: '80px',
        marginTop: '30px'
      },
      watsonxIcon: {
        width: '80px',
        marginTop: '50px'
      },
      tableauIcon: {
        marginTop: '20px',
        width: '80px'
      },
      msPowerIcon: {
        width: '70px',
        marginTop: '20px'
      },
      sageIcon: {
        width: '80px',
        marginTop: '40px'
      },
      inforIcon: {
        width: '40px',
        height: '40px',
        marginTop: '30px'
      },
      msDynamicIcon: {
        width: '30%',
        marginTop: '30px'
        // cursor: 'pointer'
      },
      legacyIcon: {
        width: '30%',
        marginTop: '30px'
        // cursor: 'pointer'
      },
      btnBox: {
        width: '100%',
        textAlign: 'center'
      }
    })
)

export function Integration(): JSX.Element {
  const classes = useStyles()

  return (
    <Grid
      container
      alignContent="space-around"
      spacing={1}
      className={classes.grid}
    >
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Box className={classes.box}>
          <img className={classes.itemIcon} src={SapImage} />
          <Box className={classes.btnBox}>
            <Typography className={classes.title}>SAP</Typography>
            <Button variant="outlined" className={classes.btn}>
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Box className={classes.box}>
          <img className={classes.itemIcon} src={OracleImage} />
          <Box className={classes.btnBox}>
            <Typography className={classes.title}>Oracle</Typography>
            <Button variant="outlined" className={classes.btn}>
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Box className={classes.box}>
          <img className={classes.itemIcon} src={KyribaImage} />
          <Box className={classes.btnBox}>
            <Typography className={classes.title}>Kyriba</Typography>
            <Button variant="outlined" className={classes.btn}>
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Box className={classes.box}>
          <img className={classes.inforIcon} src={InforImage} />
          <Box className={classes.btnBox}>
            <Typography className={classes.title}>Infor</Typography>
            <Button variant="outlined" className={classes.btn}>
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Box className={classes.box}>
          <img className={classes.msDynamicIcon} src={MsDynamicImage} />
          <Box className={classes.btnBox}>
            <Typography className={classes.title}>Ms Dynamics</Typography>
            <Button variant="outlined" className={classes.btn}>
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Box className={classes.box}>
          <img className={classes.itemIcon} src={FisImage} />
          <Box className={classes.btnBox}>
            <Typography className={classes.title}>FIS</Typography>
            <Button variant="outlined" className={classes.btn}>
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Box className={classes.box}>
          <img className={classes.itemIcon} src={FiservImage} />
          <Box className={classes.btnBox}>
            <Typography className={classes.title}>FiServ</Typography>
            <Button variant="outlined" className={classes.btn}>
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Box className={classes.box}>
          <img className={classes.watsonxIcon} src={WatsonxImage} />
          <Box className={classes.btnBox}>
            <Typography className={classes.title}>Watsonx</Typography>
            <Button variant="outlined" className={classes.btn}>
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Box className={classes.box}>
          <img className={classes.tableauIcon} src={TableauImage} />
          <Box className={classes.btnBox}>
            <Typography className={classes.title}>Tableau</Typography>
            <Button variant="outlined" className={classes.btn}>
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Box className={classes.box}>
          <img className={classes.msPowerIcon} src={MsPowerImage} />
          <Box className={classes.btnBox}>
            <Typography className={classes.title}>MS Power</Typography>
            <Button variant="outlined" className={classes.btn}>
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Box className={classes.box}>
          <img className={classes.sageIcon} src={SageImage} />
          <Box className={classes.btnBox}>
            <Typography className={classes.title}>Sage</Typography>
            <Button variant="outlined" className={classes.btn}>
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sm={2} className={classes.gridItem}>
        <Box className={classes.box}>
          <img className={classes.legacyIcon} src={LegacyImage} />
          <Box className={classes.btnBox}>
            <Typography className={classes.title}>Legacy (Custom)</Typography>
            <Button variant="outlined" className={classes.btn}>
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
