export interface Tenant {
  id: string
  display: string
  name: string
  image: string
  tenantTypeId: string
}

export interface UserState {
  email: string | null
  name: string | null
  nickname: string | null
  picture: string | null
  token: string | null,
  firm_ids: string[] | null,
  tenant_id: string | null,
  tenants: Tenant[],
  baseCurrency: string
}

export interface UserSet {
  email?: string | null
  name?: string | null
  nickname?: string | null
  picture?: string | null
  token?: string | null
  firm_ids: string[] | null
}

export const SET_USER = 'SET_USER'
export interface SetUser {
  type: typeof SET_USER
  payload: UserSet
}

export const setUser = (payload: UserSet): SetUser => ({
  type: SET_USER,
  payload
})
export interface BaseCurrencySet {
  baseCurrency: string
}

export const SET_BASE_CURRENCY = 'SET_BASE_CURRENCY'
export interface SetBaseCurrency {
  type: typeof SET_BASE_CURRENCY
  payload: BaseCurrencySet
}

export const setBaseCurrency = (payload: BaseCurrencySet): SetBaseCurrency => ({
  type: SET_BASE_CURRENCY,
  payload
})

export interface PrimaryTenantIdSet {
  tenant_id: string
}

export const SET_PRIMARY_TENANT_ID = 'SET_PRIMARY_TENANT_ID'
export interface SetPrimaryTenantId {
  type: typeof SET_PRIMARY_TENANT_ID
  payload: PrimaryTenantIdSet
}

export const setPrimaryTenantId = (payload: PrimaryTenantIdSet): SetPrimaryTenantId => ({
  type: SET_PRIMARY_TENANT_ID,
  payload
})

//

export interface TenantsSet {
  tenants: Tenant[]
}

export const SET_TENANTS = 'SET_TENANTS'
export interface SetTenants {
  type: typeof SET_TENANTS
  payload: TenantsSet
}

export const setTenants = (payload: TenantsSet): SetTenants => ({
  type: SET_TENANTS,
  payload
})

export const userReducer = (
  state: UserState = {
    email: null,
    name: null,
    nickname: null,
    picture: null,
    token: null,
    firm_ids: null,
    tenant_id: null,
    tenants: [],
    baseCurrency: 'USD'
  },
  action: SetUser | SetBaseCurrency | SetPrimaryTenantId | SetTenants
): UserState => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload
      }
    case SET_BASE_CURRENCY:
      return {
        ...state,
        ...action.payload
      }
    case SET_PRIMARY_TENANT_ID:
      return {
        ...state,
        ...action.payload
      }
    case SET_TENANTS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
