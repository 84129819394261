import React, { useState, useEffect, useMemo } from 'react'
import {
  Paper,
  Typography,
  CircularProgress,
  Chip,
  Tooltip,
  Grid
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'
import classNames from 'classnames'

import { Download } from './Download'
import { getChips } from '../../utils'
import { SelectionConstraints } from '../../rawApi'
import { useSelector } from 'react-redux'
import { getBaseCurrency, getUsdRate } from '../../selectors'
import { currencies } from '../../temp/currencies_map'
import { RegionTotal } from '../../reducers/constraints'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary
      // maxWidth: '400px'
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary
    },
    line: {
      textAlign: 'left'
    },
    header: {
      color: '#7c7c7c',
      fontSize: '12px'
    },
    name: {
      color: '#333',
      fontSize: '14px',
      float: 'left'
    },
    value: {
      color: 'black',
      display: 'inline-block',
      fontSize: '14px',
      width: '100%'
    },
    red: {
      backgroundColor: '#ffc4c5',
      width: '100%',
      borderRadius: '10px'
    },
    green: {
      backgroundColor: '#b4dfc2'
    },
    table: {
      display: 'table',
      width: '100%'
    },
    tr: {
      display: 'table-row',
      whiteSpace: 'nowrap'
    },
    td: {
      display: 'table-cell',
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: '2em'
    }
  })
)

interface TopPerformingProducts extends SelectionConstraints {
  getData: Function
  region: RegionTotal | null
  pricing: string
  title: string
}
interface ESResValue {
  value: number
}
interface TopPerformingResponseItem {
  doc_count: number
  gross_agg: ESResValue
  home_price_target_agg: ESResValue
  key: string
  settled_price_agg: ESResValue
  settled_xo_agg: ESResValue
  target_xo_agg: ESResValue
  txn_count_agg: ESResValue
  xo_delta_agg: ESResValue
  xo_gain_loss_agg: ESResValue
}
interface TopPerformingMapping {
  settledPrice: number
  targetPrice: number
  loss: number
  key: string
}

export const TopPerformingProducts = ({
  country,
  merchant,
  range,
  getData,
  region,
  pricing,
  tenant_id,
  title
}: TopPerformingProducts): JSX.Element => {
  const classes = useStyles()
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  let name = 'Global'

  if (merchant) {
    name = country + ' / ' + merchant
  } else if (country) {
    name = country || ''
  }

  const USDRate = useSelector(getUsdRate)
  const baseCurrency = useSelector(getBaseCurrency)

  const currencyCode =
    name == 'Global' ? baseCurrency : (region && region.currency) || 'USD'
  const currencySymbol = currencies[currencyCode as string].symbol
  let transformRate = USDRate ? USDRate : 1

  useEffect((): void => {
    const fetchData = async (): Promise<any> => {
      setIsLoading(true)
      const result = await getData({ range, country, merchant, tenant_id })
      if (result) {
        const mapped = result
          .map(
            (item: TopPerformingResponseItem): TopPerformingMapping => {
              return {
                key: item.key,
                settledPrice: item.settled_price_agg.value,
                targetPrice: item.home_price_target_agg.value,
                loss: item.xo_gain_loss_agg.value
              }
            }
          )
          .sort(
            (
              { settledPrice: a }: TopPerformingMapping,
              { settledPrice: b }: TopPerformingMapping
            ): number => b - a
          )
        setData(mapped)
      }
      setIsLoading(false)
    }
    fetchData()
  }, [country, merchant, range, getData])

  const recalculatedData = useMemo(() => {
    return data.map(({ key, settledPrice, targetPrice, loss }) => ({
      key,
      settledPrice: settledPrice * transformRate,
      targetPrice: targetPrice * transformRate,
      loss: loss * transformRate
    }))
  }, [data, transformRate])
  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper}>
        {pricing && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant="subtitle2"
              component="h4"
              className={classes.caption}
            >
              {title ? title : 'Top Performing Products Report'}
            </Typography>
            <Typography
              variant="subtitle2"
              component="h4"
              className={classes.caption}
            >
              Financial Performance
            </Typography>
            <Typography
              variant="subtitle2"
              component="h4"
              className={classes.caption}
            >
              Location {name}
            </Typography>
          </div>
        )}
        <br />
        <Paper className={classes.paper}>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            Top Performing Products {name}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
          <div className={classes.table}>
            <div className={classes.tr}>
              <div className={classes.td}>
                <span className={classes.header}>Item</span>
              </div>
              <div className={classes.td}>
                <span className={classes.header}>Sales Target</span>
              </div>
              <div className={classes.td}>
                <span className={classes.header}>Actual Sales</span>
              </div>
            </div>
            {recalculatedData.map(
              (product: TopPerformingMapping, index): JSX.Element => (
                <div className={classes.tr} key={'tcl-' + product.key}>
                  <div className={classes.td}>
                    <span className={classes.name}>
                      {index + 1 + '. ' + product.key}
                    </span>
                  </div>
                  <div className={classes.td}>
                    <span className={classes.value}>
                      {currencySymbol + getChips(product.targetPrice)}
                    </span>
                  </div>
                  <div className={classes.td}>
                    <span className={classes.value}>
                      <Tooltip
                        title={`${product.loss < 0 ? 'Gain' : 'Loss:'} ${getChips(
                          Math.abs(product.loss)
                        )}`}
                        aria-label="add"
                      >
                        <Chip
                          label={getChips(product.settledPrice)}
                          className={classNames(classes.red, {
                            [classes.green]: product.loss < 0
                          })}
                        />
                      </Tooltip>
                    </span>
                  </div>
                </div>
              )
            )}
          </div>
          <Download data={recalculatedData} />
        </Paper>
      </Paper>
    </Grid>
  )
}
