import React, { useState, useEffect } from 'react'
import { Paper, Typography, CircularProgress, Grid } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'

import { ReactComponent as ClockIcon } from '../../assets/clock.svg'
import { SelectionConstraints } from '../../rawApi'
import { currencies } from '../../temp/currencies_map'
import { BC } from '../BCTooltip'
import { RegionTotal } from '../../reducers/constraints'
import { useSelector } from 'react-redux'
import { getBaseCurrency, getUsdRate } from '../../selectors'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      maxWidth: '300px'
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary
    },
    description: {
      marginTop: '0px',
      fontSize: '11px',
      textAlign: 'center'
    },
    value: {
      color: '#5645b8',
      fontSize: '20px',
      fontWeight: 700
    },
    table: {
      display: 'table',
      width: '100%',
      paddingTop: '10px'
    },
    tr: {
      display: 'table-row',
      whiteSpace: 'nowrap'
    },
    td: {
      display: 'table-cell',
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: '2em'
    },
    watch: {
      textAlign: 'left',
      color: theme.palette.text.secondary,
      fontSize: '10px'
    },
    clockIcon: {
      opacity: 0.5,
      width: '20px',
      height: '20px',
      position: 'relative',
      top: '5px'
    }
  })
)

interface BankableProps extends SelectionConstraints {
  getData: Function
  region: RegionTotal | null
  title: string
}

export const Bankable = ({
  country = '',
  merchant,
  range,
  getData,
  region,
  tenant_id,
  title,
  countries,
  merchantIds
}: BankableProps): JSX.Element => {
  const classes = useStyles()
  const [data, setData] = useState({ bankable: 0 })
  const [isLoading, setIsLoading] = useState(false)

  let name = 'Global'

  if (merchant) {
    name = country + ' / ' + merchant
  } else if (country) {
    name = country
  }
  const USDRate = useSelector(getUsdRate)
  const baseCurrency = useSelector(getBaseCurrency)

  const currencyCode =
    name === 'Global' ? baseCurrency : (region && region.currency) || 'USD'

  let transformRate = USDRate ? USDRate : 1

  useEffect((): void => {
    const fetchData = async (): Promise<any> => {
      setIsLoading(true)
      const result = await getData({ country, merchant, tenant_id, countries, merchantIds })
      if (result) {
        const mapped = { bankable: result * transformRate }
        setData(mapped)
      }
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, merchant, countries, merchantIds])
  return (
    <Grid item xs={12} sm={12}>
      <Paper className={classes.paper} elevation={1}>
        <Typography
          variant="subtitle2"
          component="h3"
          className={classes.caption}
        >
          {title ? title : `Unsettled Funds ${name}`}
          {isLoading && (
            <CircularProgress
              className={classes.progress}
              size={15}
              color={'secondary'}
            />
          )}
        </Typography>
        <div className={classes.table}>
          <div className={classes.tr}>
            <BC value={data.bankable} currency={currencyCode}>
              <span className={classes.value}>
                {data.bankable.toLocaleString(undefined, {
                  style: 'currency',
                  currency: currencyCode
                })}
              </span>
            </BC>
          </div>
          <div className={classes.tr}>
            <span className={classes.description}>
              Projected Bankable by 1700 UTC
            </span>
          </div>
        </div>
        <div className={classes.watch}>
          <ClockIcon className={classes.clockIcon} /> One Hour Ago
        </div>
      </Paper>
    </Grid>
  )
}
