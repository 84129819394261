import React from 'react'
import { connect } from 'react-redux'
import { Theme } from '@mui/material/styles'
import { makeStyles, StyleRules, createStyles } from '@mui/styles'
import { Button, Chip, Paper, Menu, MenuItem } from '@mui/material'

import { State, Dispatch } from '../reducers'
import {
  Compare,
  Location,
  setCompare,
  removeCompareLocation
} from '../reducers/constraints'
import { createSelector } from 'reselect'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> =>
    createStyles({
      root: { display: 'flex' },
      paper: {
        display: 'flex',
        padding: '5px'
      },
      chip: {
        marginLeft: theme.spacing(0.5)
      }
    })
)

interface ComparingSet {
  onDelete: Function
  compare: Compare
  setCompairing: Function
}

export const ComparingComponent = (props: ComparingSet): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  function handleClick(event: React.MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event.currentTarget)
  }
  function handleClose(): void {
    setAnchorEl(null)
  }
  const classes = useStyles()
  const compare = props.compare

  return (
    <div className={classes.root}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {compare.mode === 'locations' ? 'Locations' : 'Compare'}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {compare.mode && (
          <MenuItem
            onClick={(): void => {
              props.setCompairing({ mode: null })
              handleClose()
            }}
          >
            Cancel Comparing
          </MenuItem>
        )}
        <MenuItem
          onClick={(): void => {
            props.setCompairing({ mode: 'locations', locations: [] })
            handleClose()
          }}
        >
          Locations
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>Dates</MenuItem> */}
      </Menu>
      {compare.mode === 'locations' && compare.locations.length > 0 && (
        <Paper className={classes.paper}>
          {compare.locations.map(
            (data: Location, index: number): JSX.Element => {
              const label = data.merchantId || data.country || 'Global'
              return (
                <Chip
                  key={label + '-' + index}
                  label={label}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onDelete={(): void => props.onDelete(index)}
                  className={classes.chip}
                />
              )
            }
          )}
        </Paper>
      )}
    </div>
  )
}

interface ComparingProps {
  compare: Compare
}

const mapStateToProps = createSelector(
  (state: State): ComparingProps['compare'] => state.constraintsState.compare,
  (
    compare
  ): ComparingProps => ({
    compare
  })
)

interface DispatchFromProps {
  setCompairing: (compare: Compare) => void
  onDelete: (index: number) => void
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  setCompairing: (compare): void => {
    dispatch(setCompare(compare))
  },
  onDelete: (index): void => {
    dispatch(removeCompareLocation(index))
  }
})

export const Comparing = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComparingComponent)
