import React from 'react'
import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import '@syncfusion/ej2-base/styles/material.css'
import '@syncfusion/ej2-buttons/styles/material.css'
import '@syncfusion/ej2-inputs/styles/material.css'
import '@syncfusion/ej2-popups/styles/material.css'

import { ThemeProvider,StyledEngineProvider } from '@mui/material/styles'
import theme from './theme'
import { reducers } from './reducers'
import { useAuth0 } from './auth0_wrapper'
import { PrivateRoute } from './components/PrivateRoute'
import { Main } from './components/Main'
// import { sagaDataByRange } from './sagas'

const sagaMiddleware = createSagaMiddleware()

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)
// sagaMiddleware.run(sagaDataByRange)

export const Protected = (): JSX.Element => <>Protected route</>

export default function App(): JSX.Element {
  const { loading } = useAuth0()

  if (loading) {
    return <>loading...</>
  }
  return (
    <Router>
      <Provider store={store}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <PrivateRoute path="/" component={Main} />
        </StyledEngineProvider>
      </ThemeProvider>  
      </Provider>
    </Router>
  )
}
