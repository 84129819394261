import React, { useEffect, useState } from 'react'
import { Paper, Grid, Box, Typography, CircularProgress } from '@mui/material'
import { StyleRules, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { getForeignExchange } from '../../api'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '35px 25px',
      marginTop: '10px',
      overflow: 'hidden'
    },
    box: {},
    caption: {
      color: theme.palette.text.primary,
      textTransform: 'uppercase',
      marginBottom: '25px'
    },
    table: {},
    tableBody: {},
    tableHeaderItem: {
      textAlign: 'center',
      fontWeight: 700,
      flex: 1,
      '&:first-child': {
        textAlign: 'left'
      }
    },
    tableRow: {
      display: 'flex',
      borderBottom: '1px solid #E1E1E1',
      paddingTop: '10px',
      paddingBottom: '10px'
    },
    tableRowItem: {
      flex: 1,
      textAlign: 'center',
      '&:first-child': {
        textAlign: 'left'
      }
    }
  })
)

interface ForeignExchangeItem {
  itemName: string
  symbol: string
  spread: number
  lotSize: number
  limitAndStopOrders: number
  maxPerTrade: number
  swapsShort: number
  swapsLong: number
}
interface ForeignExchangeType {
  details: ForeignExchangeItem[]
}

const ForeignExchange = ({ title, token }: any) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<ForeignExchangeType>()

  const tableColumns = [
    {
      title: 'Item Name',
      field: 'itemName'
    },
    {
      title: 'Symbol',
      field: 'symbol'
    },
    {
      title: 'Spread',
      field: 'spread'
    },
    {
      title: 'Lot Size(*1)',
      field: 'lotSize'
    },
    {
      title: 'Limit & Stop Orders (*2)',
      field: 'limitAndStopOrders'
    },
    {
      title: 'Max Per Trade (*3)',
      field: 'maxPerTrade'
    },
    {
      title: 'Swaps Short (*4)',
      field: 'swapsShort'
    },
    {
      title: 'Swaps Long (*4)',
      field: 'swapsLong'
    }
  ]

  useEffect((): void => {
    if (!token) return
    const callForeignExchange = async (): Promise<any> => {
      const result: any = await getForeignExchange({
        token
      })
      setData(result)
    }
    callForeignExchange()
  }, [token])

  const renderExchangeTable = (data: any[], columns: any[]) => {
    return (
      <Box className={classes.tableBody}>
        <Box className={classes.tableRow}>
          {columns.map((column, index) => (
            <Typography
              key={index}
              className={classes.tableHeaderItem}
              sx={{
                flex: column.field == 'itemName' ? '1.2 !important' : 1
              }}
            >
              {column.title}
            </Typography>
          ))}
        </Box>
        {data.map((item, index) => (
          <Box className={classes.tableRow} key={index}>
            {columns.map((column, index1) => {
              if (column.field == 'symbol') {
                return (
                  <Typography
                    key={index1}
                    className={classes.tableRowItem}
                    sx={{
                      color: '#999999'
                    }}
                  >
                    ({item?.[column.field]})
                  </Typography>
                )
              } else if (column.field == 'maxPerTrade') {
                return (
                  <Typography key={index1} className={classes.tableRowItem}>
                    {item?.[column.field].toFixed(0)} lots
                  </Typography>
                )
              } else if (column.field == 'lotSize') {
                return (
                  <Typography key={index1} className={classes.tableRowItem}>
                    {new Intl.NumberFormat(undefined, {
                      maximumFractionDigits: 0
                    }).format(Math.floor(item?.[column.field]))}{' '}
                    {item?.['symbol'].substring(0, 3)}
                  </Typography>
                )
              } else if (column.field == 'swapsShort') {
                return (
                  <Typography key={index1} className={classes.tableRowItem}>
                    {item?.[column.field] > 0 ? '+' : ''}
                    {item?.[column.field].toFixed(2)}
                  </Typography>
                )
              } else if (column.field == 'swapsLong') {
                return (
                  <Typography key={index1} className={classes.tableRowItem}>
                    {item?.[column.field] > 0 ? '+' : ''}
                    {item?.[column.field].toFixed(2)}
                  </Typography>
                )
              } else if (column.field == 'itemName') {
                return (
                  <Typography
                    key={index1}
                    className={classes.tableRowItem}
                    sx={{
                      flex: '1.2 !important'
                    }}
                  >
                    {item?.[column.field]}
                  </Typography>
                )
              } else {
                return (
                  <Typography key={index1} className={classes.tableRowItem}>
                    {item?.[column.field]}
                  </Typography>
                )
              }
            })}
          </Box>
        ))}
      </Box>
    )
  }

  return (
    <Grid item xs={12} sm={12}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {title}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
          <Box className={classes.table}>
            {data?.details && renderExchangeTable(data?.details, tableColumns)}
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
}

export default ForeignExchange
