import React, { useEffect, useState } from 'react'
import { Paper, Grid, Box, Typography, CircularProgress } from '@mui/material'
import { StyleRules, makeStyles } from '@mui/styles'
import Chart from 'react-apexcharts'
import { Theme } from '@mui/material/styles'
import { Download } from './Download'
import { getStatusOpenDebt } from '../../api'
import '../../style.css'

interface IDownloadType {
  description: string
  amount: string
  percentage: string
}

interface AmountPercentage {
  description: string
  amount: number
  percentage: number
}

interface ChartItem {
  item: string
  percentage: number
}

interface StatusOpenDebtType {
  open_debts: number
  debt_amount: number
  details: AmountPercentage[]
  chart_details: ChartItem[]
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    grid: {
      display: 'flex',
      alignItems: 'stretch'
    },
    paper: {
      width: '100%',
      padding: '35px 25px',
      marginTop: '10px',
      overflow: 'hidden'
    },
    box: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '100%'
    },
    tableBox: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    tableBody: {
      marginBottom: '20px'
    },
    tableCaption: {
      color: theme.palette.text.primary,
      borderBottom: '1px solid #E1E1E1',
      paddingBottom: '17px',
      textTransform: 'uppercase'
    },
    tableRowBox: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '10px',
      paddingBottom: '10px',
      borderBottom: '1px solid #E1E1E1'
    },
    text: {
      fontSize: '12px',
      flex: 1
    },
    chartBox: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    chartCaption: {
      color: theme.palette.text.primary,
      marginBottom: '10px',
      textAlign: 'center'
    }
  })
)

const StatusOpenDebts = ({ title, token }: any) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})
  const [downloadData, setDownloadData] = useState<IDownloadType[]>([])
  const [data, setData] = useState<StatusOpenDebtType>()

  useEffect((): void => {
    if (!token) return
    const callStatusOpenDebt = async (): Promise<any> => {
      const result: any = await getStatusOpenDebt({
        token
      })
      setData(result)
      var tmpData: IDownloadType[] = []
      tmpData.push({
        description: 'Open Debts',
        amount: result?.open_debts?.toString(),
        percentage: ''
      })
      tmpData.push({
        description: 'Debts Amount',
        amount: result?.debt_amount?.toString(),
        percentage: ''
      })
      result?.details.map((item: AmountPercentage) => {
        tmpData.push({
          description: item.description,
          amount: item.amount?.toString(),
          percentage: item.percentage?.toString()
        })
      })

      setDownloadData(tmpData)

      setOptions({
        chart: {
          width: 450,
          type: 'pie'
        },
        labels: result?.chart_details?.map(
          (chart_item: any) => chart_item.item
        ),
        legend: {
          position: 'bottom',
          horizontalAlign: 'center'
        }
      })
      setSeries(
        result?.chart_details?.map((chart_item: any) => chart_item.percentage)
      )
    }
    callStatusOpenDebt()
  }, [token])

  return (
    <Grid item xs={12} sm={6} className={classes.grid}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <Box className={classes.tableBox}>
            <Box>
              <Typography
                variant="subtitle2"
                component="h3"
                className={classes.tableCaption}
              >
                {title}
                {isLoading && (
                  <CircularProgress
                    className={classes.progress}
                    size={15}
                    color={'secondary'}
                  />
                )}
              </Typography>
              <Box className={classes.tableBody}>
                <Box className={classes.tableRowBox}>
                  <Typography variant="subtitle2" className={classes.text}>
                    Open Debts
                  </Typography>
                  <Typography variant="subtitle2" className={classes.text}>
                    {data?.open_debts?.toLocaleString()}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.text}
                    sx={{ flex: '0.5 !important' }}
                  ></Typography>
                </Box>
                <Box className={classes.tableRowBox}>
                  <Typography variant="subtitle2" className={classes.text}>
                    Debts Amount
                  </Typography>
                  <Typography variant="subtitle2" className={classes.text}>
                    {data?.debt_amount
                      ? Math.floor(data?.debt_amount)?.toLocaleString()
                      : ''}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.text}
                    sx={{ flex: '0.5 !important' }}
                  ></Typography>
                </Box>
                {data?.details?.map((item, index) => (
                  <Box className={classes.tableRowBox} key={index}>
                    <Typography variant="subtitle2" className={classes.text}>
                      {item.description}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.text}>
                      {item.amount
                        ? Math.floor(item.amount)?.toLocaleString()
                        : ''}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.text}
                      sx={{ flex: '0.5 !important' }}
                    >
                      {item.percentage}%
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Download data={downloadData} />
          </Box>
          <Box className={classes.chartBox}>
            <Typography
              variant="subtitle2"
              component="h3"
              className={classes.chartCaption}
            >
              Current Balance
            </Typography>
            <div className="status-open-debts-chart">
              <Chart
                options={options}
                series={series}
                type={'pie'}
                height="300"
              />
            </div>
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
}

export default StatusOpenDebts
