import axios from './axiosInstance'

interface DateRange {
  startDate?: Date
  endDate?: Date
}

export interface SelectionConstraints {
  range?: DateRange
  country?: string
  merchant?: string
  ascending?: boolean
}

interface TopLifeValueI {
  took: number
  timed_out: boolean
  _shards: Shards
  hits: Hits
  aggregations: Aggregations
}

interface Aggregations {
  id: Id
}

interface Id {
  doc_count_error_upper_bound: number
  sum_other_doc_count: number
  buckets: Bucket[]
}

interface Bucket {
  key: string
  doc_count: number
  refundCount: RefundCount
  feeTotal: RefundCount
  gross: RefundCount
  unitCount: RefundCount
  txnCount: RefundCount
  tax: RefundCount
  net: RefundCount
  refunds: RefundCount
}

interface RefundCount {
  value: number
}

interface Hits {
  total: Total
  max_score?: any
  hits: any[]
}

interface Total {
  value: number
  relation: string
}

interface Shards {
  total: number
  successful: number
  skipped: number
  failed: number
}

const getTopLifetimeValueQuery = (ascending: boolean, tenant_id: string | null): {} => {
  const must = []
  if (tenant_id) {
    must.push({
      match_phrase: {
        tenant_id: {
          query: tenant_id
        }
      }
    })
  }
  return {
    aggs: {
      id: {
        terms: {
          field: '_id',
          size: 10,
          order: {
            gross: ascending ? 'asc' : 'desc'
          }
        },
        aggs: {
          gross: {
            sum: {
              field: 'gross'
            }
          },
          feeTotal: {
            sum: {
              field: 'fee_total'
            }
          },
          net: {
            sum: {
              field: 'net'
            }
          },
          tax: {
            sum: {
              field: 'tax'
            }
          },
          txnCount: {
            sum: {
              field: 'txn_count'
            }
          },
          unitCount: {
            sum: {
              field: 'unit_count'
            }
          },
          refunds: {
            sum: {
              field: 'refunds'
            }
          },
          refundCount: {
            sum: {
              field: 'refund_count'
            }
          }
        }
      }
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match_all: {}
          },
          ...must
        ],
        filter: [],
        should: [],
        must_not: []
      }
    }
  }
}

export const getTopLifetimeValue = async (
  token: string,
  ascending: boolean,
  tenant_id: string | null
): Promise<TopLifeValueI | null> => {
  try {
    const request = getTopLifetimeValueQuery(ascending, tenant_id)
    const response = await axios.post(
      '/settlement/aggregations/raw?index=customer_value',
      request,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return response.data.error ? 0 : response.data
  } catch (error) {
    console.warn('request error')
    return null
  }
}
