import axios from './apis/axiosInstance'
import { DateTime } from 'luxon'

import { SelectionConstraints } from './rawApi'

export const getForex = (token: string | null): Function =>
  token
    ? async (
        base: string = 'USD',
        vs: string = 'EUR',
        start: Date = DateTime.utc()
          .startOf('day')
          .minus({ days: 3 })
          .toJSDate(),
        end: Date = DateTime.utc()
          .endOf('day')
          .plus({ days: 3 })
          .toJSDate()
      ): Promise<[]> => {
        const from = DateTime.fromJSDate(start).toISODate()
        const to = DateTime.fromJSDate(end).toISODate()
        try {
          const response = await axios.get(
            `/currency-management/quotes/forex?targetCurrencyId=${base}&sourceCurrencyId=${vs}&from=${from}Z&to=${to}Z`,
            {
              headers: { Authorization: 'Bearer ' + token }
            }
          )
          return response.data ? response.data.items : []
        } catch (error) {
          console.warn('request error')
          return []
        }
      }
    : (): void => {
        console.warn('no token provided')
      }

interface GetProductCurrencyPerformance extends SelectionConstraints {
  token: string
  tenant_id: string | null
  sku?: string
}

export const getProductCurrencyPerformance = async ({
  token,
  tenant_id,
  range,
  country,
  merchant,
  sku
}: GetProductCurrencyPerformance): Promise<{}> => {
  if (!range || !range.startDate || !range.endDate) return {}
  const from = DateTime.fromJSDate(range.startDate).toISODate()
  const to = DateTime.fromJSDate(range.endDate).toISODate()
  let must = ''
  if (country) must += `&country=${country}`
  if (merchant) must += `&location=${merchant}`
  if (sku) must += `&sku=${sku}`
  try {
    const response = await axios.get(
      `/performance/product-currency-performance-raw?from=${from}Z&to=${to}Z${must}&tenant_id=${tenant_id}`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}

interface WithToken extends SelectionConstraints {
  token: string
}

export const getExpectedVsActual = async ({
  token,
  range,
  country,
  merchant
}: WithToken): Promise<any> => {
  if (!range || !range.startDate || !range.endDate) return {}
  const from = DateTime.fromJSDate(range.startDate).toISODate()
  const to = DateTime.fromJSDate(range.endDate).toISODate()
  let must = ''
  if (country) must += `&country=${country}`
  if (merchant) must += `&location=${merchant}`
  try {
    const response = await axios.get(
      `/currency-conversion-rate-forecast-rc?from=${from}Z&to=${to}Z${must}`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}

const mapContext = {
  global: 0,
  country: 1,
  merchant: 2
}
export const getPromotionUseRate = (
  token: string | null,
  tenant_id: string | null
): Function =>
  token
    ? async (props: SelectionConstraints): Promise<{}> => {
        try {
          if (!props.range) return {}
          const response = await axios.post(
            '/performance/reports/promotion-use',
            {
              start: props.range.startDate,
              end: props.range.endDate,
              tenant_id,
              mapContext: props.merchant
                ? mapContext.merchant
                : props.country
                ? mapContext.country
                : mapContext.global,
              locationId: props.merchant ? props.merchant : props.country
            },
            {
              headers: { Authorization: 'Bearer ' + token }
            }
          )
          return response.data ? response.data : {}
        } catch (error) {
          console.warn('request error')
          return {}
        }
      }
    : (): void => {
        console.warn('no token provided')
      }

export const getSalesVolumeWeekly = (
  token: string | null,
  tenant_id: string | null
): Function =>
  token
    ? async (props: SelectionConstraints): Promise<{}> => {
        try {
          if (!props.range) return {}
          const response = await axios.post(
            '/performance/reports/sales-volume',
            {
              start: props.range.startDate,
              end: props.range.endDate,
              tenant_id,
              mapContext: props.merchant
                ? mapContext.merchant
                : props.country
                ? mapContext.country
                : mapContext.global,
              locationId: props.merchant ? props.merchant : props.country
            },
            {
              headers: { Authorization: 'Bearer ' + token }
            }
          )
          return response.data ? response.data : {}
        } catch (error) {
          console.warn('request error')
          return {}
        }
      }
    : (): void => {
        console.warn('no token provided')
      }

export const getExpectedRevenueOverTime = (token: string | null): Function =>
  token
    ? async (props: SelectionConstraints): Promise<{}> => {
        try {
          const response = await axios.post(
            '/settlement/reports/expected-revenue',
            {
              mapContext: props.merchant
                ? mapContext.merchant
                : props.country
                ? mapContext.country
                : mapContext.global,
              locationId: props.merchant ? props.merchant : props.country
            },
            {
              headers: { Authorization: 'Bearer ' + token }
            }
          )
          return response.data ? response.data : {}
        } catch (error) {
          console.warn('request error')
          return {}
        }
      }
    : (): void => {
        console.warn('no token provided')
      }

export interface ShapeMapOverview {
  items: MapOverviewCountry[]
}

export interface MapOverviewCountry {
  country: string
  currentTotals: CurrentTotals
  lastPeriodTotals: CurrentTotals
  locations: Location[]
}

interface Location {
  id: string
  country: string
  address: string
  latitude: number
  longitude: number
  currentTotals: CurrentTotals
  lastPeriodTotals: CurrentTotals
}

interface CurrentTotals {
  gross: number
  net: number
  fees: number
  taxes: number
  refunds: number
  refundsCount: number
  txnCount: number
}

export const getMapOverview = async (
  props: WithToken
): Promise<ShapeMapOverview> => {
  try {
    if (!props.range) return { items: [] }
    const response = await axios.post(
      '/performance/reports/map-overview',
      {
        start: props.range.startDate,
        end: props.range.endDate,
        tenant_id: props.tenant_id
      },
      {
        headers: { Authorization: 'Bearer ' + props.token }
      }
    )
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return { items: [] }
  }
}

export const getPerformanceCloseDebt = async ({ token }: any): Promise<{}> => {
  try {
    const response = await axios.post(
      '/financial-operation/debt/performance-close-debt',
      {},
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}

export const getStatusOpenDebt = async ({ token }: any): Promise<{}> => {
  try {
    const response = await axios.post(
      '/financial-operation/debt/status-open-debt',
      {},
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}

export const getForeignExchange = async ({ token }: any): Promise<{}> => {
  try {
    const response = await axios.post(
      '/financial-operation/foreign-exchange',
      {},
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}

export const getTrading = async ({ token }: any): Promise<{}> => {
  try {
    const response = await axios.post(
      '/financial-operation/trading',
      {},
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}

export const getInvestments = async ({ token }: any): Promise<{}> => {
  try {
    const response = await axios.post(
      '/financial-operation/investment',
      {},
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}

export const getAgingBalance = async ({ token }: any): Promise<{}> => {
  try {
    const response = await axios.post(
      '/financial-operation/aging-balance',
      {},
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}

export const getDebtServiceSchedule = async ({ token }: any): Promise<{}> => {
  try {
    const response = await axios.post(
      '/financial-operation/debt-service-schedule',
      {},
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}

export const getLiveCurrenciesRate = async ({ token }: any): Promise<{}> => {
  try {
    const response = await axios.post(
      '/financial-operation/live-rate/currencies',
      {},
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}

export const getLiveIndicesRate = async ({ token }: any): Promise<{}> => {
  try {
    const response = await axios.post(
      '/financial-operation/live-rate/indices',
      {},
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}

export interface PaymentTypes {
  items: Item[]
}

interface Item {
  id: string
  name: string
}

export const getPaymentTypes = async (token: string, tenant_id: string | null): Promise<PaymentTypes> => {
  try {
    const response = await axios.get('/geography/payment-types?tenant_id=' + tenant_id, {
      headers: { Authorization: 'Bearer ' + token }
    })
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return { items: [] }
  }
}

export const addPaymentType = async (
  token: string,
  tenant_id: string | null,
  item: Item
): Promise<Item | null> => {
  try {
    const response = await axios.post('/geography/payment-types', {
      id: item.id,
      name: item.name,
      tenant_id
    }, {
      headers: { Authorization: 'Bearer ' + token }
    })
    return response.data ? response.data : null
  } catch (error) {
    console.warn('request error', error)
    return null
  }
}

export const editPaymentType = async (
  token: string,
  tenant_id: string | null,
  item: Item
): Promise<Item | null> => {
  try {
    const response = await axios.put(
      `/geography/payment-types/${item.id}`,
      { name: item.name, tenant_id },
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return response.data
      ? { id: response.data.id, name: response.data.name }
      : null
  } catch (error) {
    console.warn('request error', error)
    return null
  }
}

export const removePaymentType = async (
  token: string,
  tenant_id: string | null,
  item: Item
): Promise<true | null> => {
  try {
    await axios.delete(`/geography/payment-types/${item.id}?tenant_id=` + tenant_id, {
      headers: { Authorization: 'Bearer ' + token }
    })
    return true
  } catch (error) {
    console.warn('request error', error)
    return null
  }
}

export const editDelay = async (
  token: string,
  delay: {
    countryId: string
    paymentTypeId: string
    delay: number
    tenant_id: string
  }
): Promise<true | null> => {
  try {
    await axios.put(`/geography/transit-delays`, delay, {
      headers: { Authorization: 'Bearer ' + token }
    })
    return true
  } catch (error) {
    console.warn('request error', error)
    return null
  }
}

export const setBaseCurrency = async (
  token: string,
  base: string
): Promise<boolean> => {
  try {
    await axios.put(
      `/geography/preferences/base_currency`,
      { value: base },
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    )
    return true
  } catch (error) {
    console.warn('request error', error)
    return false
  }
}

export const editFees = async (
  token: string,
  fees: {
    tenant_id: string
    countryId: string
    paymentTypeId: string
    currencyId: string
    amount: number
    percent: number
    description: string
  }
): Promise<true | null> => {
  try {
    await axios.put(`/geography/fees`, fees, {
      headers: { Authorization: 'Bearer ' + token }
    })
    return true
  } catch (error) {
    console.warn('request error', error)
    return null
  }
}

export const addFees = async (
  token: string,
  fees: {
    tenant_id: string
    countryId: string
    paymentTypeId: string
    currencyId: string
    amount: number
    percent: number
    description: string
  }
): Promise<boolean> => {
  try {
    await axios.post(`/geography/fees`, fees, {
      headers: { Authorization: 'Bearer ' + token }
    })
    return true
  } catch (error) {
    console.warn('request error', error)
    return false
  }
}

export const removeFees = async (
  token: string,
  fees: {
    tenant_id: string
    countryId: string
    paymentTypeId: string
    currencyId: string
  }
): Promise<boolean> => {
  try {
    await axios.delete(`/geography/fees`, {
      headers: { Authorization: 'Bearer ' + token },
      params: fees
    })
    return true
  } catch (error) {
    console.warn('request error', error)
    return false
  }
}

export const editLocation = async (
  token: string,
  location: {
    tenant_id: string
    countryId: string
    id: string
    merchantId: string
    bankAccount: string
    address: string
    contactInfo: string
    latitude: number
    longitude: number
    regionId: string
    regionName: string
    ip: string
    batchCutoff: string
  }
): Promise<boolean> => {
  try {
    await axios.put(`/geography/locations`, location, {
      headers: { Authorization: 'Bearer ' + token }
    })
    return true
  } catch (error) {
    console.warn('request error', error)
    return false
  }
}

export const addLocation = async (
  token: string,
  location: {
    countryId: string
    id: string
    merchantId: string
    bankAccount: string
    address: string
    contactInfo: string
    latitude: number
    longitude: number
    regionId: string
    regionName: string
    ip: string
    batchCutoff: string
  }
): Promise<boolean> => {
  try {
    await axios.post(`/geography/locations`, location, {
      headers: { Authorization: 'Bearer ' + token }
    })
    return true
  } catch (error) {
    console.warn('request error', error)
    return false
  }
}

export const removeLocation = async (
  token: string,
  fees: {
    countryId: string
    locationId: string
  }
): Promise<boolean> => {
  try {
    await axios.delete(`/geography/locations`, {
      headers: { Authorization: 'Bearer ' + token },
      params: fees
    })
    return true
  } catch (error) {
    console.warn('request error', error)
    return false
  }
}

export const editRep = async (
  token: string,
  rep: {
    tenant_id: string
    countryId: string
    locationId: string
    name: string
    contactInfo: string
  }
): Promise<boolean> => {
  try {
    await axios.post(`/geography/reps`, rep, {
      headers: { Authorization: 'Bearer ' + token }
    })
    return true
  } catch (error) {
    console.warn('request error', error)
    return false
  }
}

export const addRep = async (
  token: string,
  rep: {
    countryId: string
    locationId: string
    name: string
    contactInfo: string
  }
): Promise<boolean> => {
  try {
    await axios.post(`/geography/reps`, rep, {
      headers: { Authorization: 'Bearer ' + token }
    })
    return true
  } catch (error) {
    console.warn('request error', error)
    return false
  }
}

export const removeRep = async (
  token: string,
  rep: {
    countryId: string
    locationId: string
  }
): Promise<boolean> => {
  try {
    await axios.delete(`/geography/reps`, {
      headers: { Authorization: 'Bearer ' + token },
      params: rep
    })
    return true
  } catch (error) {
    console.warn('request error', error)
    return false
  }
}

export const getChartByTenantId = async ({
  token,
  tenant_id
}: any): Promise<{}> => {
  try {
    const response = await axios.get(`/dynamic-ui-mapper/charts/${tenant_id}`, {
      headers: { Authorization: 'Bearer ' + token }
    })
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}

export const getTenantType = async ({
  token,
  tenantTypeId
}: any): Promise<{}> => {
  try {
    const response = await axios.get(`/dynamic-ui-mapper/tenant-type/${tenantTypeId}`, {
      headers: { Authorization: 'Bearer ' + token }
    })
    return response.data ? response.data : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}

export const getMerchants = async ({ token, tenant_id }: any): Promise<{}> => {
  try {
    const response = await axios.get(`/geography/merchants`, {
      headers: { Authorization: 'Bearer ' + token },
      params: {
        tenant_id
      }
    })
    return response.data ? response.data.items : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}

export const getAllSkus = async ({ token, tenant_id }: any): Promise<{}> => {
  try {
    const response = await axios.get(`/product-management/skus`, {
      headers: { Authorization: 'Bearer ' + token },
      params: {
        tenant_id
      }
    })
    return response.data ? response.data.items : {}
  } catch (error) {
    console.warn('request error')
    return {}
  }
}
