import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import MaterialTable, { Column } from 'material-table'

import { State } from '../../reducers'
import { mapCountryToCurrency } from '../../temp/currencies_map'
import { BC } from '../BCTooltip'
import { getChipWithCurrency } from '../../utils'
import { createSelector } from 'reselect'
import { getTenantType } from '../../api'

interface TransactionUnitsProps {
  country: string
  token: string
  tenant_id: string | null
  tenants: any[]
}

const mapStateToProps = createSelector(
  (state: State): TransactionUnitsProps['country'] =>
    state.mapState.position.country || '',
  (state: State): TransactionUnitsProps['token'] => state.userState.token || '',
  (state: State): TransactionUnitsProps['tenant_id'] =>
    state.userState.tenant_id,
  (state: State): TransactionUnitsProps['tenants'] => state.userState.tenants,
  (country, token, tenant_id, tenants): TransactionUnitsProps => ({
    country,
    token,
    tenant_id,
    tenants
  })
)

interface Row {
  sku: string
  name: string
  gross: number
  brand: string
}

const renderAmount = (currency: string, type: string): React.FC =>
  function renderAmountNext(data: any): JSX.Element {
    return (
      <BC value={data[type]} currency={currency}>
        <span>{getChipWithCurrency(data[type], 2, currency)}</span>
      </BC>
    )
  }

export const TransactionUnits: React.FC<{ units: Row[] }> = ({
  units
}): JSX.Element | null => {
  const { country, token, tenant_id, tenants } = useSelector(mapStateToProps)
  const [tenantType, setTenantType] = useState('')

  useEffect((): void => {
    if (!token) return
    const callTenantTypeByTenantId = async (): Promise<any> => {
      const tenantTypeId = tenants.filter(item => item.id == tenant_id)?.[0]
        .tenantTypeId
      const result: any = await getTenantType({ token, tenantTypeId })
      setTenantType(result?.name ?? 'Corp')
    }
    callTenantTypeByTenantId()
  }, [token, tenant_id])

  const columns: Column<any>[] =
    tenantType == 'Bank'
      ? [
          {
            title: 'Transaction ID',
            field: 'sku'
          },
          {
            title: 'Name',
            field: 'name'
          },

          {
            title: 'Gross',
            field: 'gross',
            render: renderAmount(
              country ? mapCountryToCurrency[country] : 'USD',
              'gross'
            )
          }
        ]
      : [
          {
            title: 'SKU',
            field: 'sku'
          },
          {
            title: 'Brand',
            field: 'brand'
          },
          {
            title: 'Name',
            field: 'name'
          },

          {
            title: 'Gross',
            field: 'gross',
            render: renderAmount(
              country ? mapCountryToCurrency[country] : 'USD',
              'gross'
            )
          }
        ]

  return (
    <MaterialTable
      columns={columns}
      data={units}
      options={{
        header: true,
        toolbar: false,
        showTitle: false,
        paging: false
      }}
    />
  )
}
