import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import MaterialTable, { Column } from 'material-table'
import { StyleRules, createStyles, makeStyles } from '@mui/styles'

import { State } from '../../reducers'
import { editLocation, addLocation, removeLocation } from '../../api'
import { getCountries, Country, Location } from '../../apis/GetCountries'
import { createSelector } from 'reselect'

const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      row: {
        flexGrow: 1,
        backgroundColor: '#f1f1f1',
        display: 'flex',
        height: 224
      }
    })
)

interface GeographyProps {
  token: string
  tenant_id: string
}

const mapState = createSelector(
  (state: State): GeographyProps['token'] => state.userState.token || '',
  (state: State): GeographyProps['tenant_id'] => state.userState.tenant_id || '',
  (
    token,
    tenant_id,
  ): GeographyProps => ({
    token,
    tenant_id,
  })
)

// Province

interface Row extends Location {
  country: string
  countryId: string
}

interface MapCountries {
  [id: string]: string
}
export function Geography(): JSX.Element {
  const classes = useStyles()
  const { token, tenant_id } = useSelector(mapState)
  const [data, setData] = useState<Row[]>([])
  const [cLookup, setCLookup] = useState<MapCountries>({})
  const [trigger, setTrigger] = useState<number>(1)

  useEffect((): void => {
    const callCountries = async (): Promise<any> => {
      const countries: Country[] | null = await getCountries(token, tenant_id)
      const newData: Row[] = []
      if (countries) {
        const countryLookup: MapCountries = {}
        countries.forEach((country: Country): void => {
          const { name, id } = country
          newData.push(
            ...country.locations.map(
              (location): Row => ({
                ...location,
                country: name,
                countryId: id
              })
            )
          )
          countryLookup[id] = name
        })
        setCLookup(countryLookup)
        setData(newData)
      }
    }
    callCountries()
  }, [token, trigger])
  const columns: Column<any>[] = [
    {
      field: 'countryId',
      // hidden: true,
      lookup: cLookup,
      searchable: true,
      editable: 'onAdd'
    },
    {
      title: 'Country',
      field: 'country',
      editable: 'always',
      defaultGroupOrder: 0
    },
    { field: 'id', hidden: true, searchable: false },
    {
      title: 'Merchant Id',
      field: 'merchantId',
      editable: 'always',
      type: 'numeric'
    },
    {
      title: 'Bank Account',
      field: 'bankAccount',
      editable: 'always'
    },
    {
      title: 'Region Id',
      field: 'regionId',
      editable: 'always'
    },
    {
      title: 'Region',
      field: 'regionName',
      editable: 'always'
    },
    { title: 'Address', field: 'address', editable: 'always' },
    { title: 'Info', field: 'contactInfo', editable: 'always' },
    { title: 'Lat', field: 'latitude', editable: 'always', type: 'numeric' },
    { title: 'Lon', field: 'longitude', editable: 'always', type: 'numeric' },
    { title: 'IP', field: 'ip', editable: 'always' },
    {
      title: 'batchCutoff',
      field: 'batchCutoff',
      editable: 'always'
    }
  ]

  return (
    <MaterialTable
      isLoading={data.length === 0}
      columns={columns}
      data={data}
      options={{
        header: false,
        paging: false,
        showTitle: false,
        searchFieldAlignment: 'left',
        grouping: false,
        addRowPosition: 'first'
      }}
      editable={{
        onRowUpdate: async (row: Row): Promise<void> => {
          const {
            id,
            merchantId,
            bankAccount,
            address,
            contactInfo,
            latitude,
            longitude,
            regionId,
            regionName,
            ip,
            batchCutoff,
            countryId
          } = row

          const item = await editLocation(token, {
            id: id || '',
            tenant_id: tenant_id,
            merchantId: merchantId || '',
            regionId: '' + regionId,
            bankAccount,
            address,
            contactInfo,
            latitude,
            longitude,
            regionName,
            ip,
            batchCutoff,
            countryId
          })
          if (item) {
            const itemToChange = data.findIndex(
              (item): boolean => item.id === id
            )
            if (itemToChange !== -1) {
              data.splice(itemToChange, 1, row)
              setData([...data])
            }
          }
        },
        onRowAdd: async (): Promise<void> => {},
        // async (row: Row): Promise<void> => {
        //   const {
        //     id,
        //     merchantId,
        //     bankAccount,
        //     address,
        //     contactInfo,
        //     latitude,
        //     longitude,
        //     regionId,
        //     regionName,
        //     ip,
        //     batchCutoff,
        //     countryId
        //   } = row

        //   const item = await addLocation(token, {
        //     id: id || '',
        //     merchantId: merchantId || '',
        //     regionId: '' + regionId,
        //     bankAccount,
        //     address,
        //     contactInfo,
        //     latitude,
        //     longitude,
        //     regionName,
        //     ip,
        //     batchCutoff,
        //     countryId
        //   })
        //   if (item) {
        //     setTrigger(trigger + 1)
        //     // setData([...data, row])
        //   }
        // },
        onRowDelete: async (): Promise<void> => {}
        // async (row: Row): Promise<void> => {
        //   const { id, countryId } = row
        //   const item = await removeLocation(token, {
        //     locationId: id || '',
        //     countryId
        //   })
        //   if (item) {
        //     const itemToRemove = data.findIndex(
        //       (item): boolean => item.id === id
        //     )
        //     if (itemToRemove !== -1) {
        //       data.splice(itemToRemove, 1)
        //       setData([...data])
        //     }
        //   }
        // }
      }}
    />
  )
}
