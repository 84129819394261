import React, { useState, useEffect, useRef } from 'react'
import { Paper, Typography, TextField, MenuItem, Grid, Select, Box, FormControl, SelectChangeEvent } from '@mui/material'
import { StyleRules, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import moment from 'moment'
import { Download } from './Download'
import Chart from "react-apexcharts"
import { tenant_id as TenantId } from '../../stories/globalVariables'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      marginTop: '10px',
      textAlign: 'center',
      overflow: 'hidden'
    },
    labelCont: { display: 'flex', flexDirection: 'column' },
    feBold: { fontWeight: 'bold' },
    gridCont: { display: 'flex', justifyContent: 'space-evenly' },
    gridCenter: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center'
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    formControl: {
      margin: theme.spacing(1),
    },
    legend: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px'
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '10px',
      '&:last-child': {
        marginRight: '0px'
      }
    },
    legendMarker: {
      width: '10px',
      height: '10px',
      background: 'red',
      borderRadius: '100%',
      marginRight: '5px'
    },
    legendText: {
      fontSize: '13px',
      margin: '0px',
      color: 'rgb(53,53,53)'
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary,
      paddingBottom: '15px'
    },
  })
)

export const CurrencyPerformanceGraph = ({
  tenant_id,
  title
}: any) => {
  const classes = useStyles()
  const [isValueChanged, setIsValueChanged] = useState(false)
  const [newValue, setNewValue] = useState(1)
  useEffect(() => {
    setTimeout(() => {
      setNewValue(2)
    }, 2000)
  }, [])
  const base_locations = [
    {
      label: (
        <div className={classes.labelCont}>
          <span className={classes.fwBold}>United State</span>
          <span>2425 Vista Way El Camino Oceanside, CA 92054</span>
        </div>
      ),
      value: '1'
    },
    {
      label: (
        <div className={classes.labelCont}>
          <span className={classes.fwBold}>United State</span>
          <span>961 N Market St Waterloo Waterloo, IL 62298</span>
        </div>
      ),
      value: '2'
    },
    {
      label: (
        <div className={classes.labelCont}>
          <span className={classes.fwBold}>United State</span>
          <span>
            2900 Veterans Rd W Staten Island Staten Island, NY 10309
          </span>
        </div>
      ),
      value: '3'
    },
    {
      label: (
        <div className={classes.labelCont}>
          <span className={classes.fwBold}>Switzerland</span>
          <span>Rue de Rive 4 Geneva, Rue de Rive 1204 Geneva</span>
        </div>
      ),
      value: '4'
    },
    {
      label: (
        <div className={classes.labelCont}>
          <span className={classes.fwBold}>Netherlands</span>
          <span>
            Hirschgebouw, Leidseplein 25 Amsterdam 1017 PS Amsterdam
          </span>
        </div>
      ),
      value: '0'
    }
  ]

  const nike_locations = [
    {
      label: (
        <div className={classes.labelCont}>
          <span className={classes.fwBold}>United State</span>
          <span>1425 Shaw Ave Clovis Clovis, CA 93611</span>
        </div>
      ),
      value: '1'
    },
    {
      label: (
        <div className={classes.labelCont}>
          <span className={classes.fwBold}>United State</span>
          <span>2900 Kirk Rd Aurora Aurora, IL 60502</span>
        </div>
      ),
      value: '2'
    },
    {
      label: (
        <div className={classes.labelCont}>
          <span className={classes.fwBold}>United State</span>
          <span>
            Atlantic Terminal Mall Brooklyn  139 Flatbush Ave  Brooklyn, NY 11217
          </span>
        </div>
      ),
      value: '3'
    },
    {
      label: (
        <div className={classes.labelCont}>
          <span className={classes.fwBold}>Germany</span>
          <span>CentrO Oberhausen Oberhausen, CentrO Centroallee 147 46047 Oberhausen</span>
        </div>
      ),
      value: '4'
    },
    {
      label: (
        <div className={classes.labelCont}>
          <span className={classes.fwBold}>France</span>
          <span>
            Centre commercial regional Lieusaint, Carre Senart 3, allee du Preambule 77127 Lieusaint
          </span>
        </div>
      ),
      value: '0'
    }
  ]
  const products = [
    {
      label: 'pp5004320142',
      value: '1'
    },
    {
      label: 'pp5003910607',
      value: '2'
    },
    {
      label: 'pp5003721910',
      value: '3'
    },
    {
      label: 'pp5006333356',
      value: '4'
    },
    {
      label: 'pp5003920438',
      value: '0'
    }
  ]
  const [data, setData] = useState<any>([
    {
      name: 'Sales',
      arr: [
        {
          duration: moment()
            .add(-30, 'days')
            .format("MMM D"),
          discounted_sales: 4200,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-21, 'days')
            .format("MMM D"),
          discounted_sales: 3900,
          list_sales_total: 3600
        },
        {
          duration: moment()
            .add(-15, 'days')
            .format("MMM D"),
          discounted_sales: 2700,
          list_sales_total: 2200
        },
        {
          duration: moment()
            .add(-7, 'days')
            .format("MMM D"),
          discounted_sales: 3700,
          list_sales_total: 3400
        },
        {
          duration: moment()
            .add(-6, 'days')
            .format("MMM D"),
          discounted_sales: 3900,
          list_sales_total: 3700
        },
        {
          duration: moment()
            .add(-5, 'days')
            .format("MMM D"),
          discounted_sales: 2200,
          list_sales_total: 1200
        },
        {
          duration: moment()
            .add(-4, 'days')
            .format("MMM D"),
          discounted_sales: 2900,
          list_sales_total: 2600
        },
        {
          duration: moment()
            .add(-3, 'days')
            .format("MMM D"),
          discounted_sales: 3700,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-2, 'days')
            .format("MMM D"),
          discounted_sales: 4700,
          list_sales_total: 3600
        },
        {
          duration: moment()
            .add(-1, 'days')
            .format("MMM D"),
          discounted_sales: 4900,
          list_sales_total: 3200
        }
      ]
    }
  ])

  const dataArr = [
    {
      name: 'Sales',
      arr: [
        {
          duration: moment()
            .add(-30, 'days')
            .format("MMM D"),
          discounted_sales: 4200,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-21, 'days')
            .format("MMM D"),
          discounted_sales: 2900,
          list_sales_total: 2600
        },
        {
          duration: moment()
            .add(-15, 'days')
            .format("MMM D"),
          discounted_sales: 3900,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-7, 'days')
            .format("MMM D"),
          discounted_sales: 3700,
          list_sales_total: 3000
        },
        {
          duration: moment()
            .add(-6, 'days')
            .format("MMM D"),
          discounted_sales: 4200,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-5, 'days')
            .format("MMM D"),
          discounted_sales: 2500,
          list_sales_total: 1500
        },
        {
          duration: moment()
            .add(-4, 'days')
            .format("MMM D"),
          discounted_sales: 3900,
          list_sales_total: 1500
        },
        {
          duration: moment()
            .add(-3, 'days')
            .format("MMM D"),
          discounted_sales: 2900,
          list_sales_total: 1200
        },
        {
          duration: moment()
            .add(-2, 'days')
            .format("MMM D"),
          discounted_sales: 3400,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-1, 'days')
            .format("MMM D"),
          discounted_sales: 4800,
          list_sales_total: 2000
        }
      ]
    },
    {
      name: 'Sales',
      arr: [
        {
          duration: moment()
            .add(-30, 'days')
            .format("MMM D"),
          discounted_sales: 4200,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-21, 'days')
            .format("MMM D"),
          discounted_sales: 3900,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-15, 'days')
            .format("MMM D"),
          discounted_sales: 3500,
          list_sales_total: 3100
        },
        {
          duration: moment()
            .add(-7, 'days')
            .format("MMM D"),
          discounted_sales: 4700,
          list_sales_total: 3600
        },
        {
          duration: moment()
            .add(-6, 'days')
            .format("MMM D"),
          discounted_sales: 4900,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-5, 'days')
            .format("MMM D"),
          discounted_sales: 2400,
          list_sales_total: 2200
        },
        {
          duration: moment()
            .add(-4, 'days')
            .format("MMM D"),
          discounted_sales: 2200,
          list_sales_total: 2100
        },
        {
          duration: moment()
            .add(-3, 'days')
            .format("MMM D"),
          discounted_sales: 3300,
          list_sales_total: 3100
        },
        {
          duration: moment()
            .add(-2, 'days')
            .format("MMM D"),
          discounted_sales: 4500,
          list_sales_total: 4200
        },
        {
          duration: moment()
            .add(-1, 'days')
            .format("MMM D"),
          discounted_sales: 4800,
          list_sales_total: 4500
        }
      ]
    },
    {
      name: 'Sales',
      arr: [
        {
          duration: moment()
            .add(-30, 'days')
            .format("MMM D"),
          discounted_sales: 4400,
          list_sales_total: 4000
        },
        {
          duration: moment()
            .add(-21, 'days')
            .format("MMM D"),
          discounted_sales: 3900,
          list_sales_total: 2600
        },
        {
          duration: moment()
            .add(-15, 'days')
            .format("MMM D"),
          discounted_sales: 3700,
          list_sales_total: 2200
        },
        {
          duration: moment()
            .add(-7, 'days')
            .format("MMM D"),
          discounted_sales: 3700,
          list_sales_total: 3600
        },
        {
          duration: moment()
            .add(-6, 'days')
            .format("MMM D"),
          discounted_sales: 4900,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-5, 'days')
            .format("MMM D"),
          discounted_sales: 2700,
          list_sales_total: 2300
        },
        {
          duration: moment()
            .add(-4, 'days')
            .format("MMM D"),
          discounted_sales: 1900,
          list_sales_total: 1700
        },
        {
          duration: moment()
            .add(-3, 'days')
            .format("MMM D"),
          discounted_sales: 2700,
          list_sales_total: 2200
        },
        {
          duration: moment()
            .add(-2, 'days')
            .format("MMM D"),
          discounted_sales: 3300,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-1, 'days')
            .format("MMM D"),
          discounted_sales: 4800,
          list_sales_total: 4700
        }
      ]
    },

    {
      name: 'Sales',
      arr: [
        {
          duration: moment()
            .add(-30, 'days')
            .format("MMM D"),
          discounted_sales: 4200,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-21, 'days')
            .format("MMM D"),
          discounted_sales: 2000,
          list_sales_total: 2600
        },
        {
          duration: moment()
            .add(-15, 'days')
            .format("MMM D"),
          discounted_sales: 3700,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-7, 'days')
            .format("MMM D"),
          discounted_sales: 4700,
          list_sales_total: 3600
        },
        {
          duration: moment()
            .add(-6, 'days')
            .format("MMM D"),
          discounted_sales: 4900,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-5, 'days')
            .format("MMM D"),
          discounted_sales: 3000,
          list_sales_total: 2200
        },
        {
          duration: moment()
            .add(-4, 'days')
            .format("MMM D"),
          discounted_sales: 1700,
          list_sales_total: 1600
        },
        {
          duration: moment()
            .add(-3, 'days')
            .format("MMM D"),
          discounted_sales: 2700,
          list_sales_total: 2400
        },
        {
          duration: moment()
            .add(-2, 'days')
            .format("MMM D"),
          discounted_sales: 3600,
          list_sales_total: 3400
        },
        {
          duration: moment()
            .add(-1, 'days')
            .format("MMM D"),
          discounted_sales: 4900,
          list_sales_total: 4600
        }
      ]
    },
    {
      name: 'Sales',
      arr: [
        {
          duration: moment()
            .add(-30, 'days')
            .format("MMM D"),
          discounted_sales: 2200,
          list_sales_total: 1200
        },
        {
          duration: moment()
            .add(-21, 'days')
            .format("MMM D"),
          discounted_sales: 2900,
          list_sales_total: 2600
        },
        {
          duration: moment()
            .add(-15, 'days')
            .format("MMM D"),
          discounted_sales: 3700,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-7, 'days')
            .format("MMM D"),
          discounted_sales: 4700,
          list_sales_total: 3600
        },
        {
          duration: moment()
            .add(-6, 'days')
            .format("MMM D"),
          discounted_sales: 4900,
          list_sales_total: 3200
        },
        {
          duration: moment()
            .add(-5, 'days')
            .format("MMM D"),
          discounted_sales: 2300,
          list_sales_total: 1200
        },
        {
          duration: moment()
            .add(-4, 'days')
            .format("MMM D"),
          discounted_sales: 1800,
          list_sales_total: 1700
        },
        {
          duration: moment()
            .add(-3, 'days')
            .format("MMM D"),
          discounted_sales: 2700,
          list_sales_total: 2500
        },
        {
          duration: moment()
            .add(-2, 'days')
            .format("MMM D"),
          discounted_sales: 3600,
          list_sales_total: 3500
        },
        {
          duration: moment()
            .add(-1, 'days')
            .format("MMM D"),
          discounted_sales: 4800,
          list_sales_total: 4500
        }
      ]
    }
  ]

  const [type, setType] = useState('Line')
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})
  const types = [
    'Line',
    'Bar',
    'Area'
  ]

  const setDefaultOptions = (mapped: any[]) => {
    setOptions({
      ...options,
      chart: { 
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      tooltip: {
        y: {
          formatter: (val: number) =>  {
            return Intl.NumberFormat('en-US', {
              maximumSignificantDigits: 3,
            }).format(val)
          }
        }
      },
      xaxis: {
        categories: mapped && mapped.length > 0 ? mapped: []
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            //@ts-ignore
            return Intl.NumberFormat('en-US', {
              //@ts-ignore
              notation: 'compact',
              compactDisplay: 'short',
            }).format(val)
          }
        }
      },
      fill: {
        type: 'solid'
      }
    })
  }

  const handleChange = (name: any, event: React.ChangeEvent<HTMLInputElement>) => {
    let tempArr: any = []
    let arrIndex = Math.floor(Math.random() * dataArr.length)
    tempArr.push(dataArr[arrIndex])
    setData(tempArr)
  }

  useEffect(() => {
    switch(type) {
      case 'Line':
        setOptions({
          ...options, 
            chart: { 
              type: 'line',
            },
        })
      break
      case 'Bar':
        setOptions({
          ...options, 
            chart: { 
              type: 'bar',
            },
        })
      break
      case 'Area':
        setOptions({
          ...options, 
            chart: { 
              type: 'area',
            },
        })
      break
    }
  }, [type])

  useEffect(() => {
    if(data.length === 0) return

    let actualData:any[] = []
    let budgetData:any[] = []
    let series: any[] = []
    let dateList: any[] = []
    
    data.map((dataItem: any) => {
      dataItem.arr.map((item: any) => {
        actualData.push(item.discounted_sales)
        budgetData.push(item.list_sales_total)
        dateList.push(item.duration)
      })
    })

    setDefaultOptions(dateList)

    series.push({
      name: 'Actual',
      data: actualData
    })

    series.push({
      name: 'Budget',
      data: budgetData
    })

    setSeries(series)
  }, [data])

  const typeChange = (event: SelectChangeEvent): void => {
    setType(event.target.value as string)
  }

  
  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {title ? title : 'Product Performance'}
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={type}
              onChange={typeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {types.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        <Grid item xs={12} className={classes.gridCont}>
          <Grid item sm={6} xs={12} className={classes.gridCenter}>
            {' '}
            <Typography
              variant="subtitle2"
              component="h3"
              className={classes.caption}
            >
              Products:
            </Typography>
            <TextField
              style={{ width: '50%' }}
              id="outlined-select-promotion_id"
              select
              variant='standard'
              //   label="Sub Classification"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChange('products', event)
                // setIsValueChanged(!isValueChanged)
              }}
              defaultValue={newValue}
            >
              {products.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sm={6} xs={12} className={classes.gridCenter}>
            {' '}
            <Grid item sm={4} xs={12}>
              <Typography
                variant="subtitle2"
                component="h3"
                className={classes.caption}
              >
                Location:
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <TextField
                id="outlined-select-location"
                select
                variant='standard'
                style={{ width: '100%' }}
                //   label="Sub Classification"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange('location', event)
                  // setIsValueChanged(!isValueChanged)
                }}
                defaultValue={newValue}
              >
                {
                  tenant_id == TenantId || tenant_id == null ? (
                    base_locations.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  ): (
                    nike_locations.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))   
                  )
                }
                {}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Chart
          options={options}
          series={series}
          type={type === 'Line' ? 'line' : type === 'Bar' ? 'bar' : 'area'}
          height="300"
        />
        <Download data={data[0].arr} />
      </Paper>
    </Grid>
  )
}