import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { useAuth0 } from '../auth0_wrapper'
import { Dispatch } from '../reducers'
import { setUser, UserSet, setPrimaryTenantId, PrimaryTenantIdSet } from '../reducers/user'
import { getDataByRange } from '../sagas'

const PrivateRouteComponent = ({
  component: Component,
  path,
  setTheUser,
  setThePrimaryTenantId,
  getTheDataByRange,
  ...rest
}: any): JSX.Element => {
  const {
    isAuthenticated,
    loginWithRedirect,
    getTokenSilently,
    user
  } = useAuth0()

  useEffect((): void => {
    const fn = async (): Promise<void> => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: path }
        })
      } else {
        if (getTokenSilently && user) {
          const token = await getTokenSilently()
          setTheUser({ ...user, token })
          //@ts-ignore
          if (user.firm_ids && user.firm_ids.length == 1) {
            //@ts-ignore
            setThePrimaryTenantId(user.firm_ids[0]);
          }
          getTheDataByRange()
          // setTimeout((): void => getTheDataByRange(), 3000)
        }
      }
    }
    fn()
  }, [
    isAuthenticated,
    loginWithRedirect,
    path,
    getTokenSilently,
    user,
    setTheUser,
    setThePrimaryTenantId,
    getTheDataByRange
  ])

  const render = (props: any): JSX.Element | null =>
    isAuthenticated === true ? <Component {...props} /> : null

  return <Route path={path} render={render} {...rest} />
}

interface DispatchFromProps {
  setTheUser: (user: UserSet) => void
  setThePrimaryTenantId: (tenant_id: string) => void
  getTheDataByRange: () => void
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  setTheUser: (user): void => {
    dispatch(setUser(user))
  },
  setThePrimaryTenantId: (tenant_id: string): void => {
    dispatch(setPrimaryTenantId({tenant_id}))
  },
  getTheDataByRange: (): void => dispatch(getDataByRange())
})

export const PrivateRoute = connect(
  null,
  mapDispatchToProps
)(PrivateRouteComponent)
