import React, { useState, useEffect, useRef } from 'react'
import { Paper, Typography, CircularProgress, Box, FormControl, Select, MenuItem, SelectChangeEvent, Grid } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'
import { DateTime } from 'luxon'

import { SelectionConstraints } from '../../rawApi'
import { Download } from './Download'
import Chart from "react-apexcharts"

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      marginTop: '5px'
      //   minWidth: '400px'
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary,
      paddingBottom: '15px'
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    formControl: {
      margin: theme.spacing(1),
    },
    legend: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px'
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '10px',
      '&:last-child': {
        marginRight: '0px'
      }
    },
    legendMarker: {
      width: '10px',
      height: '10px',
      background: 'red',
      borderRadius: '100%',
      marginRight: '5px'
    },
    legendText: {
      fontSize: '13px',
      margin: '0px',
      color: 'rgb(53,53,53)'
    }
  })
)

interface ReturningCustomerProps extends SelectionConstraints {
  getData: Function
  title: string
}

interface CSVRow {
  date: string
  value: number
}

const getCSV = (data: {}[]): CSVRow[] =>
  data.map(
    (line: any): CSVRow => ({
      date: DateTime.fromJSDate(line.x).toFormat('MMM dd y'),
      value: line.y
    })
  )

export const ReturningCustomer = ({
  range,
  country,
  merchant,
  getData,
  tenant_id,
  title,
  countries,
  merchantIds
}: ReturningCustomerProps): JSX.Element => {
  const classes = useStyles()
  const [data, setData] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState('Line')
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})

  let name = 'Global'
  if (merchant) {
    name = country + ' / ' + merchant
  } else if (country) {
    name = country || ''
  }

  const types = [
    'Line',
    'Bar',
    'Area'
  ]

  const setDefaultOptions = (mapped: {x: Date, y: number}[]) => {
    setOptions({
      ...options,
      chart: { 
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      tooltip: {
        y: {
          formatter: (val: number) =>  {
            return Intl.NumberFormat('en-US', {
              maximumSignificantDigits: 3,
            }).format(val)
          }
        }
      },
      xaxis: {
        categories: mapped && mapped.length > 0 ? mapped.map((i: {x: Date, y: number}) => DateTime.fromJSDate(i.x).toFormat('LLL dd')): []
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            //@ts-ignore
            return Intl.NumberFormat('en-US', {
              //@ts-ignore
              notation: 'compact',
              compactDisplay: 'short',
            }).format(val)
          }
        }
      },
      fill: {
        type: 'solid'
      }
    })
  }

  useEffect((): void => {
    const fetchData = async (): Promise<any> => {
      setIsLoading(true)
      const result = await getData({ range, country, merchant, tenant_id, countries, merchantIds })
      if (result) {
        const mapped = result.map(
          (item: {
            key_as_string: string
            1: { value: number }
          }): {
            y: number
            x: Date
          } => ({
            y: item[1].value,
            x: DateTime.fromISO(item.key_as_string).toJSDate()
          })
        )

        setDefaultOptions(mapped)
        setSeries([
          {
            name: name,
            data: mapped.map((i: {x: Date, y: number}) => i.y)
          }
        ])
        setData(mapped)
      } else {
        setData([])
      }
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData])

  useEffect(() => {
    switch(type) {
      case 'Line':
        setOptions({
          ...options, 
            chart: { 
              type: 'line',
            },
        })
      break
      case 'Bar':
        setOptions({
          ...options, 
            chart: { 
              type: 'bar',
            },
        })
      break
      case 'Area':
        setOptions({
          ...options, 
            chart: { 
              type: 'area',
            },
        })
      break
    }
  }, [type])

  const typeChange = (event: SelectChangeEvent): void => {
    setType(event.target.value as string)
  }

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {title ? title : `Returning customer ${name}`}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={type}
              onChange={typeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {types.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        <Chart
          options={options}
          series={series}
          type={type === 'Line' ? 'line' : type === 'Bar' ? 'bar' : 'area'}
          height="400"
        />
        <Download data={getCSV(data)} />
      </Paper>
    </Grid>
  )
}
