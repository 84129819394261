import React, { useEffect, useState } from 'react'
import { Paper, Grid, Box, Typography, CircularProgress } from '@mui/material'
import { StyleRules, makeStyles } from '@mui/styles'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Theme } from '@mui/material/styles'
import { getTrading } from '../../api'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '35px 25px',
      marginTop: '10px',
      overflow: 'hidden'
    },
    box: {},
    caption: {
      color: theme.palette.text.primary,
      textTransform: 'uppercase',
      marginBottom: '25px'
    },
    table: {},
    tableBody: {},
    tableHeaderItem: {
      textAlign: 'center',
      fontWeight: 700,
      flex: 1,
      '&:first-child': {
        textAlign: 'left'
      }
    },
    tableRow: {
      display: 'flex',
      borderBottom: '1px solid #E1E1E1',
      paddingTop: '10px',
      paddingBottom: '10px'
    },
    tableRowItem: {
      flex: 1,
      textAlign: 'center',
      '&:first-child': {
        textAlign: 'left'
      }
    },
    ArrowUpwardIcon: {
      fontSize: '20px',
      color: '#EE6D52',
      marginLeft: '10px'
    },
    ArrowDownwardIcon: {
      fontSize: '20px',
      color: '#00E396',
      marginLeft: '10px'
    }
  })
)

interface TradingItem {
  trader: string
  periodOfTrading: number
  risk: number
  profitability: number
  commission: number
  totalAssets: number
}

interface TradingType {
  details: TradingItem[]
}

const Trading = ({ title, token }: any) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<TradingType>()
  const currencyCode = 'USD'

  const tableColumns = [
    {
      title: 'Trader',
      field: 'trader'
    },
    {
      title: 'Period of Trading',
      field: 'periodOfTrading'
    },
    {
      title: 'Risk',
      field: 'risk'
    },
    {
      title: 'Profitability',
      field: 'profitability'
    },
    {
      title: 'Commission',
      field: 'commission'
    },
    {
      title: 'Total Assets',
      field: 'totalAssets'
    }
  ]

  useEffect((): void => {
    if (!token) return
    const callTrading = async (): Promise<any> => {
      const result: any = await getTrading({
        token
      })
      setData(result)
    }
    callTrading()
  }, [token])

  const renderTradingTable = (data: any[], columns: any[]) => {
    return (
      <Box className={classes.tableBody}>
        <Box className={classes.tableRow}>
          {columns.map((column, index) => (
            <Typography key={index} className={classes.tableHeaderItem}>
              {column.title}
            </Typography>
          ))}
        </Box>
        {data.map((item, index) => (
          <Box className={classes.tableRow} key={index}>
            {columns.map((column, index1) => {
              if (column.field == 'risk') {
                return (
                  <Box
                    key={index1}
                    className={classes.tableRowItem}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography>{item?.[column.field]}</Typography>
                    {item?.['profitability'] < 0 ? (
                      <ArrowUpwardIcon className={classes.ArrowUpwardIcon} />
                    ) : (
                      <ArrowDownwardIcon
                        className={classes.ArrowDownwardIcon}
                      />
                    )}
                  </Box>
                )
              } else if (column.field == 'profitability') {
                return (
                  <Typography
                    key={index1}
                    className={classes.tableRowItem}
                    sx={{
                      color: item?.[column.field] >= 0 ? '#00E396' : '#EE6D52'
                    }}
                  >
                    {item?.[column.field]}%
                  </Typography>
                )
              } else if (column.field == 'commission') {
                return (
                  <Typography key={index1} className={classes.tableRowItem}>
                    {item?.[column.field]}%
                  </Typography>
                )
              } else if (column.field == 'periodOfTrading') {
                return (
                  <Typography key={index1} className={classes.tableRowItem}>
                    {item?.[column.field]}d
                  </Typography>
                )
              } else if (column.field == 'totalAssets') {
                return (
                  <Typography key={index1} className={classes.tableRowItem}>
                    {item?.[column.field]
                      ? new Intl.NumberFormat(undefined, {
                          style: 'currency',
                          currency: currencyCode,
                          maximumFractionDigits: 0
                        }).format(Math.floor(item?.[column.field]))
                      : '-'}
                  </Typography>
                )
              } else {
                return (
                  <Typography key={index1} className={classes.tableRowItem}>
                    {item?.[column.field]}
                  </Typography>
                )
              }
            })}
          </Box>
        ))}
      </Box>
    )
  }

  return (
    <Grid item xs={12} sm={12}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {title}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
          <Box className={classes.table}>
            {data?.details && renderTradingTable(data?.details, tableColumns)}
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
}

export default Trading
