import React, { SFC } from 'react'
import { Tooltip } from '@mui/material'
import { useSelector } from 'react-redux'

import { State } from '../reducers'
import { BaseRates } from '../reducers/constraints'
import { getChipWithCurrency } from '../utils'
import { createSelector } from 'reselect'

interface BCInterface {
  value: number
  currency: string
  children: React.ReactElement
}

interface BCTooltipProps {
  base: string
  rates: BaseRates
}

const mapState = createSelector(
  (state: State): BCTooltipProps['base'] => state.userState.baseCurrency,
  (state: State): BCTooltipProps['rates'] => state.constraintsState.baseRates,
  (
    base,
    rates
  ): BCTooltipProps => ({
    base,
    rates
  })
)

export function BC({ children, value, currency }: BCInterface): JSX.Element {
  const { rates, base } = useSelector(mapState)
  const available = currency !== base && rates[currency] ? true : false
  const valueOnBase = available ? value * rates[currency] : 0
  return available ? (
    <Tooltip title={getChipWithCurrency(valueOnBase, 2, base)} placement="top">
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  )
}

interface BCCalcInterface {
  value: number
  currency: string
  rates: BaseRates
  base: string
}

export function calcToBase({
  value,
  currency,
  rates,
  base
}: BCCalcInterface): number {
  const available = currency !== base && rates[currency] ? true : false
  return available ? value * rates[currency] : value
}

export function BCCalc(props: BCCalcInterface): string {
  const valueOnBase = calcToBase(props)
  return getChipWithCurrency(valueOnBase, 2, props.base)
}
