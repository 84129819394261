import React from 'react'
import { Typography, Button } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'
import { CSVLink } from 'react-csv'

const exportToJson = (
  objectData: Record<string, any>,
  filename: string = 'export'
): void => {
  let contentType = 'application/json;charset=utf-8;'
  filename += '.json'
  //@ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob(
      [decodeURIComponent(encodeURI(JSON.stringify(objectData)))],
      { type: contentType }
    )
    //@ts-ignore
    navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    var a = document.createElement('a')
    a.download = filename
    a.href =
      'data:' +
      contentType +
      ',' +
      encodeURIComponent(JSON.stringify(objectData))
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    download: {
      textAlign: 'left',
      color: theme.palette.text.secondary
    }
  })
)

interface Download {
  data?: any
  headers?: any
  filename?: string
}
export const Download = ({
  data,
  headers,
  filename
}: Download): JSX.Element => {
  const classes = useStyles()
  return data ? (
    <Typography variant="caption" display="block" className={classes.download}>
      Download{' '}
      <Button onClick={(): void => exportToJson(data, filename)} size="small">
        JSON
      </Button>
      {data.length > 0 ? (
        <Button size="small">
          <CSVLink data={data} filename={'my-file.csv'} target="_blank">
            CSV
          </CSVLink>
        </Button>
      ) : null}
    </Typography>
  ) : (
    <></>
  )
}
