import React, { useState, useEffect } from 'react'
import {
  Paper,
  Typography,
  CircularProgress,
  Tabs,
  Box,
  Tab,
  AppBar,
  Grid
} from '@mui/material'
import SwipeableViews from 'react-swipeable-views'
import { Theme, useTheme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'

import { Download } from './Download'
import { getChips } from '../../utils'
import { SelectionConstraints } from '../../rawApi'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      marginTop: '5px'
      // maxWidth: '300px'
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary
    },
    table: {
      display: 'table',
      width: '100%'
    },
    tr: {
      display: 'table-row',
      whiteSpace: 'nowrap',
      color: 'black'
    },
    tabs: {
      width: '100%'
    },
    tab: {
      color: '#333',
      fontSize: '12px'
    },
    td: {
      display: 'table-cell',
      textAlign: 'left',
      verticalAlign: 'middle',
      paddingLeft: '10px',
      lineHeight: '2em'
    },
    header: {
      color: '#7c7c7c',
      fontSize: '12px'
    },
    name: {
      color: '#333',
      fontSize: '14px',
      float: 'left'
    },
    value: {
      color: 'black',
      display: 'inline-block',
      fontSize: '14px',
      fontWeight: 700,
      width: '100%'
    },
    green: {
      backgroundColor: '#b4dfc2',
      width: '100%',
      borderRadius: '10px'
    },
    red: {
      backgroundColor: '#ffc4c5'
    },
    description: {
      marginTop: '5px',
      marginBottom: '5px',
      color: '#7c7c7c',
      fontSize: '12px'
    }
  })
)

interface TopPerformingProducts {
  getData: Function
  range: SelectionConstraints['range']
  tenant_id: string | null
  title: string
  // country: string
  // merchant: string
}
interface ESResValue {
  value: number
}
interface TopRepeatPurchaseItem {
  1: ESResValue
  key: string
}
interface TopRepeatPurchaseMapping {
  key: string
  value: number
}
const a11yProps = (index: number): {} => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

export const TopRepeatPurchaseItems = ({
  range,
  getData,
  tenant_id,
  title
}: TopPerformingProducts): JSX.Element => {
  const classes = useStyles()
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()

  useEffect((): void => {
    const fetchData = async (): Promise<any> => {
      setIsLoading(true)
      const result = await getData({ range, tenant_id })
      if (result) {
        const mapped = result.map(
          (item: TopRepeatPurchaseItem): TopRepeatPurchaseMapping => {
            return {
              key: item.key,
              value: item[1].value
            }
          }
        )
        setData(mapped)
      }
      setIsLoading(false)
    }
    fetchData()
  }, [range, getData])
  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Typography variant="subtitle2" className={classes.caption}>
          {title ? title: 'Top Repeat Purchase Items'}
          {isLoading && (
            <CircularProgress
              className={classes.progress}
              size={15}
              color={'secondary'}
            />
          )}
        </Typography>
        <AppBar elevation={0} position="static" color="inherit">
          <Tabs
            value={0}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            TabIndicatorProps={{ className: classes.tabs }}
          >
            <Tab label="Top" {...a11yProps(0)} className={classes.tab} />
          </Tabs>
        </AppBar>
        <div className={classes.description}>
          Purchase Average Over Selected Range
        </div>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={0}
        >
          <Sview
            data={data}
            index={0}
            value={0}
            classes={classes}
            theme={theme}
          />
        </SwipeableViews>
        <Download data={data} />
      </Paper>
    </Grid>
  )
}
const TabPanel = (props: {
  children: any
  value: number
  index: number
  dir: string
}): JSX.Element => {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  )
}

interface SVIEW {
  data: TopRepeatPurchaseMapping[]
  index: number
  value: number
  classes: any
  theme: any
}

const Sview = ({ data, index, value, classes, theme }: SVIEW): JSX.Element => (
  <TabPanel value={value} index={index} dir={theme.direction}>
    <div className={classes.table}>
      {data.map(
        (product): JSX.Element => (
          <div
            className={classes.tr}
            key={`top-repeat-purchase-item-${product.key}`}
          >
            <div className={classes.td}>
              <span className={classes.name}>{product.key}</span>
            </div>
            <div className={classes.td}>
              <span className={classes.value}>
                {getChips(product.value, 0)}
              </span>
            </div>
          </div>
        )
      )}
    </div>
  </TabPanel>
)
