import React, { useEffect, useState } from 'react'
import { Paper, Grid, Box, Typography, CircularProgress } from '@mui/material'
import { StyleRules, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { getDebtServiceSchedule } from '../../api'
import classNames from 'classnames'

interface debtPriceItem {
  principal: number | null
  interest: number | null
}
interface detailItem {
  year: string
  series_2014_full_faith_and_credit_refunding_bonds: debtPriceItem
  ifa_final_decision_loan: debtPriceItem
  ifa_6th_street_waterline_improvement_project_refunding_note: debtPriceItem
}

interface dueItem {
  year: string
  series_2014_full_faith_and_credit_refunding_bonds: debtPriceItem
  ifa_final_decision_loan: debtPriceItem
  ifa_6th_street_waterline_improvement_project_refunding_note: debtPriceItem
}

interface DebtServiceScheduleType {
  details: detailItem[]
  due: dueItem[]
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '35px 25px',
      marginTop: '10px',
      overflow: 'hidden'
    },
    box: {},
    caption: {
      textTransform: 'uppercase',
      color: theme.palette.text.primary
    },
    tableBox: {},
    tableRow: {
      display: 'flex',
      borderBottom: '1px solid #D9D9D9',
      paddingTop: '10px',
      paddingBottom: '10px'
    },
    tableRowItem: {
      display: 'flex',
      flex: 1,
      fontSize: '14px'
    },
    textRight: {
      textAlign: 'right'
    },
    boldText1: {
      fontWeight: 'bold',
      color: '#B5B5B5'
    },
    boldText2: {
      fontWeight: 'bold'
    }
  })
)

const DebtServiceSchedule = ({ title, token }: any) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<DebtServiceScheduleType>()
  const currencyCode = 'USD'

  useEffect((): void => {
    if (!token) return
    const callDebtService = async (): Promise<any> => {
      const result: any = await getDebtServiceSchedule({
        token
      })
      setData(result)
    }
    callDebtService()
  }, [token])

  const renderTable = () => {
    return (
      <Box className={classes.tableBox}>
        <Box className={classes.tableRow}>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            Year Ending June 30
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            Series 2014 Full Faith and Credit Refunding Bonds
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            IFA Final Decision Loan
          </Typography>
          <Typography
            className={classes.tableRowItem}
            sx={{ fontWeight: 'bold' }}
          >
            IFA 6th Street Waterline Improvement Project Refunding Note
          </Typography>
        </Box>
        <Box className={classes.tableRow}>
          <Box className={classes.tableRowItem}></Box>
          <Box className={classes.tableRowItem}>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText1)}
            >
              Principal
            </Typography>
            <Typography
              className={classNames(
                classes.tableRowItem,
                classes.textRight,
                classes.boldText1
              )}
            >
              Interest
            </Typography>
          </Box>
          <Box className={classes.tableRowItem}>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText1)}
            >
              Principal
            </Typography>
            <Typography
              className={classNames(
                classes.tableRowItem,
                classes.textRight,
                classes.boldText1
              )}
            >
              Interest
            </Typography>
          </Box>
          <Box className={classes.tableRowItem}>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText1)}
            >
              Principal
            </Typography>
            <Typography
              className={classNames(
                classes.tableRowItem,
                classes.textRight,
                classes.boldText1
              )}
            >
              Interest
            </Typography>
          </Box>
        </Box>
        {data?.details?.map((item, index) => (
          <Box className={classes.tableRow} key={index}>
            <Typography className={classes.tableRowItem}>
              {item?.year}
            </Typography>
            <Box className={classes.tableRowItem}>
              <Typography className={classes.tableRowItem}>
                {item?.series_2014_full_faith_and_credit_refunding_bonds
                  ?.principal
                  ? new Intl.NumberFormat(undefined, {
                      style: 'currency',
                      currency: currencyCode,
                      maximumFractionDigits: 0
                    }).format(
                      Math.floor(
                        item?.series_2014_full_faith_and_credit_refunding_bonds
                          ?.principal
                      )
                    )
                  : '-'}
              </Typography>
              <Typography
                className={classNames(classes.tableRowItem, classes.textRight)}
              >
                {item?.series_2014_full_faith_and_credit_refunding_bonds
                  ?.interest
                  ? new Intl.NumberFormat(undefined, {
                      style: 'currency',
                      currency: currencyCode,
                      maximumFractionDigits: 0
                    }).format(
                      Math.floor(
                        item?.series_2014_full_faith_and_credit_refunding_bonds
                          ?.interest
                      )
                    )
                  : '-'}
              </Typography>
            </Box>
            <Box className={classes.tableRowItem}>
              <Typography className={classes.tableRowItem}>
                {item?.ifa_final_decision_loan?.principal
                  ? new Intl.NumberFormat(undefined, {
                      style: 'currency',
                      currency: currencyCode,
                      maximumFractionDigits: 0
                    }).format(
                      Math.floor(item.ifa_final_decision_loan.principal)
                    )
                  : '-'}
              </Typography>
              <Typography
                className={classNames(classes.tableRowItem, classes.textRight)}
              >
                {item?.ifa_final_decision_loan?.interest
                  ? new Intl.NumberFormat(undefined, {
                      style: 'currency',
                      currency: currencyCode,
                      maximumFractionDigits: 0
                    }).format(
                      Math.floor(item?.ifa_final_decision_loan?.interest)
                    )
                  : '-'}
              </Typography>
            </Box>
            <Box className={classes.tableRowItem}>
              <Typography className={classes.tableRowItem}>
                {item
                  ?.ifa_6th_street_waterline_improvement_project_refunding_note
                  ?.principal
                  ? new Intl.NumberFormat(undefined, {
                      style: 'currency',
                      currency: currencyCode,
                      maximumFractionDigits: 0
                    }).format(
                      Math.floor(
                        item
                          ?.ifa_6th_street_waterline_improvement_project_refunding_note
                          ?.principal
                      )
                    )
                  : '-'}
              </Typography>
              <Typography
                className={classNames(classes.tableRowItem, classes.textRight)}
              >
                {item
                  ?.ifa_6th_street_waterline_improvement_project_refunding_note
                  ?.interest
                  ? new Intl.NumberFormat(undefined, {
                      style: 'currency',
                      currency: currencyCode,
                      maximumFractionDigits: 0
                    }).format(
                      Math.floor(
                        item
                          ?.ifa_6th_street_waterline_improvement_project_refunding_note
                          ?.interest
                      )
                    )
                  : '-'}
              </Typography>
            </Box>
          </Box>
        ))}
        <Box className={classes.tableRow}>
          <Typography
            className={classes.tableRowItem}
            sx={{
              fontWeight: 'bold',
              color: '#999999',
              textAlign: 'right',
              justifyContent: 'end'
            }}
          >
            Total&nbsp;&nbsp;&nbsp;
          </Typography>
          <Box className={classes.tableRowItem} sx={{ fontWeight: 'bold' }}>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText2)}
            >
              {data?.details
                ?.reduce(
                  (sum, item) =>
                    sum +
                    (item?.series_2014_full_faith_and_credit_refunding_bonds
                      ?.principal ?? 0),
                  0
                )
                ?.toLocaleString(undefined, {
                  style: 'currency',
                  currency: currencyCode
                }) ?? '-'}
            </Typography>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText2)}
            >
              {data?.details
                ?.reduce(
                  (sum, item) =>
                    sum +
                    (item?.series_2014_full_faith_and_credit_refunding_bonds
                      ?.interest ?? 0),
                  0
                )
                ?.toLocaleString(undefined, {
                  style: 'currency',
                  currency: currencyCode
                }) ?? '-'}
            </Typography>
          </Box>
          <Box className={classes.tableRowItem} sx={{ fontWeight: 'bold' }}>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText2)}
            >
              {data?.details
                ?.reduce(
                  (sum, item) =>
                    sum + (item?.ifa_final_decision_loan?.principal ?? 0),
                  0
                )
                ?.toLocaleString(undefined, {
                  style: 'currency',
                  currency: currencyCode
                }) ?? '-'}
            </Typography>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText2)}
            >
              {data?.details
                ?.reduce(
                  (sum, item) =>
                    sum + (item?.ifa_final_decision_loan?.interest ?? 0),
                  0
                )
                ?.toLocaleString(undefined, {
                  style: 'currency',
                  currency: currencyCode
                }) ?? '-'}
            </Typography>
          </Box>
          <Box className={classes.tableRowItem} sx={{ fontWeight: 'bold' }}>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText2)}
            >
              {data?.details
                ?.reduce(
                  (sum, item) =>
                    sum +
                    (item
                      ?.ifa_6th_street_waterline_improvement_project_refunding_note
                      ?.principal ?? 0),
                  0
                )
                ?.toLocaleString(undefined, {
                  style: 'currency',
                  currency: currencyCode
                }) ?? '-'}
            </Typography>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText2)}
            >
              {data?.details
                ?.reduce(
                  (sum, item) =>
                    sum +
                    (item
                      ?.ifa_6th_street_waterline_improvement_project_refunding_note
                      ?.interest ?? 0),
                  0
                )
                ?.toLocaleString(undefined, {
                  style: 'currency',
                  currency: currencyCode
                }) ?? '-'}
            </Typography>
          </Box>
        </Box>
        {data?.due?.map((item, index) => (
          <Box
            className={classes.tableRow}
            key={index}
            sx={{ border: 'none !important' }}
          >
            <Typography className={classes.tableRowItem}>
              {item?.year}
            </Typography>
            <Box className={classes.tableRowItem}>
              <Typography className={classes.tableRowItem}>
                {item?.series_2014_full_faith_and_credit_refunding_bonds?.principal?.toLocaleString(
                  undefined,
                  {
                    style: 'currency',
                    currency: currencyCode
                  }
                ) ?? '-'}
              </Typography>
              <Typography
                className={classNames(classes.tableRowItem, classes.textRight)}
              >
                {item?.series_2014_full_faith_and_credit_refunding_bonds?.interest?.toLocaleString(
                  undefined,
                  {
                    style: 'currency',
                    currency: currencyCode
                  }
                ) ?? '-'}
              </Typography>
            </Box>
            <Box className={classes.tableRowItem}>
              <Typography className={classes.tableRowItem}>
                {item?.ifa_final_decision_loan?.principal?.toLocaleString(
                  undefined,
                  {
                    style: 'currency',
                    currency: currencyCode
                  }
                ) ?? '-'}
              </Typography>
              <Typography
                className={classNames(classes.tableRowItem, classes.textRight)}
              >
                {item?.ifa_final_decision_loan?.interest?.toLocaleString(
                  undefined,
                  {
                    style: 'currency',
                    currency: currencyCode
                  }
                ) ?? '-'}
              </Typography>
            </Box>
            <Box className={classes.tableRowItem}>
              <Typography className={classes.tableRowItem}>
                {item?.ifa_6th_street_waterline_improvement_project_refunding_note?.principal?.toLocaleString(
                  undefined,
                  {
                    style: 'currency',
                    currency: currencyCode
                  }
                ) ?? '-'}
              </Typography>
              <Typography
                className={classNames(classes.tableRowItem, classes.textRight)}
              >
                {item?.ifa_6th_street_waterline_improvement_project_refunding_note?.interest?.toLocaleString(
                  undefined,
                  {
                    style: 'currency',
                    currency: currencyCode
                  }
                ) ?? '-'}
              </Typography>
            </Box>
          </Box>
        ))}
        <Box className={classes.tableRow} sx={{ border: 'none !important' }}>
          <Typography
            className={classes.tableRowItem}
            sx={{
              fontWeight: 'bold',
              color: '#999999',
              textAlign: 'right',
              justifyContent: 'end'
            }}
          >
            Total&nbsp;&nbsp;&nbsp;
          </Typography>
          <Box className={classes.tableRowItem} sx={{ fontWeight: 'bold' }}>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText2)}
            >
              {data?.details
                ?.reduce(
                  (sum, item) =>
                    sum +
                    (item?.series_2014_full_faith_and_credit_refunding_bonds
                      ?.principal ?? 0),
                  0
                )
                ?.toLocaleString(undefined, {
                  style: 'currency',
                  currency: currencyCode
                }) ?? '-'}
            </Typography>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText2)}
            >
              {data?.details
                ?.reduce(
                  (sum, item) =>
                    sum +
                    (item?.series_2014_full_faith_and_credit_refunding_bonds
                      ?.interest ?? 0),
                  0
                )
                ?.toLocaleString(undefined, {
                  style: 'currency',
                  currency: currencyCode
                }) ?? '-'}
            </Typography>
          </Box>
          <Box className={classes.tableRowItem} sx={{ fontWeight: 'bold' }}>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText2)}
            >
              {data?.details
                ?.reduce(
                  (sum, item) =>
                    sum + (item?.ifa_final_decision_loan?.principal ?? 0),
                  0
                )
                ?.toLocaleString(undefined, {
                  style: 'currency',
                  currency: currencyCode
                }) ?? '-'}
            </Typography>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText2)}
            >
              {data?.due
                ?.reduce(
                  (sum, item) =>
                    sum + (item?.ifa_final_decision_loan?.interest ?? 0),
                  0
                )
                ?.toLocaleString(undefined, {
                  style: 'currency',
                  currency: currencyCode
                }) ?? '-'}
            </Typography>
          </Box>
          <Box className={classes.tableRowItem} sx={{ fontWeight: 'bold' }}>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText2)}
            >
              {data?.due
                ?.reduce(
                  (sum, item) =>
                    sum +
                    (item
                      ?.ifa_6th_street_waterline_improvement_project_refunding_note
                      ?.principal ?? 0),
                  0
                )
                ?.toLocaleString(undefined, {
                  style: 'currency',
                  currency: currencyCode
                }) ?? '-'}
            </Typography>
            <Typography
              className={classNames(classes.tableRowItem, classes.boldText2)}
            >
              {data?.due
                ?.reduce(
                  (sum, item) =>
                    sum +
                    (item
                      ?.ifa_6th_street_waterline_improvement_project_refunding_note
                      ?.interest ?? 0),
                  0
                )
                ?.toLocaleString(undefined, {
                  style: 'currency',
                  currency: currencyCode
                }) ?? '-'}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Grid item xs={12} sm={12}>
      <Paper className={classes.paper} elevation={1}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {title}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
          <Typography sx={{ textAlign: 'center', fontSize: '14px' }}>
            Enterprise Business Type
          </Typography>
          <Box></Box>
        </Box>
        <Box className={classes.box}>
          <Box className={classes.boxItem}>{renderTable()}</Box>
        </Box>
      </Paper>
    </Grid>
  )
}

export default DebtServiceSchedule
