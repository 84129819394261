import React, { useEffect, useState, useRef } from 'react'
import { Paper, Typography, CircularProgress, Box, FormControl, Select, MenuItem, SelectChangeEvent, Grid } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'

import { getPaymentTypes } from '../../rawApi'
import { Download } from './Download'
import { SelectionConstraints } from '../../rawApi'
import { getUsdRate, getBaseCurrency } from '../../selectors'
import { useSelector } from 'react-redux'
import { RegionTotal } from '../../reducers/constraints'
import Chart from "react-apexcharts"

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary
    },
    progress: {
      marginLeft: '10px'
    },
    tooltip: {
      marginTop: '10px',
      marginLeft: '-20px',
      width: 'auto',
      whiteSpace: 'nowrap',
      background: '#000',
      opacity: 0.7,
      color: '#fff',
      borderRadius: '2px',
      boxShadow: '1px 1px 5px 0 rgba(0,0,0,0.4)',
      fontSize: '14px',
      padding: '3px 5px'
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    formControl: {
      margin: theme.spacing(1),
    },
    legend: {
      display: 'flex',
      justifyContent: 'center',
      '&:last-child': {
        marginBottom: '20px'
      }
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '10px',
      '&:last-child': {
        marginRight: '0px'
      }
    },
    legendMarker: {
      width: '10px',
      height: '10px',
      background: 'red',
      borderRadius: '100%',
      marginRight: '5px'
    },
    legendText: {
      fontSize: '13px',
      margin: '0px',
      color: 'rgb(53,53,53)'
    }
  })
)

interface PaymentTypeBucket {
  '1': Value
  key: string
  doc_count: number
}

interface Value {
  value: number
}

interface PaymentTypeItem {
  gross: number
  name: string
}
interface Data {
  arr: PaymentTypeItem[]
  total: number
}

interface PaymentTypesProps extends SelectionConstraints {
  token: string
  region: RegionTotal | null
  title: string
}

export const PaymentTypesAggregatedRechart = ({
  country,
  merchant,
  range,
  region,
  token,
  title,
  tenant_id,
  countries,
  merchantIds
}: PaymentTypesProps): JSX.Element => {
  const classes = useStyles()
  const [data, setData] = useState<Data>({ arr: [], total: 0 })
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState('Line')
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})
  const getData = getPaymentTypes(token)
  const USDRate = useSelector(getUsdRate) ? useSelector(getUsdRate) : 1
  const baseCurrency = useSelector(getBaseCurrency)
  let name = 'Global'

  if (merchant) {
    name = country + ' / ' + merchant
  } else if (country) {
    name = country || ''
  }
  const currencyCode =
    name === 'Global' ? baseCurrency : (region && region.currency) || 'USD'

  let transformRate = USDRate ? USDRate : 1

  const types = [
    'Line',
    'Bar',
    'Area'
  ]
  
  const setDefaultOptions = (mapped: PaymentTypeItem[]) => {
    setOptions({
      ...options,
      chart: { 
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      tooltip: {
        y: {
          formatter: (val: number) =>  {
            return val.toLocaleString('en-US', {
              style: 'currency',
              currency: currencyCode
            })
          }
        }
      },
      xaxis: {
        categories: mapped && mapped.length > 0 ? mapped.map((i: PaymentTypeItem) => i.name): []
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            //@ts-ignore
            return Intl.NumberFormat('en-US', {
              //@ts-ignore
              notation: 'compact',
              compactDisplay: 'short',
            }).format(val)
          }
        }
      },
      fill: {
        type: 'solid'
      }
    })
  }

  useEffect((): void => {
    // for the case of global chart, need to wait for currencies data
    if (((!countries || !merchantIds) && !USDRate) || !token) return

    const fetchData = async (): Promise<void> => {
      setIsLoading(true)
      const result = await getData({ range, country, merchant, tenant_id, countries, merchantIds })
      if (result && result.length) {
        let total = 0
        const mapped = result.map(
          (item: PaymentTypeBucket): PaymentTypeItem => {
            const gross = item[1].value * transformRate
            total += gross
            return {
              name: item.key,
              gross
            }
          }
        )

        setDefaultOptions(mapped)

        setSeries([
          {
            name: name,
            data: mapped.map((i: PaymentTypeItem) => i.gross)
          }
        ])

        setData({ ...data, arr: mapped, total })
      }
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, token, USDRate, countries, merchantIds])

  useEffect(() => {
    switch(type) {
      case 'Line':
        setOptions({
          ...options, 
            chart: { 
              type: 'line',
            },
        })
      break
      case 'Bar':
        setOptions({
          ...options, 
            chart: { 
              type: 'bar',
            },
        })
      break
      case 'Area':
        setOptions({
          ...options, 
            chart: { 
              type: 'area',
            },
        })
      break
    }
  }, [type])

  const typeChange = (event: SelectChangeEvent): void => {
    setType(event.target.value as string)
  }

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {title ? title : `Payment Types Aggregated ${name}`}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={type}
              onChange={typeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {types.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        <Chart
          options={options}
          series={series}
          type={type === 'Line' ? 'line' : type === 'Bar' ? 'bar' : 'area'}
          height="400"
        />      
        <Download data={data.arr} />
      </Paper>
    </Grid>
  )
}
