import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import MaterialTable, { Column } from 'material-table'
import { Grid } from '@mui/material'
import { StyleRules, createStyles, makeStyles } from '@mui/styles'

import { Download } from './Download'
import { State } from '../../reducers'
import { getBankableByPaymentType } from '../../rawApi'
import {
  getCountries,
  Country,
  TransitDelay as CountryTransitDelay
} from '../../apis/GetCountries'
import { currencies } from '../../temp/currencies_map'
import { BC } from '../BCTooltip'
import { chooseRegion } from '../../utils'
import { Transactions } from './Transactions'
import { getUsdRate, getBaseCurrency } from '../../selectors'
import { RegionTotal } from '../../reducers/constraints'
import { createSelector } from 'reselect'
import moment from 'moment'
import { getTenantType } from '../../api'

const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      row: {
        flexGrow: 1,
        backgroundColor: '#f1f1f1',
        display: 'flex',
        height: 224
      }
    })
)

interface TransitDelaysProps {
  token: string
  tenant_id: string | null
  country: string
  merchant: string
  merchantIds: string[] | undefined
  region: RegionTotal | null
  tenants: any[]
}

const mapStateToProps = createSelector(
  (state: State): TransitDelaysProps['token'] => state.userState.token || '',
  (state: State): TransitDelaysProps['tenant_id'] => state.userState.tenant_id,
  (state: State): TransitDelaysProps['country'] =>
    state.mapState.position.country || '',
  (state: State): TransitDelaysProps['merchant'] =>
    state.mapState.position.merchantId || '',
  (state: State): TransitDelaysProps['merchantIds'] =>
    state.mapState.position.merchantIds,
  (state: State): TransitDelaysProps['region'] =>
    (state.mapState.position.country &&
      chooseRegion(
        state.constraintsState.regions,
        state.mapState.position.country
      )) ||
    null,
  (state: State): TransitDelaysProps['tenants'] => state.userState.tenants,
  (
    token,
    tenant_id,
    country,
    merchant,
    merchantIds,
    region,
    tenants
  ): TransitDelaysProps => ({
    token,
    tenant_id,
    country,
    merchant,
    merchantIds,
    region,
    tenants
  })
)

interface Row {
  type: string
  hours: string
  count: number
  gross: number
}

const renderAmount = (currencyCode: string): React.FC =>
  function renderAmountNext(data: any): JSX.Element {
    return (
      <BC value={data.gross} currency={currencyCode}>
        <span>
          {(data.gross as number).toLocaleString(undefined, {
            style: 'currency',
            currency: currencyCode
          })}
        </span>
      </BC>
    )
  }
interface TDState {
  data: Row[]
  txnIdsMap: { [type: string]: string[] }
}
export const TransitDelays: React.FC = ({ title }: any): JSX.Element | null => {
  const classes = useStyles()
  const { token, region, tenants, ...sProps } = useSelector(mapStateToProps)
  const [state, setState] = useState<TDState>({ data: [], txnIdsMap: {} })
  const [pending, setPending] = useState<boolean>(false)
  const [tenantType, setTenantType] = useState('')

  let name = 'Global'
  if (sProps.merchant) {
    name = sProps.country + ' / ' + sProps.merchant
  } else if (sProps.country) {
    name = sProps.country
  }

  const country = region && region.country ? region.country : undefined

  const USDRate = useSelector(getUsdRate)
  const baseCurrency = useSelector(getBaseCurrency)

  const currencyCode =
    name === 'Global' ? baseCurrency : (region && region.currency) || 'USD'
  const currencySymbol = currencies[currencyCode as string].symbol
  let transformRate = USDRate ? USDRate : 1

  useEffect((): void => {
    if (!token) return
    const callTenantTypeByTenantId = async (): Promise<any> => {
      const tenantTypeId = tenants.filter(
        item => item.id == sProps.tenant_id
      )?.[0].tenantTypeId
      const result: any = await getTenantType({ token, tenantTypeId })
      setTenantType(result?.name ?? 'Corp')
    }
    callTenantTypeByTenantId()
  }, [token, sProps.tenant_id])

  useEffect((): void => {
    if (!token) return
    ;(async (): Promise<void> => {
      setPending(true)
      const countries: Country[] | null = await getCountries(
        token,
        sProps.tenant_id
      )
      const globalDelays: {
        [name: string]: { max: number; min: number }
      } = {}
      if (!countries) {
        return
      } else if (!sProps.country) {
        countries.forEach((country): void => {
          country.transitDelays.forEach((delay: CountryTransitDelay): void => {
            const pT: string = delay.paymentTypeId
            if (!globalDelays[pT]) {
              globalDelays[pT] = {
                max: delay.delay || 0,
                min: delay.delay
              }
            } else {
              if (globalDelays[pT].max < delay.delay)
                globalDelays[pT].max = delay.delay
              if (globalDelays[pT].min > delay.delay)
                globalDelays[pT].min = delay.delay
            }
          })
        })
      }
      const paymentTypes = await getBankableByPaymentType(token, sProps)
      if (paymentTypes) {
        const data = paymentTypes.aggregations.byType.buckets.map(
          (value): Row => {
            let hours = '0'
            if (sProps.country) {
              const country = countries.find(
                (val): boolean => val.name === sProps.country
              )
              if (country) {
                const delay = country.transitDelays.find(
                  (val): boolean => val.paymentTypeId === value.key
                )
                if (delay) hours = '' + delay.delay
              }
            } else {
              hours =
                globalDelays[value.key].min !== globalDelays[value.key].max
                  ? `${globalDelays[value.key].min}-${
                      globalDelays[value.key].max
                    }`
                  : '' + globalDelays[value.key].max
            }
            return {
              type: value.key,
              count: value.txn_count.value,
              gross: value.gross.value * transformRate,
              hours
            }
          }
        )
        const txnIdsMap: TDState['txnIdsMap'] = {}
        data.forEach((row): void => {
          const hits = paymentTypes.hits.hits
            .filter((item): boolean => {
              return item._source.payment_type === row.type
            })
            .reduce<string[]>((prev, current): string[] => {
              let tmpStr: string[] = []
              current._source.txn_ids.map(item => {
                tmpStr = tmpStr.concat(item.split(','))
              })
              return prev.concat(tmpStr)
            }, [])
          txnIdsMap[row.type] = hits
        })
        setState({ data, txnIdsMap })
        setPending(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, sProps.country, sProps.merchant, sProps.merchantIds])

  const columns: Column<any>[] =
    tenantType == 'Bank'
      ? [
          {
            title: 'Payment Type',
            field: 'type'
          },
          {
            title: 'Transactions Count',
            field: 'count'
          },
          {
            title: 'Amount',
            field: 'gross',
            render: renderAmount(currencyCode)
          }
        ]
      : [
          {
            title: 'Payment Type',
            field: 'type'
          },
          {
            title: 'Hours',
            field: 'hours'
          },
          {
            title: 'Transactions Count',
            field: 'count'
          },
          {
            title: 'Amount',
            field: 'gross',
            render: renderAmount(currencyCode)
          }
        ]

  return (
    <Grid item xs={12} sm={8}>
      <MaterialTable
        isLoading={pending}
        columns={columns}
        data={state.data}
        title={title ? title : `${name} Non Reconciled Cash`}
        options={{
          paging: false,
          searchFieldAlignment: 'right'
        }}
        detailPanel={
          sProps.merchantIds && sProps.merchantIds.length == 1
            ? function renderDetails({ type }: { type: string }): JSX.Element {
                return <Transactions txnIds={state.txnIdsMap[type]} />
              }
            : undefined
        }
      />
      {tenantType == 'Bank' ? (
        <Download
          data={state.data.map(({ type, count, gross }): any => ({
            type,
            count,
            gross
          }))}
        />
      ) : (
        <Download
          data={state.data.map(
            ({ type, hours, count, gross }): Row => ({
              type,
              hours,
              count,
              gross
            })
          )}
        />
      )}
    </Grid>
  )
}
