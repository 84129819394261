import React, { useState, useEffect } from 'react'
import {
  Paper,
  Typography,
  FormControl,
  TextField,
  Grid,
  Button,
  MenuItem,
  Alert
} from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutlined';
import { Theme } from '@mui/material/styles'
import { makeStyles, StyleRules } from '@mui/styles'
import { getAllSkus } from '../../api';
import { State } from '../../reducers'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    formItem: {
      width: '100%',
      marginBottom: '20px'
    },
    textCenter: {
      textAlign: 'center',
      color: 'black'
    },
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary
    },
    w50: {
      width: '50%'
    }
  })
)
const classifications = [
  {
    value: 'Breakfast',
    label: 'Breakfast'
  },
  {
    value: 'Health & Wellness',
    label: 'Health'
  },
  {
    value: 'Snacks',
    label: 'Snacks'
  },
  {
    value: 'Fluids',
    label: 'Fluids'
  },
  {
    value: 'Meals',
    label: 'Meals'
  },
  {
    value: 'Custom',
    label: 'Custom'
  }
]
const subClassifications = [
  {
    value: 'Retail',
    label: 'Breakfast'
  },
  {
    value: 'Wholesale',
    label: 'Wholesale'
  },
  {
    value: 'Specialty',
    label: 'Specialty'
  },
  {
    value: 'Custom',
    label: 'Custom'
  }
]
const specialtyTax = [
  {
    value: 'Supplier',
    label: 'Supplier'
  },
  {
    value: 'Local',
    label: 'Local'
  },
  {
    value: 'City',
    label: 'City'
  },
  {
    value: 'Government',
    label: 'Government'
  },
  {
    value: 'Product',
    label: 'Product'
  },
  {
    value: 'Export',
    label: 'Export'
  },
  {
    value: 'Import',
    label: 'Import'
  }
]
const payPlan = [
  {
    value: 'Net 30',
    label: 'Net 30'
  },
  {
    value: 'Net Sale +10',
    label: 'Net Sale +10'
  },
  {
    value: 'Budget +15',
    label: 'Budget +15'
  },
  {
    value: 'Custom',
    label: 'Custom'
  }
]
const accountingTreatment = [
  {
    value: 'Standard Ledger',
    label: 'Standard Ledger'
  },
  {
    value: 'Ledger x101',
    label: 'Ledger x101'
  },
  {
    value: 'Ledger Exclude 102',
    label: 'Ledger Exclude 102'
  },
  {
    value: 'Currency Pair Ledger 501',
    label: 'Currency Pair Ledger 501'
  },
  {
    value: 'Custom',
    label: 'Custom'
  }
]

interface AddProductProps {
  token: string | null,
  tenant_id: string | null
}

const mapStateToProps = createSelector(
  (state: State): AddProductProps['token'] => state.userState.token,
  (state: State): AddProductProps['tenant_id'] => state.userState.tenant_id,
  (
    token,
    tenant_id
  ): AddProductProps => ({
    token,
    tenant_id
  })
)

export const AddProduct = ({title}: any) => {
  const { token, tenant_id } = useSelector(mapStateToProps)
  const classes = useStyles()
  const [dataObj, setDataObj] = useState({})
  const [skus, setSkus] = useState<any>([])

  useEffect((): void => {
    if(!token) return;
    const callSkus = async (): Promise<any> => {
      const result: any = await getAllSkus({token, tenant_id})
      setSkus(result);
    }
    callSkus()
  }, [token])

  const handleChange = (name: any, event: React.ChangeEvent<HTMLInputElement>) => {
    setDataObj({ ...dataObj, [name]: event.target.value })
  }

  const [isShowMessage, setIsShowMessage] = useState(false)
  const onHandleSubmit = (): void => {
    if (Object.keys(dataObj).length > 0) {
      setIsShowMessage(true)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setIsShowMessage(false)
    }, 2000)
  }, [isShowMessage])

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper}>
        {isShowMessage && (
          <Alert
            iconMapping={{
              success: <CheckCircleOutlineIcon fontSize="inherit" />
            }}
          >
            Product Added!
          </Alert>
        )}
        <Grid container justifyContent="space-around">
          <Grid item xs={12}>
            <Typography
              className={classes.textCenter}
              variant="subtitle2"
              component="h3"
              sx={{marginBottom: '10px'}}
            >
              {title ? title : 'Add Product'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <FormControl className={classes.formItem}>
              <TextField
                id="brand-input"
                name="brand"
                label="Brand"
                type="text"
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange('brand', value)}
              />
            </FormControl>

            <FormControl className={classes.formItem}>
              {/* <FormLabel>Gender</FormLabel> */}
              <TextField
                id="product-input"
                name="product_name"
                label="Product Name"
                type="text"
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange('product_name', value)}
                //   value={formValues.age}
                //   onChange={handleInputChange}
              />
            </FormControl>

            <FormControl className={classes.formItem}>
              {/* <FormLabel>Gender</FormLabel> */}
              {/* <TextField
                id="sku-input"
                name="sku"
                label="SKU"
                type="text"
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange('sku', value)}
              /> */}
              <TextField
                id="outlined-select-currency"
                select
                label="SKU"
                defaultValue=""
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange('sku', value)}
              >
                {skus.map((option: any) => (
                  <MenuItem key={option.id} value={option.sku}>
                    {option.sku}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <FormControl className={classes.formItem}>
              <TextField
                id="outlined-select-currency"
                select
                label="Classification"
                defaultValue=""
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange('classification', value)}
              >
                {classifications.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <FormControl className={classes.formItem}>
              <TextField
                id="outlined-select-currency"
                select
                label="Sub Classification"
                defaultValue=""
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange('sub_classification', value)}
              >
                {subClassifications.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl className={classes.formItem}>
              {/* <FormLabel>Gender</FormLabel> */}
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange('description', value)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5}>
            <FormControl className={classes.formItem}>
              {/* <FormLabel>Gender</FormLabel> */}
              <TextField
                id="sku-input"
                name="supplier"
                label="Supplier"
                type="text"
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange('supplier', value)}
              />
            </FormControl>

            <FormControl className={classes.formItem}>
              {/* <FormLabel>Gender</FormLabel> */}
              <TextField
                id="sku-input"
                name="account"
                label="Account"
                type="text"
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange('account', value)}
              />
            </FormControl>
            <FormControl className={classes.formItem}>
              <TextField
                id="outlined-select-currency"
                select
                label="Specialty Tax"
                defaultValue=""
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange('specialty_Tax', value)}
              >
                {specialtyTax.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl className={classes.formItem}>
              <TextField
                id="outlined-select-currency"
                select
                label="Pay Plan"
                defaultValue=""
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange('pay_plan', value)}
              >
                {payPlan.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl className={classes.formItem}>
              {/* <FormLabel>Gender</FormLabel> */}
              <TextField
                id="sku-input"
                name="cost"
                label="Cost"
                type="text"
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange('cost', value)}
              />
            </FormControl>

            <FormControl className={classes.formItem}>
              {/* <FormLabel>Gender</FormLabel> */}
              <TextField
                id="sku-input"
                name="price"
                label="Breakeven Price"
                type="text"
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange('price', value)}
              />
            </FormControl>

            <FormControl className={classes.formItem}>
              <TextField
                id="outlined-select-currency"
                select
                label="Accounting Treatment"
                defaultValue=""
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange('accounting_treatment', value)}
              >
                {accountingTreatment.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            <FormControl className={classes.formItem}>
              <TextField
                name="upload-photo"
                type="file"
                helperText="Import CSV or JSON"
              />
            </FormControl>
            <FormControl className={classes.w50}>
              <Button
                variant="contained"
                color="primary"
                sx={{color:'white'}}
                onClick={() => onHandleSubmit()}
              >
                Add
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}