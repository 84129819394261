import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  Paper,
  Typography,
  CircularProgress,
  Select,
  Box,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Grid
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'
import { getMerchant, chooseRegion } from '../../utils'
import { State } from '../../reducers'
import { SelectionConstraints, getSalesVolumeWeekly } from '../../rawApi'
import { getUsdRate, getBaseCurrency } from '../../selectors'
import { DateTime } from 'luxon'
import { Download } from './Download'
import {
  last,
  getPredictions,
  convertDateToTimestamp,
  linearRegression
} from '../../common/forecast/forecastManager'
import Chart from 'react-apexcharts'
import { createSelector } from 'reselect'
import { BaseRates, RegionTotal } from '../../reducers/constraints'

interface DataType {
  date: Date
  value: number
}

interface DownloadType {
  date: Date
  revenue: number
  expenditure: number
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      marginTop: '5px',
      marginBottom: '10px'
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    caption: {
      textAlign: 'center',
      color: theme.palette.text.primary
    },
    formControl: {
      margin: theme.spacing(1)
    },
    legend: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px'
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '10px',
      '&:last-child': {
        marginRight: '0px'
      }
    },
    legendMarker: {
      width: '10px',
      height: '10px',
      background: 'red',
      borderRadius: '100%',
      marginRight: '5px'
    },
    legendText: {
      fontSize: '13px',
      margin: '0px',
      color: 'rgb(53,53,53)'
    }
  })
)

interface LiquidityForecastProps {
  region: RegionTotal | null
  merchant: any
  token: string
  tenant_id: string | null
  datesRange: { startDate: Date; endDate: Date }
  rates: BaseRates
  countries: string[] | undefined
  merchantIds: string[] | undefined
}

const mapStateToProps = createSelector(
  (state: State): LiquidityForecastProps['region'] =>
    (state.mapState.position.country &&
      chooseRegion(
        state.constraintsState.regions,
        state.mapState.position.country
      )) ||
    null,
  (state: State): LiquidityForecastProps['merchant'] =>
    state.mapState.position.merchantId
      ? getMerchant(state.mapState.geojson, state.mapState.position.merchantId)
      : undefined,
  (state: State): LiquidityForecastProps['token'] =>
    state.userState.token || '',
  (state: State): LiquidityForecastProps['tenant_id'] =>
    state.userState.tenant_id,
  (state: State): LiquidityForecastProps['datesRange']['startDate'] =>
    state.constraintsState.startDate,
  (state: State): LiquidityForecastProps['datesRange']['endDate'] =>
    state.constraintsState.endDate,
  (state: State): LiquidityForecastProps['rates'] =>
    state.constraintsState.baseRates,
  (state: State): LiquidityForecastProps['countries'] =>
    state.mapState.position.countries,
  (state: State): LiquidityForecastProps['merchantIds'] =>
    state.mapState.position.merchantIds,
  (
    region,
    merchant,
    token,
    tenant_id,
    startDate,
    endDate,
    rates,
    countries,
    merchantIds
  ): LiquidityForecastProps => ({
    region,
    merchant,
    token,
    tenant_id,
    datesRange: { startDate, endDate },
    rates,
    countries,
    merchantIds
  })
)

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const LiquidityForecast = ({ title }: any): JSX.Element => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState('Line')
  const [timelineType, setTimelineType] = useState('Monthly')
  const [dailyRevenueData, setDailyRevenueData] = useState<DataType[]>([])
  const [dailyExpenditureData, setDailyExpenditureData] = useState<DataType[]>(
    []
  )
  const [weeklyRevenueData, setWeeklyRevenueData] = useState<DataType[]>([])
  const [weeklyExpenditureData, setWeeklyExpenditureData] = useState<
    DataType[]
  >([])
  const [monthlyRevenueData, setMonthlyRevenueData] = useState<DataType[]>([])
  const [monthlyExpenditureData, setMonthlyExpenditureData] = useState<
    DataType[]
  >([])
  const [dailyDownloadData, setDailyDownData] = useState<DownloadType[]>([])
  const [weeklyDownloadData, setWeeklyDownData] = useState<DownloadType[]>([])
  const [monthlyDownloadData, setMonthlyDownData] = useState<DownloadType[]>([])
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})
  const {
    region,
    merchant,
    token,
    datesRange,
    rates,
    tenant_id,
    countries,
    merchantIds
  } = useSelector(mapStateToProps)
  const merchantId = merchant ? merchant.merchant_id : undefined
  const country = region && region.country ? region.country : undefined

  let date = new Date(datesRange.endDate)
  const endDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - 1
  )
  const oneDay = 24 * 60 * 60 * 1000
  const forecastDays = 30
  const startDate = DateTime.fromJSDate(datesRange.endDate)
    .minus({ days: forecastDays * 2 })
    .toJSDate()

  const cProps: SelectionConstraints = {
    tenant_id,
    country,
    merchant: merchantId,
    range: {
      startDate,
      endDate
    },
    countries,
    merchantIds
  }

  const USDRate = useSelector(getUsdRate)
  const baseCurrency = useSelector(getBaseCurrency)

  let name = 'Global'

  if (merchantId) {
    name = country + ' / ' + merchantId
  } else if (country) {
    name = country || ''
  }

  const currencyCode =
    name === 'Global' ? baseCurrency : (region && region.currency) || 'USD'

  let oldCurrency = 0,
    newCurrency = 0
  if (rates.hasOwnProperty(baseCurrency)) {
    oldCurrency = rates[baseCurrency]
  }
  if (rates.hasOwnProperty(currencyCode)) {
    newCurrency = rates[currencyCode]
  }

  let transformRate = newCurrency
    ? oldCurrency / newCurrency
    : USDRate
    ? USDRate
    : 1

  const types = ['Line', 'Bar', 'Area']

  const timelineTypes = ['Daily', 'Weekly', 'Monthly']

  const getMonthlyData = (dailyData: DataType[]): DataType[] => {
    let tmpYear = 0,
      tmpMonth = 0
    let monthlyData: DataType[] = []
    dailyData.map(item => {
      if (
        item.date.getFullYear() !== tmpYear ||
        item.date.getMonth() !== tmpMonth
      ) {
        monthlyData.push({
          date: item.date,
          value: 0
        })
        tmpYear = item.date.getFullYear()
        tmpMonth = item.date.getMonth()
      }
    })

    monthlyData.map(monthlyItem => {
      dailyData.map(newItem => {
        if (
          newItem.date.getFullYear() === monthlyItem.date.getFullYear() &&
          newItem.date.getMonth() === monthlyItem.date.getMonth()
        ) {
          monthlyItem.value += newItem.value
        }
      })
    })
    return monthlyData
  }

  const getWeeklyData = (dailyData: DataType[]): DataType[] => {
    let count = 0,
      tmpValue = 0,
      tmpDate: Date
    let weeklyData: DataType[] = []
    dailyData.map(item => {
      if (count === 0) {
        tmpDate = item.date
      }
      tmpValue += item.value
      count++
      if (count === 7) {
        weeklyData.push({
          date: tmpDate,
          value: tmpValue
        })
        count = 0
        tmpValue = 0
      }
    })
    return weeklyData
  }

  const getPredictedRow = (dataPreparedToPredict: number[]): number[] => {
    const FORECAST_CONFIG = {
      periodSize: forecastDays,
      observationsToForeast: forecastDays - 1
    }

    const predictedRow = last(
      //@ts-ignore
      getPredictions(dataPreparedToPredict, FORECAST_CONFIG),
      FORECAST_CONFIG.observationsToForeast + 1
    )

    return predictedRow
  }

  function generateDates(startDate: string, endDate: string) {
    var start = new Date(startDate)
    var end = new Date(endDate)
    for (var arr = [], dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt).toISOString().slice(0, 10))
    }
    return arr
  }

  useEffect((): void => {
    // for the case of global chart, need to wait for currencies data
    if (((!countries || !merchantIds) && !USDRate) || !token) return

    const fetchData = async (): Promise<any> => {
      setIsLoading(true)
      const result = await getSalesVolumeWeekly(token, cProps)
      if (
        result &&
        cProps.range &&
        cProps.range.startDate &&
        result.aggregations.perDay.buckets.length > 0
      ) {
        let data: DataType[] = []
        let tmpData: any[] = []
        let dates: any[] = []
        let newRevenueData: DataType[] = []
        let newExpenditureData: DataType[] = []
        let newDownloadData: DownloadType[] = []
        let categories: any[] = []

        result.aggregations.perDay.buckets.forEach(
          (item, dayIndex: number): void => {
            let value = 0
            const itemDate = DateTime.fromISO(item.key_as_string).toJSDate()
            for (let h in item.perHour.buckets) {
              value += item.perHour.buckets[h].gross.value * transformRate
            }
            tmpData.push(value)
            dates.push(item.key_as_string)
            if (DateTime.fromJSDate(itemDate).month === DateTime.now().month) {
              newRevenueData.push({
                date: itemDate,
                value: value
              })
              newDownloadData.push({
                date: itemDate,
                revenue: value,
                expenditure: 0
              })
              categories.push(itemDate)
            }
          }
        )

        var allDates = generateDates(dates[0], dates[dates.length - 1])

        var dateToData = dates.reduce(function(map, date, index) {
          map[date.slice(0, 10)] = tmpData[index]
          return map
        }, {})

        // Interpolate data for missing dates
        allDates.forEach(function(date, index) {
          if (!(date in dateToData)) {
            var prevData = null
            for (var i = index - 1; i >= 0; i--) {
              if (allDates[i] in dateToData) {
                prevData = dateToData[allDates[i]]
                break
              }
            }
            var nextData = null
            for (var i = index + 1; i < allDates.length; i++) {
              if (allDates[i] in dateToData) {
                nextData = dateToData[allDates[i]]
                break
              }
            }
            if (prevData !== null && nextData !== null) {
              dateToData[date] = (prevData + nextData) / 2
            } else if (prevData !== null) {
              dateToData[date] = prevData
            } else if (nextData !== null) {
              dateToData[date] = nextData
            }
          }
        })

        // Convert the object to an array of pairs
        let pairs: [string, number][] = Object.keys(dateToData).map(
          (key: string) => {
            return [key, dateToData[key]]
          }
        )

        // Sort the array by the date (which is the first item of each pair)
        pairs.sort((pair1: [string, number], pair2: [string, number]) => {
          return new Date(pair1[0]).getTime() - new Date(pair2[0]).getTime()
        })

        // If you need to convert it back to an object
        let sortedData: { [key: string]: number } = {}
        pairs.forEach((pair: [string, number]) => {
          sortedData[pair[0]] = pair[1]
        })

        // Get the final dates and data
        dates = Object.keys(sortedData)
        data = dates.map(function(date) {
          return { date: date, value: sortedData[date] }
        })

        let timestamps = data.map(i => i.date).map(convertDateToTimestamp)
        let result1 = linearRegression(
          timestamps,
          data.map(i => i.value)
        )

        let date1 = new Date(startDate)
        let date2 = new Date(data[0].date)
        let data1: any[] = []

        if (
          date1.getFullYear() != date2.getFullYear() ||
          date1.getDate() != date2.getDate() ||
          date1.getMonth() != date2.getMonth()
        ) {
          var getDaysArray = function(s: any, e: any) {
            for (
              var a = [], d = new Date(s);
              d <= new Date(e);
              d.setDate(d.getDate() + 1)
            ) {
              a.push(new Date(d))
            }
            return a
          }
          var daylist = getDaysArray(date1, date2)

          daylist.map(date => {
            const futureTimestamp = convertDateToTimestamp(date)
            const prediction =
              result1.slope * futureTimestamp + result1.intercept
            data1.push({
              date: DateTime.fromISO(date.toISOString()).toJSDate(),
              value: prediction
            })
          })
        }

        data = data1.concat(data)

        for (let monthIndex = 0; monthIndex < 3; monthIndex++) {
          let dataPreparedToPredict: number[] = []
          if (newRevenueData.length > 0) {
            if (newRevenueData.length >= forecastDays * 2) {
              dataPreparedToPredict = newRevenueData
                .slice(Math.max(newRevenueData.length - forecastDays * 2, 0))
                .map(i => i.value)
            } else {
              dataPreparedToPredict = data
                .slice(
                  Math.max(
                    data.length - (forecastDays * 2 - newRevenueData.length),
                    0
                  )
                )
                .map(i => i.value)
                .concat(newRevenueData.map(i => i.value))
            }
          } else {
            dataPreparedToPredict = data.map(i => i.value)
          }

          let predicted = false

          const predictedRow = getPredictedRow(dataPreparedToPredict)
          if (predictedRow && predictedRow.length === forecastDays) {
            predicted = true
          }

          for (let i = 0; i < forecastDays; i++) {
            const date = DateTime.now()
              .plus({ days: i + (forecastDays * monthIndex + 1) })
              .toJSDate()
            newRevenueData.push({
              date: date,
              value: predicted === true ? predictedRow[i] : 0
            })
            newDownloadData.push({
              date: date,
              revenue: predicted === true ? predictedRow[i] : 0,
              expenditure: 0
            })
            categories.push(date)
          }
        }

        // calculate expenditure from revenue data like -27%~-37% variant
        newRevenueData.map((item, index) => {
          const _tmpData =
            item.value - (item.value * randomIntFromInterval(27, 37)) / 100
          newExpenditureData.push({
            date: item.date,
            value: _tmpData
          })
          newDownloadData[index].expenditure = _tmpData
        })

        setDailyRevenueData(newRevenueData)
        setDailyExpenditureData(newExpenditureData)
        setDailyDownData(newDownloadData)

        // calculate monthly data
        const newMonthlyRevenueData = getMonthlyData(newRevenueData)
        const newMonthlyExpenditureData = getMonthlyData(newExpenditureData)
        const newMonthlyDownloadData = newMonthlyRevenueData.map(
          (item, index) => {
            return {
              date: item.date,
              revenue: item.value,
              expenditure: newMonthlyExpenditureData[index].value
            }
          }
        )
        setMonthlyRevenueData(newMonthlyRevenueData)
        setMonthlyExpenditureData(newMonthlyExpenditureData)
        setMonthlyDownData(newMonthlyDownloadData)

        // calculate weekly data
        const newWeeklyRevenueData = getWeeklyData(newRevenueData)
        const newWeeklyExpenditureData = getWeeklyData(newExpenditureData)
        const newWeeklyDownloadData = newWeeklyRevenueData.map(
          (item, index) => {
            return {
              date: item.date,
              revenue: item.value,
              expenditure: newWeeklyExpenditureData[index].value
            }
          }
        )

        setWeeklyRevenueData(newWeeklyRevenueData)
        setWeeklyExpenditureData(newWeeklyExpenditureData)
        setWeeklyDownData(newWeeklyDownloadData)

        setDefaultOptions(
          newMonthlyRevenueData.map(i =>
            DateTime.fromJSDate(i.date).toFormat('LLL')
          )
        )
      }
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, token, datesRange, USDRate, countries, merchantIds])

  const setDefaultOptions = (mapped: any[]) => {
    setOptions({
      ...options,
      chart: {
        zoom: { enabled: false },
        toolbar: { show: false }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 1
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      tooltip: {
        y: {
          formatter: (val: number) => {
            return val.toLocaleString('en-US', {
              style: 'currency',
              currency: currencyCode
            })
          },
          title: {
            formatter: (seriesName: string) =>
              seriesName === 'Revenues'
                ? 'Revenue'
                : seriesName === 'Expenditures'
                ? 'Expenditure'
                : seriesName
          }
        }
      },
      xaxis: {
        categories: mapped && mapped.length > 0 ? mapped : [],
        tickAmount: 20
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            //@ts-ignore
            return Intl.NumberFormat('en-US', {
              //@ts-ignore
              notation: 'compact',
              compactDisplay: 'short'
            }).format(val)
          }
        }
      },
      fill: {
        type: 'solid'
      }
    })
  }

  useEffect(() => {
    const revenue =
      timelineType === 'Daily'
        ? dailyRevenueData
        : timelineType === 'Weekly'
        ? weeklyRevenueData
        : monthlyRevenueData
    const expenditure =
      timelineType === 'Daily'
        ? dailyExpenditureData
        : timelineType === 'Weekly'
        ? weeklyExpenditureData
        : monthlyExpenditureData

    if (timelineType === 'Monthly') {
      setDefaultOptions(
        revenue.map(i => DateTime.fromJSDate(i.date).toFormat('LLL'))
      )
    } else {
      setDefaultOptions(
        revenue.map(i => DateTime.fromJSDate(i.date).toFormat('LLL dd'))
      )
    }

    setSeries([
      {
        name: 'Revenues',
        data: revenue.map(i => i.value)
      },
      {
        name: 'Expenditures',
        data: expenditure.map(i => i.value)
      }
    ])
  }, [timelineType, isLoading])

  useEffect(() => {
    switch (type) {
      case 'Line':
        setOptions({
          ...options,
          chart: {
            type: 'line',
            stacked: false
          }
        })
        break
      case 'Bar':
        setOptions({
          ...options,
          chart: {
            type: 'bar',
            stacked: true
          }
        })
        break
      case 'Area':
        setOptions({
          ...options,
          chart: {
            type: 'area'
          }
        })
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isLoading])

  const typeChange = (event: SelectChangeEvent): void => {
    setType(event.target.value as string)
  }

  const timelineTypeChange = (event: SelectChangeEvent): void => {
    setTimelineType(event.target.value as string)
  }

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={timelineType}
              onChange={timelineTypeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {timelineTypes.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {title ? title : 'Liquidity Forecast'}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={type}
              onChange={typeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {types.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        <Chart
          options={options}
          series={series}
          type={type === 'Line' ? 'line' : type === 'Bar' ? 'bar' : 'area'}
          height="400"
        />
        <Download
          data={
            timelineType === 'Daily'
              ? dailyDownloadData
              : timelineType === 'Weekly'
              ? weeklyDownloadData
              : monthlyDownloadData
          }
        />
      </Paper>
    </Grid>
  )
}
