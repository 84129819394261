import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Paper, Typography, CircularProgress } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  DateTime as ej2DateTime,
  Legend,
  Tooltip,
  LineSeries,
  DataLabel
} from '@syncfusion/ej2-react-charts'
import { DateTime } from 'luxon'

import { mapCountryToCurrency } from '../../temp/currencies_map'
import { BaseRates, Compare } from '../../reducers/constraints'
import { SFWrapper } from './SampleBase'
import { State } from '../../reducers'
import { getCompareLocations } from '../../apis/GetCompareLocations'
import { calcToBase } from '../BCTooltip'
import { createSelector } from 'reselect'

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      marginTop: '5px'
      //   minWidth: '400px'
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary,
      paddingBottom: '15px'
    }
  })
)

interface Value {
  value: number
}

interface RFItem {
  key_as_string: string
  key: number
  doc_count: number
  dailySales: Value
  qtyAtStock: Value
}
interface ChartLine {
  date: Date
  value: number
  skuQty: number
}

interface DisplayDataItem {
  line: ChartLine[]
  name: string
}

interface CompareLocationGraphProps {
  token: string
  base: string
  rates: BaseRates
  startDate: Date
  endDate: Date
  compare: Compare
}

const mapState = createSelector(
  (state: State): CompareLocationGraphProps['token'] => state.userState.token || '',
  (state: State): CompareLocationGraphProps['base'] => state.userState.baseCurrency,
  (state: State): CompareLocationGraphProps['rates'] => state.constraintsState.baseRates,
  (state: State): CompareLocationGraphProps['startDate'] => state.constraintsState.startDate,
  (state: State): CompareLocationGraphProps['endDate'] => state.constraintsState.endDate,
  (state: State): CompareLocationGraphProps['compare'] => state.constraintsState.compare,
  (
    token,
    base,
    rates,
    startDate,
    endDate,
    compare
  ): CompareLocationGraphProps => ({
    token,
    base,
    rates,
    startDate,
    endDate,
    compare
  })
)

export const CompareLocationsGraph: React.FC = (): JSX.Element => {
  const classes = useStyles()
  //   const getData = getCompareLocationsGraph(token)
  const fS = useSelector(mapState)
  const [data, setData] = useState<DisplayDataItem[]>([])
  const [isLoading, setIsLoading] = useState(false)
  let name = ''
  if (fS.compare.mode === 'locations' && fS.compare.locations.length > 1) {
    name = `${fS.compare.locations[0].country}${
      fS.compare.locations[0].merchantId
        ? ' / ' + fS.compare.locations[0].merchantId
        : ''
    } vs ${fS.compare.locations[1].country}${
      fS.compare.locations[1].merchantId
        ? ' / ' + fS.compare.locations[1].merchantId
        : ''
    }, ${fS.base}`
  }

  useEffect((): void => {
    const fetchData = async (): Promise<void> => {
      if (fS.compare.mode === 'locations' && fS.compare.locations.length > 1) {
        setIsLoading(true)
        const first = await getCompareLocations(fS.token, {
          country: fS.compare.locations[0].country || '',
          merchant: fS.compare.locations[0].merchantId || '',
          startDate: fS.startDate,
          endDate: fS.endDate
        })
        const second = await getCompareLocations(fS.token, {
          country: fS.compare.locations[1].country || '',
          merchant: fS.compare.locations[1].merchantId || '',
          startDate: fS.startDate,
          endDate: fS.endDate
        })
        const firstP: DisplayDataItem = {
          name: `${fS.compare.locations[0].country}${
            fS.compare.locations[0].merchantId
              ? ' / ' + fS.compare.locations[0].merchantId
              : ''
          }`,
          line: []
        }
        if (first) {
          firstP.line = first.aggregations.byDay.buckets.map(
            (day: any): ChartLine => {
              const res = {
                date: DateTime.fromISO(day.key_as_string).toJSDate(),
                value: +calcToBase({
                  value: day.byCountry.buckets[0].gross.value,
                  currency:
                    mapCountryToCurrency[
                      fS.compare.mode === 'locations' &&
                      fS.compare.locations[0].country
                        ? fS.compare.locations[0].country
                        : ''
                    ] || '',
                  base: fS.base,
                  rates: fS.rates
                }).toFixed(2),
                skuQty: 0
              }
              return res
            }
          )
        }
        //second
        const secondP: DisplayDataItem = {
          name: `${fS.compare.locations[1].country}${
            fS.compare.locations[1].merchantId
              ? ' / ' + fS.compare.locations[1].merchantId
              : ''
          }`,
          line: []
        }
        if (second) {
          secondP.line = second.aggregations.byDay.buckets.map(
            (day: any): ChartLine => ({
              date: DateTime.fromISO(day.key_as_string).toJSDate(),
              value: +calcToBase({
                value: day.byCountry.buckets[0].gross.value,
                currency:
                  mapCountryToCurrency[
                    fS.compare.mode === 'locations' &&
                    fS.compare.locations[1].country
                      ? fS.compare.locations[1].country
                      : ''
                  ] || '',
                base: fS.base,
                rates: fS.rates
              }).toFixed(2),
              skuQty: 0
            })
          )
        }
        setData([firstP, secondP])
        setIsLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Paper className={classes.paper} elevation={1}>
      <Typography
        variant="subtitle2"
        component="h3"
        className={classes.caption}
      >
        Comparing{' ' + name}
        {isLoading && (
          <CircularProgress
            className={classes.progress}
            size={15}
            color={'secondary'}
          />
        )}
      </Typography>
      <SFWrapper refreshOnResize={true}>
        {(setInstance: Function): JSX.Element => (
          <ChartComponent
            ref={(m: any): void => setInstance(m)}
            id="inventory-resupply-forecasting-chart"
            style={{ textAlign: 'center', marginTop: '5px' }}
            height="250px"
            width="100%"
            useGroupingSeparator={true}
            primaryXAxis={{
              valueType: 'DateTime',
              labelFormat: 'MMM d',
              intervalType: 'Days',
              edgeLabelPlacement: 'Shift',
              majorGridLines: { width: 0 }
            }}
            primaryYAxis={{
              labelFormat: 'n2',
              rangePadding: 'Normal',
              lineStyle: { width: 0 },
              majorTickLines: { width: 0 },
              minorTickLines: { width: 0 }
            }}
            chartArea={{ border: { width: 0 } }}
            tooltip={{ enable: true }}
          >
            <SeriesCollectionDirective>
              {data.map(
                (line: DisplayDataItem, index): JSX.Element => (
                  <SeriesDirective
                    key={`irf-line-${index}`}
                    dataSource={line.line}
                    xName="date"
                    yName="value"
                    name={line.name}
                    width={2}
                    marker={{ visible: true, width: 5, height: 5 }}
                    type="Line"
                  ></SeriesDirective>
                )
              )}
            </SeriesCollectionDirective>
            <Inject
              services={[DataLabel, ej2DateTime, Legend, Tooltip, LineSeries]}
            />
          </ChartComponent>
        )}
      </SFWrapper>
      {/* <Download data={data} /> */}
    </Paper>
  )
}
