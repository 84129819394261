import React, { useState, useEffect, useRef } from 'react'
import { Paper, Typography, CircularProgress, Box, FormControl, Select, MenuItem, SelectChangeEvent, Grid } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, makeStyles } from '@mui/styles'

import { Download } from './Download'
import { DateTime } from 'luxon'
import { getProjectedStaffingNeeds } from '../../rawApi'
import Chart from "react-apexcharts"

const useStyles = makeStyles(
  (theme: Theme): StyleRules<{}> => ({
    paper: {
      padding: '10px',
      textAlign: 'center',
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      marginTop: '5px'
      //   minWidth: '400px'
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    caption: {
      textAlign: 'left',
      color: theme.palette.text.primary,
      paddingBottom: '15px'
    },
    formControl: {
      margin: theme.spacing(1),
    },
    legend: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px'
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '10px',
      '&:last-child': {
        marginRight: '0px'
      }
    },
    legendMarker: {
      width: '10px',
      height: '10px',
      background: 'red',
      borderRadius: '100%',
      marginRight: '5px'
    },
    legendText: {
      fontSize: '13px',
      margin: '0px',
      color: 'rgb(53,53,53)'
    }
  })
)

interface TheItem {
  key_as_string: string
  key: number
  doc_count: number
  staffRequired: Value
  staffScheduled: Value
}

interface Value {
  value: number
}

interface DisplayItem {
  value: number
  date: Date
}

interface DisplayData {
  required: DisplayItem[]
  scheduled: DisplayItem[]
}

interface ProjectedStaffingNeedsProps {
  token: string
  merchant: string,
  tenant_id: string | null
  title: string
}

interface CSVRow {
  date: string
  required: number
  scheduled: number
}

const getCSV = (data: DisplayData): CSVRow[] =>
  data.required.map(
    (required: DisplayItem, index: number): CSVRow => ({
      date: DateTime.fromJSDate(required.date).toFormat('MMM dd y'),
      required: required.value,
      scheduled: data.scheduled[index].value
    })
  )

export const ProjectedStaffingNeeds = ({
  token,
  merchant,
  tenant_id,
  title
}: ProjectedStaffingNeedsProps): JSX.Element => {
  const classes = useStyles()
  const getData = getProjectedStaffingNeeds(token)
  const [data, setData] = useState<DisplayData>({ required: [], scheduled: [] })
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState('Line')
  const [series, setSeries] = useState<any[]>([])
  const [options, setOptions] = useState({})

  const types = [
    'Line',
    'Bar',
    'Area'
  ]

  const setDefaultOptions = (mapped: {x: Date, y: number}[]) => {
    setOptions({
      ...options,
      chart: { 
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 1
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      xaxis: {
        categories: mapped && mapped.length > 0 ? mapped: []
      },
      yaxis: {
        labels: {
          //@ts-ignore
          formatter: (val: number) => parseFloat(val).toFixed(0)
        }
      },
      fill: {
        type: 'solid'
      }
    })
  }

  const legendTitles = ['Required', 'Scheduled']
  useEffect((): void => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)
      const result = await getData({
        range: {
          startDate: DateTime.utc()
            .startOf('day')
            .toJSDate(),
          endDate: DateTime.utc()
            .endOf('day')
            .plus({ days: 6 })
            .toJSDate()
        },
        merchant,
        tenant_id
      })
      if (result) {
        const newData: DisplayData = { required: [], scheduled: [] }
        let categories:any[] = []
        result.forEach((item: TheItem, index: number, arr: TheItem[]): void => {
          if (index !== arr.length - 1 || item.staffRequired.value > 0) {
            const date = DateTime.fromISO(item.key_as_string).toJSDate()
            newData.required.push({ value: item.staffRequired.value, date })
            newData.scheduled.push({ value: item.staffScheduled.value, date })
            categories.push(DateTime.fromJSDate(date).toFormat('LLL dd'))
          }
        })

        setDefaultOptions(categories)
        setSeries([
          {
            name: 'Required',
            data: newData.required.map((i: any) => i.value)
          },
          {
            name: 'Scheduled',
            data: newData.scheduled.map((i: any) => i.value)
          }
        ])
        setData(newData)
      }
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, merchant])

  useEffect(() => {
    switch(type) {
      case 'Line':
        setOptions({
          ...options, 
            chart: { 
              type: 'line',
            },
        })
      break
      case 'Bar':
        setOptions({
          ...options, 
            chart: { 
              type: 'bar',
            },
        })
      break
      case 'Area':
        setOptions({
          ...options, 
            chart: { 
              type: 'area',
            },
        })
      break
    }
  }, [type])

  const typeChange = (event: SelectChangeEvent): void => {
    setType(event.target.value as string)
  }

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.box}>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.caption}
          >
            {title ? title : `Projected staffing needs ${merchant ? ' / ' + merchant : ' Global'}`}
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={15}
                color={'secondary'}
              />
            )}
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={type}
              onChange={typeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {types.map(
                (curr: string): JSX.Element => (
                  <MenuItem key={`base-select-${curr}`} value={curr}>
                    {curr}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        <Chart
          options={options}
          series={series}
          type={type === 'Line' ? 'line' : type === 'Bar' ? 'bar' : 'area'}
          height="400"
        />
        <Download data={getCSV(data)} />
      </Paper>
    </Grid>
  )
}
